import React, { useState, useEffect } from "react";
// import { ThreeDots } from 'react-loader-spinner';
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";
import axiosInstance from "../../../axiosInstance";
import moment from "moment";
function LatestViewPage() {
  const [loading, setLoading] = useState(true);

  const [allNews, setAllNews] = useState([]);
  const [latestNews, setLatestNews] = useState([]);
  const _techId = localStorage.getItem("teccenternewsTokenID");
  const _techAchievementId = localStorage.getItem("achievementID");

  useEffect(() => {
    axiosInstance({
      url: `/newsAnnouncements/getNewsAnnouncementById/${_techId}`,
    })
      .then((response) => {
        setLatestNews(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      {loading && <LoadingOverlay />}
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className=" is-active">
                  TEC Center News
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>
      {/* ========================= */}

      <section className="news-view-detail-main pad-sec-50">
        <div className="container">
          <div className="main-heading-for-internalpage position-relative">
            <h2>Latest News </h2>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="main-tabingof-announcement w-100">
                {/* <ul className="nav nav-tabing-events d-flex justify-content-center" role="tablist">
                  <li className="nav-item button-clik-change">
                    <a href="#newslatest" role="tab" data-toggle="tab" className="nav-link active">
                      Latest News
                    </a>
                  </li>
                
                </ul> */}
                <div className="tab-content">
                  {loading ? (
                    <div className="loader-container">
                      {/* <ThreeDots
                        color="#F23F2C"
                        height={80}
                        width={80}
                        className="pt-4"
                      />
                      <h2>Please wait while data is loading....</h2> */}
                    </div>
                  ) : (
                    <>
                      <div
                        className="tab-pane active mt-3"
                        role="tabpanel"
                        id="newslatest"
                      >
                        <div className="row">
                          <div className="col-lg-7">
                            <div className="left-for-detailsnews">
                              {latestNews.title && <h3>{latestNews.title}</h3>}
                              <div className="d-flex">
                                {latestNews?.date && (
                                  <span className="whois-posted mb-2">
                                    <i
                                      className="fa fa-user"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Admin{" "}
                                    <i
                                      className="fa fa-calendar"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    {latestNews.date.substring(0, 10)}
                                  </span>
                                )}
                              </div>
                              {latestNews?.image && (
                                <img
                                  src={latestNews.image}
                                  className="w-100 mt-2 mb-3"
                                  alt="Latest News"
                                />
                              )}
                              {latestNews?.description && (
                                <p>{latestNews.description}</p>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-5">
                            {allNews.map((item) => (
                              <div
                                className="right-new-onclick mb-4"
                                key={item._id}
                              >
                                <div className="news-boxclick d-flex justify-content-between align-items-start pointer">
                                  <div className="image-slidernews w-25">
                                    <img
                                      src={item.image}
                                      className="w-100"
                                      alt="News"
                                    />
                                  </div>
                                  <div className="content-new-slide w-75 ml-3">
                                    <p>{item.title}</p>
                                    <span className="notify-datetime ml-2">
                                      {moment(item.date).format("DD/MM/YYYY")}
                                    </span>
                                    <p>
                                      {item.description.substring(0, 70)}...{" "}
                                      <span>Read More</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default LatestViewPage;
