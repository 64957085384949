import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";

import moment from "moment";

import Pics from "../pictures/pic";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "./indexes.css";

import axiosInstance from "../../../axiosInstance";
// import { ThreeDots } from "react-loader-spinner";
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";
import blankImage from "../../../assests/blankImage.jpg";
import "../../../assests/css/responsive.css";

function Index() {
  let limit = 10;

  const [bannersdata, setBannersData] = useState({
    clicable: false,
    redirectUrl: "",
  });

  const [categorylist, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [achievementdata, setAchievementData] = useState([]);
  const [data, setData] = useState([]);
  const [announcemnet, setAnnouncement] = useState([]);
  const [eventdata, setEventData] = useState([]);
  const [latestnews, setLatestNews] = useState([]);
  const [researchloading, setResearchLoading] = useState(true);
  const [media, setMedia] = useState([]);
  const [gallery, setGallery] = useState([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [tecceneter, setTeccenter] = useState([]);

  useEffect(() => {
    const getBannerData = async () => {
      try {
        const response = await axiosInstance.post("/banners/getAll", {
          filter: {
            status: 1,
            isDeleted: false,
          },
        });
        setBannersData(response.data.bannerData);
      } catch (error) {
        console.log("error", error);
      }
    };
    getBannerData();
  }, []);

  useEffect(() => {
    const getTecCenter = async () => {
      try {
        const response = await axiosInstance.post(`/technicalCentres/getAll`, {
          filter: {
            status: true,
          },
        });
        setTeccenter(response.data.items);
        setResearchLoading(false);
      } catch (error) {
        setResearchLoading(false);
      }
    };
    getTecCenter();
  }, []);
  useEffect(() => {
    const getCategoryData = async () => {
      try {
        const response = await axiosInstance.get(
          "/subCategories/getAllSubCategoryByCategoryId/64d9d3911cae26c449f95fa4"
        );
        if (response.data && Array.isArray(response.data.data)) {
          setCategoryList(response.data.data);
        } else {
          setCategoryList([]);
        }
      } catch (error) {}
    };
    getCategoryData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axiosInstance.get(
          `/technologyTransfers/getAllTechnologyTransfer?status=Reject`
        );

        const filteredData = response.data.data.items.reverse().slice(0, 4);

        setData(filteredData);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch data:", error);
        setLoading(false);
      }
    };
    getData();

    setTimeout(() => {
      setLoading(false);
    }, 10000);
  }, [limit]);

  useEffect(() => {
    const getAchievementData = async () => {
      try {
        const response = await axiosInstance.get(
          "/achievements/getAllAchievementForUser"
        );
        setAchievementData(response.data.items);
      } catch (error) {
        console.log("error", error);
      }
    };
    getAchievementData();
  }, []);
  useEffect(() => {
    const getEventData = async () => {
      try {
        const response = await axiosInstance.post(`/events/getAll`, {
          filter: {
            status: 1,
          },
        });
        setEventData(response.data.items);
      } catch (error) {
        console.log("error fetching event data ", error);
      }
    };
    getEventData();
  }, []);

  useEffect(() => {
    const getLatestNewsData = async () => {
      try {
        const response = await axiosInstance.get(
          "/newsAnnouncements/getAllNewsForUser"
        );
        const sortedNews = response.data.items.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );

        setLatestNews(sortedNews);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    getLatestNewsData();
  }, []);

  useEffect(() => {
    const getAnnouncementData = async () => {
      try {
        const response = await axiosInstance.get(
          "/newsAnnouncements/getAllAnnouncement"
        );
        setAnnouncement(response.data.items);
      } catch (error) {}
    };
    getAnnouncementData();
  }, []);

  useEffect(() => {
    const getMediaData = async () => {
      try {
        const response = await axiosInstance.post(`tecpuMedia/getAll`, {
          filter: {
            status: 1,
          },
        });

        setMedia(response.data.items);
      } catch (error) {
        console.log("media error", error);
      }
    };
    getMediaData();
  }, []);

  useEffect(() => {
    const getGalleryData = async () => {
      try {
        const response = await axiosInstance.post("/galleries/getAll", {
          filter: {
            status: 1,
          },
        });
        setGallery(response.data.items);
      } catch (error) {}
    };
    getGalleryData();
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const goToNextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % gallery.length);
  };

  const goToPreviousImage = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + gallery.length) % gallery.length
    );
  };

  const tecCenterHandleClick = (card_id, nameOfUniversity) => {
    localStorage.setItem("TecCenterID", card_id);
    sessionStorage.setItem("teccenter", nameOfUniversity);
    navigate("/data-center-details");
  };

  const handleBannersClick = (item) => {
    if (item.clicable) {
      navigate(item.redirectUrl);
    }
  };

  const settings_achievemnets = {
    infinite: true,
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1008,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settings_banner = {
    infinite: true,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };
  const settings_category = {
    infinite: true,
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1008,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settings_readiness = {
    infinite: true,
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1008,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings_research = {
    infinite: true,
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1008,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const navigate = useNavigate();

  // const featuredTechnologiesHandleClick = (card_id) => {
  //   sessionStorage.setItem("techTokenID", card_id);
  //   // setSelectedTechId(card_id);
  //   navigate("/tech-trensfer-detail-page");
  // };
  const featuredTechnologiesHandleClick = (card_id) => {
    localStorage.setItem("techTokenID", card_id);
    navigate("/tech-trensfer-detail-page", );
  };
  const AchievementHandleClick = (card_id, title) => {
    localStorage.setItem("achievementID", card_id);
    localStorage.setItem("achievementtitle", title);
    navigate("/achivement-detail");
  };
  const onlineEventsHandleClick = (onlineEvents_id) => {
    localStorage.setItem("onlineEvents_id", onlineEvents_id);

    navigate("/online-event-detail");
  };
  const handleSubCategoryTecClick = (categoryId, categoryName) => {
    localStorage.setItem("TecsubcaretogoryTokenID", categoryId);
    localStorage.setItem("TecsubcaretogoryName", categoryName);

    navigate("/tec-category-listing");
  };

  const handleBannerClick = (card_id) => {
    navigate("/banner-detail");
  };

  const latestNewsHandleClick = (latestNews_id, title) => {
    localStorage.setItem("latestNews_id", latestNews_id);
    localStorage.setItem("selectedPostTitle", title);
    // localStorage.removeItem('latestAnnouncement_id')
    localStorage.removeItem("eventname", "Event");
    navigate("/teccenter-news");
  };

  const latestAnnouncementsHandleClick = (latestAnnouncement_id, title) => {
    localStorage.setItem("latestAnnouncement_id", latestAnnouncement_id);
    localStorage.setItem("selectedPostTitle", title);
    // localStorage.removeItem('latestNews_id')
    localStorage.setItem("eventname", "Event");
    navigate("/teccenter-news");
  };

  const newsAnnouncementsViewAll = () => {
    navigate("/teccenter-news");
  };
  const galleryHandleClick = (_id, title) => {
    sessionStorage.setItem("galleryId", _id);
    sessionStorage.setItem("galleryTitle", title);
    navigate("/gallery");
  };
  const handleSubCategoryClick = (card_id, categoryName) => {
    localStorage.setItem("indussubcaretogoryTokenID", card_id);
    localStorage.setItem("indussubcaretogoryName", categoryName);

    navigate("/category_listing");
  };
  const tecpuMediaViewAll = (media_id, title) => {
    localStorage.setItem("latestMedia_id", media_id);
    localStorage.setItem("latestMedia_title", title);
    navigate("/tecup-media");
  };

  console.log('data', data)

  return (
    <>
      {loading && <LoadingOverlay />}
      <main id="maincontent">
        <section id="banner-crousel">
          {loading ? (
            <div className="loader-container">
              {/* <ThreeDots
                color="#F23F2C"
                height={80}
                width={80}
                className="pt-4"
              />
              <h2>Please wait while data is loading....</h2> */}
            </div>
          ) : bannersdata && bannersdata?.length > 0 ? (
            <div className="imgslider">
              <Slider {...settings_banner} className="banner-slider-top">
                {bannersdata.map((item) => (
                  // <div key={item._id} onClick={() => handleBannerClick(item._id)}>
                  <div key={item._id} onClick={() => handleBannersClick(item)}>
                    <img src={item.image} alt={item.alt} className="w-100" />
                  </div>
                ))}
              </Slider>
            </div>
          ) : (
            <div className="no-record-found text-center">
              <p>No records found.</p>
            </div>
          )}
        </section>

        <section className="welcome-main-tecpu pad-sec-50">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-sm-11">
                <div className="welcome-content">
                  <h2>Welcome to the Ecosystem for Technology Development</h2>
                  <p>
                    We are a platform that connects researchers, industry
                    partners, and government agencies to promote innovation and
                    collaboration. We believe that by bringing together these
                    three key stakeholders, we can accelerate the
                    commercialization of new technologies and create new jobs.
                    Whether you are a researcher looking for funding or
                    collaborators, an industry partner looking for new research
                    opportunities, or a government agency looking to promote
                    innovation, we have something for you. We hope you enjoy
                    browsing our website and finding the resources that you
                    need. We hope you enjoy browsing our website and finding the
                    resources that you need.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Featured Technologies-start --> */}
        <section className="featured-tech-main pad-sec-50">
          <div className="main-heading-sec position-relative">
            <h2>Featured Technologies</h2>
          </div>
          <div className="container">
            {/* <!-------------> */}
            <div className="row category_btn w-75 mx-auto mb-3">
              <div className="col-lg-12 col-md-12 col-sm-12">
                {categorylist && categorylist.length > 0 ? (
                  <Slider {...settings_category}>
                    {categorylist.map((item, index) => (
                      <div key={index} className="nav-item">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a
                          className="nav-link active"
                          onClick={() =>
                            handleSubCategoryTecClick(item._id, item.name)
                          }
                          role="tab"
                        >
                          {item.name}
                        </a>
                      </div>
                    ))}
                  </Slider>
                ) : (
                  <div className="no-record-found text-center">
                    <p>No Category record found.</p>
                  </div>
                )}
              </div>
            </div>
            {/* start here */}
            {loading ? (
              <div className="loader-container">
              
              </div>
            ) : data && data.length > 0 ? (
              <div className="row">
                {data.map((item, index) => (
                  <div
                    key={item._id}
                    className="col-lg-3 col-md-6 col-sm-12 d-flex"
                  >
                    <div className="featured-boxes">
                      <div className="bg-sec-inner">
                        <div className="inner-fetured-cont">
                          <h3>{item.title}</h3>
                          <p
                            dangerouslySetInnerHTML={{ __html: item.briefNote }}
                          ></p>
                        </div>
                        <div className="image-part-featured">
                          <img
                            src={item.images[0] ? item.images[0] : blankImage}
                            className="rounded"
                            alt=""
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <div className="box-content-big">
                            <div className="view-details-btn text-center">
                              <button
                                onClick={() =>
                                  featuredTechnologiesHandleClick(item._id)
                                }
                                type="button"
                              >
                                View Details
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="no-record-found text-center">
                <p>No records found.</p>
              </div>
            )}

            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="text-center pt-5 space-on-mob">
                  <Link to="/technology">
                    {" "}
                    <button type="button" className="btn-viewall">
                      View Details
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* industrial problem  */}
        <section
          className="project-fatured-main pad-sec-50"
          id="industrialproblem"
        >
          <div className="main-heading-sec position-relative">
            <h2> Industrial Problems</h2>
          </div>
          <div className="container">
            <div className="row category_btn w-75 mx-auto mb-2">
              <div className="col-lg-12 col-md-12 col-sm-12">
                {categorylist && categorylist.length > 0 ? (
                  <Slider {...settings_category}>
                    {categorylist.map((item) => {
                      return (
                        <>
                          <div key={item._id} className="nav-item">
                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                            <a
                              className="nav-link active"
                              onClick={() =>
                                handleSubCategoryClick(item._id, item.name)
                              }
                              role="tab"
                            >
                              {item.name}
                            </a>
                          </div>
                        </>
                      );
                    })}
                  </Slider>
                ) : (
                  <div className="no-record-found text-center">
                    <p>No category record found.</p>
                  </div>
                )}
              </div>
            </div>
            <Pics />
          </div>
        </section>

        {/* <!-- our-vision-start --> */}
        {/* <section className="our-vision-main pad-sec-50">
          <div className="main-heading-sec text-center position-relative">
            <h2> Our Vision</h2>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-12 d-flex">
                <div className="vision-box-list d-flex align-items-center listdata-shadow">
                  <div className="left-content-vision">
                    <img src={vision1} />
                  </div>
                  <div className="rigt-content-vision">
                    <h3>Launch Innovative Ideas</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 d-flex">
                <div className="vision-box-list d-flex align-items-center listdata-shadow">
                  <div className="left-content-vision">
                    <img src={vision2} />
                  </div>
                  <div className="rigt-content-vision">
                    <h3>Technology Showcase</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 d-flex">
                <div className="vision-box-list d-flex align-items-center listdata-shadow">
                  <div className="left-content-vision">
                    <img src={vision3} />
                  </div>
                  <div className="rigt-content-vision">
                    <h3>Access Consulting Service</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 d-flex">
                <div className="vision-box-list d-flex align-items-center listdata-shadow">
                  <div className="left-content-vision">
                    <img src={vision4} />
                  </div>
                  <div className="rigt-content-vision">
                    <h3>Engage in Programs</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <!-- our-vision-End --> */}

        {/* <!-- upcoming-events-start --> */}
        <section className="upcoming-news-devider pad-sec-50">
          <div className="container">
            <div className="upcoming-both-tabs">
              <div className="row d-flex justify-content-between">
                <div className="col-lg-5 col-md-6 col-sm-12">
                  <div className="main-inner-upcoming">
                    <div className="upcoming-main">
                      <h2>Upcoming Events</h2>
                    </div>
                    <div className="scroll-inner-part">
                      {eventdata && eventdata.length > 0 ? (
                        eventdata.map((item) => (
                          <div
                            className={"pointer"}
                            key={item._id}
                            onClick={() => onlineEventsHandleClick(item._id)}
                          >
                            <div className="inner-upcoming-box">
                              <img src={item.image} alt={`Event ${item.id}`} />
                              <div className="daten-months-event">
                                <ul>
                                  <li>
                                    <span className="number-of-date">
                                      {item.eventDate}
                                    </span>
                                  </li>
                                  <li>
                                    <p>{item.title}</p>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="no-record-found">
                          <p>No upcoming events found.</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 ">
                  <div className="main-tabingof-announcement">
                    <ul className="nav nav-tabing-events" role="tablist">
                      <li className="nav-item button-clik-change">
                        <a
                          href="#info"
                          role="tab"
                          data-toggle="tab"
                          className="nav-link  active"
                        >
                          Latest News
                        </a>
                      </li>
                      <li className="nav-item button-clik-change">
                        <a
                          href="#ratings"
                          role="tab"
                          data-toggle="tab"
                          className="nav-link "
                        >
                          Event
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div
                        className="tab-pane active tab-line-more"
                        role="tabpanel"
                        id="info"
                      >
                        {latestnews && latestnews.length > 0 ? (
                          <ul>
                            {latestnews
                              .slice()
                              .reverse()
                              .map((item) => (
                                <div
                                  onClick={() =>
                                    latestNewsHandleClick(item._id, item.title)
                                  }
                                  key={item._id}
                                  className="pointer"
                                >
                                  <li>
                                    {item.title}
                                    <br></br>
                                    <span className="notify-datetime ml-2">
                                      {moment(item.date).format("DD/MM/YYYY")}
                                    </span>
                                  </li>
                                </div>
                              ))}
                          </ul>
                        ) : (
                          <div className="no-record-found">
                            <p>No latest news found.</p>
                          </div>
                        )}
                      </div>
                      <div
                        className="tab-pane tab-line-more"
                        role="tabpanel"
                        id="ratings"
                      >
                        {announcemnet && announcemnet.length > 0 ? (
                          <ul>
                            {announcemnet
                              .slice()
                              .reverse()
                              .map((item) => (
                                <div
                                  onClick={() =>
                                    latestAnnouncementsHandleClick(
                                      item._id,
                                      item.title
                                    )
                                  }
                                  key={item._id}
                                  className="pointer"
                                >
                                  <li>
                                    {item.title}
                                    <br></br>
                                    <span className="notify-datetime ml-2">
                                      {moment(item.date).format("DD/MM/YYYY")}
                                    </span>{" "}
                                  </li>
                                </div>
                              ))}
                          </ul>
                        ) : (
                          <div className="no-record-found">
                            <p>No Announcement found.</p>
                          </div>
                        )}
                      </div>
                      <div className="view-details-btn">
                        <button
                          type="button"
                          onClick={newsAnnouncementsViewAll}
                        >
                          View All
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- upcoming-events-End --> */}

        {/* <!-- Reserch-and-innovation-start --> */}
        <section className="research-innovation-main pad-sec-50">
          <div className="main-head-reserch">
            <h2>Nationwide TEC Centers</h2>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-12 pt-3">
                <div id="reserchinovation" className="">
                  {researchloading ? (
                    <div className="loader-container">
                      {/* <ThreeDots color="#F23F2C" height={80} width={80} />
                      <h2>Please wait while data is Loading ....</h2> */}
                    </div>
                  ) : (
                    <div className="imgslider">
                      {tecceneter && tecceneter.length > 0 ? (
                        tecceneter.length === 1 ? (
                          <div
                            className="reserch-boxs pointer"
                            style={{ width: "328px", margin: "0 auto" }}
                            onClick={() =>
                              tecCenterHandleClick(
                                tecceneter[0]._id,
                                tecceneter[0].nameOfUniversity
                              )
                            }
                          >
                            <div className="reserch-image-box">
                              <img
                                src={
                                  tecceneter[0].image
                                    ? tecceneter[0].image
                                    : blankImage
                                }
                                alt=""
                              />
                            </div>
                            <div className="reserch-box-contentw">
                              <p>{tecceneter[0].nameOfUniversity}</p>
                              <p>{tecceneter[0].yearOfEstablishment}</p>
                            </div>
                          </div>
                        ) : tecceneter.length > 3 ? (
                          <Slider {...settings_research}>
                            {tecceneter.map((item) => (
                              <div
                                className="reserch-boxs pointer"
                                key={item._id}
                                onClick={() =>
                                  tecCenterHandleClick(
                                    item._id,
                                    item.nameOfUniversity
                                  )
                                }
                              >
                                <div className="reserch-image-box">
                                  <img
                                    src={item.image ? item.image : blankImage}
                                    alt=""
                                  />
                                </div>
                                <div className="reserch-box-contentw">
                                  <p>{item.nameOfUniversity}</p>
                                  <p>{item.yearOfEstablishment}</p>
                                </div>
                              </div>
                            ))}
                          </Slider>
                        ) : (
                          <div className="d-flex">
                            {tecceneter.map((item) => (
                              <div
                                className="reserch-boxs pointer"
                                style={{ width: "328px", margin: "0 auto" }}
                                key={item._id}
                                onClick={() =>
                                  tecCenterHandleClick(item._id, item.title)
                                }
                              >
                                <div className="reserch-image-box">
                                  <img
                                    src={item.image ? item.image : blankImage}
                                    alt=""
                                  />
                                </div>
                                <div className="reserch-box-contentw">
                                  <p>{item.nameOfUniversity}</p>
                                  <p>{item.yearOfEstablishment}</p>
                                </div>
                              </div>
                            ))}
                          </div>
                        )
                      ) : (
                        <div className="no-record-found text-center">
                          <p style={{ color: "white" }}>Data not found.</p>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- tecpu-media-start --> */}
        <section className="media-of-tecpu pad-sec-50">
          <div className="main-heading-sec position-relative">
            <h2>TEC Achievements</h2>
            {/* <a href="/about-achievements">View all <i className="fa fa-arrow-right" aria-hidden="true"></i></a> */}
          </div>
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <div
                  className="carousel"
                  style={{ width: "100%" }}
                  id="achivementdata"
                >
                  {loading ? (
                    <div className="loader-container">
                      {/* <ThreeDots color="#F23F2C" height={80} width={80} />
                      <h2>Please wait while data is Loading ....</h2> */}
                    </div>
                  ) : (
                    <div className="imgslider">
                      {achievementdata && achievementdata.length > 0 ? (
                        achievementdata.length === 1 ? (
                          <div
                            className="fatured-boxs"
                            key={achievementdata._id}
                            style={{ width: "328px", margin: "0 auto" }}
                            onClick={() =>
                              AchievementHandleClick(
                                achievementdata[0]._id,
                                achievementdata[0].title
                              )
                            }
                          >
                            <div className="imagepart-fatured">
                              <img
                                src={
                                  achievementdata[0].image
                                    ? achievementdata[0].image
                                    : blankImage
                                }
                                alt="Project"
                                className="w-100 rounded"
                              />
                            </div>

                            <div className="content-project-box text-center">
                              {/* <p>{achievementdata[0].title}</p> */}
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: achievementdata[0].title,
                                }}
                              ></p>
                              {/* <p>{achievementdata[0].description}</p> */}
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: achievementdata[0].description,
                                }}
                              ></p>
                            </div>
                            <div className="view-details-btn text-center">
                              {/*<button type="button">View Details</button>*/}
                              <button
                                onClick={() =>
                                  AchievementHandleClick(
                                    achievementdata[0]._id,
                                    achievementdata[0].title
                                  )
                                }
                                type="button"
                              >
                                Read More
                              </button>
                            </div>
                          </div>
                        ) : achievementdata.length > 3 ? (
                          <Slider {...settings_achievemnets}>
                            {achievementdata.map((item) => (
                              <div
                                className="fatured-boxs"
                                key={item._id}
                                style={{ width: "328px", margin: "0 auto" }}
                                onClick={() =>
                                  AchievementHandleClick(item._id, item.title)
                                }
                              >
                                <div className="imagepart-fatured">
                                  {/* <img src={item.image ? item.image : blankImage} alt="Item Image" /> */}
                                  <img
                                    src={item.image ? item.image : blankImage}
                                    alt="Project"
                                    className="w-100 rounded"
                                  />
                                </div>
                                <div className="content-project-box text-center">
                                  {/* <p>{item.title}</p> */}
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: item.title,
                                    }}
                                  ></p>
                                  {/* <p>{item.description}</p> */}
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: item.description,
                                    }}
                                  ></p>
                                </div>
                                <div className="view-details-btn text-center">
                                  {/*<button type="button">View Details</button>*/}
                                  <button
                                    onClick={() =>
                                      AchievementHandleClick(
                                        item._id,
                                        item.title
                                      )
                                    }
                                    type="button"
                                  >
                                    Read More
                                  </button>
                                </div>
                              </div>
                            ))}
                          </Slider>
                        ) : (
                          <div className="d-flex">
                            {achievementdata.map((item) => (
                              <div
                                className="imagepart-fatured"
                                style={{ width: "328px", margin: "0 auto" }}
                                key={item._id}
                                onClick={() => tecCenterHandleClick(item._id)}
                              >
                                <div className="reserch-image-box">
                                  <img
                                    src={item.image ? item.image : blankImage}
                                    alt=""
                                  />
                                </div>
                                <div className="content-project-box text-center">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: item.title,
                                    }}
                                  ></p>
                                  {/* <p>{item.title}</p> */}
                                  {/* <p>{item.description}</p> */}
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: item.description,
                                    }}
                                  ></p>
                                </div>
                              </div>
                            ))}
                          </div>
                        )
                      ) : (
                        <div className="no-record-found text-center">
                          <p style={{ color: "white" }}>Data not found.</p>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- tecpu-media-End --> */}
        {/* <!-- Gallery and campus-start --> */}
        <section className="gallery-main-campus pad-sec-50 b-shadow">
          <div className="container">
            <div className="main-gallery-start">
              <div className="row d-flex align-items-strech">
                <div className="col-lg-6 col-md-12 col-sm-12 border-right">
                  <div className="techpumediares">
                    <div className="media-box-devide d-flex w-100 justify-content-between align-items-center ">
                      <h2>PU-TEC Media</h2>
                      <a href="/tecup-media">
                        View all{" "}
                        <i className="fa fa-arrow-right" aria-hidden="true"></i>
                      </a>
                    </div>
                    {media && media.length > 0 ? (
                      media
                        .slice(-2)
                        .reverse()
                        .map((item) => (
                          <div
                            key={item._id}
                            className="media-inner-content d-flex justify-content-between"
                          >
                            <div className="card-img w-50">
                              <img src={item.image[0]} alt="" />
                            </div>
                            <div className="content-brief-media ml-4 w-50">
                              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                              <a href="javascript: void(0)">{item.title}</a>
                              <h4 className="date-of-media">
                                {moment(item.date).format("DD/MM/YYYY")}
                              </h4>
                              <div className="view-details-btn">
                                <button
                                  type="button"
                                  onClick={() =>
                                    tecpuMediaViewAll(item._id, item.title)
                                  }
                                >
                                  View Details
                                </button>
                                {/* <button onClick={() => AchievementHandleClick(item._id)} type="button" >Read More</button> */}

                                {/* </a> */}
                              </div>
                            </div>
                          </div>
                        ))
                    ) : (
                      <div className="no-record-found text-center">
                        <p>No media data found.</p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 ">
                  <div className="media-box-devide d-flex w-100 justify-content-between align-items-center">
                    <h2>See Our Gallery</h2>
                    <a href="/gallery" onClick={openModal}>
                      View all{" "}
                      <i className="fa fa-arrow-right" aria-hidden="true"></i>
                    </a>
                  </div>

                  <div className="homePageGrid">
                    {gallery && gallery.length > 0 ? (
                      gallery
                        .slice(-4)
                        .reverse()
                        .map((item) => (
                          <div
                            className="gridImage"
                            key={item._id}
                            onClick={() =>
                              galleryHandleClick(item._id, item.title)
                            }
                          >
                            <img src={item["image"][0]} alt="" />
                          </div>
                        ))
                    ) : (
                      <div className="no-record-found text-center">
                        <p>Gallery data not found.</p>
                      </div>
                    )}
                  </div>
                  {/* <Modal isOpen={isModalOpen} onRequestClose={closeModal} style={{ content: { width: '800px', height: '500px', inset: '0px', margin: 'auto' } }}>
                   
                    {gallery.length > 0 && (
                      <div className='modalImageContainer'>
                        <img src={gallery[currentIndex].image} alt={gallery[currentIndex].title} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                      </div>
                    )}
                    <button className="arrow-button left" onClick={goToPreviousImage}>
                      <i className="fa fa-arrow-left"></i>
                    </button>
                    <button className="arrow-button right" onClick={goToNextImage}>
                      <i className="fa fa-arrow-right"></i>
                    </button>
                    <button className="close-button" onClick={closeModal}>
                      <i className="fa fa-times"></i>
                    </button>
                  </Modal> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- Gallery and campus-End --> */}

        {/* <!-- Chat-boat-contact-us-start -->  */}
      </main>
    </>
  );
}

export default Index;
