import React, { useEffect, useState } from "react";
import validate from "./validation";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Techno from "../../../assests/user/user-images/technofor-login.png";
import "react-phone-input-2/lib/style.css";
import axiosInstance from "../../../axiosInstance";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function Register(props) {
  const navigate = useNavigate();
  const [error, setError] = useState({});
  const [account, setAccount] = useState([]);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [checkdata, setCheckData] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [formdata, setFormdata] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    mobile: "",
    password: "",
    confirmPassword: "",
    data: "",
  });
  const handleInputKeyUp = (fieldName) => {
    setError((prevError) => ({
      ...prevError,
      [fieldName]: "",
    }));
  };

  const handleConfirmPasswordKeyUp = () => {
    const { password, confirmPassword } = formdata;
    setPasswordsMatch(password === confirmPassword);
  };
  const handleDisabledButtonClick = () => {
    // toast.error(' Please fill in all required fields.');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "data") {
      setError((prevError) => ({
        ...prevError,
        [name]: "",
      }));
    }
    if (name === "mobile" && value.length > 25) {
      return;
    }
    setFormdata({
      ...formdata,
      [name]: value,
    });

    setIsButtonDisabled(!areAllFieldsFilled());
  };

  useEffect(() => {
    const fetchAccountData = async () => {
      try {
        const response = await axiosInstance.get("/userTypes/getAll");
        setAccount(response.data);
      } catch (error) {
        setAccount([]);
      }
    };
    fetchAccountData();
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleCheck = (e) => {
    const isChecked = e.target.checked;
    setCheckData(isChecked);
  };

  const areAllFieldsFilled = () => {
    const {
      firstName,
      lastName,
      email,
      mobile,
      password,
      confirmPassword,
      data,
    } = formdata;
    return (
      firstName &&
      lastName &&
      email &&
      mobile &&
      password &&
      confirmPassword &&
      data &&
      checkdata
    );
  };
  const onChange = (value) => {
    console.log("Captcha value:", value);
  };

  const handleInputBlur = (fieldName) => {
    const { data } = formdata;

    if (fieldName === "data") {
      if (data === "") {
        setError((prevError) => ({
          ...prevError,
          [fieldName]: "Please select an account type",
        }));
      } else {
        setError((prevError) => ({
          ...prevError,
          [fieldName]: "",
        }));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!checkdata) {
      toast.error("Please check the captcha before submitting.");
      return;
    }
    const validationErrors = validate(
      formdata.firstName,
      formdata.lastName,
      formdata.email,
      formdata.mobile,
      formdata.password,
      formdata.confirmPassword,
      formdata.data
    );

    if (Object.keys(validationErrors).length > 0) {
      setError(validationErrors);
      toast.error("Please fill in all required fields.");
      return;
    }

    try {
      const payload = {
        firstName: formdata.firstName,
        lastName: formdata.lastName,
        email: formdata.email,
        mobileNo: formdata.mobile,
        password: formdata.password,
        confirmPassword: formdata.confirmPassword,
        userTypeId: formdata.data,
        agreeTermsCondition: checkdata,
      };

      const response = await axiosInstance.post("/users/register", payload);

      if (response.data.status) {
        toast.success("Registration is successful!");
        localStorage.setItem("token", response?.data?.user?.token);
        localStorage.setItem("userId", response?.data?.user?._id);
        localStorage.setItem("accounttype", response?.data?.user?.userTypeId);

        props.onSubmit(formdata.firstName);

        setTimeout(() => {
          navigate("/user/dashboard");
        }, 1000);
      } else if (response?.data?.status === false) {
        if (
          response?.data?.message ===
          "Mobile number already exists in our system."
        ) {
          toast.error(
            "Mobile number already exists. Please use a different number."
          );
        } else if (
          response?.data?.message === "Email already exists in our system."
        ) {
          toast.error("Email already exists. Please use a different email.");
        } else {
          toast.error(` ${response.data?.message}`);
          console.error(` ${response.data?.message}`);
        }
      } else {
        toast.error(`Unexpected Error: Status Code ${response.status}`);
        console.error(`Unexpected Error: Status Code ${response.status}`);
      }
    } catch (error) {
      if (error.response && error.response.status !== 200) {
        console.error(
          "Error during registration:",
          error?.response?.data?.message
        );
        const errorMessage =
          error?.response?.data?.message ||
          "An error occurred during registration.";
        toast.error(errorMessage);
      }
    }
  };

  return (
    <>
      <div
        className="login-5 login-form-main register-ui-main pad-sec-50"
        id="loginpage"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="form-main-side position-relative register-set w-100">
                <div className="login-logo-box text-center">
                  <img src={Techno} alt="logo" />
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-md-6 col-12">
                      <div className="form-outline mb-4">
                        <label>First Name <sup>*</sup></label>
                        <input
                          type="text"
                          id="firstName"
                          name="firstName"
                          className="form-control"
                          placeholder="First Name"
                          value={formdata.firstName}
                          onChange={handleChange}
                          onKeyUp={() => handleInputKeyUp("firstName")}
                        />
                        {error.firstName && (
                          <p
                            style={{
                              color: "red",
                              textAlign: "start",
                              fontSize: "12px",
                              paddingTop: "4px",
                            }}
                          >
                            {error.firstName}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="form-outline mb-4">
                      <label>Middle Name</label>
                        <input
                          type="text"
                          id="middleName"
                          className="form-control"
                          name="middleName"
                          placeholder="Middle Name"
                          value={formdata.middleName}
                          onChange={handleChange}
                        />
                        {error.middleName && (
                          <p
                            style={{
                              color: "red",
                              textAlign: "start",
                              fontSize: "12px",
                              paddingTop: "4px",
                            }}
                          >
                            {error.lastName}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="form-outline mb-4">
                      <label>Last Name <sup>*</sup></label>
                        <input
                          type="text"
                          id="lastName"
                          className="form-control"
                          name="lastName"
                          placeholder="Last Name"
                          value={formdata.lastName}
                          onChange={handleChange}
                          onKeyUp={() => handleInputKeyUp("lastName")}
                        />
                        {error.lastName && (
                          <p
                            style={{
                              color: "red",
                              textAlign: "start",
                              fontSize: "12px",
                              paddingTop: "4px",
                            }}
                          >
                            {error.lastName}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="form-outline mb-4">
                      <label>Email <sup>*</sup></label>
                        <input
                          type="email"
                          id="email"
                          className="form-control"
                          name="email"
                          placeholder="Email address"
                          value={formdata.email}
                          onChange={handleChange}
                          onKeyUp={() => handleInputKeyUp("email")}
                        />
                        {error.email && (
                          <p
                            style={{
                              color: "red",
                              textAlign: "start",
                              fontSize: "12px",
                              paddingTop: "4px",
                            }}
                          >
                            {error.email}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="form-outline mb-4">
                      <label>Mobile <sup>*</sup></label>
                        <PhoneInput
                          country={"in"}
                          value={formdata.mobile}
                          max={25}
                          onChange={(value) =>
                            handleChange({ target: { name: "mobile", value } })
                          }
                          inputStyle={{ width: "100%", padding: "0px 50px" }}
                        />
                        {/* {error.mobile && (
      <p
        style={{
          color: "red",
          textAlign: "start",
          fontSize: "12px",
          paddingTop: "4px",
        }}
      >
        {error.mobile}
      </p>
    )} */}
                      </div>
                    </div>

                    <div className="col-md-6 col-12">
                      <div className="form-outline mb-4">
                      <label>Password <sup>*</sup></label>
                        <input
                          type={showPassword ? "text" : "password"}
                          id="password"
                          className="form-control"
                          name="password"
                          placeholder="Password"
                          value={formdata.password}
                          onChange={handleChange}
                          onKeyUp={() => handleInputKeyUp("password")}
                        />
                        <span
                          className="password-toggle-icon position-absolute"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? (
                            <i className="fa fa-eye-slash" />
                          ) : (
                            <i className="fa fa-eye" />
                          )}
                        </span>
                        {error.password && (
                          <p
                            style={{
                              color: "red",
                              textAlign: "start",
                              fontSize: "12px",
                              paddingTop: "4px",
                            }}
                          >
                            {error.password}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="form-outline mb-4">
                      <label>Confirm Password</label>
                        <input
                          type={showConfirmPassword ? "text" : "password"}
                          id="confirmPassword"
                          className="form-control"
                          name="confirmPassword"
                          placeholder=" Confirm Password"
                          value={formdata.confirmPassword}
                          onChange={handleChange}
                          onKeyUp={(e) => {
                            handleConfirmPasswordKeyUp(e);
                            handleInputKeyUp("confirmPassword");
                          }}
                        />
                        <span
                          className="password-toggle-icon position-relative"
                          onClick={toggleConfirmPasswordVisibility}
                        >
                          {showConfirmPassword ? (
                            <i className="fa fa-eye-slash" />
                          ) : (
                            <i className="fa fa-eye" />
                          )}
                        </span>
                        {!passwordsMatch && (
                          <p
                            style={{
                              color: "red",
                              textAlign: "start",
                              fontSize: "12px",
                              paddingTop: "4px",
                            }}
                          >
                            Passwords do not match.
                          </p>
                        )}

                        {error.confirmPassword && (
                          <p
                            style={{
                              color: "red",
                              textAlign: "start",
                              fontSize: "12px",
                              paddingTop: "4px",
                            }}
                          >
                            {error.confirmPassword}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 col-12">
                      <div className="form-outline mb-4">
                      <label>Select Account <sup>*</sup></label>
                        <select
                          id="data"
                          name="data"
                          className="form-control"
                          value={formdata.data}
                          onChange={handleChange}
                          onBlur={() => handleInputBlur("data")}
                        >
                          <option>Select Account Type</option>
                          {account.map((item) => (
                            <option key={item._id} value={item._id}>
                              {item.name}
                            </option>
                          ))}
                        </select>

                        {error.data && (
                          <p
                            style={{
                              color: "red",
                              textAlign: "start",
                              fontSize: "12px",
                              paddingTop: "4px",
                            }}
                          >
                            {error.data}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="d-flex align-item-center justify-content-center align-items-center">
                      <div className="col-md-6 col-12">
                        <div className="captcha">
                          <ReCAPTCHA
                            className="captcha-google"
                            sitekey="6Ld2r3opAAAAAD09JK4W9Sg6gGu4y7c6-5tlLFNe"
                            onChange={onChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-12">
                        <div className="form-outline mb-3 d-flex align-items-center ml-4  p-2">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="flexCheckDefault"
                            checked={checkdata}
                            onChange={handleCheck}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            I agree to the terms of service
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-12 mt-2">
                      <div className="sign-in-btn text-center pt-1  pb-1">
                        <button
                          className={`btn btn-block mb-3 ${
                            isButtonDisabled ? "disabled" : ""
                          }`}
                          id="registerbtn"
                          name="registerbtn"
                          onClick={
                            isButtonDisabled
                              ? handleDisabledButtonClick
                              : handleSubmit
                          }
                        >
                          Sign up
                        </button>
                      </div>
                    </div>

                    <div className="col-md-12 col-12">
                      <div className="d-flex justify-content-around allready-member">
                        <p className="mb-0 me-2">
                          Already a member? <a href="/login">Login here</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Register;
