import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Techno from '../../../assests/user/user-images/technofor-login.png';
import axiosInstance from '../../../axiosInstance';

function Login({setToken}) {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [captchaNum1, setCaptchaNum1] = useState(0);
  const [captchaNum2, setCaptchaNum2] = useState(0);
  const [captchaInput, setCaptchaInput] = useState('');
  const [userStatus, setUserStatus] = useState('');

  const [formdata, setFormdata] = useState({
    email: '',
    password: '',
  });

  const handlechange = (e) => {
    const { name, value } = e.target;
    setFormdata({
      ...formdata,
      [name]: value
    });
  }
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const areAllFieldsFilled = () => {
    const { email, password } = formdata;
    return email && password;
  };
  const handleRefreshCaptcha = () => {
    generateCaptcha();
  };
  const userId = localStorage.getItem('userId');
  const generateCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    setCaptchaNum1(num1);
    setCaptchaNum2(num2);
  };


  const formSubmit = async (e) => {
    e.preventDefault();
    setUserStatus('');
   
   
    let payload = {};
  
    if (!areAllFieldsFilled()) {
      toast.error('Email and password are required.');
      return;
    }
  
    if (!captchaInput) {
      toast.error('Please enter the captcha.');
      return;
    }
  
    try {
      const { email, password } = formdata;
  
      if (/\S+@\S+\.\S+/.test(email) || /^\d{10}$/.test(email)) {
        payload = { email, password, _id: userId };
      } else {
        toast.error('Please enter a valid email address or phone number.');
        return;
      }
     
      const expectedCaptchaResult = captchaNum1 + captchaNum2;
      const userCaptchaInput = parseInt(captchaInput, 10);
  
      if (userCaptchaInput !== expectedCaptchaResult) {
        toast.error('Captcha verification failed. Please try again.');
        return;
      }
  
      generateCaptcha();
  
      const response = await axiosInstance.post('/users/login', payload);
  
      if (response.data?.status) {
        const authToken = response.data.data.token;
        localStorage.setItem('token', authToken);
        localStorage.setItem('userId', response.data.data._id);
        localStorage.setItem('accounttype', response.data.data.userTypeId);
         localStorage.getItem("image", response.data.data.image);
        setUserStatus(response.data.data.status);
        setToken(authToken);
      } else if (
        response.data?.status === false &&
        response.data?.message === 'Invalid Credentials'
      ) {
        toast.error(
          'Invalid Credentials. Please check your email/phone and password.'
        );
      } else {
        toast.error(`Unexpected Error: Status Code ${response.status}`);
        console.error(`Unexpected Error: Status Code ${response.status}`);
      }
    } catch (error) {
      console.error('Error during Login:', error?.response?.data?.message);

      const errorMessage =
        error.response?.data?.message || 'An error occurred during login.';
      toast.error(` ${errorMessage}`);
    }
  };

  useEffect(() => {
    if (userStatus === 'Active') {
      toast.success('Login is successful');
  
      setTimeout(() => {
        navigate('/user/dashboard');
      }, 1000);
    } else if (userStatus === 'Inactive' ) {
      toast.error('Your account is Inactive.',{
        position: "top-right",
        autoClose: 5000, 
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      
     
    } else {
      navigate('/login');
    }
  
    
  }, [userStatus, navigate, ]);
  
  
  useEffect(() => {
    generateCaptcha();
  }, []);
  

  const handleRegister = () => {
    navigate('/signup')
  }
  return (
    <>
      <div className="login-5 login-form-main pad-sec-50" id="loginpage">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="form-main-side position-relative">
                <div className="login-logo-box text-center">
                  <img src={Techno} alt="logo" />
                </div>
                <form onSubmit={formSubmit} >
                  <div className="form-outline mb-4">
                    <input
                      type="text"
                      id="form2Example11"
                      className="form-control"
                      name="email"
                      placeholder="Email address or Phone number"
                      value={formdata.email}
                      onChange={handlechange}
                    />
                  </div>
                  <div className="form-outline mb-4">
                    <div className="password-input-container">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="form2Example22"
                        name="password"
                        className="form-control "
                        placeholder="Password"
                        value={formdata.password}
                        onChange={handlechange}
                      />
                      <span
                        className="password-toggle-icon position-relative"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? (
                          <i className="fa fa-eye-slash fapass fapss-lo" />
                        ) : (
                          <i className="fa fa-eye fapass fapss-lo" />
                        )}
                      </span>

                    </div>
                  </div>

                  <div className="captcha-container mb-4">
                    <div className="captcha-numbers-container">
                      <span className="captcha-numbers">
                        {captchaNum1} + {captchaNum2} =
                      </span>
                    </div>
                    <div className="captcha-input-refresh-container">
                      <input
                        type="text"
                        className="form-control captcha-input"
                        placeholder="Enter the sum"
                        value={captchaInput}
                        onChange={(e) => setCaptchaInput(e.target.value)}
                      />
                      <button
                        type="button"
                        className="btn btn-outline-primary btn-refresh-captcha"
                        onClick={handleRefreshCaptcha}
                      >
                        <i className="fa fa-refresh" />
                      </button>
                    </div>
                  </div>
                  <div className="sign-in-btn text-center pt-1 mb-4 pb-1">
                    <button className="btn btn-block mb-3" type="submit">
                      Login
                    </button>
                    <a className="text-muted" href={`/forget-password-link`}>
                      Forgot password?
                    </a>
                  </div>
                  <div className="d-flex align-items-center justify-content-around">
                    <p className="mb-0 me-2">Don't have an account?</p>
                    <button
                      type="button"
                      className="btn btn-outline-danger"
                      onClick={handleRegister}
                    >
                      Create new
                    </button>
                  </div>

                  {/* <div className="google-in-btn text-center pt-1 mb-4 pb-1">
                    <button className="btn btn-block mb-3" type="button">
                      <a href="https://www.google.com/" target="_blank" rel='noreferrer'>
                        <i className="fa fa-google" style={{ color: 'white', margin: '5px', padding: '2px' }}>
                          <span className='pl-2'> Continue with Google</span>
                        </i>
                      </a>
                    </button>
                  </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    <ToastContainer/>
    </>
  );
}

export default Login;