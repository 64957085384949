import React, { useState, useEffect, useCallback, useRef } from "react";
import axiosInstance from "../../../axiosInstance";
// import { ThreeDots } from 'react-loader-spinner';
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";

function Reports() {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [filteredTechList, setFilteredTechList] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const gridRef = useRef(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axiosInstance.get(
          "/reports/getAllReportForUser"
        );
        setData(response.data);
        setFilteredTechList(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
    };
    getData();
  }, []);

  // const handleSearchByName = (element) => {
  //     if (searchByName) {
  //         return element.title.toLowerCase().includes(searchByName.toLowerCase());
  //     } else return true;
  // };
  //     useEffect(() => {
  //         findFilteredTechList();
  //     }, [searchByName]);

  //     const findFilteredTechList = () => {
  //         setFilteredTechList(data.filter(element => handleSearchByName(element)));

  //     };
  // console.log('findFilteredTechList',filteredTechList)

  const columnDefs = [
    {
      headerName: "S.No.",
      valueGetter: (params) => params.node.rowIndex + 1 + ".",
      sortable: true,
      filter: true,
      width: 210,
    },

    {
      headerName: "Title",
      field: "title",
      sortable: true,
      filter: true,
      width: 360,
    },
    {
      headerName: "documents",
      field: "document",
      cellRenderer: (params) => (
        <a href={params?.data?.document} target="_blank">
          Click here
        </a>
      ),
      sortable: true,
      filter: true,
      width: 230,
    },
    {
      headerName: "Date",
      field: "achievementDate",
      valueFormatter: (params) => moment(params.value).format("YYYY-MM-DD"),
      sortable: true,
      filter: true,
      width: 310,
    },
  ];
  const handleSearch = () => {
    const filteredData = data.filter((item) =>
      item.title.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredTechList(filteredData);
  };

  const onGridReady = useCallback((params) => {
    setGridApi(params.api);
  });
  const onSearch = (searchText) => {
    if (gridApi) {
      gridApi.setQuickFilter(searchText);
    }
  };

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchText(value);
    onSearch(value);
  };

  return (
    <>
      {loading && <LoadingOverlay />}
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                <a href="javascript: void(0)" className=" is-active">
                  {" "}
                  Reports
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="funding-scheme-main pad-sec-30">
        <div className="container">
          {loading ? (
            <div className="loader-container">
              {/* <ThreeDots
                  color="#F23F2C"
                  height={80}
                  width={80}
                  className="pt-4"
                />
                <h2>Please wait while data is loading....</h2> */}
            </div>
          ) : (
            <div className="row">
              <div className="col-md-12 col-lg-12">
                <div className="table-shorting-main w-100">
                  <div className="shoring-n-search d-flex justify-content-between align-items-center w-100 mb-3">
                    <div className="shoring-content">
                      <p>
                        Showing 1 to {data.length} of {data.length} entries
                      </p>
                    </div>
                    <div className="search-icon">
                      <form className="form-inline my-2 my-lg-0">
                        <input
                          class="form-control mr-sm-2"
                          type="text"
                          placeholder="Search..."
                          value={searchText}
                          onChange={handleSearchChange}
                          aria-label="Search"
                        />
                        {/* <button onClick={handleSearch} className="btn btn-theme my-2 my-sm-0" type="button">
                                                    Search
                                                </button> */}
                      </form>
                    </div>
                  </div>
                  <div className="table-responsive">
                    <div
                      className="ag-theme-alpine"
                      style={{ height: "100%", width: "100%" }}
                    >
                      <AgGridReact
                        ref={gridRef}
                        rowData={filteredTechList}
                        columnDefs={columnDefs}
                        domLayout="autoHeight"
                        enableSorting={true}
                        enableFilter={true}
                        pagination={true}
                        paginationPageSize={10}
                        // overlayLoadingTemplate={
                        //     '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                        // }
                        // overlayNoRowsTemplate={
                        //     '<span>No rows to show</span>'
                        // }
                        onGridReady={onGridReady}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default Reports;
