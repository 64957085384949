import React from "react";

import mission from "../../../../assests/user/user-images/Mission.png";
import vision from "../../../../assests/user/user-images/vision.png";
import "./index.css"
function VisionMissionIndex() {
  return (
    <>
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className="breadcrumbs__item">
                  About Us
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className=" is-active">
                  Vision Mission
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>

      {/* =================================== */}
      <section className="vision-mission-main pad-sec-30">
        <div className="container">
          {/* <div className="internal-mainheadsec text-center mb-5"><h2>Vision Mission</h2>
               </div> */}
          <div className="row">
            <div className="col-12 col-md-4  col-lg-4 mb-2 d-flex">
              <img src={vision} className="visionimg-box rounded" alt="" />
            </div>

            <div className="col-12 col-md-8 col-lg-8 mb-2 d-flex">
              <div className="vision-box w-100">
                <div className="vision-box-content mt-4 mb-3">
                  <h3>Vision</h3>
                  <p className="">
                    To make our region fountainhead of technological solutions
                    for needs of society and industry{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-8 mb-2 d-flex">
              <div className="vision-box w-100">
                <div className="vision-box-content mt-4 mb-3">
                  <h3>Mission</h3>
                  <p>
                    Our mission is to bridge the gap between industry and
                    academia by assessing the technological requirements of
                    Micro, Small, and Medium Enterprises (MSMEs) in the
                    surrounding area. We aim to mine technologies being
                    developed at various regional labs and evaluate their
                    maturity, both in terms of products and processes.
                    Additionally, we strive to conduct market studies and
                    assessments to determine the market potential of these
                    technologies. Ultimately, our goal is to transfer these
                    ready technologies to industries for commercialization,
                    fostering innovation and driving economic growth.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4 mb-2 d-flex">
              <img src={mission} className="visionimg-box rounded" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default VisionMissionIndex;
