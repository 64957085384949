function Validation(
  firstName,
  lastName,
  email,
  mobile,
  password,
  confirmPassword,
  data
) {
  let error = {};
  const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const password_regex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

  if (firstName === "") {
    error.firstName = "First Name should not be empty";
  }

  if (lastName === "") {
    error.lastName = "Last name should not be empty";
  }

  if (email === "") {
    error.email = "Email should not be empty";
  } else if (!email_pattern.test(email)) {
    error.email = "Please Enter a valid email";
  }

  if (mobile === "") {
    error.mobile = "Mobile Number should not be empty";
  } else {
    // const formattedMobile = mobile.replace(/\D/g, '');
    // const countryCode = '91';
    // const mobileWithoutCountryCode = formattedMobile.startsWith(countryCode)
    //   ? formattedMobile.slice(countryCode.length)
    //   : formattedMobile;
    // if (mobileWithoutCountryCode.length !== 10) {
    //   error.mobile = "Mobile Number should have exactly 10 digits (excluding country code)";
    // }
  }

  if (password === "") {
    error.password = "Password should not be empty";
  } else if (!password_regex.test(password)) {
    error.password =
      "Password should be at least 8 characters and contain at least 1 upper case, 1 lower case, 1 digit, and 1 symbol";
  }

  if (confirmPassword === "") {
    error.confirmPassword = "Confirm Password should not be empty";
  } else if (confirmPassword !== password) {
    error.confirmPassword = "Passwords do not match";
  }

  if (data === "") {
    error.data = "Please select an account type";
  }

  return error;
}

export default Validation;
