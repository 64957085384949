import React from "react";

import objective from "../../../../assests/user/user-images/Objective.png";

import techpointsbox4 from "../../../../assests/user/user-images/tech-points-box-4.jpg";

import tech1 from "../../../../assests/user/user-images/1.jpg";
import tech2 from "../../../../assests/user/user-images/2.jpg";
import tech3 from "../../../../assests/user/user-images/3.png";

import tech5 from "../../../../assests/user/user-images/5.jpg";
import tech6 from "../../../../assests/user/user-images/6.jpg";
import tech7 from "../../../../assests/user/user-images/7.jpg";
import tech8 from "../../../../assests/user/user-images/8.jpg";
import tech9 from "../../../../assests/user/user-images/9.jpg";
import tech10 from "../../../../assests/user/user-images/10.jpg";
import "./index.css"

function Introduction() {
  return (
    <>
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className="breadcrumbs__item">
                  About Us
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className=" is-active">
                  Objective
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>
      {/*  =======objective=============  */}
      <section className="objective-inner-main pad-sec-50" id="aboutobjective">
        <div className="container">
          {/* <div className="main-heading-for-internalpage position-relative"><h2>Objective</h2></div> */}
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="objective-content-part">
                {/* <h2>Objective</h2> */}
                <p>
                  The objective of TEC is to create an Ecosystem for Technology
                  Development in the Universities and provide a platform to
                  network researchers with other institutes, National
                  laboratories and industry. The focus of Centres is on
                  providing an enabling eco system, process and support system.
                </p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="objective-img">
                <img src={objective} className="rounded" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* =======Tech-aims==========  */}
      {/* <section className="tech-aims-main pad-sec-50" id="techaims">
        <div className="container">
          <div className="row align-items-center">
          <div className="col-lg-5 col-sm-12">
              <div className="tech-rgt-img">
                <img src={techaims} />
              </div>
            </div>
            <div className="col-lg-7 col-sm-12">
              <div className="tech-points">
                <h2>Activities of TEC</h2>
                <ul>
                  <li>To serve the Industry by preparing reports and assisting in Research and Development in areas of dense Industry Clusters.</li>
                  <li>To serve the Industry by preparing reports and assisting in Research and Development in areas of dense Industry Clusters.</li>
                  <li>To perform periodic Workshops/Seminars in Collaboration with Industries.</li>
                  <li>To bring out Information’ updates on the Technologies available in different areas.</li>
                  <li>To bring out TEC Newsletter & Bulletin on Quarterly basis.</li>
                </ul>
              </div>
            </div>
           
          </div>
        </div>
      </section> */}
      {/* ============================  */}

      {/* <!-- ======Technology-Enabling-Center====== --> */}
      {/* <section className="Tech-enable-center pad-sec-50" id="techenablecentre">id="techaims" */}
      <section className="Tech-enable-center pad-sec-50" id="techenablecentre">
        <div className="container">
          <h2 className="text-center mb-3">Activities of TEC</h2>
          <div className="width-reducetech">
            <div className="row align-items-strech">
              <div className="col-lg-4 col-md-6 col-sm-12 d-flex">
                <div className="teh-points-box">
                  <img src={tech1} alt="" />
                  <p>
                    Mining of Technologies being developed at various Labs in
                    University.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 d-flex">
                <div className="teh-points-box">
                  <img src={tech2} alt="" />
                  <p>
                    Assessment of technologies requirement of MSMEs in and
                    around.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 d-flex">
                <div className="teh-points-box">
                  <img src={tech3} alt="" />
                  <p>
                    Evaluating maturity of the identified Technologies
                    (Products/Process or both) for further development.
                  </p>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-12 d-flex">
                <div className="teh-points-box">
                  <img src={techpointsbox4} alt="" />
                  <p>
                    To carry out the Market Study/Assessment of the Technologies
                    for their Market Potential.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 d-flex">
                <div className="teh-points-box">
                  <img src={tech5} alt="" />
                  <p>
                    To recommend the Pilot stage development & co-development of
                    the Technologies with established Institutions/Industries to
                    make the Technology as Market ready product to DST.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 d-flex">
                <div className="teh-points-box">
                  <img src={tech6} alt="" />
                  <p>
                    TEC will serve the Industry by preparing reports and
                    assisting in Research and Development in areas of dense
                    Industry Clusters
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 d-flex">
                <div className="teh-points-box">
                  <img src={tech7} alt="" />
                  <p>
                    Networking with various Technology Development Institutions
                    & Labs for refining of the technologies.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 d-flex">
                <div className="teh-points-box">
                  <img src={tech8} alt="" />
                  <p>
                    Periodic Workshops / Seminars in Collaboration with
                    Industries
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 d-flex">
                <div className="teh-points-box">
                  <img src={tech9} alt="" />
                  <p>
                    To bring out Information' updates on the Technologies
                    available in different areas
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 d-flex">
                <div className="teh-points-box">
                  <img src={tech10} alt="" />
                  <p>
                    To bring out TEC Newsletter & Bulletin on Quarterly basis
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* =======================================  */}
      {/* ===Other Facilities by Panjab University===  */}
      {/* <section className="other-facilities-uni pad-sec-50" id="otherfacility">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 col-md-12 ">
              <div className="Facilitiesleft mr-3">
                <img src={facilitiesleft} />
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="facilities-box">
                <h2>Other Facilities by Panjab University</h2>
                <ul>
                  <li>To serve the Industry by preparing reports and assisting in Research and Development in areas of dense Industry Clusters.</li>
                  <li>To serve the Industry by preparing reports and assisting in Research and Development in areas of dense Industry Clusters.</li>
                  <li>To perform periodic Workshops/Seminars in Collaboration with Industries. </li>
                  <li> To bring out Information’ updates on the Technologies available in different areas.- To bring out TEC Newsletter & Bulletin on Quarterly basis.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
}

export default Introduction;
