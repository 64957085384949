import React, { useState, useEffect } from "react";
import axiosInstance from "../../../axiosInstance";
import { useNavigate } from "react-router-dom";
import "./onlineEvents.css";
// import { ThreeDots } from 'react-loader-spinner';
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";

function OnlineEve() {
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);

  const itemsPerPage = 10;
  const [data, setData] = useState([]);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axiosInstance.post(`/events/getAll`, {
          filter: {
            status: 1,
            isDeleted: false,
          },
        });
        setData(response.data.items);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    getData();
  }, []);

  const navigate = useNavigate();
  const onlineEventsHandleClick = (onlineEvents_id) => {
    localStorage.setItem("onlineEvents_id", onlineEvents_id);

    navigate("/online-event-detail");
  };
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  return (
    <>
      {loading && <LoadingOverlay />}
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className=" is-active">
                  Online Events
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>
      {/* ===============online event design start=============== */}
      <section className="py-5">
        <div className="container">
          <div className="main-heading-for-internalpage position-relative">
            <h2>Online Events</h2>
          </div>
          {loading ? (
            <div className="loader-container">
              {/* <ThreeDots color="#F23F2C" height={80} width={80} />
               <h2>Please wait while data is loading....</h2> */}
            </div>
          ) : (
            <div className="row">
              {currentItems && currentItems.length > 0 ? (
                currentItems.map((item) => (
                  <div key={item._id} className="col-12 col-lg-6">
                    <div className="online_event">
                      <div className="row no-gutters">
                        <div className="col-12 col-lg-5 d-flex">
                          <img
                            src={item.image}
                            className="mx-auto d-block img-fluid"
                            alt={item.title}
                          />
                        </div>
                        <div className="col-12 col-lg-7 d-flex">
                          <div
                            className="caption-event-main pointer"
                            onClick={() => onlineEventsHandleClick(item._id)}
                          >
                            <div className="caption_event">
                              <h3>{item.title}</h3>
                              <span>
                                <i className="fa fa-clock-o"></i>
                                {item.startTime}-{item.endTime}
                              </span>
                              {/* <p>{item.description}</p> */}
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.description,
                                }}
                              ></p>
                              <a
                                className="ticket-link"
                                href={item.meetingLink}
                                target="a_blank"
                              >
                                <i
                                  className="fa fa-video-camera"
                                  aria-hidden="true"
                                ></i>{" "}
                                Click here to join Event
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="no-record-found text-center">
                  <p>No records found.</p>
                </div>
              )}
            </div>
          )}
          <div className="row mt-5 web-page">
            <div className="col-12 col-lg-12">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => setCurrentPage(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                  </li>
                  {/* Generate pagination buttons */}
                  {Array.from({
                    length: Math.ceil(data.length / itemsPerPage),
                  }).map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${
                        currentPage === index + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => setCurrentPage(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                  <li
                    className={`page-item ${
                      currentPage === Math.ceil(data.length / itemsPerPage)
                        ? "disabled"
                        : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => setCurrentPage(currentPage + 1)}
                      disabled={
                        currentPage === Math.ceil(data.length / itemsPerPage)
                      }
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>

      {/* ===============online event design End=============== */}
    </>
  );
}

export default OnlineEve;
