import React, { useState, useEffect } from "react";
import axiosInstance from "../../../axiosInstance";
// import { ThreeDots } from "react-loader-spinner";
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";

function TeccenterNews() {
  const [loading, setLoading] = useState(true);

  const [allNews, setAllNews] = useState([]);
  const [latestNews, setLatestNews] = useState(null);
  const latestNews_id = localStorage.getItem("latestNews_id");
  const event = localStorage.getItem("eventname");
  const [allAnnouncements, setAllAnnouncements] = useState([]);
  const [latestAnnouncement, setLatestAnnouncement] = useState(null);
  const latestAnnouncement_id = localStorage.getItem("latestAnnouncement_id");
  const [selectedPostTitle, setSelectedPostTitle] = useState("");
  const [showFullDescription, setShowFullDescription] = useState(false);
  useEffect(() => {
    axiosInstance({
      url: `/newsAnnouncements/getAllNewsForUser`,
    })
      .then((response) => {
        setAllNews(response.data.items);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  }, []);
  const handleReadMore = () => {
    setShowFullDescription(!showFullDescription);
  };
  useEffect(() => {
    axiosInstance({
      url: `/newsAnnouncements/getAllAnnouncement`,
    })
      .then((response) => {
        setAllAnnouncements(response.data.items);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  }, []);

  useEffect(() => {
    if (latestNews_id) {
      axiosInstance({
        url: `/newsAnnouncements/getNewsAnnouncementById/${latestNews_id}`,
      })
        .then((response) => {
          setLatestNews(response.data && response.data.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log("error", error);
        });
    }

  }, [latestNews_id]);

  useEffect(() => {
    if (latestAnnouncement_id) {
      axiosInstance({
        url: `/newsAnnouncements/getNewsAnnouncementById/${latestAnnouncement_id}`,
      }).then((response) => {
        setLatestAnnouncement(response.data && response.data.data);
        setLoading(false);
      })
        .catch((error) => {
          setLoading(false);
          console.log("error", error);
        });
    }

  }, [latestAnnouncement_id]);

  const selectedPostTitle1 = localStorage.getItem("selectedPostTitle");

  const changeVisibleNews = (updatedID) => {
    let updatedNewsObject = allNews.find(
      (element) => element._id === updatedID
    );
    setLatestNews(updatedNewsObject);
    setSelectedPostTitle(updatedNewsObject.title);
  };

  const changeVisibleAnnouncement = (updatedID) => {
    let updatedAnnouncementObject = allAnnouncements.find(
      (element) => element._id === updatedID
    );
    setLatestAnnouncement(updatedAnnouncementObject);
    setSelectedPostTitle(updatedAnnouncementObject.title);
  };
  return (
    <>
      {loading && <LoadingOverlay />}
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className="breadcrumbs__item">
                  Latest News/Event
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className="is-active">
                  {selectedPostTitle || selectedPostTitle1}
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="news-view-detail-main pad-sec-50">
        <div className="container">
          <div className="main-heading-for-internalpage position-relative">
            <h2>Latest News and Event</h2>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="main-tabingof-announcement w-100">
                <ul
                  className="nav nav-tabing-events d-flex justify-content-center"
                  role="tablist"
                >
                  <li className="nav-item button-clik-change">
                    <a
                      href="#newslatest"
                      role="tab"
                      data-toggle="tab"
                      className={
                        event === "Event" ? `nav-link` : `nav-link active`
                      }
                    >
                      Latest News
                    </a>
                  </li>
                  <li className="nav-item button-clik-change">
                    <a
                      href="#announcement"
                      role="tab"
                      data-toggle="tab"
                      className={
                        event === "Event" ? `nav-link active` : `nav-link`
                      }
                    >
                      Event
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  {loading ? (
                    <div className="loader-container">
                      {/* <ThreeDots
                        color="#F23F2C"
                        height={80}
                        width={80}
                        className="pt-4"
                      />
                      <h2>Please wait while data is loading....</h2> */}
                    </div>
                  ) : (
                    <>
                      <div
                        className={
                          event !== "Event"
                            ? `tab-pane active mt-3`
                            : `tab-pane mt-3`
                        }
                        role="tabpanel"
                        id="newslatest"
                      >
                        <div className="row">
                          <div className="col-lg-7">
                            <div className="left-for-detailsnews">
                              {latestNews && latestNews.title && (
                                <h3>{latestNews.title}</h3>
                              )}
                              <div className="d-flex">
                                {latestNews && latestNews.date && (
                                  <span className="whois-posted mb-2">
                                    {/* <i className="fa fa-user" aria-hidden="true"></i> Admin{" "} */}
                                    <i
                                      className="fa fa-calendar"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    {latestNews.date.substring(0, 10)}
                                  </span>
                                )}
                              </div>
                              {latestNews && latestNews.image && (
                                <img
                                  src={latestNews.image}
                                  className="w-100 mt-2 mb-3"
                                  alt="Latest News"
                                />
                              )}
                              {latestNews && latestNews.description && (
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: latestNews.description,
                                  }}
                                ></p>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-5">
                            <div className="leatest-updated-news-sec44">
                              {[...allNews].reverse().map((item) => (
                                <div
                                  className="right-new-onclick mb-4"
                                  key={item._id}
                                >
                                  <div
                                    className="news-boxclick d-flex justify-content-between align-items-start pointer"
                                    onClick={() => changeVisibleNews(item._id)}
                                  >
                                    <div className="image-slidernews w-25">
                                      <img
                                        src={item.image}
                                        className="w-100"
                                        alt="News"
                                      />
                                    </div>
                                    <div className="content-new-slide w-75 ml-3">
                                      <p>{item.title}</p>
                                      {/* <span className="notify-datetime ml-2">
                                        {formatDate(item.date)}
                                      </span> */}
                                      {/* <div>
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: `${item.description.substring(
                                              0,
                                              30
                                            )}`,
                                          }}
                                        />
                                        <span
                                          style={{
                                            cursor: "pointer",
                                            color: "blue",
                                          }}
                                          onClick={() => handleReadMore(item)}
                                        >
                                          Read More
                                        </span>
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        className={
                          event === "Event"
                            ? `tab-pane active mt-3`
                            : `tab-pane mt-3`
                        }
                        role="tabpanel"
                        id="announcement"
                      >
                        <div className="row">
                          <div className="col-lg-7">
                            <div className="left-for-detailsnews">
                              {latestAnnouncement &&
                                latestAnnouncement.title && (
                                  <h3>{latestAnnouncement.title}</h3>
                                )}
                              <div className="d-flex">
                                {latestAnnouncement &&
                                  latestAnnouncement.date && (
                                    <span className="whois-posted mb-2">
                                      {/* <i className="fa fa-user" aria-hidden="true"></i> Admin{" "} */}
                                      <i
                                        className="fa fa-calendar"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      {latestAnnouncement.date.substring(0, 10)}
                                    </span>
                                  )}
                              </div>
                              {latestAnnouncement &&
                                latestAnnouncement.image && (
                                  <img
                                    src={latestAnnouncement.image}
                                    className="w-100 mt-2 mb-3"
                                    alt="Announcement"
                                  />
                                )}
                              {latestAnnouncement &&
                                latestAnnouncement.description && (
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: latestAnnouncement.description,
                                    }}
                                  ></p>
                                )}
                            </div>
                          </div>
                          <div className="col-lg-5">
                            <div className="leatest-updated-news-sec44">
                              {[...allAnnouncements].reverse().map((item) => (
                                <div
                                  className="right-new-onclick mb-4"
                                  key={item._id}
                                >
                                  <div
                                    className="news-boxclick d-flex justify-content-between align-items-start pointer"
                                    onClick={() =>
                                      changeVisibleAnnouncement(item._id)
                                    }
                                  >
                                    <div className="image-slidernews w-25">
                                      <img
                                        src={item.image}
                                        className="w-100"
                                        alt="Announcement"
                                      />
                                    </div>
                                    <div className="content-new-slide w-75 ml-3">
                                      <p>{item.title}</p>
                                      {/* <span className="notify-datetime ml-2">
                                        {formatDate(item.date)}
                                      </span> */}

                                      {/* <div>
                                        <p
                                          dangerouslySetInnerHTML={{
                                            __html: `${item.description.substring(
                                              0,
                                              30
                                            )}...`,
                                          }}
                                        />
                                        <span
                                          style={{
                                            cursor: "pointer",
                                            color: "blue",
                                          }}
                                          onClick={() => handleReadMore(item)}
                                        >
                                          Read More
                                        </span>
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TeccenterNews;
