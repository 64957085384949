import React, { useState, useEffect } from "react";
import axiosInstance from "../../../axiosInstance";
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import blankImage from "../../../assests/blankImage.jpg";
import "react-quill/dist/quill.snow.css";
import ReactPlayer from "react-player";
import ModalBidding from "../Industrial_Problem/bidModal";
import ReactAudioPlayer from "react-audio-player";
import {
  bidBtnEnableDisable,
  calculateTimeRemaning,
} from "../../../Helper/Helper";
import { toast } from "react-toastify";
import moment from "moment";
function Techtransfer() {
  const [data, setData] = useState({});

  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const [selectedPostName, setSelectedPostName] = useState("");
  const [remainingTime, setRemainingTime] = useState("");
  // const _tecsubcateID = localStorage.getItem("TecsubcaretogoryTokenID");
  const _tecsubcateName = sessionStorage.getItem("TecsubcaretogoryName");

  const _techId = localStorage.getItem("techTokenID");

  const settings_achievemnets = {
    infinite: true,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1008,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    let intervalId;

    const fetchTechTransferData = async () => {
      try {
        const response = await axiosInstance.get(
          `/technologyTransfers/getTechnlogyTransferById/${_techId}`
        );
        setData(response.data.data);

        // const abc = JSON.stringify(response.data.data.images);
        // const parsedArray = JSON.parse(abc)[0];
        // const imageUrls = parsedArray;
        const imagesData = response.data.data.images || [];
        setImages(imagesData);

        setLoading(false);
       
      } catch (error) {
        console.log(error);
      }
    };

    fetchTechTransferData();

  
  }, [_techId]);

  const isMultipleImages = data.images && data.images.length > 1;

 

  const renderedImages = images.map((imageUrl, index) => (
    <div key={index}>
      <img
        src={imageUrl || blankImage}
        className="w-100 mb-3 tecctranfermul-image"
        alt={` ${index + 1}`}
      />
    </div>
  ));

  const handleBidBtn = (e) => {
    e.preventDefault();
    
    if (localStorage.getItem("userId") === null) {
      toast.error(`Please Login First!`);
      return false;
    }

    if (localStorage.getItem("userId") === data.userId._id) {
      toast.error(`You can't bid your own bid!`);
      return false;
    }

    setShowModal(true);
  };

  return (
    <>
      {loading && <LoadingOverlay />}

      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                <a href="/technology" className="breadcrumbs__item">
                  Technology Transfer{" "}
                </a>
                <a href="/tec-category-listing" className="breadcrumbs__item">
                  Technologies Ready for Commercialization{" "}
                </a>

                {(selectedPostName || _tecsubcateName) && (
                  <a href="/tec-category-listing" className="breadcrumbs__item">
                    {selectedPostName || _tecsubcateName}
                  </a>
                )}

                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className="is-active">
                  {data.title}
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>

      {/* -==========Tech transfer details page===== */}
      <section className="detailpage-main-tech pad-sec-50 position-relative">
        <div className="container">
          {loading ? (
            <div className="loader-container"></div>
          ) : (
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="content-for-detal-bid">
                  <div className="main-heading-for-internalpage position-relative">
                    {" "}
                    {data && data.title && <h2>{data.title}</h2>}
                  </div>

                  <div className="highlightkeys-tech">
                    <div className="total-bid-details">
                      <p className="text-center">
                        {" "}
                        {data && data.uniqueId ? (
                          <p>
                            <b>Listing ID: </b> {data.uniqueId}
                          </p>
                        ) : (
                          <p>
                            <b>Listing ID:</b> Not Available
                          </p>
                        )}
                      </p>
                    </div>
                    <div className="total-bid-details">
                      <p className="text-center">
                        {" "}
                        {data && data.technologyReadinessLevel && (
                          <p>
                            <b>Technology Readiness Level :</b>{" "}
                            {data.technologyReadinessLevel}
                          </p>
                        )}{" "}
                      </p>
                    </div>

                    <div className="total-bid-details">
                      <p className="text-center">
                        {" "}
                        {data && data.patentStatus && (
                          <p>
                            <b>Patent Status : </b> {data.patentStatus}
                          </p>
                        )}{" "}
                      </p>
                    </div>
                    <div className="total-bid-details">
                      <p className="text-center">
                        {" "}
                        {data && data.patentApplicationNumber && (
                          <p>
                            <b>Patent Application Number : </b>{" "}
                            {data?.patentApplicationNumber || "na"}
                          </p>
                        )}{" "}
                      </p>
                    </div>

                    <button type="button" class="btn btnforeoi mb-3">
                      <i class="fa fa-file mr-2" aria-hidden="true"></i>
                      EOI
                    </button>
                  </div>

                  <div className="total-bid-details mb-3">
                    <p>
                      {" "}
                      {data && data.briefNote && (
                        <>
                          <b>
                            Brief Note / Description / Objective of Technology:{" "}
                          </b>{" "}
                          <p
                            dangerouslySetInnerHTML={{ __html: data.briefNote }}
                          ></p>
                        </>
                      )}{" "}
                    </p>
                    <p>
                      {" "}
                      {data && data.specifications && (
                        <>
                          <b>Specifications / Features of Technology: </b>{" "}
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data.specifications,
                            }}
                          ></p>
                        </>
                      )}{" "}
                    </p>
                    <p>
                      {" "}
                      {data && data.applicationsOfTechnology && (
                        <>
                          <b>Applications of Technology: </b>{" "}
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data.applicationsOfTechnology,
                            }}
                          ></p>
                        </>
                      )}{" "}
                    </p>
                    <p>
                      {" "}
                      {data && data.tags && (
                        <>
                          <b>Tag As : </b>{" "}
                          <p
                            dangerouslySetInnerHTML={{
                              __html: data.tags,
                            }}
                          ></p>
                        </>
                      )}{" "}
                    </p>
                    <div>
                      <p>
                        <b>Remaining Time: </b>
                        {calculateTimeRemaning(
                          data.bidStartDate,
                          data.bidEndDate
                        )}
                      </p>
                    </div>
                    <div>
                      <p>
                        <b>Bid Start Date: </b>{" "}
                        {moment(data.bidStartDate).format("DD/MM/YYYY")}
                      </p>
                    </div>
                    <div>
                      <p>
                        <b>Bid End Date:</b>{" "}
                        {moment(data.bidEndDate).format("DD/MM/YYYY")}
                      </p>
                    </div>
                    {!bidBtnEnableDisable(
                      data.bidStartDate,
                      data.bidEndDate
                    ) && (
                      <button
                        className="custom-button"
                        onClick={(e) => handleBidBtn(e)}
                      >
                        Place a Bid
                      </button>
                    )}

                    {showModal && (
                      <ModalBidding
                        show={showModal}
                        onClose={() => setShowModal(false)}
                      />
                    )}
                  </div>
                  <div className="tab-pane" role="tabpanel" id="PatentsofPU">
                    <div className="row">
                      <div className="col-md-12"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 py-5">
                <div className="indsutrial-detail-inner-image">
                  {renderedImages.length > 0 && (
                    <Slider {...settings_achievemnets}>{renderedImages}</Slider>
                  )}
                </div>
              </div>

              {data.videos && data.videos.length > 0 && (
                <div className="col-md-6">
                  {data.videos.map((videoURL, index) => (
                    <div key={index} className="col-md-12">
                      <h5>Video </h5>
                      <div className="industrialprob-videoshow w-100 mt-4">
                        <ReactPlayer url={videoURL} controls={true} />
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {data.audios &&
                data.audios.length > 0 &&
                data.audios.map((videoURL, index) => (
                  <div className=" d-flex justify-content-start">
                    <div key={index} className="col-md-6 ">
                      <h5>Audio Guide</h5>
                      <div className="industrialprob-videoshow w-100 mt-4">
                        <ReactAudioPlayer src={videoURL} controls={true} />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default Techtransfer;
