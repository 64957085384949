import React, { useEffect } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Header from "../Components/Users/Header";
import Footer from "../Components/Users/Footer";
import Index from "../Components/Users/Indexs";
import Login from "../Components/Users/Login";
import Contact from "../Components/Users/Contact_Us";
import OnlineEve from "../Components/Users/Online_Event";
import Community from "../Components/Users/Community_Section";
import Industrial from "../Components/Users/Industrial_Problem";
import Technology from "../Components/Users/Technology_transfer";
import Introduction from "../Components/Users/About_Us/Introduction_about";
import Achievements from "../Components/Users/About_Us/Achievements_about/index";
import Teams from "../Components/Users/About_Us/Teams_about";
import Techtransfer from "../Components/Users/Technology_transfer/tech_transfer";
import ForgotPassowrd from "../Components/Users/Password";
import Register from "../Components/Users/Sign_Up";
import DataCenterDetails from "../Components/Users/About_Us/Data_Center/data_center";
import CommunityChatDetails from "../Components/Users/Community_Section/community_chat";
import Pics from "../Components/Users/pictures/pic";
import ExperticeAreaListing from "../Components/Users/Hire Expertise/Expertice_area";
import LatestViewPage from "../Components/Users/Indexs/latest-news-page";
import IndustrialDetails from "../Components/Users/Industrial_Problem/industrial-details";
import ExperticeAreaDetails from "../Components/Users/Hire Expertise/Expertice_area/expertice-area-details";
import OnlineEventDetail from "../Components/Users/Online_Event/online-event-detail";
import VisionMissionIndex from "../Components/Users/About_Us/Vision_mission";
import EstablishmentHistory from "../Components/Users/About_Us/Establishment-history";
import ConfirmPassword from "../Components/Users/Password/password";
import AchivementDetail from "../Components/Users/About_Us/Achievements_about/achivement-detail";
import PU_CSR from "../Components/Users/PU-CSR";
import CommunityDetail from "../Components/Users/Community_Section/community-detail";
import PrivacyPolicy from "../Components/Users/Privacy-policy";
import TermCondition from "../Components/Users/Term-and-condition";
import ChairpersonMessage from "../Components/Users/Indexs/message-for-chairperson";
import Datacenter from "../Components/Users/About_Us/Data_Center";
import TecupMedia from "../Components/Users/Tecup";
import FundingSchemes from "../Components/Users/Footer/funding-Schemes";
import Dashboards from "../Components/Users/Dashboard";
import TechTransferListing from "../Components/Users/Dashboard/tech_transfer";
import IndustrialProblemListing from "../Components/Users/Dashboard/industrial_problem";
import UpdateProfileListing from "../Components/Users/Dashboard/update_profile";
import ExpertiseCreateListing from "../Components/Users/Dashboard/expertise_create";
import IndustrialProblemEdit from "../Components/Users/Dashboard/industrial_pblm_edit";
import PrivateRoute from "./Privatesroutes";
import IndustrialForm from "../Components/Users/Dashboard/industrial_form";
import TechTransferEdit from "../Components/Users/Dashboard/tec_transfer_edit";
import Report from "../Components/Users/Footer/reports";
import CategoryListing from "../Components/Users/Industrial_Problem/category_listing";
import Chat_Tawkto from "../Components/Users/chatbort";
import TecCategory from "../Components/Users/Technology_transfer/tec_category";
import ExpertCategory from "../Components/Users/Hire Expertise/expert_category";
import TeccenterNews from "../Components/Users/Tecup/teccenter";
import GoogleSignup from "../Components/Users/Sign_Up/google";
import { useState } from "react";
import Gallery from "../Components/Users/Indexs/gallery";
import TeccenterAchivement from "../Components/Users/Tecup/teccenter-achivemnet";
import AllTeccenter from "../Components/Users/Tecup/allteccenter";
import AddCommunitySection from "../Components/Users/Dashboard/addCommunitySection";
import CommunitySectionListing from "../Components/Users/Dashboard/community";
import CommunitySectiionEdit from "../Components/Users/Dashboard/communityedit";
import BidProfile from "../Components/Users/Dashboard/bidprofile";
import BidListing from "../Components/Users/Dashboard/bidList";

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}

function Routing() {
  const [name, setName] = useState("");
  const [token, setToken] = useState([]);
  const navigate = useNavigate();
  const handleNameUpdate = (newname) => {
    setName(newname);
  };
  // useEffect(() => {
  //   let timeoutId;

  //   const handleActivity = () => {
  //     clearTimeout(timeoutId);
  //     timeoutId = setTimeout(logoutUser, 300000); // 5 minute
  //   };

  //   timeoutId = setTimeout(logoutUser, 300000);

  //   document.addEventListener('mousemove', handleActivity);
  //   document.addEventListener('keydown', handleActivity);

  //   return () => {

  //     clearTimeout(timeoutId);
  //     document.removeEventListener('mousemove', handleActivity);
  //     document.removeEventListener('keydown', handleActivity);
  //   };
  // }, []);
  // const logoutUser = () => {

  //   localStorage.clear();

  //   navigate('/login');
  // };
 

 

  return (
    <>
      <Header />
      <Routes>
        {/* public routes  */}

        <Route path="/" element={<Index />} />
        <Route path="/login" element={<Login setToken={setToken} />} />
        <Route path="/teccenter-achivement" element={<TeccenterAchivement />} />
        <Route path="/teccenter" element={<AllTeccenter />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about-introduction" element={<Introduction />} />
        <Route path="/about-teams" element={<Teams />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/about-achievements" element={<Achievements />} />
        <Route path="/about-data-centers" element={<Datacenter />} />
        <Route path="/online-events" element={<OnlineEve />} />
        <Route path="/community" element={<Community />} />
        <Route path="/industrial" element={<Industrial />} />
        <Route path="/technology" element={<Technology />} />
        <Route path="/tech-trensfer-detail-page" element={<Techtransfer />} />
        <Route path="/forget-password-link" element={<ForgotPassowrd />} />

        <Route
          path="/signup"
          element={<Register onSubmit={handleNameUpdate} />}
        />
        <Route path="/google-signup" element={<GoogleSignup />} />
        <Route path="/data-center-details" element={<DataCenterDetails />} />
        <Route
          path="/community-chat-details"
          element={<CommunityChatDetails />}
        />
        <Route path="/latest-news-page" element={<LatestViewPage />} />
        <Route path="/pics" element={<Pics />} />
        <Route path="/industrial-details" element={<IndustrialDetails />} />
        <Route
          path="/expertice-area-details"
          element={<ExperticeAreaDetails />}
        />
        <Route path="/online-event-detail" element={<OnlineEventDetail />} />
        <Route path="/vision-mission" element={<VisionMissionIndex />} />
        <Route path="/establishment" element={<EstablishmentHistory />} />
        <Route path="/achivement-detail" element={<AchivementDetail />} />
        <Route
          path="/expertice-area-listing"
          element={<ExperticeAreaListing />}
        />
        <Route path="/community-chat-detail" element={<CommunityDetail />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/term-and-condition" element={<TermCondition />} />
        <Route path="/pucsr" element={<PU_CSR />} />
        <Route path="/forgot-password" element={<ConfirmPassword />} />
        <Route
          path="/message-from-chairperson"
          element={<ChairpersonMessage />}
        />
        <Route path="/reports" element={<Report />} />
        <Route path="/tecup-media" element={<TecupMedia />} />
        <Route path="/funding-Schemes" element={<FundingSchemes />} />
        <Route path="/expert-category" element={<ExpertCategory />} />
        <Route path="/category_listing" element={<CategoryListing />} />
        <Route path="/tec-category-listing" element={<TecCategory />} />
        <Route path="/teccenter-news" element={<TeccenterNews />} />
        {/* priavte routes */}

        <Route path="/user" element={<PrivateRoute />}>
          <Route
            path="dashboard-tectransfer-edit-form"
            element={<TechTransferEdit />}
          />
          <Route path="dashboard" element={<Dashboards name={name} />} />
          <Route
            path="dashboard-industrial-form"
            element={<IndustrialForm />}
          />
          <Route
            path="dashboard-tech-transfer-listing"
            element={<TechTransferListing />}
          />
          <Route
            path="dashboard-community-section-edit"
            element={<CommunitySectiionEdit />}
          />
           <Route
            path="dashboard-bid-profile"
            element={<BidProfile />}
          />
          <Route
            path="dashboard-industrial-probem-listing"
            element={<IndustrialProblemListing />}
          />
          <Route
            path="dashboard-update-profile-listing"
            element={<UpdateProfileListing />}
          />
          <Route
            path="dashboard-community-add"
            element={<AddCommunitySection />}
          />
           <Route path="dashboard-bidlist" element={<BidListing />} />
          <Route
            path="dashboard-community-section"
            element={<CommunitySectionListing />}
          />

          <Route
            path="dashboard-industrial-prblm-edit"
            element={<IndustrialProblemEdit />}
          />
          <Route
            path="dashboard-expertice-create-listing"
            element={<ExpertiseCreateListing />}
          />
        </Route>
      </Routes>
      <Chat_Tawkto />
      <ScrollToTop />
      <Footer />
    </>
  );
}

export default Routing;
