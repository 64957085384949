import React, { useState, useEffect } from "react";
import AsideDashboard from "./aside";
import axiosInstance from "../../../axiosInstance";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Multiselect from "multiselect-react-dropdown";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";

function IndustrialForm() {
  const MAX_WIDTH = 309;
  const MAX_HEIGHT = 193;

  const navigate = useNavigate();
  const [selectedImages, setSelectedImages] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [category, setCategory] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);

  const [loading, setLoading] = useState(false);
  const [formdata, setFormdata] = useState({
    title: "",
    notes: "",
    link: [],
    images: [],
    videos: [],
    audios: [],
    bidStatus: "",
    bidStartDate: "",
    bidEndDate: "",
  });
  const [formErrors, setFormErrors] = useState({
    category: "",
    title: "",
    images: "",
    videos: "",
    link: "",
    description: "",
  });
  const [imageFiles, setImageFiles] = useState([]);
  const [videoFiles, setVideoFiles] = useState([]);
  // const [videoLinks, setVideoLinks] = useState('');
  const [videoLinks, setVideoLinks] = useState([""]);
  const [audioLinks, setAudioLinks] = useState([""]);
  const [selectedOption, setSelectedOption] = useState("");

  // const [showFileUpload, setShowFileUpload] = useState(false);
  // const [showLinkUpload, setShowLinkUpload] = useState(false);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormdata({
      ...formdata,
      [name]: value,
    });

    // Clear corresponding error message on input change
    if (formErrors[name]) {
      setFormErrors({
        ...formErrors,
        [name]: "",
      });
    }
  };
  useEffect(() => {
    const fetchAccountData = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(
          "/subCategories/getAllSubCategoryByCategoryId/64d9d3911cae26c449f95fa4"
        );
        setCategory(response.data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setCategory([]);
      }
    };

    fetchAccountData();
  }, []);
  const handleVideoTypeChange = (e) => {
    const { value } = e.target;
    setSelectedOption(value);
    if (value === "link") {
      const linksArray = formdata.link.split(",");
      setVideoLinks(linksArray);
    }
  };

  const handleImage = async (event) => {
    const imageFiles = event.target.files;

    try {
      setLoading(true);
      const imageUrls = [];
      for (let i = 0; i < imageFiles.length; i++) {
        const file = imageFiles[i];

        if (!file.type.startsWith("image/")) {
          toast.error("Only image files can be uploaded.");
          event.target.value = null;
          continue;
        }

        const singleFileData = new FormData();
        singleFileData.append("image", file);

        const response = await axiosInstance.post(
          "/tecTeams/uploadImage",
          singleFileData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        if (response) {
          imageUrls.push(response.data);
        }
      }

      setFormdata((prevFormData) => ({
        ...prevFormData,
        images: [...prevFormData.images, ...imageUrls],
      }));
      setImageFiles((prevImageFiles) => [...prevImageFiles, ...imageUrls]);
      setLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveImage = (index) => {
    const updatedPreviews = [...imagePreviews];
    updatedPreviews.splice(index, 1);

    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);

    setImagePreviews(updatedPreviews);
    setSelectedImages(updatedImages);

    setImageFiles((prevImageFiles) => {
      const updatedFiles = [...prevImageFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };
  const handleBidStatusChange = (e) => {
    const { name, value } = e.target;
    const bidStatusValue = value === "true";

    setFormdata({
      ...formdata,
      bidStatus: bidStatusValue,
      bidStartDate: bidStatusValue ? formdata.bidStartDate : "",
      bidEndDate: bidStatusValue ? formdata.bidEndDate : "",
    });

   
    setFormErrors({
      ...formErrors,
      bidStatus: "",
    });
  };
  // const handleSelectChange = (e) => {
  //   const { name, value } = e.target;
  //   if (name === "category") {
  //     setSelectedCategory(value);

  //     if (formErrors.category) {
  //       setFormErrors({
  //         ...formErrors,
  //         category: "",
  //       });
  //     }
  //   } else if (name === "uploadType") {
  //     setSelectedOption(value);
  //     if (value === "link") {
  //       const linksArray = formdata.link.split(",");
  //       setVideoLinks(linksArray);
  //     }
  //   }
  // };

  const handleSelectChange = (selectedList, selectedItem) => {
    setSelectedCategory(selectedList);

    // Clear the category error message when a category is selected
    if (formErrors.category) {
      setFormErrors({
        ...formErrors,
        category: "",
      });
    }
  };

  const handleVideoLinkChange = (e, index) => {
    const { name, value } = e.target;
    setVideoLinks((prevLinks) => {
      const updatedLinks = [...prevLinks];
      updatedLinks[index] = value;

      return updatedLinks;
    });
  };
  const handleAudioLinkChange = (e, index) => {
    const { name, value } = e.target;
    setAudioLinks((prevLinks) => {
      const updatedLinks = [...prevLinks];
      updatedLinks[index] = value;

      return updatedLinks;
    });
  };
  const handleRemoveVideoLink = (index) => {
    setVideoLinks((prevLinks) => prevLinks.filter((_, i) => i !== index));
  };
  const handleRemoveAudioLink = (index) => {
    setAudioLinks((prevLinks) => prevLinks.filter((_, i) => i !== index));
  };
  const handleAddMoreVideoLink = () => {
    setVideoLinks((prevLinks) => [...prevLinks, ""]);
  };
  const handleAddMoreAudioLink = () => {
    setAudioLinks((prevLinks) => [...prevLinks, ""]);
  };
  // const handleUploadTypeChange = (e) => {
  //   const selectedValue = e.target.value;

  //   if (selectedValue === "file") {
  //     setShowFileUpload(true);
  //     setShowLinkUpload(false);
  //   } else if (selectedValue === "link") {
  //     setShowFileUpload(false);
  //     setShowLinkUpload(true);
  //   } else {
  //     setShowFileUpload(false);
  //     setShowLinkUpload(false);
  //     setSelectedCategory(selectedValue);
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {};
    setLoading(true);
    if (selectedCategory.length === 0) {
      errors.category = "Category is required.";
    }
    if (!formdata.title.trim()) {
      errors.title = "Title is required.";
    }
    if (!formdata.notes.trim()) {
      errors.notes = "Description is required.";
    }
    
    if (formdata.bidStatus) {
      if (!formdata.bidStartDate.trim()) {
        errors.bidStartDate = "Start date is required.";
      }
      if (new Date(formdata.bidEndDate) < new Date(formdata.bidStartDate)) {
        errors.bidEndDate = "End date cannot be earlier than start date.";
      }
      if (!formdata.bidEndDate.trim()) {
        errors.bidEndDate = "End date is required.";
      }
    } else {
      errors.bidStatus = "Bid status is required.";
    }
    const invalidVideoLinks = videoLinks.filter((link) => {
      return !/^https?:\/\/(www\.)?(youtube\.com\/(watch\?v=|embed\/)|youtu\.be\/|player\.vimeo\.com\/video\/)|<iframe.*<\/iframe>/.test(
        link
      );
    });

    if (invalidVideoLinks.length > 0) {
      errors.videoLinks = "Please enter valid YouTube formats.";
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const formData = new FormData();

      formData.append("userId", localStorage.getItem("userId"));
      formData.append("title", formdata.title);

      formData.append("description", formdata.notes);
      if (formdata.bidStatus) {
        formData.append("bidStatus", formdata.bidStatus.toString());
        formData.append("bidStartDate", formdata.bidStartDate);
        formData.append("bidEndDate", formdata.bidEndDate);
      }
      const selectedCategoriesString = selectedCategory
        .map((item) => item.value)
        .join(",");
      formData.append("categoryId", selectedCategoriesString);

      // formData.append("images", JSON.stringify(imageFiles));
      for (let i = 0; i < imageFiles.length; i++) {
        formData.append("images", imageFiles[i]);
      }
      const videosArray = videoLinks.map((link) => `${link}`);
      const videosString = videosArray.join(", ");
      formData.append("videos", videosString);
      const audioArray = audioLinks.map((link) => `${link}`);
      const audiosString = audioArray.join(", ");
      formData.append("audios", audiosString);
      const response = await axiosInstance.post(
        "/industrialProblems/create",

        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data && response.data._id) {
        localStorage.setItem("industrialedit", response.data._id);
      }

      setFormdata({
        title: "",
        notes: "",
        link: "",
      });
      setImageFiles([]);
      setVideoFiles([]);

      toast.success("Form submitted successfully!");
      setTimeout(() => {
        navigate("/user/dashboard-industrial-probem-listing");
      }, 1000);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error during form submission. Please try again.");
    }
  };
  function getCurrentDate() {
    const today = new Date();
    let month = String(today.getMonth() + 1);
    let day = String(today.getDate());
    const year = today.getFullYear();

    if (month.length === 1) {
      month = "0" + month;
    }
    if (day.length === 1) {
      day = "0" + day;
    }

    return `${year}-${month}-${day}`;
  }
  return (
    <>
      {loading && <LoadingOverlay />}
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className=" is-active">
                  Industrial Problems
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section className="main-sidenavigation pad-sec-50">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-3">
              <aside>
                <AsideDashboard />
              </aside>
            </div>

            <div className="col-12 col-lg-9 main-wrapper-form pt-3 pb-3">
              <div className="deshboard-title">
                <h4> Industrial Problems </h4>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="form-bg-box-inner">
                  <div className="main-form-for-dashboard">
                    <div className="row p-4">
                      <div className="form-group col-12 col-lg-6 col-md-6">
                        <label className="control-label">
                          Category<span className="text-danger"></span>
                        </label>
                        <Multiselect
                          showCheckbox={true}
                          options={category.map((item) => ({
                            label: item.name,
                            value: item._id,
                          }))}
                          selectedValues={selectedCategory}
                          onSelect={handleSelectChange}
                          onRemove={handleSelectChange}
                          displayValue="label"
                        />

                        {formErrors.category && (
                          <span className="text-danger">
                            {formErrors.category}
                          </span>
                        )}
                      </div>

                      <div className="form-group col-12 col-lg-6 col-md-6">
                        <label className="control-label">
                          Title<span className="text-danger">*</span>
                        </label>
                        <input
                          placeholder="Title"
                          className="form-control"
                          name="title"
                          type="text"
                          value={formdata.title}
                          onChange={handleChange}
                        />
                        {formErrors.title && (
                          <span className="text-danger">
                            {formErrors.title}
                          </span>
                        )}
                      </div>
                      <div className="form-group col-12 col-lg-6 col-md-6 d-flex flex-column">
                        <label>
                          Images Selected{" "}
                          <span className="text-danger">
                            (You can selected multiple images)
                          </span>
                        </label>
                        <input
                          type="file"
                          name="img"
                          required=""
                          accept="image/*"
                          multiple
                          className="form-control"
                          onChange={handleImage}
                        />
                        {/* {dataLoading && (
                          <div className="loader">
                            <RotatingLines
                              color="#00BFFF"
                              height={80}
                              width={80}
                            />
                          </div>
                        )} */}

                        <div className="image-preview-container">
                          {imageFiles.map((preview, index) => (
                            <div key={index} className="image-preview-wrapper ">
                              <img
                                src={preview}
                                alt={` ${index + 1}`}
                                className="image-preview"
                              />
                              <button
                                type="button"
                                className="btn-submit backtored"
                                onClick={() => handleRemoveImage(index)}
                              >
                                <i class="fa fa-times" aria-hidden="true"></i>
                              </button>
                            </div>
                          ))}
                        </div>
                        {formErrors.images && (
                          <span className="text-danger">
                            {formErrors.images}
                          </span>
                        )}
                      </div>

                      <div
                        className="form-group col-12 col-lg-6 col-md-6"
                        id="uploadLink"
                      >
                        <label>Audio Links</label>

                        <div>
                          {audioLinks.map((audioLink, index) => (
                            <div key={index}>
                              <input
                                placeholder="Audio Link"
                                className="form-control m-2"
                                name="link"
                                type="text"
                                value={audioLink}
                                onChange={(e) =>
                                  handleAudioLinkChange(e, index)
                                }
                              />
                              {index > 0 && (
                                <button
                                  className="btn-submit backtored"
                                  type="button"
                                  onClick={() => handleRemoveAudioLink(index)}
                                >
                                  <i
                                    className="fa fa-times"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              )}
                            </div>
                          ))}
                          <button
                            type="button"
                            onClick={handleAddMoreAudioLink}
                          >
                            Add More
                          </button>
                        </div>
                      </div>

                      <div
                        className="form-group col-12 col-lg-6 col-md-6"
                        id="uploadLink"
                      >
                        <label>Video Links</label>
                        <div>
                          {videoLinks.map((videoLink, index) => (
                            <div key={index}>
                              <input
                                placeholder="Video Link"
                                className="form-control m-2"
                                name="link"
                                type="text"
                                value={videoLink}
                                onChange={(e) =>
                                  handleVideoLinkChange(e, index)
                                }
                              />
                              {index > 0 && (
                                <button
                                  className="btn-submit backtored"
                                  type="button"
                                  onClick={() => handleRemoveVideoLink(index)}
                                >
                                  <i
                                    className="fa fa-times"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              )}
                            </div>
                          ))}
                          <button
                            type="button"
                            onClick={handleAddMoreVideoLink}
                          >
                            Add More
                          </button>
                        </div>
                        {formErrors.videoLinks && (
                          <span className="text-danger">
                            {formErrors.videoLinks}
                          </span>
                        )}
                      </div>
                      <div className="form-group col-12 col-lg-6 col-md-6">
                        <label for="exampleFormControlTextarea1">
                          Description<span className="text-danger">*</span>
                        </label>
                        <ReactQuill
                          value={formdata.notes}
                          onChange={(value) =>
                            handleChange({
                              target: { name: "notes", value },
                            })
                          }
                        ></ReactQuill>
                        {formErrors.notes && (
                          <span className="text-danger">
                            {formErrors.notes}
                          </span>
                        )}
                      </div>

                      <div className="form-group col-12 col-lg-6 col-md-6 d-flex flex-column">
                        <label className="control-label">
                          Do you want to do bid
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control"
                          id="select"
                          value={formdata.bidStatus}
                          onChange={handleBidStatusChange}
                          name="bidStatus"
                        >
                          <option value="">Select Option</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                        {formErrors.bidStatus && (
                          <span className="text-danger">
                            {formErrors.bidStatus}
                          </span>
                        )}
                      </div>
                      {formdata.bidStatus === true && (
                        <>
                          <div className="form-group col-12 col-lg-6 col-md-6 d-flex flex-column">
                            <label className="control-label">
                              Bid Start Date{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              name="bidStartDate"
                              placeholder="Start date"
                              value={formdata.bidStartDate}
                              onChange={handleChange}
                              min={getCurrentDate()}
                            />
                            {formErrors.bidStartDate && (
                              <span className="text-danger">
                                {formErrors.bidStartDate}
                              </span>
                            )}
                          </div>

                          <div className="form-group col-12 col-lg-6 col-md-6 d-flex flex-column">
                            <label className="control-label">
                              Bid End Date{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              name="bidEndDate"
                              placeholder="End date"
                              min={formdata.bidStartDate}
                              value={
                                formdata.bidEndDate
                                  ? formdata.bidEndDate.split("T")[0]
                                  : ""
                              }
                              onChange={(e) => handleChange(e)}
                            />

                            {formErrors.bidEndDate && (
                              <span className="text-danger">
                                {formErrors.bidEndDate}
                              </span>
                            )}
                          </div>
                        </>
                      )}
                      <div className="col-md-12 d-flex justify-content-center">
                        <>
                          <button
                            className="btn-submit creategreen"
                            type="submit"
                          >
                            Create
                          </button>
                          <button
                            className="btn-submit backtored"
                            type="button"
                            onClick={() =>
                              navigate(
                                "/user/dashboard-industrial-probem-listing"
                              )
                            }
                          >
                            Back
                          </button>
                        </>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
}

export default IndustrialForm;
