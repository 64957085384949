import React, { useRef, useState, useEffect } from "react";
import AsideDashboard from "./aside";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import axiosInstance from "../../../axiosInstance";
import { ToastContainer, toast } from "react-toastify";
function BidProfile() {
  const tokenId = localStorage.getItem("techTokenID");
  const userId = localStorage.getItem("userId");
  const gridRef = useRef(null);
  const [filteredTechList, setFilteredTechList] = useState([]);
  const [paymentResponse, setPaymentResponse] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(
          `/technologyTransfers/getAllTechnologyBidByUserId/${userId}`
        );
        setFilteredTechList(response.data.data.reverse());
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    script.onload = () => {
      console.log("Razorpay SDK loaded");
    };
    script.onerror = () => {
      console.error("Failed to load Razorpay SDK");
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [tokenId]);

  const buyNow = async (bidAmount) => {
    const selectedBid = filteredTechList.find(item => item.selected); 
    if (!selectedBid) {
      toast.error("Please select a bid before making the payment.");
      return;
    }
    const amountInPaise = bidAmount * 100;
    const payload = {
      userId: userId,
      technologyTransferId: selectedBid.technologyTransferId,
      bidAmount: amountInPaise ,
    };

    try {
      const response = await axiosInstance.post(
        "/technologyTransfers/createOrder",
        payload
      );

      if (response.status === 200) {
        const responseData = response.data.data;
        console.log("===", responseData);

        const options = {
          key: "rzp_test_9XdMsRzcXJfFoF",
          amount: responseData.razorpayResponse.amount,
          currency: "INR",
          name: "TECPU",
          description: "Payment for your service",
          image: "/logo.png",
          order_id: responseData.razorpayResponse.id,
          handler: function (response) {
            toast.success("Payment Successful! Your transaction has been completed successfully",response.razorpay_payment_id); 
          },
          prefill: {
            name: "John Doe",
            email: "john@example.com",
            contact: "9999999999",
          },
          theme: {
            color: "#3399CC",
          },
        };
        console.log("option", options);
        // Create Razorpay instance
        const rzp = new window.Razorpay(options);
        rzp.open();
      }
    } catch (error) {
      console.error("Error making payment:", error);
    }
  };


  const columnDefs = [
    {
      headerName: "S.No.",
      valueGetter: (params) => params.node.rowIndex + 1 + ".",
      sortable: true,
      filter: true,
      width: 110,
    },
    {
      headerName: "Order ID",
      field: "technologyTransferId",
      sortable: true,
      filter: true,
      width: 250,
    },
    {
      headerName: "Bid Amount",
      field: "bidAmount",
      sortable: true,
      filter: true,
      width: 180,
    },
    {
      headerName: "Status",
      field: "selected",
      cellRenderer: (params) => {
        return params.value ? "Approved" : "Pending";
      },
      sortable: true,
      filter: true,
      width: 150,
    },
    {
      headerName: "Actions",
      cellRenderer: (params) => {
        const { selected, bidAmount } = params.data;
        return selected ? (
          <button  type="button"
          className="btn btn-submit btn-block btn-theme"
            onClick={() => buyNow(bidAmount)}
          >
            Buy Now
          </button>
        ) : null;
      },
      sortable: false,
      filter: false,
      width: 150,
    },
  ];
  return (
    <>
    <ToastContainer/>
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className=" is-active">
                  Bid Profile
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="main-sidenavigation pad-sec-50">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-3">
              <aside>
                <AsideDashboard />
              </aside>
            </div>

            <div className="col-md-9">
              <div className="top-heade-cretebtn d-flex justify-content-between align-items-center mb-3">
                <h4> Bid Profile</h4>
{/* 
                <a href="dashboard-industrial-form">
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </a> */}
              </div>
              <section className="stautus-chek-bid">
                <div className="table-responsive">
                  <div
                    className="ag-theme-alpine"
                    style={{ height: "100%", width: "100%" }}
                  >
                    <AgGridReact
                      ref={gridRef}
                      rowData={[...filteredTechList]}
                      columnDefs={columnDefs}
                      domLayout="autoHeight"
                      enableSorting={true}
                      enableFilter={true}
                      pagination={true}
                      paginationPageSize={10}
                    />
                  </div>
                </div>
                {/* ) : (
                  <div className="no-record-found text-center">
                    <p>No matching records found.</p>
                  </div>
                )} */}
              </section>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BidProfile;
