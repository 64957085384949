import React, { useState, useEffect } from "react";
import axiosInstance from "../../../../axiosInstance";
// import { ThreeDots } from 'react-loader-spinner';
import "../../Comman/comman.css";
import LoadingOverlay from "../../Comman/comman";
import { useLocation } from "react-router-dom";
import blankImage from "../../../../assests/blankImage.jpg";

import { useNavigate } from "react-router-dom";
function ExperticeAreaDetails() {
  const userId = localStorage.getItem("usercard_id");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const location = useLocation();
  const selectedUserId = location.state?.selectedUserId;

  const navigate = useNavigate();
  useEffect(() => {
    const getPolicyData = async () => {
      if (userId) {
        try {
          const response = await axiosInstance.get(
            `/users/getUserById/${userId}`
          );
          setData(response.data);
          setLoading(false);
        } catch (error) {
          setError("An error occurred while fetching data.");
          setLoading(false);
        }
      }
    };
    getPolicyData();
  }, [selectedUserId]);
  const handleButtonClick = () => {
    navigate("/contact");
  };
  return (
    <>
      {loading && <LoadingOverlay />}
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className=" is-active">
                  Expertise Area Details
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section className="industrail-details-main pad-sec-50">
        <div className="container">
          <div className="row d-flex">
            {loading ? (
              <div className="loader-container">
                {/* <ThreeDots color="#F23F2C" height={80} width={80} />
                 <h2>Please wait while data is loading....</h2> */}
              </div>
            ) : (
              <>
                {error ? (
                  <p>{error}</p>
                ) : data ? (
                  <>
                    <div className="col-lg-8">
                      <div className="industrial-details-content-in">
                        {data && data.title && <h2>{data.title}</h2>}
                        {/* <p><strong>Name :</strong> {data.firstName}</p> */}
                        <p>
                          <strong>Account Type :</strong>{" "}
                          {data.userType[0].name}
                        </p>
                        <p>
                          <strong>Company Name :</strong>{" "}
                          {data.additionalInfo.companyname ||
                            data.additionalInfo.organization}
                        </p>

                        {data.additionalInfo.businessentity && (
                          <p>
                            <strong>Business Entity :</strong>{" "}
                            {data.additionalInfo.businessentity}
                          </p>
                        )}
                        {!data.additionalInfo.businessentity &&
                          data.additionalInfo.designation && (
                            <p>
                              <strong>Designation :</strong>{" "}
                              {data.additionalInfo.designation}
                            </p>
                          )}

                        <p>
                          <strong>Salutation :</strong> {data.salutation}
                        </p>

                        {data.additionalInfo.thrustAreasOfResearch && (
                          <p>
                            <strong>Thrust Area of Research :</strong>{" "}
                            {data.additionalInfo.thrustAreasOfResearch}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="indsutrial-detail-inner-image">
                        {/* <img src={data.image} alt="User" /> */}
                        <img
                          src={data.image ? data.image : blankImage}
                          class="card-img-top"
                          alt="..."
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <p>No record found.</p>
                )}
              </>
            )}
          </div>
          <button className="custom-button" onClick={handleButtonClick}>
            Contact Us
          </button>
        </div>
      </section>
    </>
  );
}

export default ExperticeAreaDetails;
