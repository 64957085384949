import React, { useState, useEffect } from "react";
import axiosInstance from "../../../axiosInstance";
// import { ThreeDots } from "react-loader-spinner";
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./pics.css";
import { useNavigate, Link } from "react-router-dom";
import blankImage from "../../../assests/blankImage.jpg";

function Pics() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  let limit = 1000;

  const settings = {
    infinite: true,
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1008,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axiosInstance.get(
          `/industrialProblems/getAllIndustrialProblem?page=1&limit=${limit}`
        );

        const filteredData = response.data && response.data.items ? response.data.items.reverse() : [];
        setData(filteredData);
        
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    getData();
  }, []);

  const industrialProblemsHandleClick = (industrialProblems_id) => {
    localStorage.setItem("IndusTokenID", industrialProblems_id);

    navigate("/industrial-details");
  };

  return (
    <>
      {loading && <LoadingOverlay />}
      <div className="row align-items-center">
        <div className="col-12">
          <div className="carousel" style={{ width: "100%" }}>
            {loading ? (
              <div className="loader-container">
                {/* <ThreeDots color="#F23F2C" height={80} width={80} />
                <h2>Please wait while data is Loading ....</h2> */}
              </div>
            ) : data && data.length > 0 ? (
              // <div className="imgslider">
              <Slider {...settings}>
                {[...data.slice(0,5)].map((item) => (
                  <div key={item._id} className="fatured-boxs">
                    <div className="imagepart-fatured">
                      <img
                        src={item.images[0] ? item.images[0] : blankImage}
                        className="w-100"
                        alt=""
                      />
                    </div>
                    <div className="content-project-box text-center">
                      <h3>{item.title}</h3>
                      <p
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      ></p>
                      {/* <p>{item.description}</p> */}
                    </div>
                    <div className="view-details-btn text-center">
                      <button
                        onClick={() => industrialProblemsHandleClick(item._id)}
                        type="button"
                      >
                        View Details
                      </button>
                    </div>
                  </div>
                ))}
              </Slider>
            ) : (
              // </div>
              <div className="no-record-found text-center">
                <p style={{ color: "white" }}>Data not found.</p>
              </div>
            )}
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="text-center pt-5 space-on-mob">
                  <Link to="/industrial">
                    {" "}
                    <button type="button" className="btn-viewall">
                      View Details
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Pics;
