import React, { useEffect, useState } from "react";
import calendar from "../../../assests/user/user-images/calendar.png";
// import { ThreeDots } from 'react-loader-spinner';
import axiosInstance from "../../../axiosInstance";
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";

function OnlineEventDetail() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const [documents, setDocuments] = useState([]);

  useEffect(() => {
    const getData = async () => {
      const onlineEvents_id = localStorage.getItem("onlineEvents_id");
      const response = await axiosInstance.get(
        `/events/getEventById/${onlineEvents_id}`
      );
      setData(response.data);
      setLoading(false);
    };
    getData();

    setTimeout(() => {
      setLoading(false);
    }, 10000);
  }, []);
  return (
    <>
      {loading && <LoadingOverlay />}
      <section className="events-detal-main pad-sec-50">
        <div className="container">
          {loading ? (
            <div
              className="loader-container"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "200px",
              }}
            >
              {/* <ThreeDots color="#F23F2C" height={80} width={80} />
              <h2>Please wait while data is loading....</h2> */}
            </div>
          ) : (
            <div className="row">
              <div className="col-lg-7 col-md-7">
                <div className="heading-for-eve-detail w-100">
                  {data && data.title && <h2>{data.title}</h2>}
                  {data && data.description && (
                    <p
                      dangerouslySetInnerHTML={{ __html: data.description }}
                    ></p>
                  )}
                  <div className="date-time-shedule mb-4 pb-2">
                    <img src={calendar} alt="Calendar Icon" />
                    {data && data.eventDate && (
                      <span className="pl-2">{data.eventDate}</span>
                    )}
                  </div>
                  <div className="events-join-btn">
                    {data && data.meetingLink && (
                      <a
                        className="btnevents-deta"
                        href={data.meetingLink}
                        target="a_blank"
                      >
                        <i
                          className="fa fa-video-camera"
                          aria-hidden="true"
                        ></i>{" "}
                        Click here to join Event
                      </a>
                    )}
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <table className="tbale-eoi">
                        <thead>
                          <tr>
                            <th>S. No.</th>
                            <th>Title</th>
                            <th>PDF</th>
                          </tr>
                        </thead>
                        <tbody>
                          {documents.length > 0 ? (
                            documents.map((item, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{data.title}</td>
                                <td>
                                  <a
                                    target="_blank"
                                    href={item}
                                    rel="noreferrer"
                                  >
                                    <i
                                      className="fa fa-file-pdf-o"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Click here
                                  </a>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td></td>
                              <td>No records found</td>
                              <td></td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-5 col-md-5">
                <div className="side-image-event">
                  {data && data.image && (
                    <img
                      src={data.image}
                      className="mx-auto d-block img-fluid w-75 rounded"
                      alt=""
                    />
                  )}
                </div>
                <div className="tab-pane" role="tabpanel" id="PatentsofPU">
                  {/* =================== */}
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default OnlineEventDetail;
