import axiosInstance from "../../../axiosInstance";
import { useState, useEffect } from "react";
// import { ThreeDots } from 'react-loader-spinner';
import ReactPlayer from "react-player";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";

function Gallery() {
  const [allMedia, setAllMedia] = useState([]);
  const [latestMedia, setLatestMedia] = useState({});
  const latestMedia_id = sessionStorage.getItem("galleryId");
  const [loading, setLoading] = useState(true);
  const [selectedPostTitle, setSelectedPostTitle] = useState("");
  const selectedTile = sessionStorage.getItem("galleryTitle");

  useEffect(() => {
    const getGalleryData = async () => {
      try {
        const response = await axiosInstance.post("/galleries/getAll", {
          filter: {
            status: 1,
            isDeleted: false,
          },
        });
        setAllMedia(response.data.items);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    getGalleryData();
  }, []);

  useEffect(() => {
    if (latestMedia_id) {
      axiosInstance({
        url: `/galleries/getGalleryById/${latestMedia_id}`,
      })
        .then((response) => {
          console.log('response', response.data)
          setLatestMedia(response.data);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }

  }, [latestMedia_id]);

  const changeVisibleMedia = (updatedID) => {
    let updatedMediaObject = allMedia.find(
      (element) => element._id === updatedID
    );
    setLatestMedia(updatedMediaObject);
    setSelectedPostTitle(updatedMediaObject.title);
  };

  const extractVideoLink = (description) => {
    if (!description) {
      return null;
    }

    const youtubeRegex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = description.match(youtubeRegex);

    return match ? `https://www.youtube.com/watch?v=${match[1]}` : null;
  };

  const isMultipleImages = latestMedia.image && latestMedia.image.length > 1;
  const settings_achievemnets = {
    infinite: true,
    dots: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1008,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      {loading && <LoadingOverlay />}
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                <a href="/gallery" className="breadcrumbs__item">
                  {" "}
                  PU-TEC@ Gallery
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className="is-active">
                  {selectedPostTitle || selectedTile}
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="tecup-media-main pad-sec-30">
        <div className="container">
          {loading ? (
            <div className="loader-container">
              {/* <ThreeDots
                  color="#F23F2C"
                  height={80}
                  width={80}
                  className="pt-4"
                />
                <h2>Please wait while data is loading....</h2> */}
            </div>
          ) : (
            <div className="row">
              <div className="col-lg-7">
                <div className="left-for-detailsnews">
                  {latestMedia && latestMedia.title && (
                    <h3>{latestMedia.title} </h3>
                  )}
                  <div className="d-flex">
                    {latestMedia && latestMedia.date && (
                      <span className="whois-posted mb-2">
                        <i class="fa fa-user" aria-hidden="true"></i>{" "}
                        <i class="fa fa-calendar" aria-hidden="true"></i>
                        {/* {latestMedia.date.substring(0, 10)} */}
                      </span>
                    )}
                  </div>
                  {isMultipleImages ? (
                    <Slider {...settings_achievemnets}>
                      {latestMedia.image.map((image, index) => (
                        <div key={index}>
                          <img
                            src={image}
                            className="w-100 mt-2 mb-3"
                            alt={` ${index + 1}`}
                          />
                        </div>
                      ))}
                    </Slider>
                  ) : (
                    latestMedia &&
                    latestMedia.image && (
                      <img
                        src={latestMedia.image[0]}
                        className="w-100 mt-2 mb-3"
                        alt=""
                      />
                    )
                  )}
                  {latestMedia && latestMedia.description && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: latestMedia.description,
                      }}
                    ></p>
                  )}
                  {extractVideoLink(latestMedia.description) && (
                    <ReactPlayer
                      url={extractVideoLink(latestMedia.description)}
                      controls={true}
                    />
                  )}
                </div>
              </div>
              <div className="col-lg-5 gallery-right-tabs2">
                {allMedia.map((item) => {
                  return (
                    <>
                      <div className="right-new-onclick mb-4">
                        <div
                          className="news-boxclick d-flex justify-content-between align-items-start pointer"
                          onClick={() => changeVisibleMedia(item._id)}
                        >
                          <div className="image-slidernews w-25">
                            <img src={item.image[0]} className="w-100" alt="" />
                          </div>
                          <div className="content-new-slide w-75 ml-3">
                            <p>{item.title}</p>
                            {/* <span className='whois-posted mb-2'>
                              {item.date.substring(0, 10)}
                            </span> */}
                            <p
                              dangerouslySetInnerHTML={{
                                __html: item.description,
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default Gallery;
