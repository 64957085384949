import React, { useState, useEffect } from 'react';
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

function Chat_Tawkto() {
    useEffect(() => {
        // Load Tawk.to script
        const script = document.createElement('script');
        script.async = true;
        script.src = "https://embed.tawk.to/660e649ea0c6737bd12849a6/1hqk3i3mu";
        script.charset = 'UTF-8';
        script.setAttribute('crossorigin', '*');
        document.body.appendChild(script);
    
        return () => {
       
          document.body.removeChild(script);
        };
      }, []);
  return (
    <TawkMessengerReact />
  )
}

export default Chat_Tawkto