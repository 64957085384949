import { useState, useEffect } from "react";
import axiosInstance from "../../../axiosInstance";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";
import "./index.css"
// import { Oval } from 'react-loader-spinner';
function Contact() {
  const [termsData, setTermsData] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formdata, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formdata,
      [name]: value,
    });
  };
  const areAllFieldsFilled = () => {
    const { name, email, message } = formdata;
    return name && email && message;
  };
  const formSubmit = async (e) => {
    e.preventDefault();
    if (!areAllFieldsFilled()) {
      toast.error("All fields are required.");
      return;
    }
    try {
      setLoading(true);
      const payload = {
        name: formdata.name,
        email: formdata.email,
        message: formdata.message,
      };
      const response = await axiosInstance.post("/users/addContact", payload);
      toast.success("Data is saved successfully");
      setTimeout(() => {
        navigate("/");
      }, 1000);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error during login. Please try again.");
    }
  };
  useEffect(() => {
    const getSocialMediaData = async () => {
      try {
        const response = await axiosInstance.get("/socialMedia/getAll");
        setTermsData(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    getSocialMediaData();
  }, []);
  return (
    <>
      {loading && <LoadingOverlay />}

      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className=" is-active">
                  Contact us
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section className="contact_us pad-sec-50" id="contactus">
        <div className="container">
          <div className="row">
            <form onSubmit={formSubmit} className="w-100">
              <div className="col-md-12 offset-lg-1 col-lg-10 ">
                <div className="contact_inner">
                  <div className="row">
                    <div className="col-md-12 col-lg-10 col-sm-12">
                      <div className="contact_form_inner">
                        <div className="contact_field">
                          <h3>Contact Us</h3>
                          <p>
                            Feel Free to contact us any time. We will get back
                            to you as soon as we can!.
                          </p>
                          <input
                            type="text"
                            className="form-control form-group"
                            placeholder="Name"
                            name="name"
                            value={formdata.name}
                            onChange={handleChange}
                          />
                          <input
                            type="text"
                            className="form-control form-group"
                            placeholder="Email"
                            name="email"
                            value={formdata.email}
                            onChange={handleChange}
                          />
                          <textarea
                            className="form-control form-group"
                            placeholder="Message"
                            name="message"
                            value={formdata.message}
                            onChange={handleChange}
                          ></textarea>
                          <div className="col-md-12 d-flex justify-content-center">
                            {loading ? (
                              <div className="spinner">
                                {/* <Oval
                                    color="#00BFFF"
                                    height={30}
                                    width={30}
                                  /> */}
                              </div>
                            ) : (
                              <>
                                <button
                                  className="contact_form_submit"
                                  type="submit"
                                >
                                  Submit
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-2  col-sm-12 desktop-responsive">
                      <div className="right_conatct_social_icon d-flex align-items-end">
                        <div className="socil_item_inner d-flex ">
                          <ul>
                          {termsData.map((item, index) => (
                            <li key={index}>
                              <a
                                href={item.link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {item.title === "Facebook" && (
                                  <a
                                    href={item.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <i className="fa fa-facebook-f"></i>
                                  </a>
                                )}

                                {item.title === "Linkedin" && (
                                  <a
                                    href={item.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <i className="fa fa-linkedin"></i>
                                  </a>
                                )}

                                {item.title === "Youtube" && (
                                  <a
                                    href={item.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <i className="fa fa-youtube"></i>
                                  </a>
                                )}
                              </a>
                            </li>
                          ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="contact_info_sec">
                    <h4>Contact Info</h4>
                    <div className="d-flex info_single align-items-center">
                      <span>
                        <i
                          className="fa fa-phone fafa-colour"
                          aria-hidden="true"
                        ></i>
                        +91-111-0000000
                      </span>
                    </div>
                    <div className="d-flex info_single align-items-center">
                      <span>
                        <i
                          className="fa fa-envelope fafa-colour"
                          aria-hidden="true"
                        ></i>
                        director@tecpu.in
                      </span>
                    </div>
                    <div className="d-flex info_single align-items-center">
                      <span>
                        <i
                          className="fa fa-address-card fafa-colour"
                          aria-hidden="true"
                        ></i>
                        Technology Enabling Centre 1 <sup>st</sup> Floor Guru Tegh Bahadur
                        Bhawan Building, Opposite USOL, Panjab University Sector
                        14 Chandigarh, U.T. – 160014
                      </span>
                    </div>
                    <div className="right_conatct_social_icon d-flex align-items-end ipad-responsive">
                        <div className="socil_item_inner d-flex ">
                          <ul>
                          {termsData.map((item, index) => (
                            <li key={index}>
                              <a
                                href={item.link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {item.title === "Facebook" && (
                                  <a
                                    href={item.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <i className="fa fa-facebook-f"></i>
                                  </a>
                                )}

                                {item.title === "Linkedin" && (
                                  <a
                                    href={item.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <i className="fa fa-linkedin"></i>
                                  </a>
                                )}

                                {item.title === "Youtube" && (
                                  <a
                                    href={item.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <i className="fa fa-youtube"></i>
                                  </a>
                                )}
                              </a>
                            </li>
                          ))}
                          </ul>
                        </div>
                      </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>

      <section className="map_sec pad-sec-50" id="contactmap">
        <div className="container">
          <div className="row">
            <div className="col-md-10 offset-md-1">
              <div className="map_inner">
                <h2>Find Us on Google Map</h2>
                {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempore quo beatae quasi assumenda, expedita aliquam minima tenetur maiores neque incidunt repellat aut voluptas hic dolorem sequi ab porro, quia error.</p> */}
                <div className="map_bind">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3428.630001683954!2d76.76412087467769!3d30.756892984466194!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390fedbcab203195%3A0xd1f6dda05297e7b3!2sTechnology%20Enabling%20Centre!5e0!3m2!1sen!2sin!4v1681818603862!5m2!1sen!2sin"
                    width="100%"
                    height="450"
                    frameborder="0"
                    aria-hidden="false"
                    tabIndex={0}
                    allowFullScreen=""
                    style={{ border: "0" }}
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
}

export default Contact;
