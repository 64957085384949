import AsideDashboard from "./aside";
import React, { useState, useEffect, useCallback, useRef } from "react";
import axiosInstance from "../../../axiosInstance";
import { ThreeDots } from "react-loader-spinner";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";
import moment from "moment";

function IndustrialProblemListing() {
  const Navigate = useNavigate();
  const userid = localStorage.getItem("userId");
  const [data, setData] = useState([]);

  const [searchByName, setSearchByName] = useState("");
  const [filteredTechList, setFilteredTechList] = useState([]);
  const indusId = localStorage.getItem("industrialedit");
  const [loading, setLoading] = useState(true);

  const gridRef = useRef(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDeleteId, setItemToDeleteId] = useState("");
  const getData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `/industrialProblems/getIndustrialProblemByUserId?userId=${userid}`
      );
      setData(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const handleDeleteButtonClick = (indusId) => {
    setShowDeleteModal(true);

    setItemToDeleteId(indusId);
  };
  useEffect(() => {
    const filteredData = data.filter((item) =>
      item.title.toLowerCase().includes(searchByName.toLowerCase())
    );
    setFilteredTechList(filteredData);
  }, [data, searchByName]);
  const handleDeleteConfirmed = async () => {
    try {
      await axiosInstance.put(`/industrialProblems/delete/${itemToDeleteId}`);
      toast.success("Data deleted successfully!");

      setShowDeleteModal(false);

      getData();
    } catch (error) {
      toast.error("Error deleting data. Please try again later.");
    }
  };

  useEffect(() => {
    getData();
  }, []);
  const handleSearchByName = (element) => {
    if (searchByName) {
      return element.title.toLowerCase().includes(searchByName.toLowerCase());
    } else return true;
  };
  const findFilteredTechList = () => {
    setFilteredTechList(data.filter((element) => handleSearchByName(element)));
  };

  const resetFilterList = () => {
    // setFilteredTechList(data.data);
    getData();
    setSearchByName("");
  };

  const handleEdit = (latestAnnouncement_id) => {
    localStorage.setItem("latestindustrialeditt_id", latestAnnouncement_id);
    Navigate("/user/dashboard-industrial-prblm-edit");
  };

  const columnDefs = [
    {
      headerName: "S.No.",
      valueGetter: (params) => params.node.rowIndex + 1 + ".",
      sortable: true,
      filter: true,
      width: 110,
    },
    {
      headerName: "Title",
      field: "title",
      sortable: true,
      filter: true,
      width: 200,
    },
    {
      headerName: "Bid Start Date",
      // field: "bidStartDate",
      // sortable: true,
      // filter: true,
      width: 150,
      cellRenderer: (row) => (
        <span>{moment(row.data.bidStartDate).format("DD/MM/YYYY")}</span>
      )
    },
    {
      headerName: "Bid End Date",
      // field: "bidEndDate",
      // sortable: true,
      // filter: true,
      width: 150,
      cellRenderer: (row) => (
        <span>{moment(row.data.bidEndDate).format("DD/MM/YYYY")}</span>
      )
    },
    {
      headerName: "Description",
      field: "description",
      cellRenderer: (params) => {
        return <div dangerouslySetInnerHTML={{ __html: params.value }} />;
      },
      sortable: true,
      filter: true,
      width: 200,
    },
    {
      headerName: "Actions",
      cellRenderer: (row) => (
        <>
          <button
            className="edit-button pointer"
            onClick={() => handleEdit(row.data._id)}
          >
            <i className="fa fa-pencil"></i>
          </button>
          <button
            className="delete-button"
            onClick={() => handleDeleteButtonClick(row.data._id)}
          >
            <i className="fa fa-trash"></i>
          </button>
        </>
      ),
      sortable: false,
      filter: false,
      width: 150,
    },
  ];

  return (
    <>
      {loading && <LoadingOverlay />}
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className=" is-active">
                  {" "}
                  List your R&D Need / Technological Challenge
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="main-sidenavigation pad-sec-50">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-3">
              <aside>
                <AsideDashboard />
              </aside>
            </div>

            <div className="col-md-9">
              <div className="top-heade-cretebtn d-flex justify-content-between align-items-center mb-3">
                <h4> List your R&D Need / Technological Challenge</h4>

                <a href="dashboard-industrial-form">
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </a>
              </div>
              <section className="stautus-chek-bid">
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <input
                      className="form-control"
                      type="search"
                      placeholder="Search by name"
                      onChange={(e) => {
                        setSearchByName(e.target.value);
                      }}
                      value={searchByName}
                    />
                  </div>
                  <div className="form-group col-md-2">
                    <button
                      onClick={findFilteredTechList}
                      type="button"
                      className="btn btn-submit btn-block btn-theme"
                    >
                      Search
                    </button>
                  </div>
                  <div className="form-group col-md-2">
                    <button
                      onClick={resetFilterList}
                      type="button"
                      className="btn btn-submit btn-block"
                    >
                      Reset
                    </button>
                  </div>
                </div>
                {/* {loading ? (
                  <div className="loader-container">
                    <ThreeDots color="#F23F2C" height={80} width={80} />
                    <h2>Please wait while data is loading....</h2>
                  </div>
                ) : recordsFound ? ( */}
                <div className="table-responsive">
                  <div
                    className="ag-theme-alpine"
                    style={{ height: "100%", width: "100%" }}
                  >
                    <AgGridReact
                      ref={gridRef}
                      rowData={[...filteredTechList].reverse()}
                      columnDefs={columnDefs}
                      domLayout="autoHeight"
                      enableSorting={true}
                      enableFilter={true}
                      pagination={true}
                      paginationPageSize={10}
                    />
                  </div>
                </div>
                {/* ) : (
                  <div className="no-record-found text-center">
                    <p>No matching records found.</p>
                  </div>
                )} */}
              </section>
            </div>
          </div>
        </div>
      </section>
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirmed}>
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
      />
    </>
  );
}

export default IndustrialProblemListing;
