import React, { useEffect, useState } from "react";
import axiosInstance from "../../../../axiosInstance";
import blankImage from "../../../../assests/blankImage.jpg";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { ThreeDots } from "react-loader-spinner";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function DataCenterDetails() {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [newsData, setNewsData] = useState([]);
  const [reportsData, setReportsData] = useState([]);
  const [achievemnetData, setAchievemnetData] = useState([]);
  const _techcenterId = localStorage.getItem("TecCenterID");
  const _teccentertitle = localStorage.getItem("TecCentertitle");
  const [loading, setLoading] = useState(true);

  const settings_achievemnets = {
    infinite: true,
    dots: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1008,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const techCenterResponse = await axiosInstance.get(
          `/technicalCentres/getTechnicalCentreById/${_techcenterId}`
        );
        setData(techCenterResponse.data.data);

        const newsResponse = await axiosInstance.get(
          `/newsAnnouncements/getAllNewsByTechCentreId?technicalCentreId=${_techcenterId}`
        );
        setNewsData(newsResponse.data.items);

        const reportsResponse = await axiosInstance.get(
          `/reports/getAllReportForTechCentreId?technicalCentreId=${_techcenterId}`
        );
        setReportsData(reportsResponse.data);

        const achievementResponse = await axiosInstance.get(
          `/achievements/getAllAchievementForTechCentreId?technicalCentreId=${_techcenterId}`
        );
        setAchievemnetData(achievementResponse.data.items);

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, [_techcenterId]);

  useEffect(() => {}, [reportsData]);

  const nameOfUniversity = sessionStorage.getItem("teccenter");
  const AchievementHandleClick = (card_id, title, university_name) => {
    localStorage.setItem("achievementID", card_id);
    sessionStorage.setItem("title_achivement", title);
    sessionStorage.setItem("teccenter", data.nameOfUniversity);
    navigate("/teccenter-achivement");
  };
  const NewsHandleClick = (card_id) => {
    localStorage.setItem("teccenternewsTokenID", card_id);

    navigate("/latest-news-page");
  };

  return (
    <>
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                <a href="/teccenter" className="breadcrumbs__item">
                  Nationwide TEC Centers
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className=" is-active">
                  {nameOfUniversity}
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section className="datacenter">
        <div className="container">
          {loading ? (
            <div className="loader-container">
              <ThreeDots
                color="#F23F2C"
                height={80}
                width={80}
                className="pt-4"
              />
              <h2>Please wait while data is loading....</h2>
            </div>
          ) : (
            <div className="row">
              <div className="col-12 col-lg-4">
                <div className="left-side-university">
                  <div>
                    <img
                      className="w-100 rounded"
                      alt="Technical Center"
                      src={data.image}
                    />
                    <div className="datacenter-content pt-3 text-left">
                      <h3 className="title-here">
                        <h2>{data.nameOfUniversity}</h2>
                      </h3>
                      <p className="university-side-con">
                        <strong>Phase of Establishment:</strong>{" "}
                        {data.phaseOfEstablishment}
                      </p>
                      <p className="university-side-con">
                        <strong>Name of Coordinator:</strong>{" "}
                        {data.nameOfCoordinator}
                      </p>
                      <p className="university-side-con">
                        <strong>Contact Number:</strong>{" "}
                        {data.contactNumberOfCoordinator}
                      </p>
                      <p className="university-side-con">
                        <strong>Establishment Year:</strong>{" "}
                        {data.yearOfEstablishment}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="news-updated">
                  <div className="card-header news-updated-heding">
                    <h3>Updated News</h3>
                    <div className="marquree-play-btn">
                      <span>
                        <i className="fa fa-play-circle"></i>
                      </span>
                      <span>
                        <i className="fa fa-stop"></i>
                      </span>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <marquee
                          id="mymarquee"
                          behavior="scroll"
                          direction="up"
                          height="600"
                        >
                          <div className="news-container">
                            {newsData.length === 0 ? (
                              <p className="text-center">No data found</p>
                            ) : (
                              newsData.map((item) => (
                                <div
                                  className="card news-card"
                                  key={item._id}
                                  onClick={() => NewsHandleClick(item._id)}
                                >
                                  <img
                                    src={item.image}
                                    className="card-img-top"
                                    alt="News"
                                  />
                                  <div className="card-body">
                                    <p className="card-text">
                                      <strong>{item.title}</strong>
                                      <span className="date-author">
                                        {moment(item.date).format("DD/MM/YYYY")}
                                      </span>
                                    </p>
                                    <p className="card-text">
                                      {item.description}
                                    </p>
                                    <button className="btn btn-cu">
                                      Read More
                                    </button>
                                    <div className="view-details-btn text-center">
                                      <button type="button">
                                        View Details
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              ))
                            )}
                          </div>
                        </marquee>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-8">
                <div className="right-side-university">
                  <div className="main-heading-for-internalpage position-relative">
                    <h2>Achievement</h2>
                  </div>
                  <div className="datacenter-content" id="achivementdata">
                    <div className="row">
                      <div
                        className="carousel mx-auto"
                        style={{ width: "80%" }}
                      >
                        {achievemnetData && achievemnetData.length === 1 ? (
                          <div
                            key={achievemnetData[0]._id}
                            className="fatured-boxs"
                          >
                            <div className="imagepart-fatured">
                              <img
                                src={achievemnetData[0].image}
                                alt="Project"
                                className="w-100 rounded"
                              />
                            </div>
                            <div className="content-project-box text-center">
                              {/* <h3>{achievemnetData[0].title}</h3> */}
                              <h3
                                dangerouslySetInnerHTML={{
                                  __html: achievemnetData[0].title,
                                }}
                              ></h3>
                              {/* <p>{achievemnetData[0].description}</p> */}
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: achievemnetData[0].description,
                                }}
                              ></p>
                            </div>
                            <div className="view-details-btn text-center">
                              <button
                                onClick={() =>
                                  AchievementHandleClick(
                                    achievemnetData[0]._id,
                                    achievemnetData[0].title,
                                    achievemnetData[0].nameOfUniversity
                                  )
                                }
                                type="button"
                              >
                                View Details
                              </button>
                            </div>
                          </div>
                        ) : achievemnetData && achievemnetData.length > 0 ? (
                          <Slider {...settings_achievemnets}>
                            {achievemnetData.map((item) => (
                              <div key={item._id} className="fatured-boxs">
                                <div className="imagepart-fatured">
                                  <img
                                    src={item.image ? item.image : blankImage}
                                    alt="Project"
                                    className="w-100 rounded"
                                  />
                                </div>
                                <div className="content-project-box text-center">
                                  <h3>{item.title}</h3>
                                  <h3
                                    dangerouslySetInnerHTML={{
                                      __html: item.title,
                                    }}
                                  ></h3>
                                  {/* <p>{item.description}</p> */}
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: item.description,
                                    }}
                                  ></p>
                                </div>
                                <div className="view-details-btn text-center">
                                  <button
                                    onClick={() =>
                                      AchievementHandleClick(
                                        item._id,
                                        item.title,
                                        item.nameOfUniversity
                                      )
                                    }
                                    type="button"
                                  >
                                    View Details
                                  </button>
                                </div>
                              </div>
                            ))}
                          </Slider>
                        ) : (
                          <p className="text-center">
                            No achievement data available.
                          </p>
                        )}
                      </div>
                    </div>
                  </div>

                  <div>
                    <div id="accordion">
                      <div className="card">
                        <div className="card-header" id="headingTwo">
                          <h5 className="mb-0">
                            <button
                              className="btn btn-link collapsed"
                              data-toggle="collapse"
                              data-target="#collapseTwo"
                              aria-expanded="true"
                              aria-controls="collapseTwo"
                            >
                              Report{" "}
                            </button>{" "}
                          </h5>
                        </div>
                        <div
                          id="collapseTwo"
                          className="collapse show"
                          aria-labelledby="headingTwo"
                          data-parent="#accordion"
                        >
                          <div className="card-body">
                            <div className="col-md-12">
                              <div className="table-responsive w-100">
                                <table className="table-eoi w-100">
                                  <thead>
                                    <tr>
                                      <th style={{ width: "100px" }}>S. No.</th>
                                      <th>Title</th>
                                      <th>Pdf</th>
                                      {/* <th>Last Date</th> */}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {reportsData.length === 0 ? (
                                      <tr>
                                        <td colSpan="4">No data found.</td>
                                      </tr>
                                    ) : (
                                      reportsData.map((item, index) => (
                                        <tr key={item._id}>
                                          <td>{index + 1}</td>
                                          <td>{item.title}</td>
                                          <td>
                                            <a
                                              href={item.document}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              <i
                                                className="fa fa-file-pdf-o"
                                                aria-hidden="true"
                                              ></i>{" "}
                                              Click here
                                            </a>
                                          </td>
                                          {/* <td>
                                            <i className="fa fa-calendar" aria-hidden="true"></i> {moment(item.createdAt).format('DD/MM/YYYY')}
                                          </td> */}
                                        </tr>
                                      ))
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
export default DataCenterDetails;
