import { useState, useEffect } from "react";
import axiosInstance from "../../../axiosInstance";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { Oval } from 'react-loader-spinner';
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";
import './index.css';

function Footer() {
  const [documents, setDocuments] = useState([]);
  const [termsData, setTermsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAllReports, setShowAllReports] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    axiosInstance({
      url: `/reports/getAllReportForUser`,
    })
      .then((response) => {
        setDocuments(response.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);
  useEffect(() => {
    const getSocialMediaData = async () => {
      try {
        const response = await axiosInstance.get("/socialMedia/getAll");
        setTermsData(response.data);
      } catch (error) {}
    };
    getSocialMediaData();
  }, []);

  const [formdata, setFormData] = useState({
    email: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formdata,
      [name]: value,
    });
  };
  const formSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      const payload = {
        email: formdata.email,
      };
      const response = await axiosInstance.post(
        "/settings/createNewsletter",
        payload
      );
      const {status} = response
      if(status === 200) {
        toast.success("Your email is subscribed!");
      }
      
      // toast.success("Data is sent successfully");
      setFormData({ email: "" });
      // setTimeout(() => {
      //   navigate("/");
      // }, 1000);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(" Please enter email id .");
    }
  };
  const handleToggleReports = () => {
    navigate("/reports");
    setShowAllReports(showAllReports);
  };

  return (
    <>
      {loading && <LoadingOverlay />}
      <footer className="footer-main">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6">
              <div className="inner-content-footer cotbold-inner">
                <ul>
                  <li>
                    <i
                      className="fa fa-calendar fafa-colour"
                      aria-hidden="true"
                    ></i>{" "}
                    <a href="/online-events">Online Events</a>{" "}
                  </li>
                  <li>
                    <i
                      className="fa fa-phone fafa-colour"
                      aria-hidden="true"
                    ></i>{" "}
                    <a href="tel:+91-111-0000000">+91-111-0000000</a>{" "}
                  </li>
                  <li>
                    <i
                      className="fa fa-envelope fafa-colour"
                      aria-hidden="true"
                    ></i>{" "}
                    <a href="mailto: director@tecpu.in" className="mailto-btn">
                      director@tecpu.in
                    </a>
                  </li>
                  <li>
                    <ul className="social-media-links d-flex">
                      {termsData.map((item, index) => (
                        <li key={index}>
                          <a
                            href={item.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {item.title === "Facebook" && (
                              <a
                                href={item.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="facebook"
                              >
                                <i className="fa fa-facebook-f"></i>
                              </a>
                            )}

                            {item.title === "Linkedin" && (
                              <a
                                href={item.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="linkedin"
                              >
                                <i className="fa fa-linkedin"></i>
                              </a>
                            )}

                            {item.title === "Youtube" && (
                              <a
                                href={item.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="youtube"
                              >
                                <i className="fa fa-youtube"></i>
                              </a>
                            )}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </li>
                </ul>
              </div>
            </div>

            {/* <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="inner-content-footer">
                <ul>
                  <li>
                    <b>Reports</b>
                  </li>
                  {documents.length > 0 ? (
                    documents
                      .slice(0, showAllReports ? documents.length : 6)
                      .map((element) => (
                        <li key={element._id}>
                          <a
                            target="_blank"
                            href={element.document}
                            rel="noreferrer"
                          >
                            {element.title}
                          </a>
                        </li>
                      ))
                  ) : (
                    <li>No data found.</li>
                  )}
                </ul>
                {documents.length > 5 && !showAllReports && (
                  <div className="view-details-btn ">
                    <button onClick={handleToggleReports}>
                      View All Reports
                    </button>
                  </div>
                )}
              </div>
            </div> */}
             <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="inner-content-footer">
               <ul>
               <li>
                    <b>Important Links</b>
                  </li>
                <li> <a href="/technology"> Technology Transfer</a>{" "}</li>
                <li> <a href="/industrial"> Industrial Problem</a>{" "}</li>
                <li> <a href="/expertice-area-listing">Hire Expertise</a>{" "}</li>
                <li> <a href="/pucsr"> PU-CSR </a>{" "}</li>
                <li> <a href="/funding-Schemes">Funding Schemes </a>{" "}</li>
               </ul>
              </div>
            </div>
            <div className="col-lg-5 col-md-12 col-sm-6">
              <div className="inner-content-footer">
                <ul>
                  <li>
                    <h3>
                      Subscribe To Our<br></br> <b>Newsletter</b>!
                    </h3>
                  </li>
                  <li className="relative email-input-icon">
                    <i className="fa fa-envelope-o" aria-hidden="true"></i>
                    <input
                      type="email"
                      id="exampleFormControlInput1"
                      name="email"
                      placeholder="Your Email"
                      value={formdata.email}
                      onChange={handleChange}
                    />
                  </li>
                  <li>
                    <div className="col-md-12 d-flex justify-content-center">
                      {loading ? (
                        <div className="spinner">
                          {/* <Oval color="#00BFFF" height={30} width={30} /> */}
                        </div>
                      ) : (
                        <>
                          <button
                            className="contact_form_submit"
                            type="submit"
                            onClick={formSubmit}
                          >
                            Subscribe
                          </button>
                        </>
                      )}
                    </div>
                  </li>
                </ul>
                <ul className="mt-2">
                  
                  <li>
                    {" "}
                    <a href="/privacy-policy">Privacy policy</a> |{" "}
                    <a href="/term-and-condition">Terms & Conditions </a> |{" "}
                    <a href="/contact">Contact Us </a>{" "}
                  </li>
                </ul>
                {/* <div className='visitor-count w-100 text-center pt-3'>
                           <span>"01"</span>
                           <span>"0"</span>
                           <span>"0"</span>
                        </div> */}
              </div>
            </div>
          </div>
        </div>
      </footer>

      <ToastContainer />
    </>
  );
}

export default Footer;
