import React, { useEffect, useState } from 'react';
import validate from './validation';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Techno from '../../../assests/user/user-images/technofor-login.png';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import axiosInstance from '../../../axiosInstance'
import { padding } from '@mui/system';

function GoogleSignup() {
    const navigate = useNavigate();
    const [error, setError] = useState({});
    const [account, setAccount] = useState([]);
    const [checkdata, setCheckData] = useState(false);
    const [formdata, setFormdata] = useState({
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        mobile: '',
        password: '',
        confirmPassword: '',
        data: ''
      });
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormdata({
          ...formdata,
          [name]: value
        });
      }
      useEffect(() => {
        const fetchAccountData = async () => {
          try {
            const response = await axiosInstance.get('/userTypes/getAll');
            setAccount(response.data);
          } catch (error) {
            setAccount([]);
          }
        };
        fetchAccountData();
      }, []);
      const handleCheck = (e) => {
        const isChecked = e.target.checked;
        setCheckData(isChecked);
      };    
      const handleMobileBlur = async (e) => {
        const mobileValue = e.target.value;
        if (!/^[0-9]+$/.test(mobileValue) || mobileValue.length !== 10) {
            setError({ ...error, mobile: 'Mobile number must be 10 digits and contain only numeric characters.', apiError: '' });
        }
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const validationErrors = validate(
          formdata.firstName,
          formdata.lastName,
          formdata.email,
          formdata.mobile,
          formdata.password,
          formdata.confirmPassword,
          formdata.data
        );
    
        if (Object.keys(validationErrors).length > 0) {
          setError(validationErrors);
          return;
        }
    
        try {
          const payload = {
            firstName: formdata.firstName,
            lastName: formdata.lastName,
            email: formdata.email,
            mobileNo: formdata.mobile,
            password: formdata.password,
            confirmPassword: formdata.confirmPassword,
            userTypeId: formdata.data,
            agreeTermsCondition: checkdata,
          };
    
          const response = await axiosInstance.post('/users/register', payload);
    
          if (response.status === 200) {
            toast.success('Registration is successful!');
            localStorage.setItem('token', response?.data?.token);
            localStorage.setItem('userId', response?.data?._id);
            localStorage.setItem('accounttype', response?.data?.userTypeId);
    
            setTimeout(() => {
              navigate('/user/dashboard');
            }, 1000);
          } else if (response.status === 400 && response.data.message === "Mobile number already exists in our system.") {
            toast.error('Mobile number already exists. Please use a different number.');
          } else {
            toast.error(`Unexpected Error: Status Code ${response.status}`);
            console.error(`Unexpected Error: Status Code ${response.status}`);
          }
        } catch (error) {
          console.error('Error during registration:', error?.response?.data?.message);
    
          const errorMessage = error?.response?.data?.message || 'An error occurred during registration.';
          toast.error(` ${errorMessage}`);
    
        }
      };
        
  return (
   <>
     <div className="login-5 login-form-main register-ui-main pad-sec-50" id="loginpage">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="form-main-side position-relative w-100">
                <div className="login-logo-box text-center">
                  <img src={Techno} alt="logo" />
                </div>
                <form onSubmit={handleSubmit}>
                  <div className='row'>
                 
                    <div className='col-md-6 col-12'>
                      <div className="form-outline mb-4">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                         
                          <span className='position-absolute p-2'style={{ backgroundColor:'rgb(240, 240, 240)'}}>+91</span>
                          <input
                            type="text"
                            id="mobile"
                            name="mobile"
                            className="form-control"
                            placeholder="Mobile Number"
                            value={formdata.mobile}
                            minLength="10"
                            maxLength="10"
                            onChange={handleChange}
                            onBlur={handleMobileBlur}
                            style={{ width: '100%', padding: '0px 50px'}}
                          />
                        </div>
                        {error.mobile && (
                          <p style={{ color: 'red', textAlign: 'start', fontSize: '12px', paddingTop: '4px' }}>
                            {error.mobile}
                          </p>
                        )}

                      </div>
                    </div>

                
                   
                    <div className='col-md-6 col-12'>
                      <div className="form-outline mb-4">
                        <select id="data" name="data" className="form-control" value={formdata.data} onChange={handleChange} >
                          <option>Select Account Type</option>
                          {account.map((item) => (
                            <option key={item._id} value={item._id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        {error.roleId && (
                          <p style={{ color: 'red', textAlign: 'start', fontSize: '12px', paddingTop: '4px' }}>{error.roleId}</p>
                        )}
                      </div>
                    </div>
                    <div className='col-md-12 col-12'>
                      <div className="form-outline mb-3 d-flex align-items-center ml-4">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexCheckDefault"
                          checked={checkdata}
                          onChange={handleCheck}
                        />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                          I agree to the terms of service
                        </label>
                      </div>
                    </div>
                  


                    <div className='col-md-12 col-12'>
                      <div className="google-in-btn text-center pt-1  pb-1">
                        <button className="btn btn-block mb-3" type="button">
                          <a href="https://www.google.com/" target="_blank">
                            <i className="fa fa-google" style={{ color: 'white', margin: '5px', padding: '2px' }}>
                              <span className='pl-2'> Continue with Google</span>
                            </i>
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className='col-md-12 col-12'>
                      <div className="d-flex justify-content-around allready-member">
                        <p className="mb-0 me-2">
                          Already a member? <a href="/login">Login here</a>
                        </p>
                      </div>
                    </div>
                  </div>

                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
   </>
  )
}

export default GoogleSignup