import React, { useState, useEffect } from "react";
import axiosInstance from "../../../axiosInstance";
// import { ThreeDots } from 'react-loader-spinner';
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";
import "./index.css"

function TermCondition() {
  const [termsData, settermsData] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getPolicyData = async () => {
      try {
        const response = await axiosInstance.get("/settings/getTermsCondition");
        settermsData(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
    };
    getPolicyData();
  }, []);

  return (
    <>
      {loading && <LoadingOverlay />}
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                <a href="javascript: void(0)" className=" is-active">
                  {" "}
                  Terms & Conditions
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section className="term-and-condi pad-sec-30 position-relative">
        <div className="container">
          <div class="main-heading-for-internalpage position-relative">
            <h2>Terms & Conditions</h2>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="term-condi-content w-75 mx-auto rounded p-4">
                {loading ? (
                  <div className="loader-container">
                    {/* <ThreeDots color="#F23F2C" height={80} width={80} />
                    <h2>Please wait while data is loading....</h2> */}
                  </div>
                ) : (
                  <>
                    {termsData ? (
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: termsData.description,
                          }}
                        />
                      </>
                    ) : (
                      <p>No record found.</p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TermCondition;
