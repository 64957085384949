import React, { useState } from "react";
import axiosInstance from "../../../axiosInstance";
import Techno from '../../../assests/user/user-images/technofor-login.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function ForgotPassowrd() {
  const [email, setEmail] = useState('')

  const areAllFieldsFilled = () => {
    return email
  };

  // const isFilled = areAllFieldsFilled();
  const handleInput = (e) => {
    setEmail(e.target.value)
  }
  const reset = () => {
    setEmail('');

  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!areAllFieldsFilled()) {
      toast.error('Email is required.');
      return;
    }
    try {
      const payload = {
        email: email
      }
      const response = await axiosInstance.post('/users/sendForgotPasswordLink', payload);
      if (response.data.status === false) {
        toast.error(response.data.message);
      } else {
        toast.success('Email sent Successfully');
        reset();
      }
    } catch (error) {
      console.log('error', error)
    }
  }
  
  return (
    <>
      <div className="login-5 login-form-main pad-sec-50" id="loginpage">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="form-main-side position-relative">
                <div className="login-logo-box text-center">
                  <img src={Techno} alt="logo" />
                </div>
                <form onSubmit={handleSubmit} >


                  <div className="form-outline mb-4">
                
                    <input  type="email" id="form2Example11" className="form-control"
                      placeholder="Email Address" value={email} onChange={handleInput} />
                  </div>

                  <div className="sign-in-btn text-center pt-1 mb-4 pb-1">
                    <button className="btn  btn-block mb-3" type="submit" >Send Me Email</button>
                  </div>

                  <div className="d-flex justify-content-around allready-member">
                    <p className="mb-0 me-2 ">Already a member? <a href="/login"> Login here</a> </p>
                  </div>

                </form>
              </div>

            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}
export default ForgotPassowrd;