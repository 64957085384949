import { useState, useEffect } from "react";
// import { ThreeDots } from "react-loader-spinner";
import ReactPlayer from "react-player";
import "../../Comman/comman.css";
import LoadingOverlay from "../../Comman/comman";
import axiosInstance from "../../../../axiosInstance";

function AchivementDetail() {
  const [allMedia, setAllMedia] = useState([]);
  const [latestMedia, setLatestMedia] = useState({});
  const achieveID = localStorage.getItem("achievementID");
  const [loading, setLoading] = useState(true);
  const [selectedPostTitle, setSelectedPostTitle] = useState("");
  const selectedTitle = localStorage.getItem("achievementtitle");

  useEffect(() => {
    setLoading(true);
    axiosInstance({
      url: `/achievements/getAll`,
    })
      .then((response) => {
        setAllMedia(response.data.items);
        setLoading(false);
      })

      .catch((error) => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    axiosInstance({
      url: `/achievements/getAchievementById/${achieveID}`,
    })
      .then((response) => {
        setLatestMedia(response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  const changeVisibleMedia = (updatedID) => {
    let updatedMediaObject = allMedia.find(
      (element) => element._id === updatedID
    );
    setLatestMedia(updatedMediaObject);
    setSelectedPostTitle(updatedMediaObject.title);
  };

  const extractVideoLink = (description) => {
    if (!description) {
      return null;
    }

    const youtubeRegex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
    const match = description.match(youtubeRegex);

    return match ? `https://www.youtube.com/watch?v=${match[1]}` : null;
  };

  return (
    <>
      {loading && <LoadingOverlay />}
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className=" breadcrumbs__item">
                  Achievements Detail
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className="is-active">
                  {selectedPostTitle || selectedTitle}
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>

      {/* ======================================== */}
      <section className="tecup-media-main pad-sec-30">
        <div className="container">
          {/* {loading ? (
            <div className="loader-container">
              <ThreeDots
                color="#F23F2C"
                height={80}
                width={80}
                className="pt-4"
              />
              <h2>Please wait while data is loading....</h2>
            </div>
          ) : ( */}
          {loading && <LoadingOverlay />}

          <div className="row">
            <div className="col-lg-7">
              <div className="left-for-detailsnews">
                {latestMedia && latestMedia.title && (
                  <h3>{latestMedia.title} </h3>
                )}
                <div className="d-flex">
                  {latestMedia && latestMedia.date && (
                    <span className="whois-posted mb-2">
                      <i class="fa fa-user" aria-hidden="true"></i>{" "}
                      <i class="fa fa-calendar" aria-hidden="true"></i>
                      {/* {latestMedia.date.substring(0, 10)} */}
                    </span>
                  )}
                </div>
                <div className="col-lg-5">
                  <img
                    src={latestMedia.image}
                    className="mx-auto img-fluid w-100"
                    alt="Achievement"
                  />
                </div>

                {latestMedia && latestMedia.description && (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: latestMedia.description,
                    }}
                  ></p>
                )}
                {extractVideoLink(latestMedia.description) && (
                  <ReactPlayer
                    url={extractVideoLink(latestMedia.description)}
                    controls={true}
                  />
                )}
              </div>
            </div>
            <div className="col-lg-5 achievement-right-tabs2">
              {allMedia.map((item) => {
                return (
                  <>
                    <div className="right-new-onclick mb-4">
                      <div
                        className="news-boxclick d-flex justify-content-between align-items-start pointer"
                        onClick={() => changeVisibleMedia(item._id)}
                      >
                        <div className="image-slidernews w-25">
                          <img src={item.image} className="w-100" alt="" />
                        </div>
                        <div className="content-new-slide w-75 ml-3">
                          <p>{item.title}</p>
                          {/* <span className='whois-posted mb-2'>
                              {item.date.substring(0, 10)}
                            </span> */}
                          <p
                            dangerouslySetInnerHTML={{
                              __html: item.description.substring(0, 10),
                            }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
          {/* )} */}
        </div>
      </section>
    </>
  );
}

export default AchivementDetail;
