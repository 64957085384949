import axiosInstance from "../../../axiosInstance";
import React, { useState, useEffect } from "react";
// import { ThreeDots } from "react-loader-spinner";
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";

function TeccenterAchivement() {
  const achieveID = localStorage.getItem("achieveID");
  const title = sessionStorage.getItem("title_achivement");
  const [achievemnetData, setAchievemnetData] = useState([]);
  const [loading, setLoading] = useState(true);
  const _techcenterId = localStorage.getItem("TecCenterID");
  const nameOfUniversity = sessionStorage.getItem("teccenter");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const achievementResponse = await axiosInstance.get(
          `/achievements/getAllAchievementForTechCentreId?technicalCentreId=${_techcenterId}`
        );
        setAchievemnetData(achievementResponse.data.items);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [_techcenterId]);

  if (loading || achievemnetData.length === 0) {
    return (
      <>
        {loading && <LoadingOverlay />}
        <div className="loading-spinner">
          {/* <ThreeDots color="#000" height={50} width={50} /> */}
        </div>
      </>
    );
  }

  return (
    <>
      {loading && <LoadingOverlay />}
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                <a href="/teccenter" className="breadcrumbs__item">
                  {" "}
                  Nationwide TEC Centers
                </a>
                <a href="/data-center-details" className="breadcrumbs__item">
                  {nameOfUniversity}
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                {/* <a href="javascript: void(0)" className=" is-active">
                  {title}
                </a> */}
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="achivement-main-detail pad-sec-50">
        <div className="container">
          <div className="row border-bottom">
            <div className="col-lg-7">
              <div className="content-achivement-de">
                <h3 className="tec-center-head">
                  {achievemnetData[0]?.title || "No Title"}
                </h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: achievemnetData[0]?.description || "",
                  }}
                ></p>
              </div>
            </div>
            <div className="col-lg-5">
              <img
                src={achievemnetData[0]?.image || ""}
                className="mx-auto img-fluid w-100"
                alt=""
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4"></div>
          </div>
        </div>
      </section>
    </>
  );
}

export default TeccenterAchivement;
