import React, { useState, useEffect } from "react";
import AsideDashboard from "./aside";
import axiosInstance from "../../../axiosInstance";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Multiselect from "multiselect-react-dropdown";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";

function IndustrialProblemEdit() {
  const navigate = useNavigate();
  const userIndusId = localStorage.getItem("latestindustrialeditt_id");
  const [imageFiles, setImageFiles] = useState([]);

  const [selectedImages, setSelectedImages] = useState([]);
  const [newVideoLinks, setNewVideoLinks] = useState([]);
  const [category, setCategory] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [newAudioLinks, setNewAudioLinks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [videoLinks, setVideoLinks] = useState([""]);
  const [audioLinks, setAudioLinks] = useState([""]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const handleAddVideoLink = () => {
    setVideoLinks((prevLinks) => [...prevLinks, ""]);
  };
  const handleAddNewVideoLink = () => {
    setNewVideoLinks((prevLinks) => [...prevLinks, ""]);
  };

  const [data, setData] = useState({
    category: "",
    title: "",
    images: [],
    videos: [],
    audios: [],
    description: "",
    bidStatus: "",
    bidStartDate: "",
    bidEndDate: "",
  });
  useEffect(() => {
    fetchData();
  }, []);
  const handleBidStatusChange = (e) => {
    const { value } = e.target;
    const bidStatusValue = value === "true";

    setData({
      ...data,
      bidStatus: bidStatusValue,
    });
  };
  const handleDateChange = (e) => {
    const { name, value } = e.target;
    console.log(`Changing ${name} to ${value}`);
    setData({ ...data, [name]: value });
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const problemResponse = await axiosInstance.get(
        `/industrialProblems/getIndustrialProblemById/${userIndusId}`
      );
      const categoriesResponse = await axiosInstance.get(
        "/subCategories/getAllSubCategoryByCategoryId/64d9d3911cae26c449f95fa4"
      );

      const subCategoryOptions =
        problemResponse.data.data[0]?.subCategories?.map((item) => ({
          label: item.name,
          value: item._id,
        })) || [];

      let videoLinksArray =
        problemResponse.data.data[0]?.videos.map((video) =>
          video.replace(/^'(.*)'$/, "$1")
        ) || [];
      let audioLinksArray =
        problemResponse.data.data[0]?.audios.map((audio) =>
          audio.replace(/^'(.*)'$/, "$1")
        ) || [];
      const preFilledImagePreviews = problemResponse.data.data[0]?.images || [];
      // console.log('preFilledImagePreviews', JSON.parse(preFilledImagePreviews))
      setData({
        ...data,
        title: problemResponse.data.data[0]?.title,
        category: subCategoryOptions,
        images: JSON.parse(preFilledImagePreviews),
        audios: problemResponse.data.data[0]?.audios,
        description: problemResponse.data.data[0]?.description,
        videos: problemResponse.data.data[0]?.videos,
        bidStatus: problemResponse.data.data[0]?.bidStatus,
        bidStartDate: problemResponse.data.data[0]?.bidStartDate,
        bidEndDate: problemResponse.data.data[0]?.bidEndDate,
      });
      setSelectedCategory(subCategoryOptions);
      setCategory(categoriesResponse.data.data);
      setSelectedImages(JSON.parse(preFilledImagePreviews))

      setVideoLinks(videoLinksArray);
      setAudioLinks(audioLinksArray);
      setImagePreviews(preFilledImagePreviews);
      // setSelectedImages([...preFilledImagePreviews]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const handleRemoveVideoField = (indexToRemove) => {
    setVideoLinks((prevLinks) =>
      prevLinks.filter((_, index) => index !== indexToRemove)
    );
  };
  const handleRemoveAudioField = (indexToRemove) => {
    setAudioLinks((prevLinks) =>
      prevLinks.filter((_, index) => index !== indexToRemove)
    );
  };
  const handleUpdateNewVideoLink = (index, value) => {
    setNewVideoLinks((prevLinks) => {
      const updatedLinks = [...prevLinks];
      updatedLinks[index] = value;
      return updatedLinks;
    });
  };
  const handleRemoveNewVideoLink = (indexToRemove) => {
    setNewVideoLinks((prevLinks) =>
      prevLinks.filter((_, index) => index !== indexToRemove)
    );
  };
  const handleVideoLink = (e, index) => {
    const link = e.target.value;
    setVideoLinks((prevLinks) => {
      const updatedLinks = [...prevLinks];
      updatedLinks[index] = link;
      return updatedLinks;
    });
  };
  const handleAudioLink = (e, index) => {
    const link = e.target.value;
    setAudioLinks((prevLinks) => {
      const updatedLinks = [...prevLinks];
      updatedLinks[index] = link;
      return updatedLinks;
    });
  };
  const handleRemoveVideoLink = (indexToRemove) => {
    const updatedVideoLinks = videoLinks.filter(
      (_, index) => index !== indexToRemove
    );
    setVideoLinks(updatedVideoLinks);
  };

  const [formErrors, setFormErrors] = useState({
    category: "",
    title: "",
    images: "",
    description: "",
    notes: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {};
    setLoading(true);
    // if (selectedCategory.length === 0) {
    //   errors.category = "Category is required.";
    // }

    // if (!data.title.trim()) {
    //   errors.title = "Title is required.";
    // }

    // if (!data.description.trim()) {
    //   errors.description = "Description is required.";
    // }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      setLoading(true);
      const formData = new FormData();
      const selectedCategoriesString = selectedCategory
        .map((item) => item.value)
        .join(",");
      formData.append("categoryId", selectedCategoriesString);
      formData.append("bidStatus", data.bidStatus.toString());
      formData.append("title", data.title);
      formData.append("description", data.description);
      formData.append("bidStartDate", data.bidStartDate);
      formData.append("bidEndDate", data.bidEndDate);
      const allimageLinks = [...selectedImages];
      // const formattedImageLinks = allimageLinks.map((link) => `${link}`);
      formData.append("images", JSON.stringify(allimageLinks));
      const allVideoLinks = [...videoLinks, ...newVideoLinks];
      const formattedVideoLinks = allVideoLinks.map((link) => `${link}`);
      formData.append("videos", formattedVideoLinks);

      const allAudioLinks = [...audioLinks, ...newAudioLinks];
      const formattedAudioLinks = allAudioLinks.map((link) => `${link}`);
      formData.append("audios", formattedAudioLinks);
      // for (let [key, value] of formData.entries()) {
      //   console.log("90", `${key}: ${value}`);
      // }
      await axiosInstance.put(
        `/industrialProblems/update/${userIndusId}`,
        formData,

        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast.success("Data updated successfully");

      setTimeout(() => {
        navigate("/user/dashboard-industrial-probem-listing");
      }, 1000);
      setImageFiles([]);

      setLoading(false);
    } catch (error) {
      setLoading(false);

      toast.error("Error updating problem. Please try again.");
      console.log("err", error);
    }
  };
  const handleImageSelect = async (event) => {
    const imageFiles = event.target.files;

    try {
      setLoading(true);
      const imageUrls = [];
      for (let i = 0; i < imageFiles.length; i++) {
        const singleFileData = new FormData();
        singleFileData.append("image", imageFiles[i]);

        const response = await axiosInstance.post(
          "/tecTeams/uploadImage",
          singleFileData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        if (response) {
          imageUrls.push(response.data);
        }
      }


      // setData((oldData) => {...oldData, images: [...prevFormData.images, ...imageUrls]});
      setSelectedImages([...selectedImages, imageUrls])
      setImageFiles((prevImageFiles) => [...prevImageFiles, ...imageUrls]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleRemoveImage = (indexToRemove) => {
    const updatedImages = selectedImages.filter(
      (_, index) => index !== indexToRemove
    );
    const updatedPreviews = imagePreviews.filter(
      (_, index) => index !== indexToRemove
    );
    // setData((prevData) => ({
    //   ...prevData,
    //   images: updatedImages,
    // }));
    setSelectedImages(updatedImages);
    // setImagePreviews(updatedPreviews);

    // setImageFiles((prevImageFiles) => {
    //   const updatedFiles = [...prevImageFiles];
    //   updatedFiles.splice(, 1);
    //   return updatedFiles;
    // });
  };
  const handleAddVideoField = () => {
    setVideoLinks((prevLinks) => [...prevLinks, ""]);
  };
  const handleAddAudioField = () => {
    setAudioLinks((prevLinks) => [...prevLinks, ""]);
  };

 

  return (
    <>
      {loading && <LoadingOverlay />}
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className=" is-active">
                  Industrial Problems Edit
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="main-sidenavigation pad-sec-50">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-3">
              <aside>
                <AsideDashboard />
              </aside>
            </div>

            <div className="col-12 col-lg-9 main-wrapper-form pt-3 pb-3">
              <div className="deshboard-title">
                <h4> Industrial Problems Edit </h4>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="form-bg-box-inner">
                  <div className="main-form-for-dashboard">
                    <div className="row p-4">
                      <div className="form-group col-12 col-lg-6 col-md-6">
                        <label className="control-label">
                          Category<span className="text-danger">*</span>
                        </label>
                        <Multiselect
                          showCheckbox={true}
                          options={category.map((item) => ({
                            label: item.name,
                            value: item._id,
                          }))}
                          selectedValues={selectedCategory}
                          placeholder="Select Category"
                          onSelect={(selectedList) =>
                            setSelectedCategory(selectedList)
                          }
                          onRemove={(selectedList) =>
                            setSelectedCategory(selectedList)
                          }
                          displayValue="label"
                        />

                        {formErrors.category && (
                          <span className="text-danger">
                            {formErrors.category}
                          </span>
                        )}
                      </div>
                      <div className="form-group col-12 col-lg-6 col-md-6">
                        <label className="control-label">
                          Title<span className="text-danger">*</span>
                        </label>
                        <input
                          placeholder="Title"
                          className="form-control"
                          name="title"
                          type="text"
                          value={data.title}
                          onChange={(e) =>
                            setData({ ...data, title: e.target.value })
                          }
                        />

                        {formErrors.title && (
                          <span className="text-danger">
                            {formErrors.title}
                          </span>
                        )}
                      </div>

                      <div className="form-group col-12 col-lg-6 col-md-6 d-flex flex-column">
                        <label>
                          Upload images{" "}
                          <span className="text-danger">
                            (You can select multiple imagess)
                          </span>
                        </label>
                        <input
                          type="file"
                          name="img"
                          required=""
                          accept="images/*"
                          multiple
                          className="form-control"
                          onChange={handleImageSelect}
                        />
                      </div>
                      <div className="col-12 col-md-12">
                        <div className="selected-images">
                          {selectedImages.map((preview, index) => (
                            <div key={index} className="">
                              <img
                                src={preview}
                                // alt={``}
                                className="selected-image"
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  marginBottom: "10px",
                                }}
                              />
                              <button
                                type="button"
                                className="btn-submit backtored"
                                onClick={() => handleRemoveImage(index)}
                              >
                                <i class="fa fa-times" aria-hidden="true"></i>
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div
                        className="form-group col-12 col-lg-12 col-md-12"
                        id="uploadLink"
                      >
                        <label>Upload Audio Link</label>

                        {audioLinks.map((link, index) => (
                          <div className="d-flex align-item-center" key={index}>
                            <div className="form-group col-9">
                              <input
                                type="text"
                                className="form-control"
                                value={link}
                                onChange={(e) => handleAudioLink(e, index)}
                              />
                            </div>
                            <div className="form-group col-3">
                              <button
                                type="button"
                                className="btn-submit backtored"
                                onClick={() => handleRemoveAudioField(index)}
                              >
                                <i class="fa fa-times" aria-hidden="true"></i>
                              </button>
                            </div>
                          </div>
                        ))}
                        <div className="form-group col-6">
                          <button
                            type="button"
                            className="btn-submit creategreen"
                            onClick={handleAddAudioField}
                          >
                            Add More
                          </button>
                        </div>
                      </div>
                      <div
                        className="form-group col-12 col-lg-12 col-md-12"
                        id="uploadLink"
                      >
                        <label>Upload Video Link</label>

                        {videoLinks.map((link, index) => (
                          <div className="d-flex align-item-center" key={index}>
                            <div className="form-group col-9">
                              <input
                                type="text"
                                className="form-control"
                                value={link}
                                onChange={(e) => handleVideoLink(e, index)}
                              />
                            </div>
                            <div className="form-group col-3">
                              <button
                                type="button"
                                className="btn-submit backtored"
                                onClick={() => handleRemoveVideoField(index)}
                              >
                                <i class="fa fa-times" aria-hidden="true"></i>
                              </button>
                            </div>
                          </div>
                        ))}
                        <div className="form-group col-6">
                          <button
                            type="button"
                            className="btn-submit creategreen"
                            onClick={handleAddVideoField}
                          >
                            Add More
                          </button>
                        </div>
                      </div>

                      <div className="form-group col-12 col-lg-6 col-md-6">
                        <label htmlFor="exampleFormControlTextarea1">
                          Description<span className="text-danger">*</span>
                        </label>
                        <ReactQuill
                          className="quill-editor"
                          value={data.description}
                          onChange={(value) =>
                            setData({ ...data, description: value })
                          }
                        />
                        {formErrors.description && (
                          <span className="text-danger">
                            {formErrors.description}
                          </span>
                        )}
                      </div>
                      <div className="form-group col-12 col-lg-6 col-md-6 d-flex flex-column">
                        <label className="control-label">
                          Do you want to do bid
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control"
                          id="select"
                          value={data.bidStatus}
                          onChange={handleBidStatusChange}
                          name="bidStatus"
                        >
                          <option value="">Select Option</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      {data.bidStatus === true && (
                        <>
                          <div className="form-group col-12 col-lg-6 col-md-6 d-flex flex-column">
                            <label className="control-label">
                              Bid Start Date{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              name="bidStartDate"
                              placeholder="Start date"
                              value={
                                data.bidStartDate
                                  ? data.bidStartDate.split("T")[0]
                                  : ""
                              }
                              onChange={handleDateChange}
                            />
                            {formErrors.bidStartDate && (
                              <span className="text-danger">
                                {formErrors.bidStartDate}
                              </span>
                            )}
                          </div>

                          <div className="form-group col-12 col-lg-6 col-md-6 d-flex flex-column">
                            <label className="control-label">
                              Bid End Date{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              name="bidEndDate"
                              placeholder="End date"
                              value={
                                data.bidEndDate
                                  ? data.bidEndDate.split("T")[0]
                                  : ""
                              }
                              onChange={handleDateChange}
                            />

                            {formErrors.bidEndDate && (
                              <span className="text-danger">
                                {formErrors.bidEndDate}
                              </span>
                            )}
                          </div>
                        </>
                      )}
                      <div className="col-md-12 d-flex justify-content-center">
                        <>
                          <button
                            className="btn-submit creategreen"
                            type="submit"
                          >
                            Update
                          </button>
                          <button
                            className="btn-submit backtored"
                            type="button"
                            onClick={() =>
                              navigate(
                                "/user/dashboard-industrial-probem-listing"
                              )
                            }
                          >
                            Back
                          </button>
                        </>
                        {/* )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
}
export default IndustrialProblemEdit;
