import React, { useState, useEffect } from "react";
import axiosInstance from "../../../../axiosInstance";
// import { ThreeDots } from 'react-loader-spinner';
import { useNavigate } from "react-router-dom";
import "../../Comman/comman.css";
import LoadingOverlay from "../../Comman/comman";

function Achievements() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const achievementsHandleClick = (card_id) => {
    localStorage.setItem("achievementID", card_id);

    navigate("/achivement-detail");
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get("/achievements/getAll");
        setData(response.data.items);
        setLoading(false);
      } catch (error) {
        console.log("error", error);
        setLoading(false);
      }
    };
    getData();
  }, []);

  return (
    <>
      {loading && <LoadingOverlay />}

      <section className="pagecover"></section>

      <section className="achievements_sec">
        <div className="container">
          <div className="main-heading-for-internalpage position-relative">
            <h2>Achievements</h2>
          </div>
          <div className="row">
            {data.length > 0 ? (
              data.map((item, index) => (
                <div key={index} className="col-12 col-lg-4 col-sm-12 d-flex">
                  <div
                    className="achievements-box rounded"
                    style={{ cursor: "pointer" }}
                    onClick={() => achievementsHandleClick(item._id)}
                  >
                    <img
                      src={item.image}
                      className="mx-auto d-block img-fluid"
                      alt="Achievement"
                    />
                    {/* <a href='/achivement-detail'> */}
                    <h3>{item.title}.</h3>
                    <p>{item.description}</p>
                  </div>
                </div>
              ))
            ) : (
              <div className="no-record-found text-center">
                <p>No records found.</p>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default Achievements;
