import moment from 'moment';

const calculateTimeRemaning = (startDate, endDate) => {
    const now = moment();
    const start = moment(startDate);
    const end = moment(endDate);

    if (now.isBefore(start)) {
        return 'Bid is not started yet';
    } else if (now.isBetween(start, end)) {
        const duration = moment.duration(end.diff(now));
        const days = duration.days();
        const hours = duration.hours();
        return `${days} days, ${hours} hours`;
    } else if (now.isAfter(end)) {
        return 'Bid is closed';
    }
}

const bidBtnEnableDisable = (startDate, endDate) => {
    const now = moment();
    const start = moment(startDate);
    const end = moment(endDate);

    if (now.isBefore(start)) {
        return true;
    } else if (now.isBetween(start, end)) {
        const duration = moment.duration(end.diff(now));
        const days = duration.days();
        const hours = duration.hours();
        return false;
    } else if (now.isAfter(end)) {
        return true;
    }
}

export { calculateTimeRemaning,bidBtnEnableDisable };
