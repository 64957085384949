import React, { useState, useEffect } from "react";
import AsideDashboard from "./aside";
import axiosInstance from "../../../axiosInstance";
import "./index.css";
function Dashboards() {
  const [data, setData] = useState({});
  const UserId = localStorage.getItem("userId");
  const [profileCompletion, setProfileCompletion] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await axiosInstance.get(
          `/users/getUserById/${UserId}`
        );
        setData(response.data);
        const accountType = localStorage.getItem("accounttype");
        if (!accountType) {
        } else {
          const completionPercentage = calculateCompletionPercentage(
            response.data,
            accountType
          );
          setProfileCompletion(completionPercentage);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [UserId]);

  const calculateCompletionPercentage = (data, accountType) => {
    let fields = [];

    if (accountType === "64d9c542d3d363444c9862ff") {
      // Industry
      fields = [
        "firstName",
        "lastName",
        "email",
        "image",
        "mobileNo",
        "address",
        "pincode",
        "country",
        "state",
        "salutation",
        "companyName",
        "designation",
        "industySector",
        "technologySector",
        "typeOfBusinessEntity",
        "gstNumber",
        "registrationNumber",
        "employeeSize",
      ];
    } else if (accountType === "64d9c54fd3d363444c986308") {
      // Academic
      fields = [
        "firstName",
        "lastName",
        "email",
        "image",
        "mobileNo",
        "address",
        "pincode",
        "country",
        "state",
        "salutation",
        "organization",
        "department",
        "designation",
        "thurstAreaOfResearch",
        "areaOfExpertise",
        "otherInformation",
        "numberOfIndustrialProjects",
        "totalResearchExperience",
        "experienceOfIndustrialProject",
        "numberOfResearchPublications",
        "numberOfPatentGranted",
        "numberOfCurrentlyPublishedPatentApplications",
      ];
    }

    let filledFields = 0;
    fields.forEach((field) => {
      if (typeof data[field] === "string" && data[field].trim() !== "") {
        filledFields++;
      } else if (data[field]) {
        filledFields++;
      }
    });

    const completionPercentage = (filledFields / fields.length) * 100;

    return completionPercentage;
  };

  useEffect(() => {
    if (!isLoading && profileCompletion < 100) {
    }
  }, [profileCompletion, isLoading]);
  console.log('profileCompletion',profileCompletion)
  return (
    <>
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                <a href="/user/dashboard" className=" is-active">
                  Dashboard
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="main-sidenavigation pad-sec-50">
        <div className="container">
          <div className="main-heading-for-internalpage position-relative">
            {/* <h2>
              <i className="fa fa-user" aria-hidden="true"></i> Welcome{" "}
              {capitalizeFirstLetter(data.firstName)}
            </h2>{" "} */}
          </div>
          <div className="row">
            <div className="col-12 col-lg-3">
              <aside>
                <AsideDashboard />
              </aside>
            </div>

            <div className="col-12 col-lg-9">
              <div className="col-12">
                <div className="graph-logo-canva profile-comletion">
                  <div className="deshboard-title mb-3">
                    <h3 className="text-center">Profile Completion</h3>
                  </div>

                  <>
                    {!isLoading && (
                      <div className="container my-3">
                        <h5>Profile Completion</h5>
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: `${profileCompletion}%` }}
                            aria-valuenow={profileCompletion}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          >
                            { (profileCompletion === NaN || profileCompletion == 'NaN') ? 0.00 : profileCompletion.toFixed(2) }%
                          </div>
                        </div>
                        {/* {profileCompletion < 100 && (
                  <div className="alert alert-warning mt-2" role="alert">
                    Your profile is {profileCompletion.toFixed(2)}% complete.
                    Please fill out all fields to reach 100%.
                  </div>
                )} */}
                      </div>
                    )}
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default Dashboards;
