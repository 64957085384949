import React, { useState } from "react";
import axiosInstance from "../../../axiosInstance";
import Techno from "../../../assests/user/user-images/technofor-login.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
function ConfirmPassword() {
  const navigate = useNavigate();
  const mydata = window.location.search;
  const urlParams = new URLSearchParams(mydata);
  const param = urlParams.get("email");
  const [data] = useState(param);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [updatepassword, setUpdatePassword] = useState("");
  const [updateconfirmpass, setUpdateConfirmpass] = useState("");
  const [error, setError] = useState({});
  const areAllFieldsFilled = () => {
    return updatepassword && updateconfirmpass;
  };

 
  const handleConfirmPasswordKeyUp = () => {
    setPasswordsMatch(updatepassword === updateconfirmpass);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleInputKeyUp = (fieldName) => {
    if (fieldName === "password") {
      const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
      if (!passwordRegex.test(updatepassword)) {
        setError((prevError) => ({
          ...prevError,
          password:
            "Password should be at least 8 characters and contain at least 1 upper case, 1 lower case, 1 digit, and 1 symbol",
        }));
      } else {
        setError((prevError) => ({
          ...prevError,
          password: "", 
        }));
      }
    } else {
      setError((prevError) => ({
        ...prevError,
        [fieldName]: "", 
      }));
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!areAllFieldsFilled()) {
      setError({
        password: !updatepassword ? "Password is required." : "",
        confirmPassword: !updateconfirmpass ? "Confirm password is required." : "",
      });
      return;
    }
    if (!passwordsMatch) {
      setError({ confirmPassword: "Passwords do not match." });
      return;
    }
    try {
      const payload = {
        email: data,
        password: updatepassword,
        confirmPassword: updateconfirmpass,
      };
      const response = await axiosInstance.post(
        "/users/forgotPassword",
        payload
      );
      toast.success("Password reset is successfull");
      setTimeout(() => {
        navigate("/");
      }, 1000);
    } catch (error) {
      console.log("error", error);
      toast.error("Error during while changing the password. Please try again");
    }
  };
  return (
    <>
      <div className="login-5 login-form-main pad-sec-50" id="loginpage">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="form-main-side position-relative">
                <div className="login-logo-box text-center">
                  <img src={Techno} alt="logo" />
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="form-outline mb-4">
                    <input
                      type="hidden"
                      id="form2Example11"
                      className="form-control"
                      placeholder="email"
                      onChange={(e) => setUpdatePassword(e.target.value)}
                      value={updatepassword}
                    />
                  </div>

                  <div className="form-outline mb-4">
                    <input
                      type={showPassword ? "text" : "password"}
                      id="form2Example11"
                      className="form-control"
                      placeholder="Password"
                      onChange={(e) => setUpdatePassword(e.target.value)}
                      value={updatepassword}
                      onKeyUp={() => handleInputKeyUp("password")}
                    />
                      <span
                          className="password-toggle-icon position-absolute"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? (
                            <i className="fa fa-eye-slash" />
                          ) : (
                            <i className="fa fa-eye" />
                          )}
                        </span>
                        {error.password && (
                          <p
                            style={{
                              color: "red",
                              textAlign: "start",
                              fontSize: "12px",
                              paddingTop: "4px",
                            }}
                          >
                            {error.password}
                          </p>
                        )}
                  </div>

                  <div className="form-outline mb-4">
                    <input
                       type={showConfirmPassword ? "text" : "password"}
                      id="form2Example11"
                      className="form-control"
                      placeholder="Confirm Password"
                      onChange={(e) => setUpdateConfirmpass(e.target.value)}
                      value={updateconfirmpass}
                      onKeyUp={(e) => {
                        handleConfirmPasswordKeyUp(e);
                        handleInputKeyUp("confirmPassword");
                      }}
                    />
                     <span
                          className="password-toggle-icon position-relative"
                          onClick={toggleConfirmPasswordVisibility}
                        >
                          {showConfirmPassword ? (
                            <i className="fa fa-eye-slash" />
                          ) : (
                            <i className="fa fa-eye" />
                          )}
                        </span>
                        {!passwordsMatch && (
                          <p
                            style={{
                              color: "red",
                              textAlign: "start",
                              fontSize: "12px",
                              paddingTop: "4px",
                            }}
                          >
                            Passwords do not match.
                          </p>
                        )}
                          {error.confirmPassword && (
                          <p
                            style={{
                              color: "red",
                              textAlign: "start",
                              fontSize: "12px",
                              paddingTop: "4px",
                            }}
                          >
                            {error.confirmPassword}
                          </p>
                        )}
                  </div>
                  <div className="sign-in-btn text-center pt-1 mb-4 pb-1">
                    <button className="btn  btn-block mb-3" type="submit">
                      Submitl
                    </button>
                  </div>
                  <div className="d-flex justify-content-around allready-member">
                    <p className="mb-0 me-2 ">
                      Already a member? <a href="/login"> Login here</a>{" "}
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default ConfirmPassword;
