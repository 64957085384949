import React, { useState, useEffect } from "react";

import axiosInstance from "../../../axiosInstance";
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";
// import { ThreeDots } from 'react-loader-spinner';
function ChairpersonMessage() {
  const [chairmandata, setChairmanData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getChairmanData = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(
          "/settings/getChairmanMessage"
        );
        setChairmanData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching chairman data:", error);
        setLoading(false);
      }
    };
    getChairmanData();
  }, []);

  return (
    <>
      {loading && <LoadingOverlay />}
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className="breadcrumbs__item">
                  About Us
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className=" is-active">
                  Message from Chairperson
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="message-from-chairperson pad-sec-30">
        <div className="container">
          <div className="main-heading-for-internalpage position-relative">
            <h2>Message from Chairperson</h2>
          </div>

          {loading ? (
            <div className="loader-container">
              {/* <ThreeDots color="#F23F2C" height={80} width={80} /> */}
              {/* <h2>Please wait while data is loading....</h2> */}
            </div>
          ) : (
            <>
              {chairmandata ? (
                <>
                  {" "}
                  <div className="row">
                    <div className="col-12 col-md-4 col-12">
                      <div className="">
                        <div className="chairperson-image-part">
                          <img src={chairmandata.image} className="" alt="" />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-8 col-12">
                      <div className="chairperson-content">
                        <h3>{chairmandata.title}</h3>
                        <div
                          className="charper-co"
                          dangerouslySetInnerHTML={{
                            __html: chairmandata.description,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <p>No record found.</p>
              )}
            </>
          )}
        </div>
      </section>
      {/* Rest of your JSX */}
    </>
  );
}

export default ChairpersonMessage;
