import React, { useState, useEffect } from "react";
import axiosInstance from "../../../axiosInstance";
// import { ThreeDots } from 'react-loader-spinner';
import "./cumunitySection.css";
import { useNavigate } from "react-router-dom";
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";
function Community() {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;
  useEffect(() => {
    const getCommnuintydata = async () => {
      try {
        const response = await axiosInstance.post("/communitySections/getAll", {
          filter: {
            status: true,
            isDeleted: false,
          },
        });
        setData(response.data.items.reverse());
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    getCommnuintydata();
  }, []);

  const navigate = useNavigate();
  const communitySectionHandleClick = (
    communitySection_id,
    community_title
  ) => {
    localStorage.setItem("communitySection_id", communitySection_id);
    localStorage.setItem("community_title", community_title);

    navigate("/community-chat-details");
  };
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <>
      {loading && <LoadingOverlay />}

      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className=" is-active">
                  Community Section
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>
      {/* ============Community section main page start================================== */}
      <section className="pad-sec-50">
        <div className="container">
          {loading ? (
            <div className="loader-container">
              {/* <ThreeDots color="#F23F2C" height={80} width={80} /> */}
              {/* <h2>Please wait while data is loading....</h2> */}
            </div>
          ) : (
            <>
              {currentItems.length > 0 ? (
                <div className="row">
                  {currentItems.map((item) => (
                    <div
                      className="col-12 col-md-6 col-lg-3 pointer d-flex"
                      onClick={() =>
                        communitySectionHandleClick(item._id, item.title)
                      }
                      key={item._id}
                    >
                      <div className="cubox">
                        <img
                          src={item.image}
                          alt={item.title}
                          className="rounded  w-100"
                        />
                        <div className="contentpart-of-chatmain mt-2 mb-2">
                          <h3>{item.title}</h3>

                          <p
                            dangerouslySetInnerHTML={{ __html: item.title }}
                          ></p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="no-record-found text-center">
                  <p>No records found.</p>
                </div>
              )}
              <div className="row mt-5 web-page">
                <div className="col-12 col-lg-12">
                  <nav aria-label="Page navigation example">
                    <ul className="pagination justify-content-center">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setCurrentPage(currentPage - 1)}
                          disabled={currentPage === 1}
                        >
                          Previous
                        </button>
                      </li>
                      {/* Generate pagination buttons */}
                      {Array.from({
                        length: Math.ceil(data.length / itemsPerPage),
                      }).map((_, index) => (
                        <li
                          key={index}
                          className={`page-item ${
                            currentPage === index + 1 ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => setCurrentPage(index + 1)}
                          >
                            {index + 1}
                          </button>
                        </li>
                      ))}
                      <li
                        className={`page-item ${
                          currentPage === Math.ceil(data.length / itemsPerPage)
                            ? "disabled"
                            : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setCurrentPage(currentPage + 1)}
                          disabled={
                            currentPage ===
                            Math.ceil(data.length / itemsPerPage)
                          }
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
}

export default Community;
