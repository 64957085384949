import React, { useState, useEffect } from "react";
import axiosInstance from "../../../axiosInstance";
// import { ThreeDots } from 'react-loader-spinner';
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";
import { useNavigate } from "react-router-dom";
import blankImage from "../../../assests/blankImage.jpg";
import "./index.css"
function Industrial() {
  const navigate = useNavigate();

  const [categorylist, setCategoryList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getCategoryData = async () => {
      try {
        const response = await axiosInstance.get(
          "/subCategories/getAllSubCategoryByCategoryId/64d9d3911cae26c449f95fa4"
        );
        if (response.data && Array.isArray(response.data.data)) {
          setCategoryList(response.data.data);
          setLoading(false);
        }
      } catch (error) {}
    };
    getCategoryData();
  }, []);

  const handleSubCategoryClick = (card_id, categoryName) => {
    localStorage.setItem("indussubcaretogoryTokenID", card_id);
    localStorage.setItem("indussubcaretogoryName", categoryName);

    navigate("/category_listing");
  };

  return (
    <>
      {loading && <LoadingOverlay />}
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className=" is-active">
                  {" "}
                  Industrial Problem
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>

      {/* ==================industrial page design start ======================== */}
      <section className="industrial-listing-page tech-transfer-main pad-sec-50">
        <div className="main-heading-for-internalpage position-relative">
          <h2>Industrial Problem</h2>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 ">
              <div className="main-tabingof-allredytaken w-100">
                <div className="tab-content">
                  <div className="tab-pane active" role="tabpanel" id="pending">
                    {loading ? (
                      <div className="loader-container">
                        {/* <ThreeDots color="#F23F2C" height={80} width={80} />
                         <h2>Please wait while data is loading....</h2> */}
                      </div>
                    ) : categorylist.length > 0 ? (
                      <div className="row">
                        {categorylist.map((item) => (
                          <div
                            key={item._id}
                            className="col-lg-3 col-sm-12 col-md-6 mb-4 d-flex w-100"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleSubCategoryClick(item._id, item.name)
                            }
                          >
                            <div className="card border-0 bg-transparent rounded-0 mb-lg-0 d-block w-100 listdata-shadow">
                              <div className="list-image rounded">
                                <img
                                  src={item.image ? item.image : blankImage}
                                  alt={item.title}
                                />
                              </div>
                              <div className="card-main-ex-cont mt-1 p-2">
                                <h5>{item.title}</h5>
                                <p> {item.name}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="no-record-found text-center">
                        <p>No matching records found.</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Industrial;
