import React, { useState } from "react";
import AsideDashboard from "./aside";
import axiosInstance from "../../../axiosInstance";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function AddCommunitySection() {
  const MAX_WIDTH = 309;
  const MAX_HEIGHT = 193;

  const navigate = useNavigate();
  const [selectedImages, setSelectedImages] = useState([]);

  const [imagePreviews, setImagePreviews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formdata, setFormdata] = useState({
    title: "",
    notes: "",
    images: [],
    category: "",
    startDate: "",
  });
  const [formErrors, setFormErrors] = useState({
    category: "",
    title: "",
    images: "",

    description: "",
  });
  const [imageFiles, setImageFiles] = useState([]);

  const categoryData = [
    { value: "Industrial Problem", label: "Industrial Problem" },
    {
      value: "Solution to a Industrial Problem",
      label: "Solution to a Industrial Problem",
    },
    {
      value: "Novel Technology",
      label: "Novel Technology",
    },
    {
      value: "Research Idea",
      label: "Research Idea",
    },
    {
      value: "Start-up Idea",
      label: "Start-up Idea",
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormdata({
      ...formdata,
      [name]: value,
    });
  };

  const handleImage = async (event) => {
    const imageFiles = event.target.files;

    try {
      setLoading(true);
      const imageUrls = [];
      for (let i = 0; i < imageFiles.length; i++) {
        const file = imageFiles[i];

        if (!file.type.startsWith("image/")) {
          toast.error("Only image files can be uploaded.");
          event.target.value = null;
          continue;
        }

        const singleFileData = new FormData();
        singleFileData.append("image", file);

        const response = await axiosInstance.post(
          "/tecTeams/uploadImage",
          singleFileData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        if (response) {
          imageUrls.push(response.data);
        }
      }

      setFormdata((prevFormData) => ({
        ...prevFormData,
        images: [...prevFormData.images, ...imageUrls],
      }));

      setImageFiles((prevImageFiles) => [...prevImageFiles, ...imageUrls]);
      setLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveImage = (index) => {
    const updatedPreviews = [...imagePreviews];
    updatedPreviews.splice(index, 1);

    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);

    setImagePreviews(updatedPreviews);
    setSelectedImages(updatedImages);

    setImageFiles((prevImageFiles) => {
      const updatedFiles = [...prevImageFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {};

    if (!formdata.title.trim()) {
      errors.title = "Title is required.";
    }
    if (!formdata.notes.trim()) {
      errors.notes = "Description is required.";
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    try {
      setLoading(true);
      const formData = new FormData();

      formData.append("userId", localStorage.getItem("userId"));
      formData.append("title", formdata.title);

      formData.append("description", formdata.notes);

      formData.append("categoryOfDiscussion", formdata.category);
      formData.append("date", formdata.startDate);
      formData.append("image", imageFiles);

      const response = await axiosInstance.post(
        "communitySections/create",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data && response.data._id) {
        localStorage.setItem("industrialedit", response.data._id);
        setLoading(false);
      }
      if (!response.data.status) {
        toast.error(response.data.message);
        setLoading(false);
        return;
      }
      setFormdata({
        title: "",
        notes: "",
        link: "",
      });

      toast.success("Form submitted successfully!");
      setTimeout(() => {
        navigate("/user/dashboard-community-section");
      }, 1000);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error during form submission. Please try again.");
    }
  };
  function getCurrentDate() {
    const today = new Date();
    let month = String(today.getMonth() + 1);
    let day = String(today.getDate());
    const year = today.getFullYear();

    if (month.length === 1) {
      month = "0" + month;
    }
    if (day.length === 1) {
      day = "0" + day;
    }

    return `${year}-${month}-${day}`;
  }

  return (
    <>
      {loading && <LoadingOverlay />}
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="#" className=" is-active">
                  Community Section
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="main-sidenavigation pad-sec-50">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-3">
              <aside>
                <AsideDashboard />
              </aside>
            </div>

            <div className="col-12 col-lg-9 main-wrapper-form pt-3 pb-3">
              <div className="deshboard-title">
                <h4> Community Section </h4>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="form-bg-box-inner">
                  <div className="main-form-for-dashboard">
                    <div className="row p-4">
                      <div className="form-group col-12 col-lg-6 col-md-6">
                        <label className="control-label">
                          Title<span className="text-danger">*</span>
                        </label>
                        <input
                          placeholder="Title"
                          className="form-control"
                          name="title"
                          type="text"
                          value={formdata.title}
                          onChange={handleChange}
                        />
                        {formErrors.title && (
                          <span className="text-danger">
                            {formErrors.title}
                          </span>
                        )}
                      </div>
                      <div className="form-group col-12 col-lg-6 col-md-6">
                        <label for="exampleFormControlTextarea1">
                          Description<span className="text-danger">*</span>
                        </label>
                        <ReactQuill
                          value={formdata.notes}
                          onChange={(value) =>
                            handleChange({
                              target: { name: "notes", value },
                            })
                          }
                        ></ReactQuill>
                        {formErrors.notes && (
                          <span className="text-danger">
                            {formErrors.notes}
                          </span>
                        )}
                      </div>
                      <div className="form-group col-12 col-lg-6 col-md-6 d-flex flex-column">
                        <label>
                          Images Selected{" "}
                          <span className="text-danger">
                            (You can selected multiple images)
                          </span>
                        </label>
                        <input
                          type="file"
                          name="img"
                          required=""
                          accept="image/*"
                          multiple
                          className="form-control"
                          onChange={handleImage}
                        />
                        <div className="image-preview-container">
                          {imageFiles.map((preview, index) => (
                            <div key={index} className="image-preview-wrapper ">
                              <img
                                src={preview}
                                alt={` ${index + 1}`}
                                className="image-preview"
                              />
                              <button
                                type="button"
                                className="btn-submit backtored"
                                onClick={() => handleRemoveImage(index)}
                              >
                                <i class="fa fa-times" aria-hidden="true"></i>
                              </button>
                            </div>
                          ))}
                        </div>
                        {formErrors.images && (
                          <span className="text-danger">
                            {formErrors.images}
                          </span>
                        )}
                      </div>
                      <div className="form-group col-12 col-lg-6 col-md-6">
                        <label className="control-label">
                          Category<span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control"
                          id="select"
                          name="category"
                          value={formdata.category}
                          onChange={handleChange}
                        >
                          <option value="">Select Category</option>
                          {categoryData.map((item) => (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                        {formErrors.category && (
                          <span className="text-danger">
                            {formErrors.category}
                          </span>
                        )}
                      </div>
                      <div className="form-group col-12 col-lg-6 col-md-6 d-flex flex-column">
                        <label className="control-label">
                          Start Date <span className="text-danger">*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          name="startDate"
                          placeholder="Start date"
                          value={formdata.startDate}
                          onChange={(e) => {
                            const { name, value } = e.target;

                            const isValidDate = /^\d{4}-\d{2}-\d{2}$/.test(
                              value
                            );
                            if (isValidDate) {
                              setFormdata((prevData) => ({
                                ...prevData,
                                [name]: value,
                              }));
                            }
                          }}
                        />

                        {formErrors.startDate && (
                          <span className="text-danger">
                            {formErrors.startDate}
                          </span>
                        )}
                      </div>
                      <div className="col-md-12 d-flex justify-content-center">
                        <>
                          <button
                            className="btn-submit creategreen"
                            type="submit"
                          >
                            Create
                          </button>
                          <button
                            className="btn-submit backtored"
                            type="button"
                            onClick={() =>
                              navigate("/user/dashboard-community-section")
                            }
                          >
                            Back
                          </button>
                        </>
                        {/* )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
}

export default AddCommunitySection;
