import React, { useState, useEffect } from "react";

import LogoSvg from "../../../assests/images/techno.svg";
import {  toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";

import axiosInstance from "../../../axiosInstance";
import "../../../assests/css/responsive.css";

import blankImage from "../../../assests/blankImage.jpg";
import './index.css';

function Header() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [userData, setUserData] = useState({});
  const token = localStorage.getItem("token");
  const [initialCheck, setInitialCheck] = useState(true);

  const [active, setActive] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [loggingOut, setLoggingOut] = useState(false);
  const userId = localStorage.getItem("userId");
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axiosInstance.get(
          `technologyTransfers/getAllTechnologyTransfer?page=1&limit=1000&isApproved=true`
        );
        setData(response.data.data.items);
      } catch (error) {
        console.log("error", error);
      }
    };

    getData();
  }, []);
  const [showToast, setShowToast] = useState(false);
  const handleMenuToggle = () => {
    setActive(!active);
    if (!active) {
      document.body.classList.add('bodyoverlay'); // Add your desired class name here
    } else {
      document.body.classList.remove('bodyoverlay');
    }
  };
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  useEffect(() => {
    let isMounted = true;

    const getUserData = async () => {
      try {
        const response = await axiosInstance.get(
          `/users/getUserById/${userId}`
        );

        setUserData(response.data);

        if (
          !initialCheck &&
          response.data.status === "Inactive" &&
          !loggingOut &&
          !showToast &&
          isMounted
        ) {
          localStorage.removeItem("token");
          localStorage.removeItem("userId");
          sessionStorage.removeItem("image");
          setShowToast(true);

          toast.error("Your account is inactive. You have been logged out.", {
            position: "top-right",
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            onClose: () => {
              setShowToast(false);
            },
          });
          navigate("/login");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (token && userId) {
      getUserData();
    }

    if (initialCheck) {
      setInitialCheck(false);
    }

    return () => {
      isMounted = false;
    };
  }, [token, userId, navigate, loggingOut, showToast, initialCheck]);

  const image = localStorage.getItem("image");
  const handleChange = (e) => {
    setSearchText(e.target.value);
  };
  const filteredData =
    data &&
    data.filter((item) => {
      return item.title.toLowerCase().includes(searchText.toLowerCase());
    });
  const capitalizeFirstLetter = (string) => {
    return string && string.charAt(0).toUpperCase() + string.slice(1);
  };
  return (
    <>
      <header  className={isSticky ? 'sticky-header top-header-main' : 'top-header-main'}>
        {/* main-logo-header-start */}
        <div className="main-header">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-lg-6 col-xxl-6 col-md-6 col-sm-6 d-flex flex-row">
                <div className="header-logo-main logoside-line d-flex align-items-center">
                  <a href="/">
                    <img src={LogoSvg} alt="Techno" />
                  </a>
                </div>
              </div>

              <div className="col-lg-3 col-xxl-3 col-md-6 col-sm-6 d-flex flex-row justify-content-end">
                <div className="search-bar">
                  <input
                    type="text"
                    name="search"
                    placeholder="Search here..."
                    value={searchText}
                    onChange={(e) => handleChange(e)}
                  />
                  <span className="fontside-sea">
                    <i
                      className="fa fa-search fasearchhead"
                      aria-hidden="true"
                    ></i>
                  </span>
                </div>
              </div>

              {searchText && (
                <div className="position-absolute filter-search">
                  <ul>
                    {filteredData &&
                      filteredData.map((item) => (
                        <li key={item._id}>
                          <Link
                            to="/technology"
                            onClick={() => setSearchText("")}
                          >
                            {item.title}
                          </Link>
                        </li>
                      ))}
                           </ul>

                    {filteredData &&
                      filteredData.length === 0 &&
                      searchText.length > 0 && <p className="text-center">No match found</p>}
             
                </div>
              )}

              <div className="col-lg-3 col-xl-3 col-md-12 col-sm-6 d-flex flex-row justify-content-end login-mobile">
                <div className="right-content-top d-flex">
                  {!token || userData.status === "Inactive" ? (
                    <div className="d-flex">
                      <i
                        className="fa fa-sign-in falogin-ic d-flex justify-content-center align-items-center"
                        aria-hidden="true"
                      ></i>
                      <Link to="/login" onClick={() => setLoggingOut(true)}>
                        Sign In
                      </Link>
                    </div>
                  ) : (
                    <div>
                      <Link to="/user/dashboard">
                        <img
                          src={
                            userData.image === "null" || !userData.image
                              ? blankImage
                              : userData.image || image
                          }
                          className="userdashboard-profile"
                          alt=""
                        />
                        Welcome, {capitalizeFirstLetter(userData.firstName)}
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bottom-header">
          {/* navigation-header-start */}
          <div className="nabbar-main">
            <div className="container">
              <nav className="navbar navbar-expand-lg navbar-light d-flex justify-content-between justify-content-sm-end ">
                <button
                  className="navbar-toggler"
                  type="button"
                  id="menutoggle"
                  onClick={handleMenuToggle}
                >
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div
                  className={`collapse navbar-collapse ${active ? "show" : ""}`}
                >
                  <ul className="navbar-nav nab-list-child">
                    <li className="nav-item">
                      <a className="nav-link active" href="/">
                        <i className="fa fa-home" aria-hidden="true"></i> Home
                        <span className="sr-only">(current)</span>
                      </a>
                    </li>
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        href="/"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        About us
                      </a>

                      <ul
                        className="dropdown-menu nav-dropdown-list"
                        aria-labelledby="navbarDropdown"
                      >
                        <li>
                          <a className="dropdown-item" href="/vision-mission">
                            Vision Mission
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/about-introduction"
                          >
                            Objective
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="/message-from-chairperson"
                          >
                            Message from Chairperson
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/about-teams">
                            TEC Teams
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="/establishment">
                            Establishment/History
                          </a>
                        </li>
                      </ul>
                    </li>

                    <li className="nav-item">
                      <a className="nav-link" href="/technology">
                        Technology Transfer
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="/industrial">
                        Industrial Problem
                      </a>
                    </li>

                    <li className="nav-item">
                      <a className="nav-link " href="/expertice-area-listing">
                        Hire Expertise
                      </a>
                    </li>

                    <li className="nav-item">
                      <a className="nav-link " href="/pucsr">
                        PU-CSR
                      </a>
                    </li>
                    <li className="nav-item">
                      <a href="/funding-Schemes" className="nav-link ">
                        Funding Schemes
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="/contact">
                        Contact Us
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link " href="/community">
                        Community Section
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <div className="overlaynav"></div>
    </>
  );
}

export default Header;
