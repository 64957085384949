import React, { useEffect, useState } from "react";
import axiosInstance from "../../../../axiosInstance";
import "../../Comman/comman.css";
import LoadingOverlay from "../../Comman/comman";

function EstablishmentHistory() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    try {
      setLoading(true);
      const resposne = await axiosInstance.get(
        "settings/getEstablishmentHistory"
      );
      setData(resposne.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);

      console.log(err);
    }
  };

  return (
    <>
      {loading && <LoadingOverlay />}
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className="breadcrumbs__item">
                  About Us
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className=" is-active">
                  Establishment/History
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>
      {/* =============================== */}
      <section className="Establishment-main pad-sec-50 position-relative">
        <div className="container">
          <div className="main-heading-for-internalpage position-relative">
            <h2>Establishment/History</h2>
          </div>
          <div className="row">
            <div className="d-flex align-items-center">
              <div className="col-md-12  col-sm-12">
                <div className="content-for-detal-bid">
                  <h2 className="text-center">{data.title}</h2>
                  <p dangerouslySetInnerHTML={{ __html: data.description }}></p>
                  <p></p>
                </div>
              </div>
              {/* <div className="col-md-5 col-sm-12 ">
                <div className="image-sec-bid">
                  <img src={data.image} className="w-100" />
                  <div className="hero-dot-shape">
                    <img
                      src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAR0AAAD0AgMAAADbOBoWAAAADFBMVEUAAAAGGTcGGTcGGTe5plmTAAAABHRSTlMAFDMBK9A4kAAAAGVJREFUeNrt1KENwEAMBEG3m4rTTtLBE4NfaQYctGSy885vY56Zjdk7tPYawDU0W7OBDs3WbKBDszUb6NBszQY6NFuzgQ7N1mygQ7M1G+jQbM0GOjRbs4EOzdZsoEOzNRvo0OzjfMZQ55AXtqwRAAAAAElFTkSuQmCC"
                      alt="shape"
                    />
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex align-items-center">
                <div className="content-for-detal-bid">
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default EstablishmentHistory;
