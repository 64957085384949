import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

function AsideDashboard() {
  const navigate = useNavigate();
  const accountType = localStorage.getItem("accounttype");
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/login");
    toast("Please Login again");
    window.location.reload();
  };

  const handleLogoutConfirmed = () => {
    handleLogout();
    setShowLogoutModal(false);
  };

  return (
    <>
      <ul className="sidebar_link">
        <li>
          <a href="/user/dashboard">
            <i className="fa fa-tachometer" aria-hidden="true"></i> Dashboard
          </a>
        </li>
        <li>
          <a href="/user/dashboard-update-profile-listing">Update Profile</a>
        </li>

        {accountType === "64d9c542d3d363444c9862ff" ? (
          <li>
            <a href="/user/dashboard-industrial-probem-listing">
              List your R&D Need / Technological Challenge
            </a>
          </li>
        ) : (
          <li>
            <a href="/user/dashboard-tech-transfer-listing">
              List your Technology
            </a>
          </li>
        )}
        <li>
          <a href="/user/dashboard-community-section">Community Section</a>
        </li>
        <li>
          <a href="/user/dashboard-bid-profile">Bid Profile</a>
        </li>

        <li>
          <a href="# " onClick={() => setShowLogoutModal(true)}>
            <i className="fa fa-sign-out" aria-hidden="true"></i> Log Out
          </a>
        </li>
      </ul>
      <ToastContainer />
      <Modal show={showLogoutModal} onHide={() => setShowLogoutModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to log out?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowLogoutModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleLogoutConfirmed}>
            Logout
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AsideDashboard;
