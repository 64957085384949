import AsideDashboard from "./aside";
import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "../../../axiosInstance";
// import { ThreeDots } from "react-loader-spinner";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";

function CommunitySectionListing() {
  const navigate = useNavigate();
  const userid = localStorage.getItem("userId");
  const [loading, setLoading] = useState(true);
  const limit = 20;
  const gridRef = useRef(null);
  const [gridApi, setGridApi] = useState(null);
  const [data, setData] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDeleteId, setItemToDeleteId] = useState("");
  const [searchByName, setSearchByName] = useState("");
  const [filteredTechList, setFilteredTechList] = useState([]);
  const [recordsFound, setRecordsFound] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `/communitySections/getCommunitySectionByUserId?userId=${userid}`
      );
      const activeData = response.data.data.filter((item) => !item.isDeleted);
      const sortedData = activeData.sort((a, b) => {
        return b._id.localeCompare(a._id);
      });
      setData(sortedData);
      setLoading(false);
      setFilteredTechList(sortedData);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  // useEffect(() => {
  //   const filteredData = data.filter((item) =>
  //     item.title.toLowerCase().includes(searchByName.toLowerCase())
  //   );
  //   setFilteredTechList(filteredData);
  // }, [data, searchByName]);
  const handleSearch = () => {
    findFilteredTechList();
  };
  useEffect(() => {
    if (gridApi) {
      gridApi.setGridOptions({ suppressPaginationPanel: false });
    }
  }, [gridApi]);
  const handleSearchByName = (element) => {
    if (searchByName) {
      return element.title.toLowerCase().includes(searchByName.toLowerCase());
    } else {
      return true;
    }
  };
  const findFilteredTechList = () => {
    setFilteredTechList(data.filter((element) => handleSearchByName(element)));
  };
  const resetFilterList = () => {
    fetchData();
    // setFilteredTechList(data.data);
    setSearchByName("");
  };
  useEffect(() => {
    fetchData();
  }, []);
  const columnDefs = [
    {
      headerName: "S.No.",
      valueGetter: (params) => params.node.rowIndex + 1 + ".",
      sortable: true,
      filter: true,
      width:100,
    },

    {
      headerName: "Title",
      field: "title",
      sortable: true,
      filter: true,
      width: 200,
    },
    {
      headerName: "Description",
      field: "description",
      cellRenderer: (params) => {
        return <div dangerouslySetInnerHTML={{ __html: params.value }} />;
      },
      sortable: true,
      filter: true,
      width:250,
    },

    {
      headerName: "Actions",
      cellRenderer: (row) => (
        <>
          <button
            className="edit-button pointer"
            onClick={() => handleEdit(row.data._id)}
          >
            <i className="fa fa-pencil"></i>
          </button>
          <button
            className="delete-button"
            onClick={() => handleDeleteButtonClick(row.data._id)}
          >
            <i className="fa fa-trash"></i>
          </button>
        </>
      ),
      sortable: false,
      filter: false,
      width:150,
    },
  ];
  const handleEdit = (id) => {
    localStorage.setItem("latestcommunityeditt_id", id);
    navigate("/user/dashboard-community-section-edit");
  };
  const handleDeleteButtonClick = (indusId) => {
    setShowDeleteModal(true);

    setItemToDeleteId(indusId);
  };
  const handleDeleteConfirmed = async () => {
    try {
      setLoading(true);

      await axiosInstance.put(`/communitySections/delete/${itemToDeleteId}`);
      toast.success("Data deleted successfully!");

      setShowDeleteModal(false);

      fetchData();
      setLoading(false);
    } catch (error) {
      toast.error("Error deleting data. Please try again later.");
      setLoading(false);
    }
  };
  return (
    <>
      {loading && <LoadingOverlay />}
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className=" is-active">
                  {" "}
                  Community Section Listing
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="main-sidenavigation pad-sec-50">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-3">
              <aside>
                <AsideDashboard />
              </aside>
            </div>

            <div className="col-md-12 col-lg-9">
              <div className="top-heade-cretebtn d-flex justify-content-between align-items-center mb-3">
                <h4> Community Section Listing</h4>
                <a href="dashboard-community-add">
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </a>
              </div>
              <section className="stautus-chek-bid">
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <input
                      className="form-control"
                      type="search"
                      placeholder="Search by name"
                      value={searchByName}
                      onChange={(e) => setSearchByName(e.target.value)}
                    />
                  </div>
                  <div className="form-group col-md-2">
                    <button
                      type="button"
                      className="btn btn-submit btn-block btn-theme"
                      onClick={handleSearch}
                    >
                      Search
                    </button>
                  </div>
                  <div className="form-group col-md-2">
                    <button
                      type="button"
                      className="btn btn-submit btn-block"
                      onClick={resetFilterList}
                    >
                      Reset
                    </button>
                  </div>
                </div>
                {loading ? (
                  <div className="loader-container">
                    {/* <ThreeDots color="#F23F2C" height={80} width={80} /> */}
                    {/* <h2>Please wait while data is loading....</h2> */}
                  </div>
                ) : recordsFound ? (
                  <div className="table-responsive">
                    <div
                      className="ag-theme-alpine"
                      style={{ height: "100%", width: "100%" }}
                    >
                      <AgGridReact
                        ref={gridRef}
                        rowData={filteredTechList}
                        columnDefs={columnDefs}
                        domLayout="autoHeight"
                        enableSorting={true}
                        enableFilter={true}
                        pagination={true}
                        paginationPageSize={10}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="no-record-found text-center">
                    <p>No matching records found.</p>
                  </div>
                )}
              </section>
            </div>
          </div>
        </div>
      </section>
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirmed}>
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CommunitySectionListing;
