import axios from "axios";
// import config from './config.json';
const axiosInstance = axios.create({
 
  // baseURL: config.serverUrl,
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization:'Bearer ' + localStorage.getItem('token')
  },
});
export default axiosInstance;