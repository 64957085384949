import React, { useState, useEffect } from "react";
import axiosInstance from "../../../axiosInstance";
// import { ThreeDots } from "react-loader-spinner";
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";
import Newadded from "../../../assests/images/newadded.png";
import blankImage from "../../../assests/blankImage.jpg";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { FaSortAlphaDown, FaSortAlphaDownAlt } from "react-icons/fa";

function CategoryListing() {
  const itemsPerPage = 12;
  const navigate = useNavigate();
  const indusSubcategoryName = localStorage.getItem("indussubcaretogoryName");
  const [selectedPostName, setSelectedPostName] = useState("");
  const [data, setData] = useState([]);
  const [searchResultCount, setSearchResultCount] = useState(0);
  const [sortingOption, setSortingOption] = useState("title");
  const [sortingDirection, setSortingDirection] = useState("asc");
  const [loading, setLoading] = useState(true);
  const [searchByName, setSearchByName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const approvedData = data.filter((item) => item.status !== "Rejected");
  const countApproved = approvedData.length;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentPageItems = approvedData.slice(startIndex, endIndex);
  const NEW_ITEM_THRESHOLD = 60000;
  const [totalCount, setTotalCount] = useState(0);
  const _techId = localStorage.getItem("indussubcaretogoryTokenID");

  const getData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `industrialProblems/getIndustrialProblemByCategoryId?categoryId=${_techId}&status=Rejected`
      );
      if (response.data?.status) {
        const activeItems = response.data.data;
        const approvedItems = activeItems.filter(
          (item) => item.status !== "Rejected"
        );
        const newData = approvedItems.map((item) => {
          const createdAt = new Date(item.updatedAt).getTime();
          const isNew = Date.now() - createdAt <= NEW_ITEM_THRESHOLD;
          return { ...item, isNew: isNew };
        });

        setData(newData);
        setTotalCount(response.data.data.length);
        setSearchResultCount(
          newData.filter((item) => item.status !== "Rejected").length
        );
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    getData();
  }, [_techId, searchByName]);

  useEffect(() => {
    const timeouts = data
      .map((item) => {
        if (item.isNew) {
          return setTimeout(() => {
            setData((prevData) =>
              prevData.map((dataItem) => {
                if (dataItem._id === item._id) {
                  return { ...dataItem, isNew: false };
                }
                return dataItem;
              })
            );
          }, NEW_ITEM_THRESHOLD);
        }
        return null;
      })
      .filter(Boolean);

    return () => {
      timeouts.forEach(clearTimeout);
    };
  }, [data]);
  const [originalData, setOriginalData] = useState([]);
  const handleSearchByName = (element) => {
    const search = searchByName.toLowerCase();
    return !search || element.title.toLowerCase().includes(search);
  };
  const findFilteredTechList = () => {
    const filteredList = data.filter((item) => {
      return handleSearchByName(item);
    });

    setData(filteredList);
    setCurrentPage(1);
  };
  const featuredTechnologiesHandleClick = (card_id, card_title) => {
    localStorage.setItem("IndusTokenID", card_id);
    sessionStorage.setItem("indusdetailedtitle", card_title);
    navigate("/industrial-details");
  };
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const resetFilterList = () => {
    setData(originalData);
    setSearchByName("");
    setCurrentPage(1);
  };

  const sortData = () => {
    const sortedData = [...data].sort((a, b) => {
      if (sortingOption === "title") {
        const titleA = a.title.toLowerCase();
        const titleB = b.title.toLowerCase();
        return sortingDirection === "asc"
          ? titleA.localeCompare(titleB)
          : titleB.localeCompare(titleA);
      } else if (sortingOption === "date") {
        return sortingDirection === "asc"
          ? moment(b.createdAt).diff(moment(a.createdAt)) 
          : moment(a.createdAt).diff(moment(b.createdAt));
      }
    });
    setData(sortedData);
    setSortingDirection(sortingDirection === "asc" ? "desc" : "asc");
  };
  
  
  return (
    <>
      {loading && <LoadingOverlay />}
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                <a href="/industrial" className="breadcrumbs__item">
                  Industrial Category
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className="breadcrumbs__item">
                  {selectedPostName || indusSubcategoryName}
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="industrial-listing-page tech-transfer-main pad-sec-50">
        <div className="main-heading-for-internalpage position-relative">
          <h2>{indusSubcategoryName}</h2>
        </div>
        <div className="container">
          <div className="row">
            <div class="col-lg-12 col-md-12 col-sm-12 ">
              <div class="main-tabingof-allredytaken w-100">
                <div className="text-center"></div>

                <div className="tab-content">
                  <div className="tab-pane active" role="tabpanel" id="pending">
                    <div className="col-12 col-lg-12 col-md-12">
                      <div className="form-row d-flex align-items-center">
                        <div className="form-group col-md-2">
                          <select
                            className="form-control"
                            value={sortingOption}
                            onChange={(e) => setSortingOption(e.target.value)}
                          >
                            <option value="title">Sort by Title</option>
                            <option value="date">Sort by Date</option>
                          </select>
                        </div>

                        <div className="form-group col-md-2">
                          <button
                            onClick={sortData}
                            type="button"
                            className="btn btn-submit btn-block"
                          >
                            {sortingDirection === "asc" ? (
                              <FaSortAlphaDown />
                            ) : (
                              <FaSortAlphaDownAlt />
                            )}
                            Sort
                          </button>
                        </div>
                        <div className="form-group col-md-3">
                          <input
                            className="form-control"
                            type="search"
                            placeholder="Search by name"
                            onChange={(e) => {
                              setSearchByName(e.target.value);
                            }}
                            value={searchByName}
                          />
                        </div>
                        <div className="form-group col-md-2">
                          <button
                            onClick={findFilteredTechList}
                            type="button"
                            className="btn btn-submit btn-block btn-theme"
                          >
                            Search
                          </button>
                        </div>
                        <div className="form-group col-md-2">
                          <button
                            onClick={resetFilterList}
                            type="button"
                            className="btn btn-submit btn-block"
                          >
                            Reset
                          </button>
                        </div>
                        <div className="col-md-5 form-group d-flex justify-content-end">
                          <h5 className="mr-2 text-info">
                            Total Count - {searchResultCount}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane active"
                      role="tabpanel"
                      id="pending"
                    ></div>

                    {loading ? (
                      <div className="loader-container">
                      
                      </div>
                    ) : currentPageItems && currentPageItems.length > 0 ? (
                      <div className="row">
                        {currentPageItems.map((item) =>
                          item.status !== "Rejected" ? (
                            <div
                              className="col-lg-3 col-sm-12 col-md-3 mb-4 d-flex  w-100"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                featuredTechnologiesHandleClick(
                                  item._id,
                                  item.title
                                )
                              }
                              key={item._id}
                            >
                              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                              <a
                                href="javascript: void(0)"
                                className="listing-expertise-box w-100"
                              >
                                <div className="card border-0 bg-transparent rounded-0  mb-lg-0 d-block listdata-shadow ">
                                  <div className="list-image rounded">
                                    <img
                                      src={
                                        item.images[0]
                                          ? item.images[0]
                                          : blankImage
                                      }
                                      class="card-img-top"
                                      alt="..."
                                    />
                                  </div>

                                  {item.isNew &&
                                    (item.status === "Pending" ||
                                      item.status === "Approved") &&
                                    moment().diff(
                                      moment(item.updatedAt),
                                      "milliseconds"
                                    ) <= NEW_ITEM_THRESHOLD && (
                                      <img
                                        src={Newadded}
                                        alt="New"
                                        className="position-absolute recentadded-cardtec"
                                      />
                                    )}

                                  <div className="card-main-ex-cont mt-1 p-2">
                                    <p>{item.title}</p>
                                  </div>
                                </div>
                              </a>
                            </div>
                          ) : null
                        )}
                      </div>
                    ) : (
                      <div className="no-record-found text-center">
                        <p>No matching records found.</p>
                      </div>
                    )}
                    <div className="row mt-5 web-page">
                      <div className="col-12 col-lg-12">
                        <nav aria-label="Page navigation example">
                          <ul className="pagination justify-content-center">
                            <li
                              className={`page-item ${
                                currentPage === 1 ? "disabled" : ""
                              }`}
                            >
                              <button
                                className="page-link"
                                onClick={() =>
                                  handlePageChange(currentPage - 1)
                                }
                                disabled={currentPage === 1}
                              >
                                Previous
                              </button>
                            </li>
                            {Array.from({
                              length: Math.ceil(countApproved / itemsPerPage),
                            }).map((_, index) => (
                              <li
                                key={index}
                                className={`page-item ${
                                  currentPage === index + 1 ? "active" : ""
                                }`}
                              >
                                <button
                                  className="page-link"
                                  onClick={() => handlePageChange(index + 1)}
                                >
                                  {index + 1}
                                </button>
                              </li>
                            ))}
                            <li
                              className={`page-item ${
                                currentPage ===
                                Math.ceil(countApproved / itemsPerPage)
                                  ? "disabled"
                                  : ""
                              }`}
                            >
                              <button
                                className="page-link"
                                onClick={() =>
                                  handlePageChange(currentPage + 1)
                                }
                                disabled={
                                  currentPage ===
                                  Math.ceil(countApproved / itemsPerPage)
                                }
                              >
                                Next
                              </button>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CategoryListing;
