import React, { useState, useEffect } from "react";
import axiosInstance from "../../../axiosInstance";
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";

function PU_CSR() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pucsrdata, setPucsrData] = useState({});
  const [activeTab, setActiveTab] = useState("");
  const [document, setDocument] = useState([]);
  const fetchData = async () => {
    try {
      const response = await axiosInstance.post("/pucsr/getAll", {
        filter: {
          status: true,
        },
      });
      const responseData = response.data || [];
      setData(responseData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      setActiveTab(data[0]._id);
    }
  }, [data]);

  const handleLinkClick = async (_id) => {
    setActiveTab(_id);
    try {
      const response = await axiosInstance.get(`/pucsr/getPucsrById/${_id}`);
      setPucsrData(response.data.data || {});

      const documentData = await axiosInstance.get(
        `/pucsr/getPucsrDocumentByPucsrId/${_id}`
      );
      setDocument(documentData.data.data);
    } catch (error) {}
  };

  useEffect(() => {
    localStorage.setItem("activeTab", activeTab);
  }, [activeTab]);

  useEffect(() => {
    const selectedData = data.find((item) => item._id === activeTab);
    if (selectedData) {
      setPucsrData(selectedData);
    }
  }, [activeTab, data]);

  return (
    <>
      {loading && <LoadingOverlay />}
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className=" is-active">
                  {" "}
                  PU-CSR
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>

      {/* =============================== */}
      <section className="pucsr-main pad-sec-30">
        <div className="container">
          <div className="main-heading-for-internalpage position-relative">
            <h2>CSR</h2>
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 ">
              <div className="main-tabingof-allredytaken w-100 ">
                <ul
                  className="nav nav-tabing-events d-flex justify-content-center mb-4"
                  role="tablist"
                  id="CSR"
                >
                  {loading ? (
                    <div className="loader-container">
                      {/* <ThreeDots color="#F23F2C" height={80} width={80} />
                      <h2>Please wait while data is loading....</h2> */}
                    </div>
                  ) : data.length > 0 ? (
                    data.map((item) => (
                      <li
                        key={item._id}
                        className="nav-item button-clik-change"
                      >
                        <a
                          href={`#${item._id}`}
                          role="tab"
                          data-toggle="tab"
                          className={`nav-link ${
                            item._id === activeTab ? "active" : ""
                          }`}
                          onClick={() => handleLinkClick(item._id)}
                        >
                          {item.title}
                        </a>
                      </li>
                    ))
                  ) : (
                    <li>No data available</li>
                  )}
                </ul>
                <div className="tab-content text-center mx-auto">
                  {Object.keys(pucsrdata).length > 0 ? (
                    <div
                      className={`tab-pane ${
                        pucsrdata._id === activeTab ? "active" : ""
                      }`}
                      role="tabpanel"
                      id={pucsrdata._id}
                    >
                      <img
                        src={pucsrdata.image}
                        className="w-100 pucsr-banner mb-3 border"
                        alt="Banner"
                      />
                      <div className="content-inner-tab">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: pucsrdata.description,
                          }}
                        ></p>
                      </div>
                    </div>
                  ) : (
                    <div>No data available</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ========= data-table-start-================= */}
      {Object.keys(pucsrdata).length > 0 && document.length > 0 && (
        <div className="data-table-main">
          <div className="container">
            <div className="row">
              <div className="table-shorting-main w-100">
                <div className="shoring-n-search d-flex justify-content-between align-items-center w-100 mb-3">
                  <div className="shoring-content"></div>
                </div>
                <table
                  id="datatable-main"
                  className="table table-striped table-bordered"
                  cellspacing="0"
                  width="100%"
                >
                  <thead>
                    <tr>
                      <th>S.NO </th>
                      <th>Title </th>
                      <th>Document </th>
                    </tr>
                  </thead>
                  <tbody>
                    {document.map((item, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.pucsrId?.title}</td>
                        <td>
                          <a
                            href={item.document}
                            target="_blank"
                            rel="noopener noreferrer"
                            download
                          >
                            Download Document
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PU_CSR;
