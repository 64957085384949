import React, { useState, useEffect } from "react";
import AsideDashboard from "./aside";
import axiosInstance from "../../../axiosInstance";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import Multiselect from "multiselect-react-dropdown";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";
import "./update_profile.css";

function UpdateProfileListing() {
  const navigate = useNavigate();
  const myid = localStorage.getItem("userId");
  const selectedAccountTypeName = localStorage.getItem("accounttype");
  const salutation = ["Prof", "Dr", "Er", "Mr", "Ms", "other - specify"];
  const [category, setCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [getToken, setGetToken] = useState([]);
  const [selectedImages, setSelectedImages] = useState(null);
  const [selectedentity, setSelectedEntity] = useState([]);
  const [businessEntity, setBusinessEntity] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    mobileNo: "",
    address: "",
    userName: "",
    pincode: "",
    image: "",
    country: "",
    state: "",
    salutation: "",
    companyName: "",
    designation: "",
    industySector: [],
    technologySector: [],
    typeOfBusinessEntity: [],
    gstNumber: "",
    registrationNumber: "",
    employeeSize: "",
    organization: "",
    department: "",
    designation: "",
    thurstAreaOfResearch: [],
    areaOfExpertise: "",
    numberOfIndustrialProjects: "",
    totalResearchExperience: "",
    experienceOfIndustrialProject: "",
    numberOfResearchPublications: "",
    numberOfPatentGranted: "",
    numberOfCurrentlyPublishedPatentApplications: "",
    otherInformation: "",
  });

  const handleImageSelect = async (event) => {
    const imageFile = event.target.files[0];
    if (!imageFile) {
      setSelectedImages(null);
      return;
    }

    if (!imageFile.type.startsWith("image/")) {
      toast.error("Only image files can be uploaded.");
      return;
    }

    const singleFileData = new FormData();
    singleFileData.append("image", imageFile);

    try {
      setLoading(true);

      const response = await axiosInstance.post(
        "/tecTeams/uploadImage",
        singleFileData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      const imageUrl = response.data;
      setLoading(false);

      if (imageUrl) {
        setSelectedImages(imageUrl);
      } else {
        toast.error("Failed to upload image.");
      }
    } catch (error) {
      toast.error(`Failed to upload image: ${imageFile.name}`);
      setLoading(false);
    }
  };

  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [states, setStates] = useState([]);

  const handleRemoveImage = (indexToRemove) => {
    // const updatedImages = selectedImages.filter(
    //   (_, index) => index !== indexToRemove
    // );

    setSelectedImages(null);

    // let updatedFormData = { ...data };
    // if (selectedAccountTypeName === "64d9c542d3d363444c9862ff") {
    //   // For industry
    //   updatedFormData.image =
    //     updatedImages && updatedImages.length > 0 ? updatedImages[0] : "";
    // } else if (selectedAccountTypeName === "6583ff79308225fbb30641f9") {
    //   // For academic
    //   updatedFormData.image =
    //     updatedImages && updatedImages.length > 0 ? updatedImages[0] : "";
    // }

    // setData(updatedFormData);
  };

  useEffect(() => {
    const fetchTokenAndCountries = async () => {
      try {
        const tokenResponse = await axios.get(
          "https://www.universal-tutorial.com/api/getaccesstoken",
          {
            headers: {
              "api-token":
                "aQmV-y9zLypGty6nrbFBR9pLD4jhR_GPQdl8cbIxz6v4Uo-0r3HfYt55Zc3HulXU0S8",
              "user-email": "amitainfoneo@gmail.com",
            },
          }
        );
        const token = tokenResponse.data.auth_token;

        setGetToken(token);
        if (token) {
          const countriesResponse = await axios.get(
            "https://www.universal-tutorial.com/api/countries",
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setCountries(countriesResponse.data);
        } else {
          console.log("No token found. Cannot fetch countries.");
        }
      } catch (error) {
        console.error(
          "Error:",
          error.response ? error.response.data : "Unknown Error"
        );
      }
    };

    fetchTokenAndCountries();
  }, []);
  useEffect(() => {
    const fetchStates = async () => {
      if (!selectedCountry || !getToken) return;

      try {
        const statesResponse = await axios.get(
          `https://www.universal-tutorial.com/api/states/${selectedCountry}`,
          {
            headers: {
              Authorization: `Bearer ${getToken}`,
            },
          }
        );
        setStates(statesResponse.data);
      } catch (error) {
        console.error("Error fetching states:", error);
      }
    };

    fetchStates();
  }, [selectedCountry, getToken]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (
      name === "totalResearchExperience" &&
      (/^\d*$/.test(value) || value === "")
    ) {
      setData({ ...data, [name]: value });
    } else if (
      name !== "totalResearchExperience" &&
      (/^[a-zA-Z]*$/.test(value) || value === "")
    ) {
      setData({ ...data, [name]: value });
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [
          problemResponse,
          industryResponse,
          businessResponse,
        ] = await Promise.all([
          axiosInstance.get(`/users/getUserById/${myid}`),
          axiosInstance.get(
            "/subCategories/getAllSubCategoryByCategoryId/64d9d3881cae26c449f95fa0"
          ),
          axiosInstance.get(
            "/subCategories/getAllSubCategoryByCategoryId/64d9d3831cae26c449f95f9c"
          ),
        ]);
        const userData = problemResponse.data;
        const industrySectorResponse = industryResponse.data.data;

        const businessEntityResponse = businessResponse.data.data;

        const formattedindustry = userData.thurstAreaOfResearch || [];

        const industrySectorOptions =
          formattedindustry.length > 0
            ? formattedindustry.map((item) => ({
                label: item.name,
                value: item._id,
              }))
            : [];
            console.log("data",industrySectorOptions)
        const formattedbusiness = userData.typeOfBusinessEntity || [];
        const businessOptions =
          formattedbusiness.length > 0
            ? formattedbusiness.map((item) => ({
                label: item.name,
                value: item._id,
              }))
            : [];
        const preFilledImagePreviews = userData.image ? userData.image : null;
   
        setData({
          ...userData,
          salutation: userData.salutation || "",
          firstName: userData.firstName || "",

          email: userData.email || "",
          mobileNo: userData.mobileNo || "",
          address: userData.address || "",
          country: userData.country || "",
          state: userData.state || "",
          pincode: userData.pincode || "",
          image: preFilledImagePreviews || "",
          companyName: userData.companyName || "",
          designation: userData.designation || "",
          industySector: industrySectorOptions,
          typeOfBusinessEntity: businessOptions || "",
          gstNumber: userData.gstNumber || "",
          registrationNumber: userData.registrationNumber || "",
          employeeSize: userData.employeeSize || "",
          organization: userData.organization || "",
          department: userData.department || "",
          designation: userData.designation || "",
          thurstAreaOfResearch: industrySectorOptions,
          areaOfExpertise: userData.areaOfExpertise || "",
          numberOfIndustrialProjects: userData.numberOfIndustrialProjects || "",
          totalResearchExperience: userData.totalResearchExperience || "",
          experienceOfIndustrialProject:
            userData.experienceOfIndustrialProject || "",
          numberOfResearchPublications:
            userData.numberOfResearchPublications || "",
          numberOfPatentGranted: userData.numberOfPatentGranted || "",
          numberOfCurrentlyPublishedPatentApplications:
            userData.numberOfCurrentlyPublishedPatentApplications || "",
          otherInformation: userData.otherInformation || "",
        });

        setSelectedCategory(industrySectorOptions);
        setCategory(industrySectorResponse);
        setSelectedEntity(businessOptions);
        setBusinessEntity(businessEntityResponse);
        setImagePreviews(preFilledImagePreviews);
        setSelectedImages(preFilledImagePreviews);
        setSelectedCountry(userData.country);
        setSelectedState(userData.state);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [myid]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {};
    if (!data.salutation) {
      errors.salutation = "Salutation is required .";
    }
    if (!data.firstName) {
      errors.firstName = "FirstName is required .";
    }

    if (!data.lastName) {
      errors.lastName = "LastName is required .";
    }
    if (data.gstNumber) {
      if (
        !/^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/.test(
          data.gstNumber
        )
      ) {
        errors.gstNumber = "GSTIN number is not valid! .";
      }
    }
    if (data.registrationNumber) {
      if (!/^[A-Za-z0-9]+$/.test(data.registrationNumber)) {
        errors.registrationNumber = "Registration number not valid.";
      }
    }
    // if (!data.mobileNo) {
    //   errors.mobileNo = " MobileNo is required .";
    // } else if (data.mobileNo.length < 10) {
    //   errors.mobileNo =
    //     "Mobile number should not be greater than 10 characters";
    // } else if (data.mobileNo.length > 10) {
    //   errors.mobileNo =
    //     "Mobile number should not be greater than 10 characters";
    // } else if (!/^\d*$/.test(data.mobileNo)) {
    //   errors.mobileNo = "Please enter only numeric characters";
    // }
    // if (!data.country) {
    //   errors.country = "Country is required .";
    // }
    // if (!data.state) {
    //   errors.state = " state is required .";
    // }
    else if (selectedAccountTypeName === "64d9c542d3d363444c9862ff") {
      if (!data.companyName) {
        errors.companyName = "Company Name is required.";
      } else if (!/^[A-Za-z\s]+$/.test(data.companyName)) {
        errors.companyName = "Company Name is Not Valid.";
      }
    }
    // academic data

    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }

    const selectedCategoriesString = selectedCategory.map((item) => item.value);
    const selectedBusinessString = selectedentity.map((item) => item.value);

    setSelectedCategory(selectedCategoriesString);
    setSelectedEntity(selectedBusinessString);

    let formData = {};
    if (selectedAccountTypeName === "64d9c542d3d363444c9862ff") {
      // For industry
      formData = {
        companyName: data.companyName,
        designation: data.designation,
        industySector: selectedCategoriesString,
        typeOfBusinessEntity: selectedBusinessString,
        gstNumber: data.gstNumber,
        registrationNumber: data.registrationNumber,
        employeeSize: data.employeeSize,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        image: selectedImages ? selectedImages : "",
        mobileNo: data.mobileNo,
        address: data.address,
        pincode: data.pincode,
        country: selectedCountry,
        state: selectedState,
        salutation: data.salutation,
        otherInformation: data.otherInformation,
      };
    } else if (selectedAccountTypeName === "6583ff79308225fbb30641f9") {
      // For academic
      formData = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        image: selectedImages ? selectedImages : "",
        mobileNo: data.mobileNo,
        address: data.address,
        pincode: data.pincode,
        country: selectedCountry,
        state: selectedState,
        salutation: data.salutation,
        organization: data.organization,
        department: data.department,
        designation: data.designation,
        thurstAreaOfResearch: selectedCategoriesString,
        areaOfExpertise: data.areaOfExpertise,
        numberOfIndustrialProjects: data.numberOfIndustrialProjects,
        totalResearchExperience: data.totalResearchExperience,
        experienceOfIndustrialProject: data.experienceOfIndustrialProject,
        numberOfResearchPublications: data.numberOfResearchPublications,
        numberOfPatentGranted: data.numberOfPatentGranted,
        numberOfCurrentlyPublishedPatentApplications:
          data.numberOfCurrentlyPublishedPatentApplications,
        otherInformation: data.otherInformation,
      };
    }

    try {
      setLoading(true);

      const response = await axiosInstance.put(
        `/users/updateUser/${myid}`,
        formData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setLoading(false);

      if (response.status === 200) {
        const updatedUserData = response.data;

        if (response.data?.status === false) {
          toast.info("No changes found.");
        } else {
          toast.success("Profile updated successfully.");
          setTimeout(() => {
            navigate("/user/dashboard");
          }, 1000);
        }
      } else {
        const errorMessage =
          response.data?.message || "An error occurred during update.";
        toast.info(errorMessage, {
          position: toast.POSITION.TOP_CENTER,
        });
      }
    } catch (error) {
      setLoading(false);

      console.error("Error during update:", error?.response?.data?.message);
      const errorMessage =
        error?.response?.data?.message || "An error occurred during update.";
      toast.info(errorMessage, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  const [fieldErrors, setFieldErrors] = useState({
    companyName: "",
    designation: "",

    typeOfBusinessEntity: "",
    organization: "",
    department: "",
    designation: "",
    thrustAreasOfResearch: "",
    expertise: "",

    salutation: "",
    firstName: "",
    middleName: "",
    lastName: "",
    mobileNo: "",
    country: "",
    state: "",
  });

  // const renderFormBasedOnAccountType = () => {
  //   if (selectedAccountTypeName === "64d9c542d3d363444c9862ff") {
  //     return (
  //       <>
  //         <div className=" main-wrapper-form pt-3 pb-4 mt-3 mr-2">
  //           <div className="deshboard-title">
  //             <h3>Industry Account:</h3>
  //           </div>

  //           <div className="form-bg-box-inner">
  //             <div className="row p-4">
  //               <div className="form-group col-12 col-lg-6 col-md-6">
  //                 <label className="control-label">
  //                   Company Name<span className="text-danger">*</span>
  //                 </label>
  //                 <input
  //                   placeholder="Company Name"
  //                   className="form-control"
  //                   name="companyName"
  //                   type="text"
  //                   value={data.companyName}
  //                   onChange={(e) =>
  //                     setData({ ...data, companyName: e.target.value })
  //                   }
  //                 />
  //                 <div className="text-danger">{fieldErrors.companyName}</div>
  //               </div>
  //               <div className="form-group col-12 col-lg-6 col-md-6">
  //                 <label className="control-label">
  //                   Designation<span className="text-danger">*</span>
  //                 </label>
  //                 <input
  //                   placeholder="Designation"
  //                   className="form-control"
  //                   name="designation"
  //                   type="text"
  //                   value={data.designation}
  //                   onChange={(e) =>
  //                     setData({ ...data, designation: e.target.value })
  //                   }
  //                 />

  //                 <div className="text-danger">{fieldErrors.designation}</div>
  //               </div>
  //               <div className="form-group col-12 col-lg-6 col-md-6">
  //                 <label className="control-label">
  //                   Industry Sector<span className="text-danger">*</span>
  //                 </label>

  //                 <Multiselect
  //                   showCheckbox={true}
  //                   options={category.map((item) => ({
  //                     label: item.name,
  //                     value: item._id,
  //                   }))}
  //                   selectedValues={selectedCategory}
  //                   placeholder="Select Category"
  //                   onSelect={(selectedList) =>
  //                     setSelectedCategory(selectedList)
  //                   }
  //                   onRemove={(selectedList) =>
  //                     setSelectedCategory(selectedList)
  //                   }
  //                   displayValue="label"
  //                 />
  //               </div>
  //               <div className="form-group col-12 col-lg-6 col-md-6">
  //                 <label className="control-label">
  //                   Technology Sector of your Interest{" "}
  //                   <span className="text-danger">*</span>
  //                 </label>
  //                 <select
  //                   className="form-control"
  //                   id="select"
  //                   name="industryCategory"
  //                 >
  //                   <option value="">Select Technology Sector</option>
  //                 </select>
  //                 <div className="text-danger">
  //                   {fieldErrors.industryCategory}
  //                 </div>
  //               </div>

  //               <div className="form-group col-12 col-lg-6 col-md-6 ">
  //                 <label className="control-label">
  //                   Type of Business Entity
  //                   <span className="text-danger">*</span>
  //                 </label>

  //                 <Multiselect
  //                   showCheckbox={true}
  //                   options={businessEntity.map((item) => ({
  //                     label: item.name,
  //                     value: item._id,
  //                   }))}
  //                   selectedValues={selectedentity}
  //                   placeholder="Select Category"
  //                   onSelect={(selectedList) => setSelectedEntity(selectedList)}
  //                   onRemove={(selectedList) => setSelectedEntity(selectedList)}
  //                   displayValue="label"
  //                 />

  //                 <div className="text-danger">
  //                   {fieldErrors.typeOfBusinessEntity}
  //                 </div>
  //               </div>
  //               <div className="form-group col-12 col-lg-6 col-md-6 ">
  //                 <label className="control-label">GST Number</label>
  //                 <input
  //                   type="text"
  //                   className="form-control"
  //                   name="gstNumber"
  //                   placeholder="GST Number"
  //                   maxLength={15}
  //                   pattern="^[a-zA-Z0-9]*$"
  //                   value={data.gstNumber}
  //                   onChange={(e) =>
  //                     setData({ ...data, gstNumber: e.target.value })
  //                   }
  //                 />
  //               </div>

  //               <div className="form-group col-12 col-lg-6 col-md-6 ">
  //                 <label className="control-label">
  //                   Registration / Incorporation number
  //                 </label>
  //                 <input
  //                   type="text"
  //                   className="form-control"
  //                   name="registrationNumber"
  //                   placeholder="Registration / Incorporation number"
  //                   required=""
  //                   value={data.registrationNumber}
  //                   onChange={(e) =>
  //                     setData({ ...data, registrationNumber: e.target.value })
  //                   }
  //                 />
  //               </div>

  //               <div className="form-group col-12 col-lg-6 col-md-6 ">
  //                 <label className="control-label">Employee Size</label>
  //                 <input
  //                   type="text"
  //                   className="form-control"
  //                   name="employeeSize"
  //                   placeholder="Employee Size"
  //                   required=""
  //                   value={data.employeeSize}
  //                   onChange={(e) =>
  //                     setData({ ...data, employeeSize: e.target.value })
  //                   }
  //                 />
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </>
  //     );
  //   } else if (selectedAccountTypeName === "6583ff79308225fbb30641f9") {
  //     return (
  //       <>
  //         <div className="main-wrapper-form pt-3 pb-4 mt-3 mr-2">
  //           <div className="deshboard-title">
  //             <h3>Academia / Researcher Account:</h3>
  //           </div>

  //           <div className="form-bg-box-inner">
  //             <div className="row p-4">
  //               <div className="form-group col-12 col-lg-6 col-md-6">
  //                 <label className="control-label">
  //                   Organization<span className="text-danger">*</span>
  //                 </label>
  //                 <input
  //                   placeholder="Organization"
  //                   className="form-control"
  //                   name="organization"
  //                   type="text"
  //                   value={data.organization}
  //                   onChange={(e) =>
  //                     setData({ ...data, organization: e.target.value })
  //                   }
  //                 />
  //                 <div className="text-danger">{fieldErrors.organization}</div>
  //               </div>

  //               <div className="form-group col-12 col-lg-6 col-md-6">
  //                 <label className="control-label">
  //                   Department<span className="text-danger">*</span>
  //                 </label>
  //                 <input
  //                   placeholder="Department"
  //                   className="form-control"
  //                   name="department"
  //                   type="text"
  //                   value={data.department}
  //                   onChange={(e) =>
  //                     setData({ ...data, department: e.target.value })
  //                   }
  //                 />
  //                 <div className="text-danger">{fieldErrors.department}</div>
  //               </div>

  //               <div className="form-group col-12 col-lg-6 col-md-6">
  //                 <label className="control-label">
  //                   Designation<span className="text-danger">*</span>
  //                 </label>
  //                 <input
  //                   placeholder="Designation "
  //                   className="form-control"
  //                   name="designation"
  //                   type="text"
  //                   value={data.designation}
  //                   onChange={(e) =>
  //                     setData({ ...data, designation: e.target.value })
  //                   }
  //                 />
  //                 <div className="text-danger">{fieldErrors.designation}</div>
  //               </div>

  //               <div className="form-group col-12 col-lg-6 col-md-6 ">
  //                 <label className="control-label">
  //                   Thrust areas of research{" "}
  //                   <span className="text-danger">*</span>
  //                 </label>
  //                 <Multiselect
  //                   showCheckbox={true}
  //                   options={category.map((item) => ({
  //                     label: item.name,
  //                     value: item._id,
  //                   }))}
  //                   selectedValues={selectedCategory}
  //                   placeholder="Select Category"
  //                   onSelect={(selectedList) =>
  //                     setSelectedCategory(selectedList)
  //                   }
  //                   onRemove={(selectedList) =>
  //                     setSelectedCategory(selectedList)
  //                   }
  //                   displayValue="label"
  //                 />
  //               </div>

  //               <div className="form-group col-12 col-lg-6 col-md-6 ">
  //                 <label className="control-label">
  //                   Area of expertise / specialization
  //                   <span className="text-danger">*</span>
  //                 </label>
  //                 <input
  //                   type="text"
  //                   className="form-control"
  //                   name="expertise"
  //                   placeholder="Area of expertise / specialization"
  //                   required=""
  //                   value={data.areaOfExpertise}
  //                   onChange={(e) =>
  //                     setData({ ...data, areaOfExpertise: e.target.value })
  //                   }
  //                 />
  //                 <div className="text-danger">
  //                   {fieldErrors.areaOfExpertise}
  //                 </div>
  //               </div>
  //               <div className="form-group col-12 col-lg-6 col-md-6 ">
  //                 <label className="control-label">
  //                   Number of Industrial Projects / Consultancies done in Past
  //                 </label>
  //                 <input
  //                   type="number"
  //                   className="form-control"
  //                   name="numberOfIndustrialProjects"
  //                   placeholder="Number of Industrial Projects / Consultancies done in Past"
  //                   required=""
  //                   value={data.numberOfIndustrialProjects}
  //                   onChange={(e) =>
  //                     setData({
  //                       ...data,
  //                       numberOfIndustrialProjects: e.target.value,
  //                     })
  //                   }
  //                 />
  //               </div>
  //               <div className="form-group col-12 col-lg-6 col-md-6 ">
  //                 <label className="control-label">
  //                   Total Research Experience (In Years)
  //                 </label>
  //                 <input
  //                   type="text"
  //                   className="form-control"
  //                   name="totalResearchExperience "
  //                   placeholder="Total Research Experience (In Years)"
  //                   required=""
  //                   value={data.totalResearchExperience}
  //                   onChange={(e) =>
  //                     setData({
  //                       ...data,
  //                       totalResearchExperience: e.target.value,
  //                     })
  //                   }
  //                 />
  //               </div>

  //               <div className="form-group col-12 col-lg-6 col-md-6 ">
  //                 <label className="control-label">
  //                   Experience (in years) of Industrial Projects / Industrial
  //                   Consultancy
  //                 </label>
  //                 <input
  //                   type="number"
  //                   className="form-control"
  //                   name="experienceOfIndustrialProject"
  //                   placeholder="Experience (in years) of Industrial Projects / Industrial Consultancy"
  //                   required=""
  //                   value={data.experienceOfIndustrialProject}
  //                   onChange={(e) =>
  //                     setData({
  //                       ...data,
  //                       experienceOfIndustrialProject: e.target.value,
  //                     })
  //                   }
  //                 />
  //               </div>

  //               <div className="form-group col-12 col-lg-6 col-md-6 ">
  //                 <label className="control-label">
  //                   Number of Research Publications
  //                 </label>
  //                 <input
  //                   type="number"
  //                   className="form-control"
  //                   name="numberOfResearchPublications"
  //                   placeholder="Number of Research Publications"
  //                   required=""
  //                   value={data.numberOfResearchPublications}
  //                   onChange={(e) =>
  //                     setData({
  //                       ...data,
  //                       numberOfResearchPublications: e.target.value,
  //                     })
  //                   }
  //                 />
  //               </div>

  //               <div className="form-group col-12 col-lg-6 col-md-6 ">
  //                 <label className="control-label">
  //                   Number of Patents Granted
  //                 </label>
  //                 <input
  //                   type="number"
  //                   className="form-control"
  //                   name="numberOfPatentGranted"
  //                   placeholder="Number of Patents Granted"
  //                   required=""
  //                   value={data.numberOfPatentGranted}
  //                   onChange={(e) =>
  //                     setData({
  //                       ...data,
  //                       numberOfPatentGranted: e.target.value,
  //                     })
  //                   }
  //                 />
  //               </div>

  //               <div className="form-group col-12 col-lg-6 col-md-6 ">
  //                 <label className="control-label">
  //                   Number of Currently Published Patent Applications{" "}
  //                 </label>
  //                 <input
  //                   type="number"
  //                   className="form-control"
  //                   name="numberOfCurrentlyPublishedPatentApplications"
  //                   placeholder="Number of Currently Published Patent Applications"
  //                   required=""
  //                   value={data.numberOfCurrentlyPublishedPatentApplications}
  //                   onChange={(e) =>
  //                     setData({
  //                       ...data,
  //                       numberOfCurrentlyPublishedPatentApplications:
  //                         e.target.value,
  //                     })
  //                   }
  //                 />
  //               </div>

  //               <div className="form-group col-12 col-lg-6 col-md-6 ">
  //                 <label className="control-label">Other Information</label>
  //                 <input
  //                   type="text"
  //                   className="form-control"
  //                   name="otherInformation"
  //                   placeholder="Other Information"
  //                   required=""
  //                   value={data.otherInformation}
  //                   onChange={(e) =>
  //                     setData({ ...data, otherInformation: e.target.value })
  //                   }
  //                 />
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </>
  //     );
  //   }
  //   return <div>Invalid account type!</div>;
  // };

  return (
    <>
      {loading && <LoadingOverlay />}

      <section className="main-sidenavigation pad-sec-50">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-3">
              <aside>
                {" "}
                <AsideDashboard />{" "}
              </aside>
            </div>

            <div className="col-12 col-lg-9 main-wrapper-form pt-3 pb-3">
              <form onSubmit={handleSubmit}>
                <div className="main-form-for-dashboard">
                  <div className="deshboard-title">
                    <h3>Update Profile </h3>
                  </div>

                  <div className="form-bg-box-inner">
                    <div className="row p-4">
                      <div className="form-group col-12 col-lg-6 col-md-6 ">
                        <label className="control-label">Email</label>
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          placeholder="Email"
                          required=""
                          value={data.email}
                          onChange={(e) =>
                            setData({ ...data, email: e.target.value })
                          }
                        />
                      </div>
                      <div className="form-group col-12 col-lg-6 col-md-6 ">
                        <label className="control-label">
                          Mobile Number<span className="text-danger">*</span>
                        </label>
                        <PhoneInput
                          country={"in"}
                          value={data.mobileNo}
                          max={25}
                          onChange={(value) =>
                            setData({ ...data, mobileNo: value })
                          }
                        />
                        <div className="text-danger">
                          {fieldErrors.mobileNo}
                        </div>
                      </div>
                      <div className="form-group col-12 col-lg-6 col-md-6 d-flex flex-column">
                        <label for="inputState">
                          Salutation<span className="text-danger">*</span>
                        </label>
                        <select
                          id="inputState"
                          className="form-control"
                          value={data.salutation}
                          onChange={(e) =>
                            setData({ ...data, salutation: e.target.value })
                          }
                        >
                          <option selected>Please select salutation</option>
                          {salutation.map((item, i) => (
                            <option key={i}>{item}</option>
                          ))}
                        </select>
                        <div className="text-danger">
                          {fieldErrors.salutation}
                        </div>
                      </div>

                      <div className="form-group col-12 col-lg-6 col-md-6">
                        <label className="control-label">
                          First Name<span className="text-danger">*</span>
                        </label>
                        <input
                          placeholder="First name"
                          className="form-control"
                          name="firstName"
                          type="text"
                          value={data.firstName}
                          // onChange={(e) =>
                          //   setData({ ...data, firstName: e.target.value })
                          // }
                          onChange={handleChange}
                        />
                        <div className="text-danger">
                          {fieldErrors.firstName}
                        </div>
                      </div>

                      <div className="form-group col-12 col-lg-6 col-md-6">
                        <label className="control-label">Middle Name</label>
                        <input
                          placeholder="Middle name"
                          className="form-control"
                          name="middleName"
                          type="text"
                          value={data.middleName}
                          // onChange={(e) =>
                          //   setData({ ...data, middleName: e.target.value })
                          // }
                          onChange={handleChange}
                        />
                        <div className="text-danger">
                          {fieldErrors.middleName}
                        </div>
                      </div>

                      <div className="form-group col-12 col-lg-6 col-md-6 ">
                        <label className="control-label">
                          Last Name<span className="text-danger">*</span>
                        </label>
                        <input
                          placeholder="Last name"
                          className="form-control"
                          name="lastName"
                          required=""
                          value={data.lastName}
                          // onChange={(e) =>
                          //   setData({ ...data, lastName: e.target.value })
                          // }
                          onChange={handleChange}
                        />
                        <div className="text-danger">
                          {fieldErrors.lastName}
                        </div>
                      </div>

                      <div className="form-group col-12 col-lg-6 col-md-6 d-flex flex-column">
                        <label>
                          <label>
                            Upload Image{" "}
                            <span className="text-danger">
                              *(You can select only one image)
                            </span>
                          </label>
                        </label>
                        <input
                          type="file"
                          name="img"
                          required=""
                          accept="images/*"
                          className="form-control"
                          // value={data.selectedImages}
                          onChange={handleImageSelect}
                        />
                      </div>
                      <div className="col-12 col-md-12">
                        {selectedImages && selectedImages.length > 0 && (
                          <div className="col-12 col-md-12">
                            <div className="selected-images">
                              <div className="">
                                <img
                                  src={selectedImages}
                                  alt=""
                                  className="selected-image"
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    marginBottom: "10px",
                                  }}
                                />
                                <button
                                  type="button"
                                  className="btn-submit backtored"
                                  onClick={() => handleRemoveImage(1)}
                                >
                                  <i
                                    className="fa fa-times"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div class="form-group col-6">
                        <label for="inputCountry">
                          Country<span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control"
                          id="select"
                          value={selectedCountry || ""}
                          onChange={(e) => setSelectedCountry(e.target.value)}
                        >
                          {countries.map((country) => (
                            <option
                              key={country.country_name}
                              value={country.country_name}
                            >
                              {country.country_name}
                            </option>
                          ))}
                        </select>
                        <div className="text-danger">{fieldErrors.country}</div>
                      </div>
                      <div class="form-group col-6">
                        <label for="inputState">
                          State<span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control"
                          id="select"
                          value={selectedState || ""}
                          onChange={(e) => setSelectedState(e.target.value)}
                          disabled={!selectedCountry}
                        >
                          {states.map((state) => (
                            <option
                              key={state.state_name}
                              value={state.state_name}
                            >
                              {state.state_name}
                            </option>
                          ))}
                        </select>

                        <div className="text-danger">{fieldErrors.state}</div>
                      </div>

                      <div className="form-group col-12 col-lg-6 col-md-6 ">
                        <label className="control-label">Pin Code</label>
                        <input
                          type="text"
                          className="form-control"
                          name="pincode"
                          placeholder="Pin Code"
                          required=""
                         
                          value={data.pincode}
                          // onChange={(e) =>
                          //   setData({ ...data, pincode: e.target.value })
                          // }
                          onChange={(e) => {
                            const pincode = e.target.value;
                            if (pincode.length <= 6 && /^\d*$/.test(pincode)) {
                              setData({ ...data, pincode });
                            }
                          }}
                        />
                      </div>
                      <div className="form-group col-12 col-lg-6 col-md-6 ">
                        <label className="control-label">Address</label>

                        <textarea
                          className="form-control"
                          rows={3}
                          placeholder="Address"
                          name="address"
                          value={data.address}
                          onChange={(e) =>
                            setData({ ...data, address: e.target.value })
                          }
                        />
                      </div>
                      <div className="form-group col-12">
                        {selectedAccountTypeName ===
                          "64d9c542d3d363444c9862ff" && (
                          <>
                            <div className="pt-3 pb-4 mt-3 mr-2">
                              <div className="deshboard-title">
                                <h3>Industry Account:</h3>
                              </div>

                              <div className="form-bg-box-inner">
                                <div className="row p-4">
                                  <div className="form-group col-12 col-lg-6 col-md-6">
                                    <label className="control-label">
                                      Company Name
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      placeholder="Company Name"
                                      className="form-control"
                                      name="companyName"
                                      type="text"
                                      value={data.companyName}
                                      onChange={(e) =>
                                        setData({
                                          ...data,
                                          companyName: e.target.value,
                                        })
                                      }
                                    />
                                    <div className="text-danger">
                                      {fieldErrors.companyName}
                                    </div>
                                  </div>
                                  <div className="form-group col-12 col-lg-6 col-md-6">
                                    <label className="control-label">
                                      Designation
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      placeholder="Designation"
                                      className="form-control"
                                      name="designation"
                                      type="text"
                                      value={data.designation}
                                      onChange={(e) =>
                                        setData({
                                          ...data,
                                          designation: e.target.value,
                                        })
                                      }
                                    />

                                    <div className="text-danger">
                                      {fieldErrors.designation}
                                    </div>
                                  </div>
                                  <div className="form-group col-12 col-lg-6 col-md-6">
                                    <label className="control-label">
                                      Industry Sector
                                      <span className="text-danger">*</span>
                                    </label>

                                    <Multiselect
                                      showCheckbox={true}
                                      options={category.map((item) => ({
                                        label: item.name,
                                        value: item._id,
                                      }))}
                                      selectedValues={selectedCategory}
                                      placeholder="Select Category"
                                      onSelect={(selectedList) =>
                                        setSelectedCategory(selectedList)
                                      }
                                      onRemove={(selectedList) =>
                                        setSelectedCategory(selectedList)
                                      }
                                      displayValue="label"
                                    />
                                  </div>
                                  <div className="form-group col-12 col-lg-6 col-md-6">
                                    <label className="control-label">
                                      Technology Sector of your Interest{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <select
                                      className="form-control"
                                      id="select"
                                      name="industryCategory"
                                    >
                                      <option value="">
                                        Select Technology Sector
                                      </option>
                                    </select>
                                    <div className="text-danger">
                                      {fieldErrors.industryCategory}
                                    </div>
                                  </div>

                                  <div className="form-group col-12 col-lg-6 col-md-6 ">
                                    <label className="control-label">
                                      Type of Business Entity
                                      <span className="text-danger">*</span>
                                    </label>

                                    <Multiselect
                                      showCheckbox={true}
                                      options={businessEntity.map((item) => ({
                                        label: item.name,
                                        value: item._id,
                                      }))}
                                      selectedValues={selectedentity}
                                      placeholder="Select Category"
                                      onSelect={(selectedList) =>
                                        setSelectedEntity(selectedList)
                                      }
                                      onRemove={(selectedList) =>
                                        setSelectedEntity(selectedList)
                                      }
                                      displayValue="label"
                                    />

                                    <div className="text-danger">
                                      {fieldErrors.typeOfBusinessEntity}
                                    </div>
                                  </div>
                                  <div className="form-group col-12 col-lg-6 col-md-6 ">
                                    <label className="control-label">
                                      GST Number
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="gstNumber"
                                      placeholder="GST Number"
                                      maxLength={15}
                                      pattern="^[a-zA-Z0-9]*$"
                                      value={data.gstNumber}
                                      onChange={(e) =>
                                        setData({
                                          ...data,
                                          gstNumber: e.target.value,
                                        })
                                      }
                                    />
                                  </div>

                                  <div className="form-group col-12 col-lg-6 col-md-6 ">
                                    <label className="control-label">
                                      Registration / Incorporation number
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="registrationNumber"
                                      placeholder="Registration / Incorporation number"
                                      required=""
                                      value={data.registrationNumber}
                                      onChange={(e) =>
                                        setData({
                                          ...data,
                                          registrationNumber: e.target.value,
                                        })
                                      }
                                    />
                                  </div>

                                  <div className="form-group col-12 col-lg-6 col-md-6 ">
                                    <label className="control-label">
                                      Employee Size
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="employeeSize"
                                      placeholder="Employee Size"
                                      required=""
                                      value={data.employeeSize}
                                      onChange={(e) =>
                                        setData({
                                          ...data,
                                          employeeSize: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        {selectedAccountTypeName ===
                          "6583ff79308225fbb30641f9" && (
                          <>
                            <div className="main-wrapper-form pt-3 pb-4 mt-3 mr-2">
                              <div className="deshboard-title">
                                <h3>Academia / Researcher Account:</h3>
                              </div>

                              <div className="form-bg-box-inner">
                                <div className="row p-4">
                                  <div className="form-group col-12 col-lg-6 col-md-6">
                                    <label className="control-label">
                                      Organization
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      placeholder="Organization"
                                      className="form-control"
                                      name="organization"
                                      type="text"
                                      value={data.organization}
                                      onChange={(e) =>
                                        setData({
                                          ...data,
                                          organization: e.target.value,
                                        })
                                      }
                                    />
                                    <div className="text-danger">
                                      {fieldErrors.organization}
                                    </div>
                                  </div>

                                  <div className="form-group col-12 col-lg-6 col-md-6">
                                    <label className="control-label">
                                      Department
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      placeholder="Department"
                                      className="form-control"
                                      name="department"
                                      type="text"
                                      value={data.department}
                                      onChange={(e) =>
                                        setData({
                                          ...data,
                                          department: e.target.value,
                                        })
                                      }
                                    />
                                    <div className="text-danger">
                                      {fieldErrors.department}
                                    </div>
                                  </div>

                                  <div className="form-group col-12 col-lg-6 col-md-6">
                                    <label className="control-label">
                                      Designation
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      placeholder="Designation "
                                      className="form-control"
                                      name="designation"
                                      type="text"
                                      value={data.designation}
                                      onChange={(e) =>
                                        setData({
                                          ...data,
                                          designation: e.target.value,
                                        })
                                      }
                                    />
                                    <div className="text-danger">
                                      {fieldErrors.designation}
                                    </div>
                                  </div>

                                  <div className="form-group col-12 col-lg-6 col-md-6 ">
                                    <label className="control-label">
                                      Thrust areas of research{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <Multiselect
                                      showCheckbox={true}
                                      options={category.map((item) => ({
                                        label: item.name,
                                        value: item._id,
                                      }))}
                                      selectedValues={selectedCategory}
                                      placeholder="Select Category"
                                      onSelect={(selectedList) =>
                                        setSelectedCategory(selectedList)
                                      }
                                      onRemove={(selectedList) =>
                                        setSelectedCategory(selectedList)
                                      }
                                      displayValue="label"
                                    />
                                  </div>

                                  <div className="form-group col-12 col-lg-6 col-md-6 ">
                                    <label className="control-label">
                                      Area of expertise / specialization
                                      <span className="text-danger">*</span>
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="expertise"
                                      placeholder="Area of expertise / specialization"
                                      required=""
                                      value={data.areaOfExpertise}
                                      onChange={(e) =>
                                        setData({
                                          ...data,
                                          areaOfExpertise: e.target.value,
                                        })
                                      }
                                    />
                                    <div className="text-danger">
                                      {fieldErrors.areaOfExpertise}
                                    </div>
                                  </div>
                                  <div className="form-group col-12 col-lg-6 col-md-6 ">
                                    <label className="control-label">
                                      Number of Industrial Projects /
                                      Consultancies done in Past
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="numberOfIndustrialProjects"
                                      placeholder="Number of Industrial Projects / Consultancies done in Past"
                                      required=""
                                      value={data.numberOfIndustrialProjects}
                                      onChange={(e) =>
                                    {
                                      const numberOfIndustrialProjects = e.target.value;
                                      if( /^\d*$/.test(numberOfIndustrialProjects)){
                                        setData({
                                          ...data,
                                          numberOfIndustrialProjects:
                                            e.target.value,
                                        })
                                      }
                                    
                                    }
                                      }
                                    />
                                  </div>
                                  <div className="form-group col-12 col-lg-6 col-md-6 ">
                                    <label className="control-label">
                                      Total Research Experience (In Years)
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="totalResearchExperience"
                                      placeholder="Total Research Experience (In Years)"
                                      required=""
                                      value={data.totalResearchExperience}
                                      onChange={handleChange}
                                    />
                                  </div>

                                  <div className="form-group col-12 col-lg-6 col-md-6 ">
                                    <label className="control-label">
                                      Experience (in years) of Industrial
                                      Projects / Industrial Consultancy
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="experienceOfIndustrialProject"
                                      placeholder="Experience (in years) of Industrial Projects / Industrial Consultancy"
                                      required=""
                                      value={data.experienceOfIndustrialProject}
                                      onChange={(e) =>
                                       {
                                        const experienceOfIndustrialProject= e.target.value
                                        if(/^\d*$/.test(experienceOfIndustrialProject))
                                        {
                                          setData({
                                            ...data,
                                            experienceOfIndustrialProject:
                                              e.target.value,
                                          })
                                        }
                                      
                                       }
                                      }
                                    />
                                  </div>

                                  <div className="form-group col-12 col-lg-6 col-md-6 ">
                                    <label className="control-label">
                                      Number of Research Publications
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="numberOfResearchPublications"
                                      placeholder="Number of Research Publications"
                                      required=""
                                      value={data.numberOfResearchPublications}
                                      onChange={(e) =>
                                       {
                                        const numberOfResearchPublications= e.target.value;
                                        if(/^\d*$/.test(numberOfResearchPublications))
                                     {
                                      setData({
                                        ...data,
                                        numberOfResearchPublications:
                                          e.target.value,
                                      })
                                     }
                                       }
                                      }
                                    />
                                  </div>

                                  <div className="form-group col-12 col-lg-6 col-md-6 ">
                                    <label className="control-label">
                                      Number of Patents Granted
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="numberOfPatentGranted"
                                      placeholder="Number of Patents Granted"
                                      required=""
                                      value={data.numberOfPatentGranted}
                                      onChange={(e) =>
                                        {
                                          const numberOfPatentGranted=e.target.value;
                                          if(/^\d*$/.test(numberOfPatentGranted)){
                                            setData({
                                              ...data,
                                              numberOfPatentGranted: e.target.value,
                                            })
                                          }
                                         
                                        }
                                      }
                                    />
                                  </div>

                                  <div className="form-group col-12 col-lg-6 col-md-6 ">
                                    <label className="control-label">
                                      Number of Currently Published Patent
                                      Applications{" "}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="numberOfCurrentlyPublishedPatentApplications"
                                      placeholder="Number of Currently Published Patent Applications"
                                      required=""
                                      value={
                                        data.numberOfCurrentlyPublishedPatentApplications
                                      }
                                      onChange={(e) =>
                                     {
                                      const numberOfCurrentlyPublishedPatentApplications=e.target.value;
                                      if(/^\d*$/.test(numberOfCurrentlyPublishedPatentApplications))
                                    {  setData({
                                      ...data,
                                      numberOfCurrentlyPublishedPatentApplications:
                                        e.target.value,
                                    })}
                                     }
                                      }
                                    />
                                  </div>

                                  <div className="form-group col-12 col-lg-6 col-md-6 ">
                                    <label className="control-label">
                                      Other Information
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      name="otherInformation"
                                      placeholder="Other Information"
                                      required=""
                                      value={data.otherInformation}
                                      onChange={(e) =>
                                        setData({
                                          ...data,
                                          otherInformation: e.target.value,
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                    
                      </div>
                      <div className="col-md-12 d-flex justify-content-center">
                        
                        <>
                          <button
                            className="btn-submit creategreen"
                            type="submit"
                          >
                            {" "}
                            Update{" "}
                          </button>
                          <button
                            className="btn-submit backtored"
                            type="button"
                            onClick={() => navigate("/user/dashboard")}
                          >
                            Back
                          </button>
                        </>
                        {/* )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
}
export default UpdateProfileListing;
