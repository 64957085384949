import React, { useState, useEffect } from "react";
import axiosInstance from "../../../axiosInstance";
// import { ThreeDots } from "react-loader-spinner";
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";
import { useNavigate } from "react-router-dom";
import blankImage from "../../../assests/blankImage.jpg";
import Newadded from "../../../assests/images/newadded.png";

function ExpertCategory() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [searchByName, setSearchByName] = useState("");
  const [filteredTechList, setFilteredTechList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedPostName, setSelectedPostName] = useState("");
  const itemsPerPage = 12;

  const _tecsubcateName = localStorage.getItem("expertsubcaretogoryName");
  const tecId = localStorage.getItem("expertsubcaretogoryTokenID");
  const techSubId = sessionStorage.getItem("TecsubcaretogoryTokenID1");
  const NEW_ITEM_THRESHOLD = 60000;
  const handleSearchByName = (element) => {
    const search = searchByName.toLowerCase();
    return !search || element.title.toLowerCase().includes(search);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(
          `users/getUserByCategoryId?categoryId=${tecId}`,
          `${techSubId}`
        );
        if (response.data.status) {
          const now = Date.now();
          const newData = response.data.data.map((item) => {
            const createdAt = new Date(item.createdAt).getTime();
            const isNew = now - createdAt <= NEW_ITEM_THRESHOLD;
            return { ...item, isNew };
          });

          setData(newData);
          setFilteredTechList(newData);
          setTotalCount(newData.length);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    };
    getData();
  }, [tecId, techSubId]);

  const findFilteredTechList = () => {
    const filteredData = data.filter((element) => handleSearchByName(element));
    setTotalCount(filteredData.length);
    setFilteredTechList(filteredData);
    setCurrentPage(1);
  };

  const resetFilterList = () => {
    setSearchByName("");
    setCurrentPage(1);
    setTotalCount(data.length);
    setFilteredTechList(data);
  };

  const featuredTechnologiesHandleClick = (card_id, card_title) => {
    sessionStorage.setItem("techTokenID", card_id);
    sessionStorage.setItem("tecdetailedtitle", card_title);
    navigate("/expertice-area-details");
  };
  useEffect(() => {
    const intervalId = setInterval(() => {
      setData((currentData) =>
        currentData.map((item) => {
          if (!item.isNew) return item;

          const createdAt = new Date(item.createdAt).getTime();
          const now = Date.now();
          const isNew = now - createdAt <= NEW_ITEM_THRESHOLD;

          return { ...item, isNew };
        })
      );
    }, 60000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      {loading && <LoadingOverlay />}
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                <a href="/expertice-area-listing" className="breadcrumbs__item">
                  Hire Expertise
                </a>
                <a href="javascript: void(0)" className="is-active">
                  {selectedPostName || _tecsubcateName}
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="industrial-listing-page tech-transfer-main pad-sec-50">
        <div className="main-heading-for-internalpage position-relative">
          <h2>{_tecsubcateName}</h2>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 ">
              <div className="form-row d-flex align-items-center">
                <div className="form-group col-md-3">
                  <input
                    className="form-control"
                    type="search"
                    placeholder="Search by name"
                    onChange={(e) => {
                      setSearchByName(e.target.value);
                    }}
                    value={searchByName}
                  />
                </div>
                <div className="form-group col-md-2">
                  <button
                    onClick={findFilteredTechList}
                    type="button"
                    className="btn btn-submit btn-block btn-theme"
                  >
                    Search
                  </button>
                </div>
                <div className="form-group col-md-2">
                  <button
                    onClick={resetFilterList}
                    type="button"
                    className="btn btn-submit btn-block"
                  >
                    Reset
                  </button>
                </div>
                <div className="col-md-5 form-group d-flex justify-content-end">
                  <h5 className="mr-4 text-info">Total Count: {totalCount}</h5>
                </div>
              </div>
              <div className="main-tabingof-allredytaken w-100">
                <div className="tab-content">
                  <div className="tab-pane active" role="tabpanel" id="pending">
                    {loading ? (
                      <div className="loader-container">
                        {/* <ThreeDots color="#F23F2C" height={80} width={80} />
                        <h2>Please wait while data is loading....</h2> */}
                      </div>
                    ) : (
                      <div className="row">
                        {filteredTechList.length > 0 ? (
                          filteredTechList.map((item) => (
                            <div
                              key={item._id}
                              className="col-lg-3 col-sm-12 col-md-3 mb-4 d-flex align-items-stretch w-100"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                featuredTechnologiesHandleClick(
                                  item._id,
                                  item.title
                                )
                              }
                            >
                              <a
                                href="javascript: void(0)"
                                className="listing-expertise-box w-100"
                              >
                                <div className="card border-0 bg-transparent rounded-0 mb-lg-0 d-block listdata-shadow">
                                  <div className="list-image rounded">
                                    <img
                                      src={
                                        item.images && item.images[0]
                                          ? item.images[0]
                                          : blankImage
                                      }
                                      className="card-img-top w-100"
                                      alt="..."
                                    />
                                  </div>
                                  {item.isNew && (
                                    <img
                                      src={Newadded}
                                      alt="New"
                                      className="position-absolute recentadded-cardtec"
                                    />
                                  )}
                                  <div className="card-main-ex-cont mt-1 p-2">
                                    <h5>{item.areaOfExpertise}</h5>
                                  </div>
                                </div>
                              </a>
                            </div>
                          ))
                        ) : (
                          <div className="no-record-found text-center">
                            <p>No matching records found.</p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5 web-page">
            <div className="col-12 col-lg-12">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => setCurrentPage(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                  </li>
                  {Array.from({
                    length: Math.ceil(filteredTechList.length / itemsPerPage),
                  }).map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${
                        currentPage === index + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => setCurrentPage(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                  <li
                    className={`page-item ${
                      currentPage ===
                      Math.ceil(filteredTechList.length / itemsPerPage)
                        ? "disabled"
                        : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => setCurrentPage(currentPage + 1)}
                      disabled={
                        currentPage ===
                        Math.ceil(filteredTechList.length / itemsPerPage)
                      }
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default ExpertCategory;
