import React, { useState, useEffect } from "react";
import axiosInstance from "../../../../axiosInstance";
// import { ThreeDots } from "react-loader-spinner";
import "../../Comman/comman.css";
import LoadingOverlay from "../../Comman/comman";

function Teams() {
  const [tecTeam, setTecTeam] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tecTeamType, setTecTeamType] = useState([]);
  const [filteredTeams, setFilteredTeams] = useState([]);

  useEffect(() => {
    getTecTeamTypeData();
    getTecTeamData();
  }, []);
  useEffect(() => {
    if (tecTeamType.length > 0) {
      handleTecTeamClick(tecTeamType[0]._id);
    }
  }, [tecTeamType]);
  const getTecTeamTypeData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.post("/tecTeamTypes/getAll", {
        filter: {
          status: 1,
          isDeleted: false,
        },
      });

      setTecTeamType(response.data);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  const getTecTeamData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.post("/tecTeams/getAll", {
        filter: {
          status: true,
          isDeleted: false,
        },
      });
      setTecTeam(response.data.items);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleTecTeamClick = (_id) => {
    const filteredTeams = tecTeam.filter((team) => team.tecTeamTypeId === _id);
    setFilteredTeams(filteredTeams);
  };

  return (
    <>
      {loading && <LoadingOverlay />}
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className="breadcrumbs__item">
                  About Us
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className=" is-active">
                  TEC Teams
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>

      <section className="gallery-box-main pad-sec-50" id="tecteamesgallery">
        <div className="container">
          <div className="main-heading-for-internalpage position-relative">
            <h2>TEC Teams</h2>
          </div>
          {loading ? (
            <div className="loader-container">
              {/* <ThreeDots color="#F23F2C" height={80} width={80} /> */}
              {/* <h2>Please wait while data is loading....</h2> */}
            </div>
          ) : tecTeamType.length > 0 ? (
            <div>
              <ul
                className="nav nav-tabing-events d-flex justify-content-center"
                role="tablist"
              >
                {tecTeamType.map((item, index) => (
                  <li
                    key={index}
                    className="nav-item button-clik-change tab"
                    onClick={() => handleTecTeamClick(item._id)}
                  >
                    <a
                      href={`#${item.name.split(" ").join("-")}`}
                      role="tab"
                      data-toggle="tab"
                      className={
                        index === 0 ? "nav-link active show" : "nav-link"
                      }
                      aria-selected={index === 0 ? "true" : "false"}
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
              <div id="tabbed-content">
                <div id="Program-Advisory-Group" className="images active">
                  {filteredTeams.map((element, elemIndex) => (
                    <div key={elemIndex} className="content-box-tecg">
                      <img src={element.image} alt={` ${elemIndex + 1}`} />
                      <div className="content-inner-info text-center">
                        {element.name && <h4>{element.name}</h4>}
                        {element.designation && (
                          <span>{element.designation}</span>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </section>
    </>
  );
}

export default Teams;
