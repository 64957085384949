// LoadingOverlay.js
import React from "react";
import "./comman.css"; // Import CSS file for styling

const comman = () => (
  <div className="loading-overlay">
    <div className="loading-spinner"></div>
  </div>
);

export default comman;
