import axiosInstance from "../../../axiosInstance";
import { useState, useEffect } from "react";
// import { ThreeDots } from "react-loader-spinner";\
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";
import blankImage from "../../../assests/blankImage.jpg";
import { useNavigate } from "react-router-dom";

function AllTeccenter() {
  const [allMedia, setAllMedia] = useState([]);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const selectedTile = sessionStorage.getItem("galleryTitle");
  const _techcenterId = localStorage.getItem("TecCenterID");
  useEffect(() => {
    const getGalleryData = async () => {
      try {
        const response = await axiosInstance.post(`/technicalCentres/getAll`, {
          filter: {
            status: true,
          },
        });
        setAllMedia(response.data.items);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    getGalleryData();
  }, []);

  const tecCenterHandleClick = (card_id, nameOfUniversity) => {
    localStorage.setItem("TecCenterID", card_id);
    sessionStorage.setItem("teccenter", nameOfUniversity);
    navigate("/data-center-details");
  };

  return (
    <>
      {loading && <LoadingOverlay />}
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className="is-active">
                  {" "}
                  Nationwide TEC Centers
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>
      {/* ============================= */}
      <section className="industrial-listing-page tech-transfer-main pad-sec-50">
        <div className="main-heading-for-internalpage position-relative">
          <h2>Nationwide TEC Centers</h2>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 ">
              <div className="main-tabingof-allredytaken w-100">
                <div className="tab-content">
                  <div className="tab-pane active" role="tabpanel" id="pending">
                    {loading ? (
                      <div className="loader-container">
                        {/* <ThreeDots color="#F23F2C" height={80} width={80} />
                        <h2>Please wait while data is loading....</h2> */}
                      </div>
                    ) : allMedia.length > 0 ? (
                      <div className="row">
                        {allMedia.reverse().map((item) => (
                          <div
                            key={item._id}
                            className="col-lg-3 col-sm-12 col-md-3 mb-4 d-flex w-100"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              tecCenterHandleClick(
                                item._id,
                                item.nameOfUniversity
                              )
                            }
                          >
                            <div className="card border-0 bg-transparent rounded-0 mb-lg-0 d-block w-100 listdata-shadow">
                              <div className="list-image rounded">
                                <img
                                  src={item.image ? item.image : blankImage}
                                  alt={item.title}
                                />
                              </div>
                              <div className="card-main-ex-cont mt-1 p-2">
                                <h5>{item.nameOfUniversity}</h5>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="no-record-found text-center">
                        <p>No matching records found.</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AllTeccenter;
