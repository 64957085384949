import React, { useState, useEffect, useCallback } from "react";
import AsideDashboard from "./aside";
import axiosInstance from "../../../axiosInstance";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Multiselect from "multiselect-react-dropdown";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";

function TechTransferEdit() {
  const navigate = useNavigate();

  const userIndusId = localStorage.getItem("latestteceditt_id");

  const Technology_Readiness = [
    "TRL1 (Basic principles observed) ",
    "TRL2 (Technology concept formulated)",
    "TRL3 (Experimental proof of concept)",
    "TRL4 (Small Scale Prototype Built in a Lab Environment)",
    "TRL5 (Large Scale Prototype tested in intended environment)",
    "TRL6 (Prototype System Tested in intended environment close to expected performance",
    "TRL7 (System prototype demonstration in operational environment at pre-commercial scale)",
    "TRL8 (First of a kind commercial system. Manufacturing issues solved)",
    "TRL9 (Full commercial application, technology available for consumers) ",
  ];
  const patentData = [
    { value: "Application Filed", label: "Application Filed" },
    { value: "Application Published", label: "Application Published" },
    {
      value: "Request for Examination Filed",
      label: "Request for Examination Filed",
    },
    {
      value: "Patent Examination Report awaiting",
      label: "Patent Examination Report awaiting",
    },
    {
      value: "Patent Application Abandoned",
      label: "Patent Application Abandoned",
    },
    {
      value: "Patent Granted and Maintained",
      label: "Patent Granted and Maintained",
    },
    { value: "Patent Granted but ceased", label: "Patent Granted but ceased" },
    {
      value: "Patent Licensed / Commercialized",
      label: "Patent Licensed / Commercialized",
    },
    { value: "Non-Patented", label: "Non-Patented" },
  ];
  const [newAudioLinks, setNewAudioLinks] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [newVideoLinks, setNewVideoLinks] = useState([]);
  const [category, setCategory] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [audioLinks, setAudioLinks] = useState([""]);
  const [videoLinks, setVideoLinks] = useState([""]);

  const [selectedCategory, setSelectedCategory] = useState("");

  const [data, setData] = useState({
    category: "",
    title: "",
    images: [],
    videos: [],
    audios:[],
    tags:"",
    technologyReadinessLevel: "",
    applicationsOfTechnology: "",
    specifications: "",
    patentStatus: "",
    patentNumber: "",
    patentApplicationNumber: "",

    briefNote: "",
    bidStatus: "",
    bidStartDate: "",
    bidEndDate: "",
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        // setLoading(true);
        const response = await axiosInstance.get(
          `/technologyTransfers/getTechnlogyTransferById/${userIndusId}`
        );
       
        setData(response.data.data);
        setLoading(false);
      } catch (err) {
        setLoading(false);

        console.log(err);
      }
    };
    fetchData();
  }, []);
  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        //  setLoading(true);

        const categoriesResponse = await axiosInstance.get(
          "/subCategories/getAllSubCategoryByCategoryId/64d9d3911cae26c449f95fa4"
        );

        if (data) {
          const subCategoryOptions =
            data.subCategories?.map((item) => ({
              label: item.name,
              value: item._id,
            })) || [];

          let videoLinksArray =
            data.videos.map((video) => video.replace(/^'(.*)'$/, "$1")) || [];
            let audioLinksArray=  data.audios.map((audio) =>
            audio.replace(/^'(.*)'$/, "$1")
          ) || [];
          const preFilledImagePreviews = data.images;
          if (data.title) {
          }
          if (data.subCategories) {
          }
          if (data.images && data.images.length) {
          }
          if (data.briefNote) {
          }
          if (data.videos && data.videos.length) {
          }
          if (data.audios && data.audios.length) {
          }
          if (data.technologyReadinessLevel) {
          }
          if (data.applicationsOfTechnology) {
          }
          if (data.specifications) {
          }
          if (data.patentStatus) {
          }
          if (data.patentNumber) {
          }
          if (data.patentApplicationNumber) {
          }
          if (data.tags) {
          }
          if (data.bidStatus) {
          }
          if (data.bidStartDate) {
          }
          if (data.bidEndDate) {
          }
          setSelectedCategory(subCategoryOptions);
          setCategory(categoriesResponse.data.data);
          setAudioLinks(audioLinksArray);
          setVideoLinks(videoLinksArray);
          setImagePreviews(preFilledImagePreviews);
          setSelectedImages([...preFilledImagePreviews]);
          // setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // setLoading(false);
      }
    };

    fetchData();
  }, [data]);
  const handleAddVideoLink = () => {
    setVideoLinks((prevLinks) => [...prevLinks, ""]);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    
    const alphanumericRegex = /^[a-zA-Z0-9]*$/;
  
    // Adjust the field names to match the state keys exactly
    if (name === "patentNumber" || name === "patentApplicationNumber") {
      if (alphanumericRegex.test(value) || value === "") {
        setData({ ...data, [name]: value });
      }
    } else {
      setData({ ...data, [name]: value });
    }
  };
  
  const handleAddNewVideoLink = () => {
    setNewVideoLinks((prevLinks) => [...prevLinks, ""]);
  };
  const handleRemoveVideoField = (indexToRemove) => {
    setVideoLinks((prevLinks) =>
      prevLinks.filter((_, index) => index !== indexToRemove)
    );
  };
  function getCurrentDate() {
    const today = new Date();
    let month = String(today.getMonth() + 1);
    let day = String(today.getDate());
    const year = today.getFullYear();

    if (month.length === 1) {
      month = "0" + month;
    }
    if (day.length === 1) {
      day = "0" + day;
    }

    return `${year}-${month}-${day}`;
  }
  const handleBidStatusChange = (e) => {
    const { value } = e.target;
    const bidStatusValue = value === "true";

    setData({
      ...data,
      bidStatus: bidStatusValue,
    });
  };
  const handleUpdateNewVideoLink = (index, value) => {
    setNewVideoLinks((prevLinks) => {
      const updatedLinks = [...prevLinks];
      updatedLinks[index] = value;
      return updatedLinks;
    });
  };
  const handleRemoveNewVideoLink = (indexToRemove) => {
    setNewVideoLinks((prevLinks) =>
      prevLinks.filter((_, index) => index !== indexToRemove)
    );
  };
  const handleVideoLink = (e, index) => {
    const link = e.target.value;
    setVideoLinks((prevLinks) => {
      const updatedLinks = [...prevLinks];
      updatedLinks[index] = link;
      return updatedLinks;
    });
  };
  const handleRemoveVideoLink = (indexToRemove) => {
    const updatedVideoLinks = videoLinks.filter(
      (_, index) => index !== indexToRemove
    );
    setVideoLinks(updatedVideoLinks);
  };
  const handleRemoveAudioField = (indexToRemove) => {
    setAudioLinks((prevLinks) =>
      prevLinks.filter((_, index) => index !== indexToRemove)
    );
  };
  const handleAudioLink = (e, index) => {
    const link = e.target.value;
    setAudioLinks((prevLinks) => {
      const updatedLinks = [...prevLinks];
      updatedLinks[index] = link;
      return updatedLinks;
    });
  };
  const [formErrors, setFormErrors] = useState({
    category: "",
    title: "",
    images: "",
    description: "",
    notes: "",
  });

  const handleImageSelect = async (event) => {
    const imageFiles = event.target.files;

    try {
      setLoading(true);
      const imageUrls = [];

      for (let i = 0; i < imageFiles.length; i++) {
        const singleFileData = new FormData();
        singleFileData.append("image", imageFiles[i]);

        const response = await axiosInstance.post(
          "/tecTeams/uploadImage",
          singleFileData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        if (response) {
          imageUrls.push(response.data);
        }
      }

      setData((prevFormData) => ({
        ...prevFormData,
        images: [...prevFormData.images, ...imageUrls],
      }));
      setSelectedImages((prevImageFiles) => [...prevImageFiles, ...imageUrls]);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleRemoveImage = (indexToRemove) => {
    const updatedImages = selectedImages.filter(
      (_, index) => index !== indexToRemove
    );
    const updatedPreviews = imagePreviews.filter(
      (_, index) => index !== indexToRemove
    );
    setData((prevData) => ({
      ...prevData,
      images: updatedImages,
    }));
    setSelectedImages(updatedImages);
    setImagePreviews(updatedPreviews);
  };

  const handleAddVideoField = () => {
    setVideoLinks((prevLinks) => [...prevLinks, ""]);
  };
  const requiredPatentStatuses = [
    "Patent Granted and Maintained",
    "Patent Granted but ceased",
    "Patent Licensed / Commercialized",
  ];

  const requiredPatentStatuses1 = [
    "Application Filed",
    "Application Published",
    "Request for Examination Filed",
    "Patent Examination Report awaiting",
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = {};

    if (!data.title.trim()) {
      errors.title = "Title is required.";
    }
    if (!data.technologyReadinessLevel.trim()) {
      errors.technologyReadinessLevel =
        "Technology Readiness Level is required.";
    }

    if (!data.patentStatus.trim()) {
      errors.patentStatus = "Patent Status is required.";
    }
    if (!data.briefNote.trim()) {
      errors.briefNote = "Description is required.";
    }
    const requiredPatentStatuses = [
      "Patent Granted and Maintained",
      "Patent Granted but ceased",
      "Patent Licensed / Commercialized",
    ];
    if (
      requiredPatentStatuses.includes(data.patentStatus) &&
      !data?.patentNumber?.trim()
    ) {
      errors.patentNumber = "Patent Number is required.";
    }
    if (
      requiredPatentStatuses.includes(data.patentStatus) &&
      !data.patentApplicationNumber.trim()
    ) {
      errors.patentApplicationNumber = "Patent Application Number is required.";
    }
    const requiredPatentStatuses1 = [
      "Application Filed",
      "Application Published",
      "Request for Examination Filed",
      "Patent Examination Report awaiting",
    ];

    if (
      requiredPatentStatuses1.includes(data.patentStatus) &&
      !data.patentApplicationNumber.trim()
    ) {
      errors.patentApplicationNumber = "Patent Application Number is required.";
    }
    if (data.bidStatus) {
      if (!data?.bidStartDate?.trim()) {
        errors.bidStartDate = "Start date is required .";
      }
      if (!data?.bidEndDate?.trim()) {
        errors.bidEndDate = "End date is required .";
      }
    }
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    const formData = new FormData();
    formData.append("title", data.title);

    const selectedCategoriesString = selectedCategory
      .map((item) => item.value)
      .join(",");
    formData.append("categoryId", selectedCategoriesString);

    formData.append("technologyReadinessLevel", data.technologyReadinessLevel);
    formData.append("bidStatus", data.bidStatus.toString());
    formData.append("patentStatus", data.patentStatus);
    formData.append("tags", data.tags);
    formData.append("patentNumber", data.patentNumber);
    formData.append("patentApplicationNumber", data.patentApplicationNumber);
    formData.append("briefNote", data.briefNote);
    if (data.bidStatus) {
      formData.append("bidStartDate", data.bidStartDate);
      formData.append("bidEndDate", data.bidEndDate);
    }
    for (let i = 0; i < selectedImages.length; i++) {
      formData.append("images", selectedImages[i]);
    }

    const allVideoLinks = [...videoLinks, ...newVideoLinks];
    const formattedVideoLinks = allVideoLinks.map((link) => `${link}`);
    const videosIncluded = formattedVideoLinks.filter(
      (link) => link.trim() !== ""
    );
    const allAudioLinks = [...audioLinks, ...newAudioLinks];
    const formattedAudioLinks = allAudioLinks.map((link) => `${link}`);
    formData.append("audios", formattedAudioLinks);
    if (videosIncluded.length > 0) {
      formData.append("videos", videosIncluded.join(","));
    }
    try {
      setLoading(true);
      await axiosInstance.put(
        `/technologyTransfers/update/${userIndusId}`,
        formData
      );
      toast.success("Data updated successfully !");
      setTimeout(() => {
        navigate("/user/dashboard-tech-transfer-listing");
      }, 1000);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Failed to submit form. Please try again!");
    }
  };
  const handleBriefNoteChange = useCallback((value) => {
    setData((prevData) => ({ ...prevData, briefNote: value }));
  }, []);
  const handlespecificationsChange = useCallback((value) => {
    setData((prevData) => ({ ...prevData, specifications: value }));
  }, []);
  const handleapplicationsOfTechnologyChange = useCallback((value) => {
    setData((prevData) => ({ ...prevData, applicationsOfTechnology: value }));
  }, []);
  const handlebidProvisionChange = useCallback((value) => {
    setData((prevData) => ({ ...prevData, tags: value }));
  }, []);
  const handleAddAudioField = () => {
    setAudioLinks((prevLinks) => [...prevLinks, ""]);
  };

 

  return (
    <>
      {loading && <LoadingOverlay />}
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}

                <a href="javascript: void(0)" className=" is-active">
                  Edit Technology Transfer
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="main-sidenavigation pad-sec-50">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-3">
              <aside>
                <AsideDashboard />
              </aside>
            </div>

            <div className="col-12 col-lg-9 main-wrapper-form pt-3 pb-3">
              <div className="deshboard-title">
                <h3>Edit Technology Transfer </h3>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="form-bg-box-inner">
                  <div className="main-form-for-dashboard">
                    <div className="row p-4">
                      <div className="form-group col-12 col-lg-12 col-md-12">
                        <label className="control-label">
                          Title of Invention / Technology
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          placeholder="Title"
                          className="form-control"
                          name="title"
                          type="text"
                          value={data.title}
                          onChange={(e) =>
                            setData({ ...data, title: e.target.value })
                          }
                        />

                        {formErrors.title && (
                          <span className="text-danger">
                            {formErrors.title}
                          </span>
                        )}
                      </div>

                      <div className="form-group col-12 col-lg-12 col-md-12">
                        <label htmlFor="exampleFormControlTextarea1">
                          Brief Note / Description / Objective of Technology
                          <span className="text-danger">*</span>
                        </label>

                        {/* Replace the textarea with ReactQuill */}
                        <ReactQuill
                          value={data.briefNote}
                          onChange={handleBriefNoteChange}
                        />

                        {formErrors.briefNote && (
                          <span className="text-danger">
                            {formErrors.briefNote}
                          </span>
                        )}
                      </div>
                      <div className="form-group col-12 col-lg-12 col-md-12">
                        <label htmlFor="exampleFormControlTextarea1">
                          Specifications / Features of Technology
                          <span className="text-danger">*</span>
                        </label>
                        <ReactQuill
                          value={data.specifications}
                          onChange={handlespecificationsChange}
                        />
                        {formErrors.specifications && (
                          <span className="text-danger">
                            {formErrors.specifications}
                          </span>
                        )}
                      </div>
                      <div className="form-group col-12 col-lg-12 col-md-12">
                        <label htmlFor="exampleFormControlTextarea1">
                          Applications of Technology
                          <span className="text-danger">*</span>
                        </label>
                        <ReactQuill
                          value={data.applicationsOfTechnology}
                          onChange={handleapplicationsOfTechnologyChange}
                        />
                        {formErrors.applicationsOfTechnology && (
                          <span className="text-danger">
                            {formErrors.applicationsOfTechnology}
                          </span>
                        )}
                      </div>
                      <div className="form-group col-12 col-lg-12 col-md-12">
                        <label htmlFor="exampleFormControlTextarea1">
                        Tag As 
                        
                        </label>
                        <ReactQuill
                          value={data.tags}
                          onChange={handlebidProvisionChange}
                        />
                        
                      </div>
                      <div className="form-group col-12 col-lg-12 col-md-12 d-flex flex-column">
                        <label>
                          Upload images{" "}
                          <span className="text-danger">
                            *(You can select multiple imagess)
                          </span>
                        </label>
                        <input
                          type="file"
                          name="img"
                          required=""
                          accept="image/*"
                          multiple
                          className="form-control"
                          onChange={handleImageSelect}
                        />
                      </div>
                      <div className="col-12 col-lg-12 col-md-12 d-flex flex-column">
                        <div className="selected-images">
                          {selectedImages[0] != "" &&
                            selectedImages.map((preview, index) => (
                              <div key={index} className="">
                                <img
                                  src={preview}
                                  alt={` `}
                                  className="selected-image"
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    marginBottom: "10px",
                                  }}
                                />
                                <button
                                  type="button"
                                  className="btn-submit backtored"
                                  onClick={() => handleRemoveImage(index)}
                                >
                                  <i class="fa fa-times" aria-hidden="true"></i>
                                </button>
                              </div>
                            ))}
                        </div>
                      </div>
                      <div
                        className="form-group col-12 col-lg-12 col-md-12"
                        id="uploadLink"
                      >
                        <label>Upload Audio Link</label>

                        {audioLinks.map((link, index) => (
                          <div className="d-flex align-item-center" key={index}>
                            <div className="form-group col-9">
                              <input
                                type="text"
                                className="form-control"
                                value={link}
                                onChange={(e) => handleAudioLink(e, index)}
                              />
                            </div>
                            <div className="form-group col-3">
                              <button
                                type="button"
                                className="btn-submit backtored"
                                onClick={() => handleRemoveAudioField(index)}
                              >
                                <i class="fa fa-times" aria-hidden="true"></i>
                              </button>
                            </div>
                          </div>
                        ))}
                        <div className="form-group col-6">
                          <button
                            type="button"
                            className="btn-submit creategreen"
                            onClick={handleAddAudioField}
                          >
                            Add More
                          </button>
                        </div>
                      </div>
                      <div
                        className="form-group col-12 col-lg-12 col-md-12"
                        id="uploadLink"
                      >
                        <label>Upload Video Link</label>
                        <span className="text-danger">*</span>
                        {videoLinks.map((link, index) => (
                          <div className="d-flex align-item-center" key={index}>
                            <div className="form-group col-9">
                              <input
                                type="text"
                                className="form-control mb-0"
                                value={link}
                                onChange={(e) => handleVideoLink(e, index)}
                              />
                            </div>
                            <div className="form-group col-3">
                              <button
                                type="button"
                                className="btn-submit backtored"
                                onClick={() => handleRemoveVideoField(index)}
                              >
                                <i class="fa fa-times" aria-hidden="true"></i>
                              </button>
                            </div>
                          </div>
                        ))}
                        <div className="form-group col-6">
                          <button
                            type="button"
                            className="btn-submit creategreen"
                            onClick={handleAddVideoField}
                          >
                            Add More
                          </button>
                        </div>
                      </div>

                      <div className="form-group col-12 col-lg-6 col-md-6 d-flex flex-column">
                        <label className="control-label">
                          Technology Readiness Level
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control"
                          id="select"
                          name="technologyReadinessLevel"
                          value={data.technologyReadinessLevel}
                          onChange={(e) =>
                            setData({
                              ...data,
                              technologyReadinessLevel: e.target.value,
                            })
                          }
                        >
                          <option value="">Select Technology Readiness</option>
                          {Technology_Readiness.map((items) => (
                            <option key={items} value={items}>
                              {items}
                            </option>
                          ))}
                        </select>
                        {formErrors.technologyReadinessLevel && (
                          <span className="text-danger">
                            {formErrors.technologyReadinessLevel}
                          </span>
                        )}
                      </div>
                      <div className="form-group col-12 col-lg-6 col-md-6">
                        <label className="control-label">
                          Technological Domain / Target Industry Sector for
                          Technology<span className="text-danger">*</span>
                        </label>
                        <Multiselect
                          showCheckbox={true}
                          options={category.map((item) => ({
                            label: item.name,
                            value: item._id,
                          }))}
                          selectedValues={selectedCategory}
                          placeholder="Select Category"
                          onSelect={(selectedList) =>
                            setSelectedCategory(selectedList)
                          }
                          onRemove={(selectedList) =>
                            setSelectedCategory(selectedList)
                          }
                          displayValue="label"
                        />
                        {formErrors.category && (
                          <span className="text-danger">
                            {formErrors.category}
                          </span>
                        )}
                      </div>
                      <div className="form-group col-12 col-lg-6 col-md-6 d-flex flex-column">
                        <label className="control-label">
                          Patent Status<span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control"
                          id="select"
                          name="patentStatus"
                          value={data.patentStatus}
                          onChange={(e) =>
                            setData({
                              ...data,
                              patentStatus: e.target.value,
                            })
                          }
                        >
                          <option value="">Select Patent Status</option>
                          {patentData.map((item) => (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </div>

                      {requiredPatentStatuses.includes(data.patentStatus) && (
                        <div className="form-group col-12 col-lg-6 col-md-6 d-flex flex-column">
                          <label className="control-label">
                            Patent Number<span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="patentNumber"
                            placeholder="Patent Number"
                            value={data.patentNumber}
                            // onChange={(e) =>
                            //   setData({ ...data, patentNumber: e.target.value })
                            // }
                            onChange={handleChange} 
                          />
                          {formErrors.patentNumber && (
                            <span className="text-danger">
                              {formErrors.patentNumber}
                            </span>
                          )}
                        </div>
                      )}
                      {![
                        "Non-Patented",
                        "Patent Application Abandoned",
                      ].includes(data.patentStatus) && (
                        <div className="form-group col-12 col-lg-6 col-md-6 d-flex flex-column">
                          <label className="control-label">
                            Patent Application Number
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="patentApplicationNumber"
                            placeholder="Patent Application Number"
                            value={data.patentApplicationNumber}
                            // onChange={(e) =>
                            //   setData({
                            //     ...data,
                            //     patentApplicationNumber: e.target.value,
                            //   })
                            // }
                            onChange={handleChange} 
                          />
                          {formErrors.patentApplicationNumber && (
                            <span className="text-danger">
                              {formErrors.patentApplicationNumber}
                            </span>
                          )}
                        </div>
                      )}
                      <div className="form-group col-12 col-lg-6 col-md-6 d-flex flex-column">
                        <label className="control-label">
                          Do you want to do bid
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control"
                          id="select"
                          value={data.bidStatus}
                          onChange={handleBidStatusChange}
                          name="bidStatus"
                        >
                          <option value="">Select Option</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                      </div>
                      {data.bidStatus === true && (
                        <>
                          <div className="form-group col-12 col-lg-6 col-md-6 d-flex flex-column">
                            <label className="control-label">
                              Bid Start Date{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              name="bidStartDate"
                              placeholder="Start date"
                              min={getCurrentDate()}
                              value={
                                data.bidStartDate
                                  ? data.bidStartDate.split("T")[0]
                                  : ""
                              }
                              onChange={handleDateChange}
                            />
                            {formErrors.bidStartDate && (
                              <span className="text-danger">
                                {formErrors.bidStartDate}
                              </span>
                            )}
                          </div>

                          <div className="form-group col-12 col-lg-6 col-md-6 d-flex flex-column">
                            <label className="control-label">
                              Bid End Date{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              name="bidEndDate"
                              placeholder="End date"
                              min={data.bidStartDate.split("T")[0] }
                              value={
                                data.bidEndDate
                                  ? data.bidEndDate.split("T")[0]
                                  : ""
                              }
                              onChange={handleDateChange}
                            />

                            {formErrors.bidEndDate && (
                              <span className="text-danger">
                                {formErrors.bidEndDate}
                              </span>
                            )}
                          </div>
                        </>
                      )}
                      <div className="col-md-12 d-flex justify-content-center">
                        {/* {loading ? (
                          <div className="spinner">
                            <Oval color="#00BFFF" height={30} width={30} />
                          </div>
                        ) : ( */}
                        <>
                          <button
                            className="btn-submit creategreen"
                            type="submit"
                          >
                            Update
                          </button>
                          <button
                            className="btn-submit backtored"
                            type="button"
                            onClick={() =>
                              navigate("/user/dashboard-tech-transfer-listing")
                            }
                          >
                            Back
                          </button>
                        </>
                        {/* )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
      />
    </>
  );
}
export default TechTransferEdit;
