import React, { useState, useEffect } from "react";
import AsideDashboard from "./aside";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./expertise_create.css";
import axiosInstance from "../../../axiosInstance";
import { useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";

function ExpertiseCreateListing() {
  const navigate = useNavigate();
  const MAX_WIDTH = 309;
  const MAX_HEIGHT = 193;

  const patentData = [
    { value: "Application Filed", label: "Application Filed" },
    { value: "Application Published", label: "Application Published" },
    {
      value: "Request for Examination Filed",
      label: "Request for Examination Filed",
    },
    {
      value: "Patent Examination Report awaiting",
      label: "Patent Examination Report awaiting",
    },
    {
      value: "Patent Application Abandoned",
      label: "Patent Application Abandoned",
    },
    {
      value: "Patent Granted and Maintained",
      label: "Patent Granted and Maintained",
    },
    { value: "Patent Granted but ceased", label: "Patent Granted but ceased" },
    {
      value: "Patent Licensed / Commercialized",
      label: "Patent Licensed / Commercialized",
    },
    { value: "Non-Patented", label: "Non-Patented" },
  ];
  const Technology_Readiness = [
    "TRL1 (Basic principles observed) ",
    "TRL2 (Technology concept formulated)",
    "TRL3 (Experimental proof of concept)",
    "TRL4 (Small Scale Prototype Built in a Lab Environment)",
    "TRL5 (Large Scale Prototype tested in intended environment)",
    "TRL6 (Prototype System Tested in intended environment close to expected performance",
    "TRL7 (System prototype demonstration in operational environment at pre-commercial scale)",
    "TRL8 (First of a kind commercial system. Manufacturing issues solved)",
    "TRL9 (Full commercial application, technology available for consumers) ",
  ];
  const [category, setCategory] = useState([]);
  const [formdata, setFormdata] = useState({
    title: "",
    readiness: "",
    images: [],
    audios: [],
    patentStatus: "",
    patentnumber: "",
    tags: "",
    patentApplicationNumber: "",
    briefNote: "",
    applicationsOfTechnology: "",
    specifications: "",
    categoryId: "",
    link: "",
    bidStatus: "",
    bidStartDate: "",
    bidEndDate: "",
  });
  const [selectedCategory, setSelectedCategory] = useState("");

  const [selectedImages, setSelectedImages] = useState([]);

  const [imagePreviews, setImagePreviews] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [audioLinks, setAudioLinks] = useState([""]);
  const [loading, setLoading] = useState(false);
  const [imageFiles, setImageFiles] = useState([]);
  const isDataAvailable = category.length > 0;
  const [videoLinks, setVideoLinks] = useState([""]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    
   
    const alphanumericRegex = /^[a-zA-Z0-9]*$/;
  
  
    if (name === "patentnumber" || name === "patentApplicationNumber") {
  
      if (alphanumericRegex.test(value) || value === "") {
        setFormdata({
          ...formdata,
          [name]: value,
        });
      }
    } else {
    
      const shouldClearError = [
        "Application Filed",
        "Application Published",
        "Request for Examination Filed",
      ].includes(value);
      const shouldClearErrors = [
        "Application Filed",
        "Application Published",
        "Request for Examination Filed",
        "Patent Examination Report awaiting",
      ].includes(value);
      const isPatentStatusRequiringApplicationNumber = ![
        "Non-Patented",
        "Patent Application Abandoned",
      ].includes(value);
  
 
      setFormdata({
        ...formdata,
        [name]: value,
        ...(shouldClearError && {
          patentApplicationNumber: "",
          patentnumber: "",
        }),
        ...(shouldClearErrors && {
          patentApplicationNumber: "",
        }),
      });
  
     
      setFormErrors({
        ...formErrors,
        ...(shouldClearError && {
          patentApplicationNumber: "",
          patentnumber: "",
        }),
        ...(shouldClearErrors && {
          patentApplicationNumber: "",
        }),
        ...(isPatentStatusRequiringApplicationNumber && {
          patentApplicationNumber: "",
        }),
        ...(name === "readiness" && { readiness: "" }),
        ...(name === "patentStatus" && { patentStatus: "" }),
        ...(name === "applicationsOfTechnology" && {
          applicationsOfTechnology: "",
        }),
        ...(name === "specifications" && { specifications: "" }),
        ...(name === "title" && { title: "" }),
        ...(name === "briefNote" && { briefNote: "" }),
        ...(name === "bidStatus" && { bidStatus: "" }),
      });
    }
  };
  
  
  
  
  

  useEffect(() => {
    const fetchAccountData = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(
          "/subCategories/getAllSubCategoryByCategoryId/64d9d3911cae26c449f95fa4"
        );
        setCategory(response.data.data);
        setLoading(false);
      } catch (error) {
        setCategory([]);
        setLoading(false);
      }
    };

    fetchAccountData();
  }, []);

  const handleImage = async (event) => {
    const imageFiles = event.target.files;

    try {
      setLoading(true);

      const imageUrls = [];
      for (let i = 0; i < imageFiles.length; i++) {
        const file = imageFiles[i];

        if (!file.type.startsWith("image/")) {
          toast.error("Only image files can be uploaded.");
          event.target.value = null;
          continue;
        }

        const singleFileData = new FormData();
        singleFileData.append("image", file);

        const response = await axiosInstance.post(
          "/tecTeams/uploadImage",
          singleFileData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        if (response) {
          imageUrls.push(response.data);
        }
      }

      setFormdata((prevFormData) => ({
        ...prevFormData,
        images: [...prevFormData.images, ...imageUrls],
      }));
      setImageFiles((prevImageFiles) => [...prevImageFiles, ...imageUrls]);
      setLoading(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  function getCurrentDate() {
    const today = new Date();
    let month = String(today.getMonth() + 1);
    let day = String(today.getDate());
    const year = today.getFullYear();

    if (month.length === 1) {
      month = "0" + month;
    }
    if (day.length === 1) {
      day = "0" + day;
    }

    return `${year}-${month}-${day}`;
  }

  const handleSelectChange = (e) => {
    const { name, value } = e.target;
    if (name === "category") {
      setSelectedCategory(value);

      setFormErrors({
        ...formErrors,
        category: "",
      });
    }
  };

  const handleVideoLinkChange = (e, index) => {
    const { name, value } = e.target;
    setVideoLinks((prevLinks) => {
      const updatedLinks = [...prevLinks];
      updatedLinks[index] = value;

      return updatedLinks;
    });
  };
  const handleAudioLinkChange = (e, index) => {
    const { name, value } = e.target;
    setAudioLinks((prevLinks) => {
      const updatedLinks = [...prevLinks];
      updatedLinks[index] = value;

      return updatedLinks;
    });
  };
  const handleRemoveAudioLink = (index) => {
    setAudioLinks((prevLinks) => prevLinks.filter((_, i) => i !== index));
  };
  const handleAddMoreAudioLink = () => {
    setAudioLinks((prevLinks) => [...prevLinks, ""]);
  };
  const handleBidStatusChange = (e) => {
    const { name, value } = e.target;
    const bidStatusValue = value === "true";

    setFormdata({
      ...formdata,
      bidStatus: bidStatusValue,
      bidStartDate: bidStatusValue ? formdata.bidStartDate : "",
      bidEndDate: bidStatusValue ? formdata.bidEndDate : "",
    });

    setFormErrors({
      ...formErrors,
      bidStatus: "",
    });
  };

  const handleRemoveVideoLink = (index) => {
    setVideoLinks((prevLinks) => prevLinks.filter((_, i) => i !== index));
  };

  const handleAddMoreVideoLink = () => {
    setVideoLinks((prevLinks) => [...prevLinks, ""]);
  };

  const [formErrors, setFormErrors] = useState({
    title: "",
    readiness: "",

    patentStatus: "",
    briefNote: "",
    category: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const errors = {};

    if (!formdata.title.trim()) {
      errors.title = "Title is required.";
    }

    if (selectedCategory.length === 0) {
      errors.category = "Category is required.";
    }
    if (!formdata.readiness.trim()) {
      errors.readiness = "Technology Readiness Level is required.";
    }

    if (!formdata.patentStatus.trim()) {
      errors.patentStatus = "Patent Status is required .";
    }
    if (!formdata.bidStatus) {
      errors.bidStatus = "Bid Status is required .";
    }

    
    if (!formdata.briefNote.trim()) {
      errors.briefNote = "Description is required .";
    }
    if (formdata.bidStatus && !formdata.bidStartDate.trim()) {
      errors.bidStartDate = "Start date is required.";
    }
    if (new Date(formdata.bidEndDate) < new Date(formdata.bidStartDate)) {
      errors.bidEndDate = "End date cannot be earlier than start date.";
    }

    if (formdata.bidStatus && !formdata.bidEndDate.trim()) {
      errors.bidEndDate = "End date is required.";
    }
    // if (!formdata.patentnumber.trim()) {
    //   errors.patentnumber = "Patentnumber is required .";
    // }
    const invalidVideoLinks = videoLinks.filter((link) => {
      return !/^https?:\/\/(www\.)?(youtube\.com\/(watch\?v=|embed\/)|youtu\.be\/|player\.vimeo\.com\/video\/)|<iframe.*<\/iframe>/.test(
        link
      );
    });

    if (invalidVideoLinks.length > 0) {
      errors.videoLinks = "Please enter valid YouTube formats.";
    }
    if (!formdata.specifications.trim()) {
      errors.specifications = "Specifications is required .";
    }
    if (!formdata.applicationsOfTechnology.trim()) {
      errors.applicationsOfTechnology =
        "Applications Of Technology is required .";
    }

    const requiredPatentStatuses = [
      "Patent Granted and Maintained",
      "Patent Granted but ceased",
      "Patent Licensed / Commercialized",
    ];
    if (
      requiredPatentStatuses.includes(formdata.patentStatus) &&
      !formdata.patentnumber.trim()
    ) {
      errors.patentnumber = "Patent Number is required.";
    }
    if (
      requiredPatentStatuses.includes(formdata.patentStatus) &&
      !formdata.patentApplicationNumber.trim()
    ) {
      errors.patentApplicationNumber = "Patent Application Number is required.";
    }
    const requiredPatentStatuses1 = [
      "Application Filed",
      "Application Published",
      "Request for Examination Filed",
      "Patent Examination Report awaiting",
    ];

    if (
      requiredPatentStatuses1.includes(formdata.patentStatus) &&
      !formdata.patentApplicationNumber.trim()
    ) {
      errors.patentApplicationNumber = "Patent Application Number is required.";
    }
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    try {
      setLoading(true);

      var data = new FormData();
      // data.append("images", JSON.stringify(imageFiles));
      for (let i = 0; i < imageFiles.length; i++) {
        data.append("images", imageFiles[i]);
      }

      const selectedCategoriesString = selectedCategory
        .map((item) => item.value)
        .join(",");
      data.append("categoryId", selectedCategoriesString);
      const videosArray = videoLinks.map((link) => `${link}`);
      const videosString = videosArray.join(", ");
      data.append("videos", videosString);
      data.append("bidStatus", formdata.bidStatus.toString());
      data.append("bidStartDate", formdata.bidStartDate);
      data.append("bidEndDate", formdata.bidEndDate);
      data.append("userId", localStorage.getItem("userId"));
      data.append("title", formdata.title);
      data.append("briefNote", formdata.briefNote);
      data.append(
        "applicationsOfTechnology",
        formdata.applicationsOfTechnology
      );
      data.append("technologyReadinessLevel", formdata.readiness);
      data.append("tags", formdata.tags);
      // data.append("technologicalDomain", formdata.techdomain);
      data.append("patentStatus", formdata.patentStatus);
      data.append("specifications", formdata.specifications);
      data.append("patentApplicationNumber", formdata.patentApplicationNumber);
      data.append("patentNumber", formdata.patentnumber);
      const audioArray = audioLinks.map((link) => `${link}`);
      const audiosString = audioArray.join(", ");
      data.append("audios", audiosString);
      const response = await axiosInstance.post(
        "/technologyTransfers/create",
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (
        response.status === 200 &&
        response.data.data &&
        response.data.data._id
      ) {
        localStorage.setItem("tectransferedit", response.data._id);
        toast.success("Data is saved successfully");
        setTimeout(() => {
          navigate("/user/dashboard-tech-transfer-listing");
        }, 1000);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Failed to add data. Please try again.");
      }
    } catch (error) {
      setLoading(false);
      toast.error("An error occurred while adding data. Please try again.");
      console.log("error", error);
    }
  };
  const handleVideoTypeChange = (e) => {
    const { value } = e.target;
    setSelectedOption(value);
    if (value === "link") {
      const linksArray = formdata.link.split(",");
      setVideoLinks(linksArray);
    }
  };
  const handleRemoveImage = (index) => {
    const updatedPreviews = [...imagePreviews];
    updatedPreviews.splice(index, 1);

    const updatedImages = [...selectedImages];
    updatedImages.splice(index, 1);

    setImagePreviews(updatedPreviews);
    setSelectedImages(updatedImages);

    setImageFiles((prevImageFiles) => {
      const updatedFiles = [...prevImageFiles];
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  return (
    <>
      {loading && <LoadingOverlay />}
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                <a href="javascript: void(0)" className=" is-active">
                  Create Technology Transfer
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>
   
      <section className="main-sidenavigation pad-sec-50">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-3">
              <aside>
                <AsideDashboard />
              </aside>
            </div>

            <div className="col-12 col-lg-9 main-wrapper-form pt-3 pb-3">
              <div className="deshboard-title">
                <h3>Create Technology Transfer </h3>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="form-bg-box-inner">
                  <div className="main-form-for-dashboard">
                    <div className="row p-4">
                      <div className="form-group col-12 col-lg-12 col-md-12">
                        <label className="control-label">
                          Title of Invention / Technology
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          placeholder="Title"
                          className="form-control"
                          name="title"
                          type="text"
                          value={formdata.title}
                          onChange={handleChange}
                        />
                        {formErrors.title && (
                          <span className="text-danger">
                            {formErrors.title}
                          </span>
                        )}
                      </div>
                      <div className="form-group col-12 col-lg-12 col-md-12">
                        <label htmlFor="exampleFormControlTextarea1">
                          Brief Note / Description / Objective of Technology
                          <span className="text-danger">*</span>
                        </label>
                        <ReactQuill
                          value={formdata.briefNote}
                          onChange={(value) =>
                            handleChange({
                              target: { name: "briefNote", value },
                            })
                          }
                        />
                        {formErrors.briefNote && (
                          <span className="text-danger">
                            {formErrors.briefNote}
                          </span>
                        )}
                      </div>
                      <div className="form-group col-12 col-lg-12 col-md-12">
                        <label htmlFor="exampleFormControlTextarea1">
                          Specifications / Features of Technology
                          <span className="text-danger">*</span>
                        </label>
                        <ReactQuill
                          value={formdata.specifications}
                          onChange={(value) =>
                            handleChange({
                              target: { name: "specifications", value },
                            })
                          }
                        />
                        {formErrors.specifications && (
                          <span className="text-danger">
                            {formErrors.specifications}
                          </span>
                        )}
                      </div>
                      <div className="form-group col-12 col-lg-12 col-md-12">
                        <label htmlFor="exampleFormControlTextarea1">
                          Applications of Technology
                          <span className="text-danger">*</span>
                        </label>
                        <ReactQuill
                          value={formdata.applicationsOfTechnology}
                          onChange={(value) =>
                            handleChange({
                              target: {
                                name: "applicationsOfTechnology",
                                value,
                              },
                            })
                          }
                        />
                        {formErrors.applicationsOfTechnology && (
                          <span className="text-danger">
                            {formErrors.applicationsOfTechnology}
                          </span>
                        )}
                      </div>
                      <div className="form-group col-12 col-lg-12 col-md-12">
                        <label htmlFor="exampleFormControlTextarea1">
                          Tag As
                        </label>
                        <ReactQuill
                          value={formdata.tags}
                          onChange={(value) =>
                            handleChange({
                              target: { name: "tags", value },
                            })
                          }
                        />
                      </div>
                      <div className="form-group col-12 col-lg-6 col-md-6 d-flex flex-column">
                        <label>
                          Images{" "}
                          <span className="text-danger">
                            (You can select multiple images)
                          </span>{" "}
                        </label>
                        <input
                          type="file"
                          name="img"
                          required=""
                          accept="image/*"
                          multiple
                          className="form-control"
                          onChange={handleImage}
                        />
                        <div className="image-preview-container">
                          {imageFiles.map((preview, index) => (
                            <div key={index} className="image-preview-wrapper ">
                              <img
                                src={preview}
                                alt={` ${index + 1}`}
                                className="image-preview"
                              />
                              <button
                                type="button"
                                className="btn-submit backtored"
                                onClick={() => handleRemoveImage(index)}
                              >
                                <i class="fa fa-times" aria-hidden="true"></i>
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div
                        className="form-group col-12 col-lg-6 col-md-6"
                        id="uploadLink"
                      >
                        <label>Audio Links</label>
                        <div>
                          {audioLinks.map((audioLink, index) => (
                            <div key={index}>
                              <input
                                placeholder="Audio Link"
                                className="form-control m-2"
                                name="link"
                                type="text"
                                value={audioLink}
                                onChange={(e) =>
                                  handleAudioLinkChange(e, index)
                                }
                              />
                              {index > 0 && (
                                <button
                                  className="btn-submit backtored"
                                  type="button"
                                  onClick={() => handleRemoveAudioLink(index)}
                                >
                                  <i
                                    className="fa fa-times"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              )}
                            </div>
                          ))}
                          <button
                            type="button"
                            onClick={handleAddMoreAudioLink}
                          >
                            Add More
                          </button>
                        </div>
                      </div>
                      <div
                        className="form-group col-12 col-lg-6 col-md-6"
                        id="uploadLink"
                      >
                        <label>Upload Video Links</label>
                        <span className="text-danger">*</span>
                        <div>
                          {videoLinks.map((videoLink, index) => (
                            <div key={index}>
                              <input
                                placeholder="Video Link"
                                className="form-control  m-2"
                                name="link"
                                type="text"
                                value={videoLink}
                                onChange={(e) =>
                                  handleVideoLinkChange(e, index)
                                }
                              />
                              {index > 0 && (
                                <button
                                  className="btn-submit backtored"
                                  type="button"
                                  onClick={() => handleRemoveVideoLink(index)}
                                >
                                  <i
                                    className="fa fa-times"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              )}
                            </div>
                          ))}
                          <button
                            type="button"
                            onClick={handleAddMoreVideoLink}
                          >
                            Add More
                          </button>
                        </div>
                        {formErrors.videoLinks && (
                          <span className="text-danger">
                            {formErrors.videoLinks}
                          </span>
                        )}
                      </div>

                      <div className="form-group col-12 col-lg-6 col-md-6 d-flex flex-column">
                        <label className="control-label">
                          Technology Readiness Level
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control"
                          id="select"
                          name="readiness"
                          value={formdata.readiness}
                          onChange={handleChange}
                        >
                          <option value="">Select Technology Readiness</option>
                          {Technology_Readiness.map((items) => {
                            return (
                              <option key={items._id} value={items}>
                                {items}
                              </option>
                            );
                          })}
                        </select>
                        {formErrors.readiness && (
                          <span className="text-danger">
                            {formErrors.readiness}
                          </span>
                        )}
                      </div>
                      <div className="form-group col-12 col-lg-6 col-md-6">
                        <label className="control-label">
                          Technological Domain / Target Industry Sector for
                          Technology
                          <span className="text-danger">*</span>
                        </label>
                        <Multiselect
                          name="subCategories"
                          showCheckbox={true}
                          options={category.map((item) => ({
                            label: item.name,
                            value: item._id,
                          }))}
                          selectedValues={selectedCategory}
                          onSelect={(selectedList) =>
                            setSelectedCategory(selectedList)
                          }
                          onRemove={(selectedList) =>
                            setSelectedCategory(selectedList)
                          }
                          displayValue="label"
                        />

                        {formErrors.category && (
                          <span className="text-danger">
                            {formErrors.category}
                          </span>
                        )}
                      </div>

                      <div className="form-group col-12 col-lg-6 col-md-6 d-flex flex-column">
                        <label className="control-label">
                          Patent Status<span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control"
                          id="select"
                          name="patentStatus"
                          value={formdata.patentStatus}
                          onChange={handleChange}
                        >
                          <option value="">Select Patent Status</option>
                          {patentData.map((item) => (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                        {formErrors.patentStatus && (
                          <span className="text-danger">
                            {formErrors.patentStatus}
                          </span>
                        )}
                      </div>
                      {![
                        "Application Filed",
                        "Application Published",
                        "Request for Examination Filed",
                        "Patent Examination Report awaiting",
                      ].includes(formdata.patentStatus) &&
                        formdata.patentStatus !== "Non-Patented" &&
                        formdata.patentStatus !==
                          "Patent Application Abandoned" && (
                          <div className="form-group col-12 col-lg-6 col-md-6 d-flex flex-column">
                            <label className="control-label">
                              Patent Number{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              name="patentnumber"
                              placeholder="Patent Number"
                              value={formdata.patentnumber}
                              onChange={handleChange}
                            />
                            {formErrors.patentnumber && (
                              <span className="text-danger">
                                {formErrors.patentnumber}
                              </span>
                            )}
                          </div>
                        )}

                      {![
                        "Non-Patented",
                        "Patent Application Abandoned",
                      ].includes(formdata.patentStatus) && (
                        <div className="form-group col-12 col-lg-6 col-md-6 d-flex flex-column">
                          <label className="control-label">
                            Patent Application Number
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="patentApplicationNumber"
                            placeholder="Patent Application Number"
                            value={formdata.patentApplicationNumber}
                            onChange={handleChange}
                          />
                          {formErrors.patentApplicationNumber && (
                            <span className="text-danger">
                              {formErrors.patentApplicationNumber}
                            </span>
                          )}
                        </div>
                      )}
                      <div className="form-group col-12 col-lg-6 col-md-6 d-flex flex-column">
                        <label className="control-label">
                          Do you want to do bid
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control"
                          id="select"
                          value={formdata.bidStatus}
                          onChange={handleBidStatusChange}
                          name="bidStatus"
                        >
                          <option value="">Select Option</option>
                          <option value="true">Yes</option>
                          <option value="false">No</option>
                        </select>
                        {formErrors.bidStatus && (
                          <span className="text-danger">
                            {formErrors.bidStatus}
                          </span>
                        )}
                      </div>

                      {formdata.bidStatus === true && (
                        <>
                          <div className="form-group col-12 col-lg-6 col-md-6 d-flex flex-column">
                            <label className="control-label">
                              Bid Start Date{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              name="bidStartDate"
                              placeholder="Start date"
                              value={formdata.bidStartDate}
                              onChange={handleChange}
                              min={getCurrentDate()}
                            />
                            {formErrors.bidStartDate && (
                              <span className="text-danger">
                                {formErrors.bidStartDate}
                              </span>
                            )}
                          </div>

                          <div className="form-group col-12 col-lg-6 col-md-6 d-flex flex-column">
                            <label className="control-label">
                              Bid End Date{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <input
                              type="date"
                              className="form-control"
                              name="bidEndDate"
                              placeholder="End date"
                              min={formdata.bidStartDate}
                              value={
                                formdata.bidEndDate
                                  ? formdata.bidEndDate.split("T")[0]
                                  : ""
                              }
                              onChange={(e) => formdata.bidStartDate ? handleChange(e): alert('Pls Select Start Date first!')}
                            />

                            {formErrors.bidEndDate && (
                              <span className="text-danger">
                                {formErrors.bidEndDate}
                              </span>
                            )}
                          </div>
                        </>
                      )}
                      <div className="col-md-12 d-flex justify-content-center">
                      
                        <>
                          <button
                            className="btn-submit creategreen"
                            type="submit"
                          >
                            {" "}
                            Create{" "}
                          </button>
                          <button
                            className="btn-submit backtored"
                            type="button"
                            onClick={() =>
                              navigate("/user/dashboard-tech-transfer-listing")
                            }
                          >
                            Back
                          </button>
                        </>
                        {/* )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
      />
    </>
  );
}

export default ExpertiseCreateListing;
