import React, { useState, useEffect, useRef } from "react";
import AsideDashboard from "./aside";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { AgGridReact } from "ag-grid-react";
import axiosInstance from "../../../axiosInstance";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
function BidListing() {
  const [userId, setUserId] = useState(null);
  const gridRef = useRef(null);
  const tokenId = localStorage.getItem("bidID");
  const [filteredTechList, setFilteredTechList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(
          `/technologyTransfers/getAllTechnologyBid/${tokenId}`
        );
        setFilteredTechList(response.data.data);
        const data = response.data.data;
        const userIds = data.map((entry) => entry.userId);

        const userId = userIds[0];
        setUserId(userId);
        console.log("---", userId);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [tokenId]);

  const limit = 10;
  const columnDefs = [
    {
      headerName: "S.No.",
      valueGetter: (params) => params.node.rowIndex + 1 + ".",
      sortable: true,
      filter: true,
      width: 110,
    },
    {
      headerName: "Order Id",
      field: "_id",
      width: 220,
    },
    {
      headerName: "Bid Amount",
      field: "bidAmount",
      sortable: true,
      filter: true,
      width: 150,
    },
    {
      headerName: "Bid Date",
      width: 150,
      cellRenderer: (row) => {
        return (<>{moment(row.data.createdAt).format("DD/MM/YYYY")}</>)
      }
    },
    {
      headerName: "Status",
      field: "selected",
      cellRenderer: (params) => {
        return params.value ? "Approved" : "Pending";
      },
      sortable: true,
      filter: true,
      width: 150,
    },
    {
      headerName: "Actions",
      cellRenderer: (row) => (
        <>
          <button
            className="edit-button pointer"
            onClick={() => {
              setShowModal(true);
              localStorage.setItem("bid", row.data._id);
            }}
          >
            <i className="fa fa-pencil"></i>
          </button>
        </>
      ),
      sortable: false,
      filter: false,
      width: 150,
    },
  ];

  const handleSelectionChanged = () => {
    const selectedNodes = gridRef.current.api.getSelectedNodes();
    setSelectedRows(selectedNodes);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleSubmit = async () => {
    try {
      const payload = {
        userId: userId,
        technologyTransferId: tokenId,
        selected: true,
      };

      const response = await axiosInstance.put(
        `/technologyTransfers/updateTechnologyBid`,
        payload
      );

      if (response.status === 200) {
        if (response.data && response.data.status === true) {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      } else {
        toast.error("Error occurred while processing the request");
      }

      setShowModal(false);
    } catch (error) {
      console.error("Error submitting:", error);
    }
  };

  return (
    <>
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="#" className=" is-active">
                  List your Bid
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="main-sidenavigation pad-sec-50">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-3">
              <aside>
                <AsideDashboard />
              </aside>
            </div>

            <div className="col-md-9">
              <div className="top-heade-cretebtn d-flex justify-content-between align-items-center mb-3">
                <h4>List your Bid</h4>
                <a href="/user/dashboard-expertice-create-listing">
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </a>
              </div>
              <section className="stautus-chek-bid">
                <div className="table-responsive">
                  <div
                    className="ag-theme-alpine"
                    style={{ height: "100%", width: "100%" }}
                  >
                    <AgGridReact
                      ref={gridRef}
                      rowData={filteredTechList}
                      columnDefs={columnDefs}
                      domLayout="autoHeight"
                      enableSorting={true}
                      enableFilter={true}
                      pagination={true}
                      paginationPageSize={limit}
                      onSelectionChanged={handleSelectionChanged}
                    />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure you want to approve this </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <b> Listing Id:</b> {tokenId}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            No
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
}

export default BidListing;
