import React, { useEffect, useState } from "react";

import axiosInstance from "../../../axiosInstance";
function CommunityDetail() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getData = async () => {
      const communitySection_id = localStorage.getItem("communitySection_id");
      const response = await axiosInstance.get(
        `/communitySections/getCommunitySectionById/${communitySection_id}`
      );

      setData(response.data);
      setLoading(false);
    };
    getData();

    setTimeout(() => {
      setLoading(false);
    }, 10000);
  }, []);
  return (
    <>
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className=" is-active">
                  Community Chat Details
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>
      {/* ================================ */}
      <section className="community-detail-chat-m pad-sec-30">
        <div className="container">
          <div class="main-heading-for-internalpage position-relative">
            <h2>Community Chat Details</h2>
          </div>
          <div className="row">
            <div className="col-lg-7 col-md-5">
              <div className="detail-chat">
                {data && data.title && <h2>{data.title}</h2>}

                {data && data.description && <p>{data.description}</p>}
                <span className="mb-5 border p-2">
                  <i class="fa fa-user" aria-hidden="true"></i> 40 Members |
                  Main discussion about Work and Our life
                </span>
              </div>
            </div>
            <div className="col-lg-5 col-md-5 mx-auto ">
              <div className="w-75 mx-auto">
                {data && data.image && (
                  <img src={data.image} className="w-100  rounded" alt="" />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CommunityDetail;
