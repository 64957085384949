import React, { useState } from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import axiosInstance from "../../../axiosInstance";
import { toast, ToastContainer } from "react-toastify";
import "./bid.css";
function ModalBidding({ show, onClose, dataid }) {
  const [bidAmount, setBidAmount] = useState("");
  const [userId, setUserId] = useState(localStorage.getItem("userId"));
  const technologyTransferId = localStorage.getItem("techTokenID");

  const handleBidAmountChange = (e) => {
    setBidAmount(e.target.value);
  };

  const handleSubmit = async () => {
   
    if (!userId) {
      toast.error("Please login to place a bid.");
      return;
    }

    const currentDate = new Date();
    const payload = {
      userId: userId,
      industrialProblemId: dataid,
      bidAmount: bidAmount,
      date: currentDate,
    };
    const response = await axiosInstance.post(
      "/industrialProblems/addIndustrialProblemBid",
      payload
    );
    const {status, message} = response.data
    if(status) {
      toast.success(message);
    }else {
      toast.error(message);
    }
    onClose();
  };

  return (
    <>
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Place a Bid</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="bidAmount">Bid Amount:</label>
            <input
              type="number"
              className="form-control"
              id="bidAmount"
              value={bidAmount}
              onChange={handleBidAmountChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="btn btn-danger" onClick={onClose}>
            Close
          </Button>
          <Button variant="primary bid" onClick={handleSubmit}>
            Submit Bid
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </>
  );
}

export default ModalBidding;
