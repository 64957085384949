import React, { useState, useEffect, useCallback, useRef } from "react";
import banner from "../../../../src/assests/images/Funding Schemes.png";
import axiosInstance from "../../../axiosInstance";
// import { ThreeDots } from "react-loader-spinner";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";

function FundingSchemes() {
  const [data, setData] = useState([]);
  const [searchByName, setSearchByName] = useState("");
  const [loading, setLoading] = useState(true);
  const [gridApi, setGridApi] = useState(null);
  const gridRef = useRef(null);
  const paginationPageSize = 20;
  const paginationPageSizeSelector = [200, 500, 1000];
  const [activeSchemes, setActiveSchemes] = useState([]);
  const [expiredSchemes, setExpiredSchemes] = useState([]);
  const [searchByExpiredName, setSearchByExpiredName] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axiosInstance.post("/fundingScheme/getAll", {
          filter: {
            status: true,
          },
        });
        setData(response.data);
        setExpiredSchemes(response.data);

        setActiveSchemes(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    getData();
  }, []);
  const onGridReady = useCallback(
    (params) => {
      setGridApi(params.api);
      if (loading) {
        params.api.showLoadingOverlay();
      } else {
        params.api.showNoRowsOverlay();
      }

      params.api.paginationSetPageSize(pageSize);
    },
    [loading, pageSize]
  );

  useEffect(() => {
    const currentDate = new Date();

    const activeSchemesData = data.filter((item) => {
      const submissionDate = new Date(item.deadline);
      return submissionDate >= currentDate;
    });

    const expiredSchemesData = data.filter((item) => {
      const submissionDate = new Date(item.deadline);
      return submissionDate < currentDate;
    });

    setActiveSchemes(activeSchemesData);
    setExpiredSchemes(expiredSchemesData);
  }, [data]);

  const handleSearchByName = (element) => {
    if (searchByName) {
      return element.title.toLowerCase().includes(searchByName.toLowerCase());
    } else return element;
  };

  const handleSearchByExpiredName = (element) => {
    if (searchByExpiredName) {
      return element.title
        .toLowerCase()
        .includes(searchByExpiredName.toLowerCase());
    } else return element;
  };

  const findFilteredTechList = () => {
    const filteredList = activeSchemes.filter((element) =>
      handleSearchByName(element)
    );
    setActiveSchemes(filteredList);
  };
  const findFilteredExpiredTechList = () => {
    const filteredExpiredList = expiredSchemes.filter((element) =>
      handleSearchByExpiredName(element)
    );
    setExpiredSchemes(filteredExpiredList);
  };

  const resetFilterList = () => {
    const currentDate = new Date();

    const activeSchemesData = data.filter((item) => {
      const submissionDate = new Date(item.deadline);
      return submissionDate >= currentDate;
    });

    setActiveSchemes(
      data.filter((item) => {
        const submissionDate = new Date(item.deadline);
        return submissionDate >= currentDate;
      })
    );

    setSearchByName("");
  };

  const resetFilterExpiredList = () => {
    const currentDate = new Date();

    const expiredSchemesData = data.filter((item) => {
      const submissionDate = new Date(item.deadline);
      return submissionDate >= currentDate;
    });

    setExpiredSchemes(
      data.filter((item) => {
        const submissionDate = new Date(item.deadline);
        return submissionDate < currentDate;
      })
    );

    setSearchByExpiredName("");
  };

  const columnDefs = [
    {
      headerName: "S.No.",
      valueGetter: (params) => params.node.rowIndex + 1 + ".",
      sortable: false,
      filter: true,
      width: 100,
    },

    {
      headerName: "Title",
      field: "title",
      sortable: true,
      filter: true,
      width: 300,
      defaultSort: "desc",
    },

    {
      headerName: "Funding Agency",
      field: "fundingAgency",
      sort: "asc",
      sortable: true,
      filter: true,
      width: 250,
    },
    {
      headerName: "Call Opens on",
      field: "callOpensOn",
      sortable: true,
      filter: true,
      width: 250,
    },
    {
      headerName: "Know",
      field: "link",
      cellRenderer: (params) => (
        <a href={params?.data?.link} target="_blank">
          Click here
        </a>
      ),
      sortable: false,
      filter: true,
      width: 250,
    },

    {
      headerName: "Last Date for Submission",
      field: "deadline",
      valueFormatter: (params) => moment(params.value).format("DD-MM-YYYY"),
      sortable: true,
      filter: true,
      width: 250,
    },
  ];

  const handleActiveTabClick = () => {
    setActiveTab(0);
  };

  const handleExpiredTabClick = () => {
    setActiveTab(1);
  };

  const pageChange = (pageSize) => {
    if (gridApi) {
      gridApi.paginationSetPageSize(pageSize);
    }
  };

  return (
    <>
      {loading && <LoadingOverlay />}

      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className=" is-active">
                  Funding Schemes
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>
      {/* ================================= */}
      <section className="funding-scheme-main pad-sec-30">
        <div className="container">
          {loading ? (
            <div className="loader-container">
              {/* <ThreeDots color="#F23F2C" height={80} width={80} />
              <h2>Please wait while data is loading....</h2> */}
            </div>
          ) : (
            <div className="row">
              <div className="banner-common-to-funding">
                <img
                  src={banner}
                  className="w-100 pucsr-banner mb-3 border"
                  alt="Banner"
                />
              </div>

              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="main-tabingof-allredytaken w-100">
                      <ul
                        className="nav nav-tabing-events d-flex justify-content-center"
                        role="tablist"
                      >
                        <li
                          className={`nav-item button-clik-change ${
                            activeTab === 0 ? "active" : ""
                          }`}
                        >
                          <a
                            href="#active"
                            role="tab"
                            data-toggle="tab"
                            className="nav-link"
                            onClick={handleActiveTabClick}
                          >
                            {" "}
                            Active{" "}
                          </a>
                        </li>
                        <li
                          className={`nav-item button-clik-change ${
                            activeTab === 1 ? "active" : ""
                          }`}
                        >
                          <a
                            href="#expired"
                            role="tab"
                            data-toggle="tab"
                            className="nav-link"
                            onClick={handleExpiredTabClick}
                          >
                            {" "}
                            Expired{" "}
                          </a>
                        </li>
                      </ul>
                      <div className="text-center">
                        {activeTab === 0 ? (
                          <h6>Total Count: {activeSchemes.length}</h6>
                        ) : (
                          <h6>Total Count: {expiredSchemes.length}</h6>
                        )}
                      </div>
                      <div className="tab-content">
                        <div
                          className="tab-pane active"
                          role="tabpanel"
                          id="active"
                        >
                          <div className="col-12 col-lg-12 col-md-12">
                            <div className="form-row">
                              <div className="form-group col-md-4">
                                <input
                                  className="form-control"
                                  type="search"
                                  placeholder="Search by name"
                                  onChange={(e) => {
                                    setSearchByName(e.target.value);
                                  }}
                                  value={searchByName}
                                />
                              </div>
                              <div className="form-group col-md-2">
                                <button
                                  onClick={findFilteredTechList}
                                  type="button"
                                  className="btn btn-submit btn-block btn-theme"
                                >
                                  Search
                                </button>
                              </div>
                              <div className="form-group col-md-2">
                                <button
                                  onClick={resetFilterList}
                                  type="button"
                                  className="btn btn-submit btn-block"
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="table-responsive">
                            <div
                              className="ag-theme-alpine"
                              style={{ height: "100%", width: "100%" }}
                            >
                              <AgGridReact
                                ref={gridRef}
                                rowData={activeSchemes.map((item, index) => ({
                                  ...item,
                                  index,
                                }))}
                                columnDefs={columnDefs}
                                domLayout="autoHeight"
                                enableSorting={true}
                                enableFilter={true}
                                pagination={true}
                                overlayLoadingTemplate={
                                  '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                                }
                                overlayNoRowsTemplate={
                                  "<span>No rows to show</span>"
                                }
                                onGridReady={onGridReady}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" role="tabpanel" id="expired">
                          <div className="col-12 col-lg-12 col-md-12">
                            <div className="form-row">
                              <div className="form-group col-md-4">
                                <input
                                  className="form-control"
                                  type="search"
                                  placeholder="Search by name"
                                  onChange={(e) => {
                                    setSearchByExpiredName(e.target.value);
                                  }}
                                  value={searchByExpiredName}
                                />
                              </div>
                              <div className="form-group col-md-2">
                                <button
                                  onClick={findFilteredExpiredTechList}
                                  type="button"
                                  className="btn btn-submit btn-block btn-theme"
                                >
                                  Search
                                </button>
                              </div>
                              <div className="form-group col-md-2">
                                <button
                                  onClick={resetFilterExpiredList}
                                  type="button"
                                  className="btn btn-submit btn-block"
                                >
                                  Reset
                                </button>
                              </div>
                            </div>
                          </div>
                          <div className="table-responsive">
                            <div
                              className="ag-theme-alpine"
                              style={{ height: "100%", width: "100%" }}
                            >
                              <AgGridReact
                                ref={gridRef}
                                rowData={expiredSchemes.map((item, index) => ({
                                  ...item,
                                  index,
                                }))}
                                columnDefs={columnDefs}
                                domLayout="autoHeight"
                                enableSorting={true}
                                enableFilter={true}
                                pagination={true}
                                paginationPageSize={pageSize}
                                overlayLoadingTemplate={
                                  '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                                }
                                overlayNoRowsTemplate="<span>No rows to show</span>"
                                onGridReady={onGridReady}
                              />
                              <select
                                onChange={(e) => pageChange(e.target.value)}
                              >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default FundingSchemes;
