import React, { useState, useEffect } from "react";
import axiosInstance from "../../../axiosInstance";
// import { ThreeDots } from 'react-loader-spinner';
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";
import "./index.css"
function PrivacyPolicy() {
  const [policyData, setPolicyData] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getPolicyData = async () => {
      try {
        const response = await axiosInstance.get("/settings/getPrivacyPolicy");
        setPolicyData(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    getPolicyData();
  }, []);
  return (
    <>
      {loading && <LoadingOverlay />}
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className=" is-active">
                  Privacy Policy
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="privacy-policy-main pad-sec-30">
        <div className="container">
          <div className="main-heading-for-internalpage position-relative">
            <h2>Privacy Policy</h2>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="content-privacy-policy">
                {loading ? (
                  <div className="loader-container">
                    {/* <ThreeDots color="#F23F2C" height={80} width={80} />
                    <h2>Please wait while data is loading....</h2> */}
                  </div>
                ) : (
                  <>
                    {policyData ? (
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: policyData.description,
                          }}
                        />
                      </>
                    ) : (
                      <p>No record found.</p>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PrivacyPolicy;
