import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import "./community_style.css";
import axiosInstance from "../../../axiosInstance";
import userchat from "../../../assests/user/discussions.jpg";
import moment from "moment/moment";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import { Oval } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";
import "./index.css";

function CommunityChatDetails() {
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [loadingdata, setLoadingdata] = useState(false);
  const [searchByName, setSearchByName] = useState("");
  const [communitySectionData, setCommunitySectionData] = useState({});
  const [loading, setLoading] = useState(true);
  const [editedComment, setEditedComment] = useState({});
  const [editedReply, setEditedReply] = useState({});
  const [commentReplyInputs, setCommentReplyInputs] = useState({});
  const [myComment, setMyComment] = useState("");
  const token = localStorage.getItem("token");
  const [selectedReplySectionId, setSelectedReplySectionId] = useState(null);

  const [viewReply, setViewReply] = useState([]);

  const getTitle = localStorage.getItem("community_title");
  const [showAllReplies, setShowAllReplies] = useState(false);
  const [filteredTechList, setFilteredTechList] = useState([]);
  const [replyInputs, setReplyInputs] = useState({});
  const [commentRepliesCount, setCommentRepliesCount] = useState({});
  const resetFilterList = () => {
    setFilteredTechList(data.data);
    setSearchByName("");
  };
  useEffect(() => {
    const initialReplyInputs = {};
    data.forEach((comment) => {
      initialReplyInputs[comment._id] = "";
    });
    setReplyInputs(initialReplyInputs);
  }, [data]);
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    const communitySection_id = localStorage.getItem("communitySection_id");
    try {
      const [response, communitySectionResponse] = await Promise.all([
        axiosInstance.get(
          `/communitySections/getAllCommentsByCommunityId/${communitySection_id}`
        ),
        axiosInstance.get(
          `/communitySections/getCommunitySectionById/${communitySection_id}`
        ),
      ]);

      const replyCounts = {};
      const viewReplyPromises = response.data.map(async (comment) => {
        const viewReplyResponse = await axiosInstance.get(
          `/communitySections/getAllReplyByQuestionId/${comment._id}`
        );
        return {
          commentId: comment._id,
          replyCount: viewReplyResponse.data.length,
        };
      });
      const replyCountsArray = await Promise.all(viewReplyPromises);
      replyCountsArray.forEach(({ commentId, replyCount }) => {
        replyCounts[commentId] = replyCount;
      });

      setCommentRepliesCount(replyCounts);
      setData(response.data);
      setCommunitySectionData(communitySectionResponse.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleCommentSubmit = async () => {
    const postComment = {
      comment: myComment,
      userId: localStorage.getItem("userId"),
      communitySectionId: localStorage.getItem("communitySection_id"),
    };
    setMyComment("");
    try {
      const postSubmitResponse = await axiosInstance.post(
        "/communitySections/addComment",
        postComment
      );
      if (postSubmitResponse.status === 200) {
        getData();
        toast.success("Your question is added", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  const findNumberOfMembers = () => {
    const mySet = [];
    let totalMembers = 0;

    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      if (!mySet.includes(element.userId)) {
        totalMembers += 1;
        mySet.push(element.userId);
      }
    }
    return totalMembers;
  };

  const handleViewReply = async (communityCommentedId, element) => {
    if (selectedReplySectionId === communityCommentedId) {
      setSelectedReplySectionId(null);
      setShowAllReplies(false);
    } else {
      const viewReplyResponse = await axiosInstance.get(
        `/communitySections/getAllReplyByQuestionId/${communityCommentedId}`
      );
      const repliesCount = viewReplyResponse.data.length;
      setCommentRepliesCount((prevCounts) => ({
        ...prevCounts,
        [communityCommentedId]: repliesCount,
      }));
      const repliesWithUserEmail = viewReplyResponse.data.map((reply) => ({
        ...reply,
        userEmail: reply?.user?.[0].email,
      }));
      setViewReply(repliesWithUserEmail);
      setSelectedReplySectionId(communityCommentedId);
      setShowAllReplies(false);
    }

    setCommentReplyInputs((prevInputs) => ({
      ...prevInputs,
      [communityCommentedId]: !prevInputs[communityCommentedId],
    }));
  };

  const handleReplySubmit = async (commentId) => {
    if (!token) {
      toast.error("Please Login to add a reply.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });

      navigate("/login");
      return;
    }
    if (token) {
      if (replyInputs[commentId].trim() === "") {
        toast.error("Please fill in the reply field.", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        const element = data.find((item) => item._id === commentId);
        if (element) {
          const replyComment = {
            communityCommentId: commentId,
            userId: localStorage.getItem("userId"),
            reply: replyInputs[commentId],
          };
          setReplyInputs({ ...replyInputs, [commentId]: "" });
          setLoadingdata(true);

          try {
            const postSubmitResponse = await axiosInstance.post(
              "/communitySections/addReply",
              replyComment
            );
            if (postSubmitResponse.status === 200) {
              setViewReply((prevReplies) => [
                ...prevReplies,
                postSubmitResponse.data,
              ]);
              getData();
              toast.success("Your reply is added", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            }
          } catch (error) {
            console.error("Error submitting reply:", error);
            toast.error("Failed to add reply. Please try again.");
          } finally {
            setLoadingdata(false);
          }
        } else {
          toast.error("Failed to add data. Please try again.");
        }
      }
    } else {
    }
  };
  const handleSearchByName = (element) => {
    if (searchByName && element.title) {
      return element.title.toLowerCase().includes(searchByName.toLowerCase());
    } else return true;
  };

  const findFilteredTechList = () => {
    setFilteredTechList(data.filter((element) => handleSearchByName(element)));
  };
  const handleDeleteButtonClick = async (commentId) => {
    try {
      await axiosInstance.put(`communitySections/deleteComment/${commentId}`);
      toast.success("Question deleted successfully!");

      const updatedData = data.filter((item) => item._id !== commentId);
      setData(updatedData);
      setFilteredTechList(updatedData);

      setSearchByName("");
    } catch (error) {
      toast.error("Error deleting data. Please try again later.");
    }
  };

  const handleEdit = (commentId) => {
    const commentToEdit = data.find((comment) => comment._id === commentId);
    if (commentToEdit) {
      setEditedComment({
        ...editedComment,
        [commentId]: commentToEdit.comment,
      });
    }
  };
  const handleUpdate = async (commentId) => {
    try {
      const updatedComment = editedComment[commentId];
      await axiosInstance.put(`/communitySections/updateComment/${commentId}`, {
        comment: updatedComment,
      });
      toast.success("Comment updated successfully!");
      setEditedComment({ ...editedComment, [commentId]: "" });
      getData();
    } catch (error) {
      toast.error("Error updating comment. Please try again later.");
    }
  };
  const handleEditReply = (replyId) => {
    const replyToEdit = viewReply.find((reply) => reply._id === replyId);
    if (replyToEdit) {
      setEditedReply({
        ...editedReply,
        [replyId]: replyToEdit.reply,
      });
    }
  };

  const handleDeleteReplyClick = async (replyId) => {
    try {
      await axiosInstance.put(`communitySections/deleteReply/${replyId}`);
      toast.success("Reply deleted successfully!");

      const updatedReplies = viewReply.filter((reply) => reply._id !== replyId);
      setViewReply(updatedReplies);
    } catch (error) {
      toast.error("Error deleting reply. Please try again later.");
    }
  };
  const handleUpdateReply = async (replyId) => {
    const updatedReply = editedReply[replyId];
    // Update the reply on the server
    try {
      await axiosInstance.put(`/communitySections/updateReply/${replyId}`, {
        reply: updatedReply,
      });
      // Update reply in the local state
      const updatedReplies = viewReply.map((reply) => {
        if (reply._id === replyId) {
          return { ...reply, reply: updatedReply };
        }
        return reply;
      });
      setViewReply(updatedReplies);
      // Reset the editedReply state for this reply
      setEditedReply((prevState) => {
        const newState = { ...prevState };
        delete newState[replyId];
        return newState;
      });
      toast.success("Reply updated successfully!");
    } catch (error) {
      toast.error("Failed to update reply.");
      console.error("Error updating reply:", error);
    }
  };

  return (
    <>
      {loading && <LoadingOverlay />}
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8 col-lg-8 mb-3 ">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  {" "}
                  Home{" "}
                </a>
                <a href="/community" className="breadcrumbs__item">
                  Community Section
                </a>
                <a href="javascript: void(0)" className="is-active">
                  {getTitle}
                </a>
              </nav>
            </div>
            <div className="col-12 col-md-4 col-lg-4">
              <div class="search-barfor-community">
                <input type="text" name="search" placeholder="Search here..." />
                <span class="fontspanabsolute position-absolute">
                  <i class="fa fa-search fasearchhead " aria-hidden="true"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div>
        {loading ? (
          <div className="loader-container">
            {/* <ThreeDots color="#F23F2C" height={80} width={80} /> */}
            {/* <h2>Please wait while data is loading....</h2> */}
          </div>
        ) : (
          <div>
            {/* Modal */}
            <div
              className="modal fade"
              id="QuestionsPost"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalCenterTitle"
              aria-hidden="true"
            >
              <div
                className="modal-dialog modal-dialog-centered"
                role="document"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                      Add Questions +
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="form-group">
                      <label htmlFor="exampleFormControlTextarea1">
                        Questions
                      </label>
                      <textarea
                        className="form-control"
                        id="exampleFormControlTextarea1"
                        rows="3"
                      ></textarea>
                    </div>
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* Chat Section */}
            <section className="chatui-community pad-sec-30">
              <div className="container">
                <div class="main-heading-for-internalpage position-relative">
                  <h2>{communitySectionData.title}</h2>
                </div>

                <div>
            

                  <div className="row">
                    <div className="col-sm-12 mb-4 category-list">
                    <ul class="list-group">
                    <li class="list-group-item active" aria-current="true"> <span> Title:</span>{communitySectionData.title}</li>
                        <li class="list-group-item">   {" "}
                        <span> Category::</span>{" "}
                    {communitySectionData.categoryOfDiscussion
                      ? communitySectionData.categoryOfDiscussion
                      : "Not Present"}</li>
                        <li class="list-group-item"> <span> UserName: </span>{communitySectionData.userId?.firstName}</li>
                        <li class="list-group-item"> <span>  Affiliation :</span>{communitySectionData.userId?.companyName}</li>
              
                      </ul>
                    </div>
                  </div>
              
                </div>
                <div className="row">
                <div className="col-12 col-md-12 col-lg-8">
                    <div className="chatui-main-in border m-auto pt-3 pb-5">
                      <div className="col-12">
                        <div className="col-12">
                          {token ? (
                            <div className="send-btn-message">
                              <div className="form-group d-flex justify-content-between">
                                <input
                                  className="form-control rounded question-enterfields"
                                  id="exampleFormControlTextarea1"
                                  rows="1"
                                  placeholder="Enter a Question"
                                  onChange={(e) => setMyComment(e.target.value)}
                                  value={myComment}
                                />
                                <button
                                  type="submit"
                                  className="btn bt-all"
                                  onClick={handleCommentSubmit}
                                >
                                  Add Question
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div className="send-btn-message">
                              <div className="chat-inner-descri-main pt-3 pb-3 d-flex justify-content-center align-items-start">
                                <p>
                                  <i>
                                    <strong>
                                      You need to be signed in to post comments.
                                    </strong>
                                  </i>
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="chat-out-box-scroll">
                        {data.length > 0 ? (
                          data
                            .slice()
                            .reverse()
                            .map((element) => {
                              const comment = element.comment;
                              const highlightedComment = comment.replace(
                                new RegExp(searchByName, "gi"),
                                (match) =>
                                  `<span style="background-color: yellow;">${match}</span>`
                              );
                              return (
                                <div
                                  className="chat-inner-descri-main pt-3 pb-3 d-flex justify-content-center align-items-start"
                                  key={element._id}
                                >
                                  <div className="col-3 col-md-2 col-lg-1">
                                    <div className="chat-logouser d-flex justify-content-center">
                                      <img src={userchat} alt="User Avatar" />
                                    </div>
                                  </div>
                                  <div className="col-9 col-md-10 col-lg-11">
                                    <div className="user-said-con">
                                      <div className="d-flex align-items-center user-timepost">
                                        <h4 className="mr-2">
                                          {element.user[0].firstName}{" "}
                                          {element.user[0].lastName}
                                        </h4>
                                        <span>
                                          {moment(element.createdAt).format(
                                            "MMMM Do YYYY - h:mm:ss a"
                                          )}
                                        </span>
                                      </div>

                                      <p
                                        className="mb-2 mt-2 query-sec"
                                        dangerouslySetInnerHTML={{
                                          __html: highlightedComment,
                                        }}
                                      ></p>
                                      {editedComment[element._id] && (
                                        <div>
                                          <input
                                            type="text"
                                            value={editedComment[element._id]}
                                            onChange={(e) =>
                                              setEditedComment({
                                                ...editedComment,
                                                [element._id]: e.target.value,
                                              })
                                            }
                                          />
                                          <button
                                            onClick={() =>
                                              handleUpdate(element._id)
                                            }
                                          >
                                            Update
                                          </button>
                                        </div>
                                      )}

                                      <i
                                        className="fa fa-pencil"
                                        onClick={() => handleEdit(element._id)}
                                      ></i>

                                      <i
                                        className="fa fa-trash"
                                        onClick={() =>
                                          handleDeleteButtonClick(element._id)
                                        }
                                      ></i>
                                      <div className="">
                                        <div className="d-flex align-items-center justify-content-between">
                                          <button
                                            type="submit"
                                            className="btn-view-reply-chat"
                                            onClick={() => {
                                              handleViewReply(element._id);
                                            }}
                                          >
                                            <i
                                              class="fa fa-comment pr-1"
                                              aria-hidden="true"
                                            ></i>
                                            {selectedReplySectionId ===
                                            element._id
                                              ? "Hide Reply"
                                              : "View Reply"}
                                          </button>
                                          <p className="mb-2">
                                            No of replies:{" "}
                                            {commentRepliesCount[element._id]}
                                          </p>
                                        </div>

                                        <div className="">
                                          {selectedReplySectionId ===
                                            element._id && (
                                            <div className="replies-container">
                                              {viewReply
                                                .slice(
                                                  0,
                                                  showAllReplies
                                                    ? viewReply.length
                                                    : 3
                                                )
                                                .map((reply) => (
                                                  <div key={reply._id}>
                                                    {editedReply[reply._id] ? (
                                                      <>
                                                        <input
                                                          type="text"
                                                          value={
                                                            editedReply[
                                                              reply._id
                                                            ]
                                                          }
                                                          onChange={(e) =>
                                                            setEditedReply({
                                                              ...editedReply,
                                                              [reply._id]:
                                                                e.target.value,
                                                            })
                                                          }
                                                        />
                                                        <button
                                                          onClick={() =>
                                                            handleUpdateReply(
                                                              reply._id
                                                            )
                                                          }
                                                        >
                                                          Save
                                                        </button>
                                                        <button
                                                          onClick={() =>
                                                            setEditedReply({
                                                              ...editedReply,
                                                              [reply._id]:
                                                                undefined,
                                                            })
                                                          }
                                                        >
                                                          Cancel
                                                        </button>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <div className="reply-box-innerqu">
                                                          <p>{reply.reply}</p>
                                                          <p>
                                                            {" "}
                                                            <strong>
                                                              Email :
                                                            </strong>{" "}
                                                            {
                                                              reply?.user?.[0]
                                                                .email
                                                            }{" "}
                                                          </p>
                                                          <span style={{}}>
                                                            {moment(
                                                              reply.createdAt
                                                            ).format(
                                                              "MMMM Do YYYY, h:mm:ss a"
                                                            )}{" "}
                                                          </span>
                                                          <i
                                                            className="fa fa-pencil"
                                                            onClick={() =>
                                                              handleEditReply(
                                                                reply._id
                                                              )
                                                            }
                                                          ></i>
                                                          <i
                                                            className="fa fa-trash"
                                                            onClick={() =>
                                                              handleDeleteReplyClick(
                                                                reply._id
                                                              )
                                                            }
                                                          ></i>
                                                        </div>
                                                      </>
                                                    )}
                                                  </div>
                                                ))}
                                              {viewReply.length > 3 && (
                                                <button
                                                  onClick={() =>
                                                    setShowAllReplies(
                                                      !showAllReplies
                                                    )
                                                  }
                                                >
                                                  {showAllReplies
                                                    ? "Show Less"
                                                    : "Show More"}
                                                </button>
                                              )}
                                            </div>
                                          )}

                                          {token &&
                                          commentReplyInputs[element._id] ? (
                                            <div className="reply-input d-flex align-items-center">
                                              <div className="userreplyimg col-md-1">
                                                <img
                                                  src={userchat}
                                                  alt="User Avatar"
                                                />
                                              </div>
                                              <div className="col-md-8">
                                                <input
                                                  className="form-control rounded"
                                                  type="text"
                                                  placeholder={`Reply to ${element.user[0].firstName}'s comment`}
                                                  rows="1"
                                                  value={
                                                    replyInputs[element._id]
                                                  }
                                                  onChange={(e) =>
                                                    setReplyInputs({
                                                      ...replyInputs,
                                                      [element._id]:
                                                        e.target.value,
                                                    })
                                                  }
                                                />
                                              </div>
                                              <div className="col-md-3">
                                                {loadingdata ? (
                                                  <div className="spinner">
                                                    {/* <Oval
                                                      color="#00BFFF"
                                                      height={30}
                                                      width={30}
                                                    /> */}
                                                  </div>
                                                ) : (
                                                  <>
                                                    <button
                                                      type="button"
                                                      className="btn bt-all"
                                                      onClick={() =>
                                                        handleReplySubmit(
                                                          element._id
                                                        )
                                                      }
                                                    >
                                                      Add Reply
                                                    </button>
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="reply-input d-flex align-items-center">
                                              <div className="userreplyimg col-md-1">
                                                <img
                                                  src={userchat}
                                                  alt="User Avatar"
                                                />
                                              </div>
                                              <div className="col-md-8">
                                                <input
                                                  className="form-control rounded"
                                                  type="text"
                                                  placeholder={`Reply to ${element.user[0].firstName}'s comment`}
                                                  rows="1"
                                                  value={
                                                    replyInputs[element._id]
                                                  }
                                                  onChange={(e) =>
                                                    setReplyInputs({
                                                      ...replyInputs,
                                                      [element._id]:
                                                        e.target.value,
                                                    })
                                                  }
                                                />
                                              </div>
                                              <div className="col-md-3">
                                                {loadingdata ? (
                                                  <div className="spinner">
                                                    {/* <Oval
                                                      color="#00BFFF"
                                                      height={30}
                                                      width={30} */}
                                                    {/* /> */}
                                                  </div>
                                                ) : (
                                                  <>
                                                    <button
                                                      type="button"
                                                      className="btn bt-all"
                                                      onClick={() =>
                                                        handleReplySubmit(
                                                          element._id
                                                        )
                                                      }
                                                    >
                                                      Add Reply
                                                    </button>
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                        ) : (
                          <div className="chat-inner-descri-main pt-3 pb-3 d-flex justify-content-center align-items-start">
                            <p>No discussions yet. Send a new message.</p>
                          </div>
                        )}
                      </div>

                      {/* Comment Input */}
                    </div>
                  </div>

                  {/* Related Questions */}
                  <div className="col-12 col-md-12 col-lg-4">
                    <div className="related-questioui">
                      <h3>
                        <i
                          className="fa fa-question-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Related questions
                      </h3>
                      <div className="white-box-related-items border rounded p-3 chatui-main-in scroll-auto-related-que">
                        <ul>
                          {data.length > 0 ? (
                            data
                              .slice(-5, data.length)
                              .reverse()
                              .map((element) => (
                                <div
                                  className="chat-inner-descri-main pt-3 pb-3 d-flex justify-content-center align-items-start"
                                  key={element.id}
                                >
                                  <div className="col-2">
                                    <div className="chat-logouser d-flex justify-content-left">
                                      <img src={userchat} alt="User Avatar" />
                                    </div>
                                  </div>
                                  <div className="col-10">
                                    <div className="user-said-con">
                                      <div>
                                        <p>
                                          <strong>
                                            {element.comment.slice(0, 20)}
                                            <span> ...</span>
                                          </strong>
                                        </p>

                                        <span
                                          style={{
                                            marginLeft: "5px",
                                            marginTop: "-5px",
                                          }}
                                        >
                                          {moment(element.createdAt).format(
                                            "MMMM Do YYYY, h:mm:ss a"
                                          )}
                                        </span>
                                      </div>
                                      <h6 style={{ margin: "0" }}>
                                        <strong>
                                          {element.user[0].firstName}{" "}
                                          {element.user[0].lastName}
                                        </strong>
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              ))
                          ) : (
                            <div className="chat-inner-descri-main pt-3 pb-3 d-flex justify-content-center align-items-start">
                              <p>No related questions</p>
                            </div>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  );
}

export default CommunityChatDetails;
