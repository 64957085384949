import React, { useState, useEffect, useCallback, useRef } from "react";
import axiosInstance from "../../../axiosInstance";
// import { ThreeDots } from "react-loader-spinner";
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";
import moment from "moment";
import blankImage from "../../../assests/blankImage.jpg";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";


function Technology() {
  const navigate = useNavigate();
  const [tecdata, setTecData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [categorylist, setCategoryList] = useState([]);
  const [patentData, setPatentData] = useState([]);
  const [searchByEOIName, setSearchByEOIName] = useState("");
  const [searchByPatentName, setSearchByPatentName] = useState("");
  const [searchByName, setSearchByName] = useState("");
  const [filteredTechList, setFilteredTechList] = useState([]);
  const [filteredTecEOIhList, setFilteredEOITechList] = useState([]);
  const [filteredTecPatentList, setFilteredPatentTechList] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [gridApi, setGridApi] = useState(null);
  const gridRef = useRef(null);

  useEffect(() => {
    const getCategoryData = async () => {
      try {
        const response = await axiosInstance.get(
          "/subCategories/getAllSubCategoryByCategoryId/64d9d3911cae26c449f95fa4"
        );
        if (response.data && Array.isArray(response.data.data)) {
          setCategoryList(response.data.data);
          setLoading(false);
          setItemsPerPage(10);
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    getCategoryData();
  }, []);

  const columnDefs = [
    {
      headerName: "S.No.",
      valueGetter: (params) => params.node.rowIndex + 1 + ".",
      sortable: false,
      filter: true,
      width: 120,
    },
    {
      headerName: "Title",
      field: "title",
      sortable: true,
      filter: true,
      width: 100,
    },
    {
      headerName: "Inventors",
      field: "inventorName",
      sortable: true,
      filter: true,
      width: 150,
    },
    {
      headerName: "Assignee Name",
      field: "assigneeName",
      sortable: true,
      filter: true,
      width: 150,
    },
    {
      headerName: "Patent Status",
      field: "patentStatus",
      sortable: true,
      filter: true,
      width: 200,
    },
    {
      headerName: "Patent Grant Number",
      field: "patentApplicationNumber",
      sortable: true,
      filter: true,
      width: 200,
    },
    {
      headerName: "Technological Domain",
      field: "technologicalDomain",
      sortable: true,
      filter: true,
      width: 670,
      cellRenderer: (params) => {
        if (Array.isArray(params.value)) {
          const labels = params.value.map((item) => item.name).join(", ");

          return labels;
        }

        return "";
      },
    },

    {
      headerName: "TRL",
      field: "technologyReadinessLevel",
      sortable: true,
      filter: true,
      width: 270,
    },
    {
      headerName: "ToT ",
      field: "totStatus",
      sortable: true,
      filter: true,
      width: 270,
    },
    {
      headerName: "Commercialization  ",
      field: "commercializationStatus",
      sortable: true,
      filter: true,
      width: 270,
    },
    {
      headerName: "Date  ",
      field: "filedOnDate",
      sortable: true,
      filter: true,
      valueFormatter: (params) => moment(params.value).format("DD/MMM/YYYY"),
      width: 270,
    },
    // {
    //   headerName: "Date   ",
    //   field: "grantedOnDate",
    //   sortable: true,
    //   filter: true,
    //   width: 270,
    // },
  ];
  const columnsEOI = [
    {
      headerName: "S.No.",
      valueGetter: (params) => params.node.rowIndex + 1 + ".",
      sortable: true,
      filter: true,
      width: 190,
    },
    {
      headerName: "Title",
      field: "title",
      sortable: true,
      filter: true,
      minWidth: 200,
      width: 'auto',
      flex: 2,
    },
    {
      headerName: "Documents",
      cellRenderer: (params) =>
        params?.data?.documents && params.data.documents.length > 0
          ? params.data.documents.map((document, index) => (
              <span key={index}>
                <a href={document} target="_blank" rel="noreferrer">
                  Click here
                </a>
                {index < params.data.documents.length - 1 ? " | " : ""}
              </span>
            ))
          : "No Documents",
      sortable: true,
      filter: true,
      width: 400,
    },
    {
      headerName: "Date",
      field: "achievementDate",
      valueFormatter: (params) => moment(params.value).format("DD/MMM/YYYY"),
      sortable: true,
      filter: true,
      width: 300,
    },
  ];

  const columnsTecMinded = [
    {
      headerName: "S.No.",
      valueGetter: (params) => params.node.rowIndex + 1 + ".",
      sortable: true,
      filter: true,
      width: 190,
      // headerStyle: { textAlign: 'center' },
      // cellStyle: { textAlign: 'center' },
    },
    {
      headerName: "Title",
      field: "title",
      sortable: true,
      filter: true,
      width: 500,
      // headerStyle: { textAlign: 'center' },
      // cellStyle: { textAlign: 'center' },
    },
    {
      headerName: "Patent Status",
      field: "patentStatus",
      sortable: true,
      filter: true,
      width: 350,
      // headerStyle: { textAlign: 'center' },
      // cellStyle: { textAlign: 'center' },
    },
    {
      headerName: "Date",
      field: "createdAt",
      valueFormatter: (params) => moment(params.value).format("DD/MM/YYYY"),
      sortable: true,
      filter: true,
      width: 300,
      // headerStyle: { textAlign: 'center' },
      // cellStyle: { textAlign: 'center' },
    },
  ];

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await axiosInstance.get(
          `/technologyTransfers/getAllTechnologyTransfer?status=Approved`
        );
        setTecData(response.data.data.items);
        setFilteredTechList(response.data.data.items);
        setFilteredEOITechList(response.data.data.items);
        setLoading(false);
        setItemsPerPage(10);
      } catch (error) {
        setLoading(false);
      }
    };
    getData();

    setTimeout(() => {
      setLoading(false);
    }, 10000);
  }, []);

  useEffect(() => {
    const getPatentData = async () => {
      try {
        const response = await axiosInstance.get(
          `/patentOfPU/getAllPatentOfPU`
        );
        setPatentData(response.data.data);
        setFilteredPatentTechList(response.data.data);

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    getPatentData();

    setTimeout(() => {
      setLoading(false);
    }, 10000);
  }, []);
  const handleSubCategoryTecClick = (categoryId, categoryName) => {
    sessionStorage.setItem("TecsubcaretogoryTokenID", categoryId);
    sessionStorage.setItem("TecsubcaretogoryName", categoryName);

    navigate("/tec-category-listing");
  };

  const onGridReady = useCallback(
    (params) => {
      setGridApi(params.api);
      if (loading) {
        params.api.showLoadingOverlay();
      } else {
        params.api.showNoRowsOverlay();
      }
    },
    [loading]
  );

  const domain = (params) => {
    if (Array.isArray(params)) {
      const labels = params.map((item) => item.name).join(", ");

      return labels;
    }

    return "";
  }

  const exportPDF = async (e) => {
    e.preventDefault();
    const unit = "pt";
    const size = "A3";
    const orientation = "landscape";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const title = "Patent of PU Report";
    const headers = [
      [
        "S.NO.",
        "Title",
        "Inventors",
        "AssigneeName",
        "PatentStatus",
        "PatentApplicationNumber",
        "TechnologicalDomain",
        "TRL",
        "ToT",
        "Commercialization",
        "Date"
      ],
    ];

    // const response = await axiosInstance.get('/technologyTransfers/getAllPatentOfPU');

    

    const data = filteredTecPatentList.map((elt, i) => [
      i + 1,
      elt?.title,
      elt?.inventorName,
      elt?.assigneeName,
      elt?.patentStatus,
      elt?.patentApplicationNumber,
      domain(elt?.technologicalDomain),
      elt?.technologyReadinessLevel,
      elt?.totStatus,
      elt?.commercializationStatus,
      moment(elt?.filedOnDate).format("DD/MMM/YYYY"),
      // elt?.grantedOnDate,
    ]);
    let content = {
      startY: 50,
      head: headers,
      body: data,
      styles: {
        cell: { textAlign: "right" },
      },
    };


    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("patentOfPU.pdf");
  };

  const exportTechnologyPDF = async () => {
    const unit = "pt";
    const customWidth = 800;
    const customHeight = 1000;
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, [customWidth, customHeight]);

    doc.setFontSize(15);

    const title = "Technologies Report";
    const headers = [
      [
        "S.NO.",
        "Name Prefix",
        "Inventors",
        "Organization",
        "Title of technology /invention",
        "Technology readiness level",
        "Technological Domain / Target IndustrySector for Technology",
        "Patented Filed (YES/NO)",
        "Source of Information",
      ],
    ];

    // const responses = await axiosInstance.get(`/technologyTransfers/getAllTechnologyTransfer?page=1&limit=${LIMIT}`);
    const techTransferData = filteredTechList;

    const mindData = techTransferData?.map((elt, i) => [
      i + 1,
      elt.user[0].firstName,
      elt.user[0].userName,
      elt?.user[0].additionalInfo.organization,
      elt?.title,
      elt?.technologyReadinessLevel,
      elt?.technologicalDomain,
      elt?.patentApplicationNumber ? "YES" : "NO",
      "Inventor approached TEC",
    ]);

    let content = {
      startY: 100,
      head: headers,
      body: mindData,
      columnStyles: {
        4: { columnWidth: "auto" },
        5: { columnWidth: "auto" },
        6: { columnWidth: "auto" },
      },
      styles: {
        cell: {
          textAlign: "center",
          fontSize: 10,
          valign: "middle",
          cellWidth: "wrap",
        },
        head: {
          fontWeight: "bold",
          fontSize: 12,
          cellWidth: "wrap",
        },
      },
      headStyles: {
        fillColor: ["#003566"],
      },
      theme: "grid",
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("technologies.pdf");
  };
  const handleSearchByName = (element) => {
    if (searchByName) {
      return element.title.toLowerCase().includes(searchByName.toLowerCase());
    } else return element;
  };
  const handleSearchByPatentName = (element) => {
    if (searchByPatentName) {
      return element.title
        .toLowerCase()
        .includes(searchByPatentName.toLowerCase());
    } else return element;
  };
  const handleSearchByEOIName = (element) => {
    if (searchByEOIName) {
      return element.title
        .toLowerCase()
        .includes(searchByEOIName.toLowerCase());
    } else return element;
  };
  const findFilteredEOITechList = () => {
    setFilteredEOITechList(
      tecdata.filter((element) => handleSearchByEOIName(element))
    );
  };
  const findFilteredPatentechList = () => {
    setFilteredPatentTechList(
      patentData.filter((element) => handleSearchByPatentName(element))
    );
  };
  const findFilteredTechList = () => {
    setFilteredTechList(
      tecdata.filter((element) => handleSearchByName(element))
    );
  };
  const resetFilterList = () => {
    setFilteredPatentTechList(patentData);
    setSearchByPatentName("");
  };
  const resetFilterEOIList = () => {
    setFilteredEOITechList(tecdata);
    setSearchByEOIName("");
  };
  const resetFilterTechnologyMindedList = () => {
    setFilteredTechList(tecdata);
    setSearchByName("");
  };

  return (
    <>
      {loading && <LoadingOverlay />}
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className=" is-active">
                  {" "}
                  Technology Transfer
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- tech-transefer-code-start --> */}
      <section className="main-sidenavigation tech-transfer-main pad-sec-30 ">
        <div className="main-heading-for-internalpage position-relative">
          <h2>Technology Transfer</h2>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="main-tabingof-allredytaken w-100">
                <ul
                  className="nav nav-tabing-events d-flex justify-content-center mb-4"
                  role="tablist"
                >
                  <li className="nav-item button-clik-change">
                    <a
                      href="#TechnologyTransfer"
                      role="tab"
                      data-toggle="tab"
                      className="nav-link active"
                    >
                      Technologies Ready for Commercialization
                    </a>
                  </li>
                  <li className="nav-item button-clik-change">
                    <a
                      href="#PatentsofPU"
                      role="tab"
                      data-toggle="tab"
                      className="nav-link"
                    >
                      Patents of PU
                    </a>
                  </li>
                  <li className="nav-item button-clik-change">
                    <a
                      href="#eoitbb"
                      role="tab"
                      data-toggle="tab"
                      className="nav-link"
                    >
                      EOI
                    </a>
                  </li>
                  {/* <li className="nav-item button-clik-change">
                    <a
                      href="#TechnologiesReadyforCommercialization"
                      role="tab"
                      data-toggle="tab"
                      className="nav-link"
                    >
                      Technologies mined
                    </a>
                  </li> */}
                </ul>

                <div className="tab-content">
                  <div
                    className="tab-pane active"
                    role="tabpanel"
                    id="TechnologyTransfer"
                  >
                    {loading ? (
                      <div className="loader-container">
                        {/* <ThreeDots color="#F23F2C" height={80} width={80} />
                        <h2>Please wait while data is loading....</h2> */}
                      </div>
                    ) : categorylist.length > 0 ? (
                      <div className="row">
                        {categorylist.map((item) => (
                          <div
                            key={item._id}
                            className="col-lg-3 col-sm-12 col-md-6 mb-2 d-flex  mb-4"
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              handleSubCategoryTecClick(item._id, item.name)
                            }
                          >
                            <div className="card border-0 bg-transparent listdata-shadow rounded-0 mb-lg-0 d-block w-100">
                              <div className="list-image rounded">
                                <img
                                  src={item.image ? item.image : blankImage}
                                  alt={item.title}
                                />
                              </div>
                              <div className="card-main-ex-cont mt-1 p-2">
                                <h5>{item.title}</h5>
                                <p> {item.name}</p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="no-record-found text-center">
                        <p>No matching records found.</p>
                      </div>
                    )}
                  </div>

                  <div className="tab-pane" role="tabpanel" id="PatentsofPU">
                    {/* =================== */}
                    <div className="row">
                      <div className="col-md-12 text-center">
                        <form class="form-inline form-row mb-3 p-3">
                          <div className=" col-md-6 col-sm-12">
                            <input
                              className="form-control mr-sm-2 w-75"
                              type="search"
                              placeholder="Search"
                              aria-label="Search"
                              onChange={(e) =>
                                setSearchByPatentName(e.target.value)
                              }
                              value={searchByPatentName}
                            />
                            <button
                              onClick={findFilteredPatentechList}
                              class="btn btn-theme my-2 my-sm-0"
                              type="button"
                            >
                              Search
                            </button>
                          </div>

                          <div className="form-group col-md-3 col-sm-12">
                            <button
                              onClick={resetFilterList}
                              type="button"
                              className="btn btn-submit btn-block"
                            >
                              Reset
                            </button>
                          </div>
                          <div className="col-md-3 col-sm-12 d-flex justify-content-end">
                            <button
                              onClick={exportPDF}
                              className="btn btn-theme"
                            >
                              <i
                                class="fa fa-file-pdf-o"
                                aria-hidden="true"
                              ></i>{" "}
                              Export PDF
                            </button>
                          </div>
                        </form>

                        <div className="table-responsive">
                          <div
                            className="ag-theme-alpine"
                            style={{ height: "100%", width: "100%" }}
                          >
                            <AgGridReact
                              ref={gridRef}
                              rowData={filteredTecPatentList}
                              columnDefs={columnDefs}
                              domLayout="autoHeight"
                              enableSorting={true}
                              enableFilter={true}
                              pagination={true}
                              paginationPageSize={10}
                              overlayLoadingTemplate={
                                '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                              }
                              overlayNoRowsTemplate={
                                "<span>No rows to show</span>"
                              }
                              onGridReady={onGridReady}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="tab-pane" role="tabpanel" id="eoitbb">
                    {/* =================== */}
                    <div className="row">
                      <div className="col-md-12 text-center ">
                        <form className="form-inline form-row mb-3 p-3">
                          <div className=" col-md-6 col-sm-12">
                            <input
                              class="form-control mr-sm-2 w-75"
                              type="search"
                              placeholder="Search"
                              aria-label="Search"
                              onChange={(e) =>
                                setSearchByEOIName(e.target.value)
                              }
                              value={searchByEOIName}
                            />
                            <button
                              onClick={findFilteredEOITechList}
                              class="btn btn-theme my-2 my-sm-0"
                              type="button"
                            >
                              Search
                            </button>
                          </div>
                          <div className="form-group col-md-2">
                            <button
                              onClick={resetFilterEOIList}
                              type="button"
                              className="btn btn-submit btn-block"
                            >
                              Reset
                            </button>
                          </div>
                        </form>
                        <div className="table-responsive">
                          <div
                            className="ag-theme-alpine"
                            style={{ height: "100%", width: "100%" }}
                          >
                            <AgGridReact
                              ref={gridRef}
                              rowData={filteredTecEOIhList}
                              columnDefs={columnsEOI}
                              domLayout="autoHeight"
                              enableSorting={true}
                              enableFilter={true}
                              pagination={true}
                              paginationPageSize={10}
                              overlayLoadingTemplate={
                                '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                              }
                              overlayNoRowsTemplate={
                                "<span>No rows to show</span>"
                              }
                              onGridReady={onGridReady}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div
                    class="tab-pane "
                    role="tabpanel"
                    id="TechnologiesReadyforCommercialization"
                  >
                  
                    <div class="row ">
                      <div class="col-md-12 text-center">
                        <form class="form-inline form-row mb-3 p-3">
                          <div className=" col-md-6 col-sm-12">
                            <input
                              class="form-control mr-sm-2 w-75"
                              type="search"
                              placeholder="Search"
                              aria-label="Search"
                              onChange={(e) => setSearchByName(e.target.value)}
                              value={searchByName}
                            />
                            <button
                              onClick={findFilteredTechList}
                              class="btn btn-theme my-2 my-sm-0"
                              type="button"
                            >
                              Search
                            </button>
                          </div>
                          <div className="form-group col-md-3 col-sm-12">
                            <button
                              onClick={resetFilterTechnologyMindedList}
                              type="button"
                              className="btn btn-submit btn-block"
                            >
                              Reset
                            </button>
                          </div>
                          <div className="form-group col-md-3 col-sm-12 d-flex justify-content-end">
                            
                            <button
                              onClick={exportTechnologyPDF}
                              className="btn btn-theme"
                            >
                              <i
                                class="fa fa-file-pdf-o"
                                aria-hidden="true"
                              ></i>{" "}
                              Export PDF
                            </button>
                          </div>
                        </form>
                        
                        <div className="table-responsive text-left">
                          <div
                            className="ag-theme-alpine"
                            style={{ height: "100%", width: "100%" }}
                          >
                          
                            <AgGridReact
                              ref={gridRef}
                              rowData={filteredTechList}
                              columnDefs={columnsTecMinded}
                              domLayout="autoHeight"
                              enableSorting={true}
                              enableFilter={true}
                              pagination={true}
                              paginationPageSize={itemsPerPage}
                              overlayLoadingTemplate={
                                '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
                              }
                              overlayNoRowsTemplate={
                                "<span>No rows to show</span>"
                              }
                              onGridReady={onGridReady}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Technology;
