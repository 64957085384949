import AsideDashboard from "./aside";
import React, { useState, useEffect, useRef } from "react";
import axiosInstance from "../../../axiosInstance";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
// import { ThreeDots } from "react-loader-spinner";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { AgGridReact } from "ag-grid-react";
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import moment from "moment";
function TechTransferListing() {
  const Navigate = useNavigate();
  const userid = localStorage.getItem("userId");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const limit = 20;
  const [gridApi, setGridApi] = useState(null);
  const gridRef = useRef(null);

  const indusId = localStorage.getItem("tectransferedit");
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [itemToDeleteId, setItemToDeleteId] = useState("");

  const [searchByName, setSearchByName] = useState("");

  const [filteredTechList, setFilteredTechList] = useState([]);
  const getData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `/technologyTransfers/getTechnlogyTransferByUserId?userId=${userid}`
      );

      const activeData = response.data.data.filter((item) => !item.isDeleted);
      const sortedData = activeData.sort((a, b) => {
        return b._id.localeCompare(a._id);
      });
      console.log('sortedData',sortedData)
      setData(sortedData);
      setLoading(false);
      setFilteredTechList(sortedData);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (gridApi) {
      gridApi.setGridOptions({ suppressPaginationPanel: false });
    }
  }, [gridApi]);

  const handleSearchByName = (element) => {
    if (searchByName) {
      return element.title.toLowerCase().includes(searchByName.toLowerCase());
    } else {
      return true;
    }
  };
  const findFilteredTechList = () => {
    setFilteredTechList(data.filter((element) => handleSearchByName(element)));
  };

  const resetFilterList = () => {
    getData();
    // setFilteredTechList(data.data);
    setSearchByName("");
  };
  useEffect(() => {
    getData();
  }, []);

  const handleDeleteButtonClick = (indusId) => {
    setShowDeleteModal(true);

    setItemToDeleteId(indusId);
  };

  const handleDeleteConfirmed = async () => {
    try {
      setLoading(true);

      await axiosInstance.put(`/technologyTransfers/delete/${itemToDeleteId}`);
      toast.success("Data deleted successfully!");

      setShowDeleteModal(false);

      const updatedData = data.filter((item) => item._id !== itemToDeleteId);
      setData(updatedData);
      setFilteredTechList(updatedData);

      setSearchByName("");
      setLoading(false);
    } catch (error) {
      toast.error("Error deleting data. Please try again later.");
      setLoading(false);
    }
  };
  const handleGeneratePdf = (id) => {
    console.log("generate", id);
    exportPDF(id);
  };

  const exportPDF = async (id) => {
    try {
      const response = await axiosInstance.get(
        `/technologyTransfers/generateEOI/${id}`
      );

      if (response.status === 200) {
        const data = response.data.technologyTransfer;

        const unit = "pt";
        const size = "A4";
        const orientation = "portrait";

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFont("helvetica", "bold");
        doc.setFontSize(20);
        doc.setTextColor("#333");

        doc.text("Technology Transfer Information", marginLeft, 40);

        doc.setFont("helvetica", "normal");
        doc.setFontSize(12);
        doc.setTextColor("#666");

        const {
          title,
          briefNote,
          specifications,
          applicationsOfTechnology,
          subCategories,
          technologyReadinessLevel,
          patentStatus,
          patentNumber,
          patentApplicationNumber,
          royaltyPercentageOfNetSale,
          totFee,
        } = data;

        const removeHTMLTags = (html) => {
          const doc = new DOMParser().parseFromString(html, "text/html");
          return doc.body.textContent || "";
        };
        const content = `
          Title: ${title}
          Brief Note: ${removeHTMLTags(briefNote)}
          Specifications: ${removeHTMLTags(specifications)}
          Applications of Technology: ${removeHTMLTags(
            applicationsOfTechnology
          )}
          Technology Readiness Level: ${removeHTMLTags(
            technologyReadinessLevel
          )}
          Patent Status: ${removeHTMLTags(patentStatus)}
          Patent Number: ${removeHTMLTags(patentNumber)}
          Patent Application Number: ${removeHTMLTags(patentApplicationNumber)}
          Royalty Percentage of Net Sale: ${royaltyPercentageOfNetSale}%
          Total Fee: $${totFee}
          Sub Categories: ${subCategories
            .map((category) => category.name)
            .join(", ")}
        
        `;

        const lines = doc.splitTextToSize(content, size - marginLeft * 2);

        let yPosition = 60;

        lines.forEach((line) => {
          doc.text(line, marginLeft, yPosition);
          yPosition += 15;
        });

        doc.save("technology_transfer_information.pdf");
      } else {
        console.log("Failed to fetch data. Status:", response.status);
      }
    } catch (err) {
      console.log("Error fetching data:", err);
    }
  };

  const handleEdit = (latestAnnouncement_id) => {
    localStorage.setItem("latestteceditt_id", latestAnnouncement_id);
    Navigate("/user/dashboard-tectransfer-edit-form");
  };
const BidListing=(bidID)=>{
  localStorage.setItem("bidID", bidID);
  Navigate('/user/dashboard-bidlist')
}
  const columnDefs = [
    {
      headerName: "S.No.",
      valueGetter: (params) => params.node.rowIndex + 1 + ".",
      sortable: true,
      filter: true,
      width: 90,
    },
    {
      headerName: "Title",
      field: "title",
      sortable: true,
      filter: true,
      width: 150,
    },
    {
      headerName: "Bid Count",
      field: "bidCount",
      width: 100,
    },
    {
      headerName: "Bid Start Date",
      width: 150,
      cellRenderer: (row) => (
        <>
          <span>{moment(row.data.bidStartDate).format("DD/MM/YYYY")}</span>
        </>
      )
    },
    {
      headerName: "Bid End Date",
      width: 150,
      cellRenderer: (row) => (
        <>
          <span>{moment(row.data.bidEndDate).format("DD/MM/YYYY")}</span>
        </>
      )
    },
    {
      headerName: "Patent Status",
      field: "patentStatus",

      sortable: true,
      filter: true,
      width: 150,
    },
    {
      headerName: "Actions",
      width: 350,
      cellRenderer: (row) => (
        <>
          <button
            className="edit-button pointer"
            onClick={() => handleEdit(row.data._id)}
          >
            <i className="fa fa-pencil"></i>
          </button>
          <button
            className="delete-button"
            onClick={() => handleDeleteButtonClick(row.data._id)}
          >
            <i className="fa fa-trash"></i>
          </button>
          {/* <button
            type="button"
            className="btn btnforeoi mb-3"
            onClick={() => handleGeneratePdf(row.data._id)}
          >
            <i class="fa fa-file mr-2" aria-hidden="true"></i>
            EOI
          </button> */}
          <button
            type="button"
            className="btn btnforeoi mb-3"
            onClick={() => BidListing(row.data._id)}
          >
            {/* <i class="fa fa-file mr-2" aria-hidden="true"></i> */}
           Bid List
          </button>
        </>
      ),
      sortable: false,
      filter: false,
      width: 240,
    },
  ];

  return (
    <>
      {loading && <LoadingOverlay />}
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className=" is-active">
                  List your Technology
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="main-sidenavigation pad-sec-50">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-3">
              <aside>
                <AsideDashboard />
              </aside>
            </div>

            <div className="col-md-9">
              <div className="top-heade-cretebtn d-flex justify-content-between align-items-center mb-3">
                <h4>List your Technology</h4>
                <a href="/user/dashboard-expertice-create-listing">
                  <i className="fa fa-plus" aria-hidden="true"></i>
                </a>
              </div>
              <section className="stautus-chek-bid">
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <input
                      className="form-control"
                      type="search"
                      placeholder="Search by name"
                      onChange={(e) => {
                        setSearchByName(e.target.value);
                      }}
                      value={searchByName}
                    />
                  </div>
                  <div className="form-group col-md-2">
                    <button
                      onClick={findFilteredTechList}
                      type="button"
                      className="btn btn-submit btn-block btn-theme"
                    >
                      Search
                    </button>
                  </div>
                  <div className="form-group col-md-2">
                    <button
                      onClick={resetFilterList}
                      type="button"
                      className="btn btn-submit btn-block"
                    >
                      Reset
                    </button>
                  </div>
                </div>
                {/* {loading ? (
                  <div className="loader-container">
                    <ThreeDots color="#F23F2C" height={80} width={80} />
                    <h2>Please wait while data is loading....</h2>
                  </div>
                ) : ( */}
                <div className="table-responsive">
                  <div
                    className="ag-theme-alpine"
                    style={{ height: "100%", width: "100%" }}
                  >
                    <AgGridReact
                      ref={gridRef}
                      rowData={filteredTechList}
                      columnDefs={columnDefs}
                      domLayout="autoHeight"
                      enableSorting={true}
                      enableFilter={true}
                      pagination={true}
                      paginationPageSize={limit}
                    />
                  </div>
                </div>
                {/* )} */}
              </section>
            </div>
          </div>
        </div>
      </section>
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirmed}>
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
      />
    </>
  );
}

export default TechTransferListing;
