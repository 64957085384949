import React, { useState, useEffect } from "react";
import axiosInstance from "../../../axiosInstance";
// import { ThreeDots } from "react-loader-spinner";
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";
import { useNavigate } from "react-router-dom";
import blankImage from "../../../assests/blankImage.jpg";
import Newadded from "../../../assests/images/newadded.png";
import { FaSortAlphaDown, FaSortAlphaDownAlt } from "react-icons/fa";
import "./index.css"

import moment from "moment";
function TecCategory() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [images, setImages] = useState([]);
  const [searchByName, setSearchByName] = useState("");
  const NEW_ITEM_THRESHOLD = 60000;
  const _tecsubcateName = sessionStorage.getItem("TecsubcaretogoryName");
  const _techSubId = sessionStorage.getItem("TecsubcaretogoryTokenID");
  const techSubId = sessionStorage.getItem("TecsubcaretogoryTokenID1");
  const [filteredTechList, setFilteredTechList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedPostName, setSelectedPostName] = useState("");
  const itemsPerPage = 12;
  const handleSearchByName = (element) => {
    const search = searchByName.toLowerCase();
    return !search || element.title.toLowerCase().includes(search);
  };
  
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredTechList.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const [sortingOption, setSortingOption] = useState("title");
  const [sortingDirection, setSortingDirection] = useState("asc");
  const findFilteredTechList = () => {
    const filteredData = data.filter((element) => handleSearchByName(element));
    setTotalCount(filteredData.length);
    setFilteredTechList(filteredData);
    setCurrentPage(1);
  };
  const resetFilterList = () => {
    setFilteredTechList(data);
    setSearchByName("");
    setCurrentPage(1);
    setTotalCount(data.length);
  };
  const featuredTechnologiesHandleClick = (card_id, card_title) => {
    localStorage.setItem("techTokenID", card_id);
    // sessionStorage.setItem("tecdetailedtitle", card_title);
    // setSelectedTechId(card_id);
    navigate("/tech-trensfer-detail-page");
  };
  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const response = await axiosInstance.get(
          `/technologyTransfers/getTechnlogyTransferByCategoryId?categoryId=${_techSubId}&status=Reject`
        );
       
        const now = Date.now();
        if (response.data?.status) {
          const activeItems = response.data.data;
  
       
          const approvedItems = activeItems.filter(
            (item) => item.status !== "Reject"
          );
         
          const newData = approvedItems.map((item) => {
            const createdAt = new Date(item.updatedAt).getTime();

            const isNew = now - createdAt <= NEW_ITEM_THRESHOLD;
            return { ...item, isNew };
          });

         
          newData.sort(
            (a, b) =>
              new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
          );
          // newData.forEach((item) => {
          //   console.log("Item images:", item.images);
          //   if (item.images) {
          //     try {
          //       const imagesJSON = JSON.parse(item.images);
          //       const imageUrls = imagesJSON[0];
          //       console.log("image------",imageUrls)
          //     } catch (error) {
          //       console.error("Error parsing item images:", error);
          //     }
          //   } else {
          //     console.warn("Item images is empty or undefined");
          //   }
          // });
          
          
          setData(newData);
          setFilteredTechList(newData);
          setTotalCount(newData.length);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    };
    getData();
  }, [_techSubId]);

  useEffect(() => {
    const timeouts = data
      .map((item) => {
        if (item.isNew) {
          return setTimeout(() => {
            setData((prevData) =>
              prevData.map((dataItem) => {
                if (dataItem._id === item._id) {
                  return { ...dataItem, isNew: false };
                }
                return dataItem;
              })
            );
          }, NEW_ITEM_THRESHOLD);
        }
        return null;
      })
      .filter(Boolean);

    return () => {
      timeouts.forEach(clearTimeout);
    };
  }, [data]);

  const sortData = () => {
    const sortedData = [...filteredTechList].sort((a, b) => {
      if (sortingOption === "title") {
        const titleA = a.title.toLowerCase();
        const titleB = b.title.toLowerCase();
        return sortingDirection === "asc"
          ? titleA.localeCompare(titleB)
          : titleB.localeCompare(titleA);
      } else if (sortingOption === "date") {
        return sortingDirection === "asc"
          ? moment(a.createdAt).diff(moment(b.createdAt))
          : moment(b.createdAt).diff(moment(a.createdAt));
      }
      return 0;
    });

    setFilteredTechList(sortedData);
    setSortingDirection(sortingDirection === "asc" ? "desc" : "asc");
  };

  return (
    <>
      {loading && <LoadingOverlay />}
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                <a href="/technology" className="breadcrumbs__item">
                  Technology Transfer{" "}
                </a>
                <a href="/tec-category-listing" className="breadcrumbs__item">
                  Technologies Ready for Commercialization{" "}
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="/tech-trensfer-detail-page" className="is-active">
                  {selectedPostName || _tecsubcateName}
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="industrial-listing-page tech-transfer-main pad-sec-50">
        <div className="main-heading-for-internalpage position-relative">
          <h2>{_tecsubcateName}</h2>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 ">
              <div className="form-row d-flex align-items-center">
                <div className="form-group col-md-2">
                  <select
                    className="form-control"
                    value={sortingOption}
                    onChange={(e) => setSortingOption(e.target.value)}
                  >
                    <option value="title">Sort by Title</option>
                    <option value="date">Sort by Date</option>
                  </select>
                </div>

                <div className="form-group col-md-2">
                  <button
                    onClick={sortData}
                    type="button"
                    className="btn btn-submit btn-block"
                  >
                    {sortingDirection === "asc" ? (
                      <FaSortAlphaDown />
                    ) : (
                      <FaSortAlphaDownAlt />
                    )}
                    Sort
                  </button>
                </div>
                <div className="form-group col-md-3">
                  <input
                    className="form-control"
                    type="search"
                    placeholder="Search by name"
                    onChange={(e) => {
                      setSearchByName(e.target.value);
                    }}
                    value={searchByName}
                  />
                </div>
                <div className="form-group col-md-2">
                  <button
                    onClick={findFilteredTechList}
                    type="button"
                    className="btn btn-submit btn-block btn-theme"
                  >
                    Search
                  </button>
                </div>
                <div className="form-group col-md-2">
                  <button
                    onClick={resetFilterList}
                    type="button"
                    className="btn btn-submit btn-block"
                  >
                    Reset
                  </button>
                </div>
                <div className="col-md-5 form-group d-flex justify-content-end">
                  <h5 className="mr-4 text-info">Total Count: {totalCount}</h5>
                </div>
              </div>
              <div className="main-tabingof-allredytaken w-100">
                <div className="tab-content">
                  <div className="tab-pane active" role="tabpanel" id="pending">
                    {loading ? (
                      <div className="loader-container">
                        {/* <ThreeDots color="#F23F2C" height={80} width={80} />
                        <h2>Please wait while data is loading....</h2> */}
                      </div>
                    ) : currentItems && currentItems.length > 0 ? (
                      <div className="row">
                        {currentItems.map((item) =>
                          item.status !== "Reject" ? (
                            <div
                              className="col-lg-3 col-sm-12 col-md-6 mb-4 d-flex align-items-stretch"
                              // onClick={() => changeVisibleMedia()}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                featuredTechnologiesHandleClick(item._id)
                              }
                            >
                              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                              <div className="listing-expertise-box w-100">
                                <div className="card border-0 bg-transparent rounded-0 mb-lg-0 d-block  listdata-shadow ">
                                <div className="list-image rounded">
                                <img
                                      src={
                                        item.images[0]
                                          ? item.images[0]
                                          : blankImage
                                      }
                                      class="card-img-top w-100"
                                      alt="..."
                                    />{" "}
                                  </div>

                                  {item.isNew &&
                                    (item.status === "Pending" ||
                                      item.status === "Approved") &&
                                    moment().diff(
                                      moment(item.updatedAt),
                                      "milliseconds"
                                    ) <= NEW_ITEM_THRESHOLD && (
                                      <img
                                        src={Newadded}
                                        alt="New"
                                        className="position-absolute recentadded-cardtec"
                                      />
                                    )}

                                  <div className="card-main-ex-cont mt-1 p-2">
                                    <h5>{item.title}</h5>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null
                        )}
                      </div>
                    ) : (
                      <div className="no-record-found text-center">
                        <p>No matching records found.</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5 web-page">
            <div className="col-12 col-lg-12">
              <nav aria-label="Page navigation example">
                <ul className="pagination justify-content-center">
                  <li
                    className={`page-item ${
                      currentPage === 1 ? "disabled" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => setCurrentPage(currentPage - 1)}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                  </li>
                  {Array.from({
                    length: Math.ceil(filteredTechList.length / itemsPerPage),
                  }).map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${
                        currentPage === index + 1 ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => setCurrentPage(index + 1)}
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                  <li
                    className={`page-item ${
                      currentPage === Math.ceil(data.length / itemsPerPage)
                        ? "disabled"
                        : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => setCurrentPage(currentPage + 1)}
                      disabled={
                        currentPage === Math.ceil(data.length / itemsPerPage)
                      }
                    >
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default TecCategory;