import React, { useState, useEffect } from "react";
import axiosInstance from "../../../../axiosInstance";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
function Datacenter() {
  let limit = 10;
  const navigate = useNavigate();
  const [tecceneter, setTeccenter] = useState([]);
  const [latestnews, setLatestNews] = useState([]);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getTecCenter = async () => {
      try {
        const response = await axiosInstance.get(
          `/technicalCentres/getAll?page=1&limit=${limit}`
        );
        setTeccenter(response.data.items);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    getTecCenter();
  }, []);

  useEffect(() => {
    const getLatestNewsData = async () => {
      try {
        const response = await axiosInstance.get(
          "/newsAnnouncements/getAllNews"
        );
        setLatestNews(response.data.items);
      } catch (error) {}
    };
    getLatestNewsData();
  }, []);
  const tecCenterHandleClick = (card_id) => {
    localStorage.setItem("TecCenterID", card_id);
    navigate("/data-center-details");
  };
  return (
    <>
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className=" is-active">
                  Technology Enabled centre
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>
      {/* ========================= */}
      <section className="industrial-listing-page tech-transfer-main pad-sec-50">
        <div class="main-heading-for-internalpage position-relative">
          <h2>TEC Centers</h2>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 ">
              <div className="main-tabingof-allredytaken w-100">
                <div className="tab-content">
                  <div className="tab-pane active" role="tabpanel" id="pending">
                    {loading ? (
                      <div className="loader-container">
                        <ThreeDots color="#F23F2C" height={80} width={80} />
                        <h2>Please wait while data is loading....</h2>
                      </div>
                    ) : tecceneter && tecceneter.length > 0 ? (
                      <div className="row">
                        {tecceneter.map((item) => {
                          return (
                            <>
                              <div
                                className="col-lg-3 col-sm-12 col-md- border-end-rgt mb-2"
                                style={{ cursor: "pointer" }}
                                onClick={() => tecCenterHandleClick(item._id)}
                              >
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a
                                  href="javascript: void(0)"
                                  className="listing-expertise-box"
                                >
                                  <div className="card border-0 bg-transparent rounded-0 mb-30 mb-lg-0 d-block ">
                                    <div className="list-image rounded">
                                      <img
                                        src={item.image}
                                        class="card-img-top"
                                        alt="..."
                                      />
                                    </div>
                                    <div className="card-main-ex-cont mt-1">
                                      <h5>{item.nameOfUniversity}</h5>
                                      <p>{item.yearOfEstablishment}</p>
                                      <p>{item.emailOfCoordinator}</p>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    ) : (
                      <div className="no-record-found text-center">
                        <p>No matching records found.</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Datacenter;
