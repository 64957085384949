import React, { useEffect, useState } from "react";
import axiosInstance from "../../../axiosInstance";
import blankImage from "../../../assests/blankImage.jpg";
import ModalBidding from "./bidModal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";
import ReactPlayer from "react-player";
import Slider from "react-slick";
import ReactAudioPlayer from "react-audio-player";
import { bidBtnEnableDisable, calculateTimeRemaning } from "../../../Helper/Helper";
import moment from "moment";
import { toast } from "react-toastify";
function IndustrialDetails() {
  const _techId = localStorage.getItem("IndusTokenID");
  const indusSubcategoryName = localStorage.getItem("indussubcaretogoryName");
  const [data, setData] = useState({});

  const [selectedPostName] = useState("");
  const [showEOIModal, setShowEOIModal] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let intervalId;
    const getData = async () => {
      const response = await axiosInstance.get(
        `/industrialProblems/getIndustrialProblemById/${_techId}`
      );

      setData(response.data.data[0]);

      setLoading(false);
    };
    getData();

    setTimeout(() => {
      setLoading(false);
    }, 10000);
  }, [_techId]);
  const settings_achievemnets = {
    infinite: true,
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1008,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const bidding = (e) => {
    e.preventDefault();

    if (localStorage.getItem("userId") === null) {
      toast.error(`Please Login First!`);
      return false;
    }

    if (localStorage.getItem("userId") === data.userId._id) {
      toast.error(`You can't bid your own bid!`);
      return false;
    }
    setShowEOIModal(true);
  };

  return (
    <>
      {loading && <LoadingOverlay />}
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                <a href="/industrial" className="breadcrumbs__item">
                  Industrial Problem
                </a>
                <a href="/category_listing" className="breadcrumbs__item">
                  {selectedPostName || indusSubcategoryName}
                </a>

                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className="is-active">
                  {data.title}
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>

      {/* ==========industrial details page start====================== */}
      <section className="industrail-details-main pad-sec-50">
        <div className="container">
          {loading ? (
            <div
              className="loader-container"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "200px",
              }}
            ></div>
          ) : (
            <div className="row">
              <div className="col-lg-8">
                <div className="row d-flex justify-content-between">
                {/* <div className="highlightkeys-tech"> */}

                  {/* <div className="total-bid-details">
                    <p className="text-center">
                      {" "}
                      {data && data.technologyReadinessLevel && (
                        <p>
                          <b>Technology Readiness Level :</b>{" "}
                          {data.technologyReadinessLevel}
                        </p>
                      )}{" "}
                    </p>
                  </div> */}

                  {/* <div className="total-bid-details">
                    <p className="text-center">
                      {" "}
                      {data && data.patentStatus && (
                        <p>
                          <b>Patent Status : </b> {data.patentStatus}
                        </p>
                      )}{" "}
                    </p>
                  </div> */}
                  {/* <div className="total-bid-details">
                    <p className="text-center">
                      {" "}
                      {data && data.patentApplicationNumber && (
                        <p>
                          <b>Patent Application Number : </b>{" "}
                          {data?.patentApplicationNumber || "na"}
                        </p>
                      )}{" "}
                    </p>
                  </div> */}

                  {/* <button type="button" class="btn btnforeoi mb-3">
                    <i class="fa fa-file mr-2" aria-hidden="true"></i>
                    EOI
                  </button> */}
                {/* </div> */}

                <div className="total-bid-details mb-3 highlightkeys-tech">
                  <p>
                    {data && data.uniqueId ? (
                      <p>
                        <b>Listing ID: </b> {data.uniqueId}
                      </p>
                    ) : (
                      <p>
                        <b>Listing ID:</b> Not Available
                      </p>
                    )}
                  </p>
                  {/* <p>
                    {data && data.briefNote && (
                      <>
                        <b>
                          Brief Note / Description / Objective of Technology:
                        </b>
                        <p
                          dangerouslySetInnerHTML={{ __html: data.briefNote }}
                        ></p>
                      </>
                    )}
                  </p> */}
                  {/* <p>
                    {data && data.specifications && (
                      <>
                        <b>Specifications / Features of Technology: </b>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: data.specifications,
                          }}
                        ></p>
                      </>
                    )}
                  </p> */}
                  {/* <p>
                    {data && data.applicationsOfTechnology && (
                      <>
                        <b>Applications of Technology: </b>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: data.applicationsOfTechnology,
                          }}
                        ></p>
                      </>
                    )}
                  </p> */}
                  {/* <p>
                    {data && data.tags && (
                      <>
                        <b>Tag As : </b>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: data.tags,
                          }}
                        ></p>
                      </>
                    )}
                  </p> */}
                  <div>
                    <p>
                      <b>Remaining Time: </b>
                      {calculateTimeRemaning(
                        data.bidStartDate,
                        data.bidEndDate
                      )}
                    </p>
                  </div>
                  <div>
                    <p>
                      <b>Bid Start Date: </b>{" "}
                      {moment(data.bidStartDate).format("DD/MM/YYYY")}
                    </p>
                  </div>
                  <div>
                    <p>
                      <b>Bid End Date:</b>{" "}
                      {moment(data.bidEndDate).format("DD/MM/YYYY")}
                    </p>
                  </div>
                  {!bidBtnEnableDisable(
                    data.bidStartDate,
                    data.bidEndDate
                  ) && (
                      <button
                        className="custom-button"
                        onClick={(e) => bidding(e)}
                      >
                        Place a Bid
                      </button>
                    )}

                  {showEOIModal && <ModalBidding show={showEOIModal} onClose={(value) => setShowEOIModal(false)} dataid={data._id} />}
                </div>
                {/* <div className="industrial-details-content-in">
                  <div class="card">
                    <div class="card-header">
                      {" "}
                      {data && data.title && <h2>{data.title}</h2>}
                    </div>
                    <div class="card-body">
                      {" "}
                      {data && data.description && (
                        <p
                          dangerouslySetInnerHTML={{ __html: data.description }}
                        ></p>
                      )}
                      <h6 className="listing-id-ui mt-2 mb-3">
                        {" "}
                        Listing ID:{" "}
                        {data._id ? data._id : "Not Available"}
                      </h6>
                    </div>
                    <div class="card-footer">
                      {" "}
                      <button
                        className="custom-button"
                        onClick={(e) => bidding(e)}
                      >
                        Place a Bid
                      </button>
                    </div>
                  </div>
                  {showEOIModal && <ModalBidding show={showEOIModal} onClose={(value) => setShowEOIModal(false)} />}
                </div> */}
              </div>
              </div>
              <div className="col-md-12 col-lg-4">
                <div className="indsutrial-detail-inner-image">
                  {/* Slider or single image */}
                  {data && data.images && data.images.length > 0 && (
                    <Slider {...settings_achievemnets}>
                      {data.images.map((image, index) => (
                        <div key={index}>
                          <img
                            src={image || blankImage}
                            className="w-100 mb-3 tecctranfermul-image"
                            alt={` ${index + 1}`}
                          />
                        </div>
                      ))}
                    </Slider>
                  )}
                </div>
              </div>
              {data.videos &&
                data.videos.length > 0 &&
                data.videos.map((videoURL, index) => (
                  <div key={index} className="col-md-12 col-lg-4">
                    <div className="industrialprob-videoshow w-100 mt-4 p-3">
                      <ReactPlayer url={videoURL} controls={true} />
                    </div>
                  </div>
                ))}
              {data.audios &&
                data.audios.length > 0 &&
                data.audios.map((videoURL, index) => (
                  <div key={index} className="col-md-4">
                    <div className="industrialprob-videoshow w-100 mt-4">
                      <ReactAudioPlayer src={videoURL} controls={true} />
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default IndustrialDetails;
