import React, { useState, useEffect, useCallback } from "react";
import AsideDashboard from "./aside";
import axiosInstance from "../../../axiosInstance";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../Comman/comman.css";
import LoadingOverlay from "../Comman/comman";
// import { Oval } from "react-loader-spinner";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function CommunitySectiionEdit() {
  const categoryData = [
    { value: "Industrial Problem", label: "Industrial Problem" },
    {
      value: "Solution to a Industrial Problem",
      label: "Solution to a Industrial Problem",
    },
    {
      value: "Novel Technology",
      label: "Novel Technology",
    },
    {
      value: "Research Idea",
      label: "Research Idea",
    },
    {
      value: "Start-up Idea",
      label: "Start-up Idea",
    },
  ];
  const navigate = useNavigate();
  const userIndusId = localStorage.getItem("latestcommunityeditt_id");
  const [loading, setLoading] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [data, setData] = useState({
    category: "",
    title: "",
    image: "",
    videos: [],
    categoryOfDiscussion: "",
    description: "",
    startDate: "",
  });
  const [formErrors, setFormErrors] = useState({
    category: "",
    title: "",
    images: "",

    description: "",
  });
  useEffect(() => {
    fetchData();
  }, []);
  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  function getCurrentDate() {
    const today = new Date();
    let month = String(today.getMonth() + 1);
    let day = String(today.getDate());
    const year = today.getFullYear();

    if (month.length === 1) {
      month = "0" + month;
    }
    if (day.length === 1) {
      day = "0" + day;
    }

    return `${year}-${month}-${day}`;
  }
  const fetchData = async () => {
    try {
      setLoading(true);

      const problemResponse = await axiosInstance.get(
        `/communitySections/getCommunitySectionById/${userIndusId}`
      );

      const preFilledImagePreviews = problemResponse.data.data.image;

      setData({
        ...data,
        title: problemResponse.data.data.title,
        categoryOfDiscussion: problemResponse.data.data.categoryOfDiscussion,
        image: preFilledImagePreviews,
        description: problemResponse.data.data.description,
        startDate: problemResponse.data.data.date,
      });

      setImagePreviews(preFilledImagePreviews);
      setSelectedImages([preFilledImagePreviews]);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  const handleBriefNoteChange = useCallback((value) => {
    setData((prevData) => ({ ...prevData, description: value }));
  }, []);
  const handleImageSelect = async (event) => {
    const imageFiles = event.target.files;
    if (imageFiles.length > 1) {
      toast.error("You can upload only 1 image.");
      return;
    }

    if (imageFiles.length === 0) {
      return;
    }

    try {
      setLoading(true);
      const uploadPromises = Array.from(imageFiles).map(async (file) => {
        if (!file.type.startsWith("image/")) {
          toast.error("Only image files can be uploaded.");
          return null;
        }

        const singleFileData = new FormData();
        singleFileData.append("image", file);

        try {
          setLoading(true);

          const response = await axiosInstance.post(
            "/tecTeams/uploadImage",
            singleFileData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );

          const imageUrl = response.data;
          if (!imageUrl) {
            return null;
          }

          return imageUrl;
          setLoading(false);
        } catch (error) {
          toast.error(`Failed to upload image: ${file.name}`);
          return null;
          setLoading(false);
        }
      });

      const uploadedUrls = (await Promise.all(uploadPromises)).filter(
        (url) => url !== null
      );

      setSelectedImages((prevImages) => [...prevImages, ...uploadedUrls]);
      setLoading(false);
    } catch (error) {
      toast.error("Failed to upload images.");
      setLoading(false);
    }
  };

  const handleRemoveImage = (indexToRemove) => {
    const updatedImages = selectedImages.filter(
      (_, index) => index !== indexToRemove
    );

    setData((prevData) => ({
      ...prevData,
      image: updatedImages,
    }));

    setSelectedImages(updatedImages);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", data.title);

    formData.append("categoryOfDiscussion", data.categoryOfDiscussion);

    formData.append("description", data.description);

    formData.append("images", selectedImages.length < 1 ? JSON.stringify([]): selectedImages );
    formData.append("date", data.startDate);

    try {
      setLoading(true);
      await axiosInstance.put(
        `/communitySections/update/${userIndusId}`,
        formData
      );
      toast.success("Data updated successfully !");
      setTimeout(() => {
        navigate("/user/dashboard-community-section");
      }, 1000);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Failed to submit form. Please try again!");
    }
  };

  return (
    <>
      {loading && <LoadingOverlay />}
      <section className="pagecover">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-12">
              <nav className="breadcrumbs">
                <a href="/" className="breadcrumbs__item">
                  Home
                </a>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="javascript: void(0)" className=" is-active">
                  Community Section Edit
                </a>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="main-sidenavigation pad-sec-50">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-3">
              <aside>
                <AsideDashboard />
              </aside>
            </div>

            <div className="col-12 col-lg-9 main-wrapper-form pt-3 pb-3">
              <div className="deshboard-title">
                <h4> Community Section </h4>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="form-bg-box-inner">
                  <div className="main-form-for-dashboard">
                    <div className="row p-4">
                      <div className="form-group col-12 col-lg-6 col-md-6">
                        <label className="control-label">
                          Title<span className="text-danger">*</span>
                        </label>
                        <input
                          placeholder="Title"
                          className="form-control"
                          name="title"
                          type="text"
                          value={data.title}
                          onChange={(e) =>
                            setData({ ...data, title: e.target.value })
                          }
                        />
                        {formErrors.title && (
                          <span className="text-danger">
                            {formErrors.title}
                          </span>
                        )}
                      </div>
                      <div className="form-group col-12 col-lg-6 col-md-6">
                        <label for="exampleFormControlTextarea1">
                          Description<span className="text-danger">*</span>
                        </label>
                        <ReactQuill
                          value={data.description}
                          onChange={handleBriefNoteChange}
                        />
                        {formErrors.notes && (
                          <span className="text-danger">
                            {formErrors.notes}
                          </span>
                        )}
                      </div>

                      <div className="form-group col-12 col-lg-6 col-md-6 d-flex flex-column">
                        <label>
                          Upload images{" "}
                          <span className="text-danger">
                            (You can select multiple imagess)
                          </span>
                        </label>
                        <input
                          type="file"
                          name="img"
                          required=""
                          accept="images/*"
                          multiple
                          className="form-control"
                          onChange={handleImageSelect}
                        />
                      </div>
                      <div className="col-12 col-md-12">
                        <div className="selected-images">
                          {selectedImages.map((preview, index) => (
                            <div key={index} className="">
                              <img
                                src={preview}
                                alt={` `}
                                className="selected-image"
                                style={{
                                  width: "100px",
                                  height: "100px",
                                  marginBottom: "10px",
                                }}
                              />
                              <button
                                type="button"
                                className="btn-submit backtored"
                                onClick={() => handleRemoveImage(index)}
                              >
                                <i class="fa fa-times" aria-hidden="true"></i>
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="form-group col-12 col-lg-6 col-md-6 d-flex flex-column">
                        <label className="control-label">
                          Category of Discussion
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control"
                          id="select"
                          name="patentStatus"
                          value={data.categoryOfDiscussion}
                          onChange={(e) =>
                            setData({
                              ...data,
                              categoryOfDiscussion: e.target.value,
                            })
                          }
                        >
                          <option value="">Select Patent Status</option>
                          {categoryData.map((item) => (
                            <option key={item.value} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group col-12 col-lg-6 col-md-6 d-flex flex-column">
                        <label className="control-label">
                          Start Date
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          name="startDate"
                          placeholder="Start date"
                          value={
                            data.startDate ? data.startDate.split("T")[0] : ""
                          }
                          onChange={(e) => {
                            const { name, value } = e.target;

                            const isValidDate = /^\d{4}-\d{2}-\d{2}$/.test(
                              value
                            );
                            if (isValidDate) {
                              setData((prevData) => ({
                                ...prevData,
                                [name]: value,
                              }));
                            }
                          }}
                        />

                        {formErrors.bidStartDate && (
                          <span className="text-danger">
                            {formErrors.bidStartDate}
                          </span>
                        )}
                      </div>
                      <div className="col-md-12 d-flex justify-content-center">
                        <>
                          <button
                            className="btn-submit creategreen"
                            type="submit"
                          >
                            Update
                          </button>
                          <button
                            className="btn-submit backtored"
                            type="button"
                            onClick={() =>
                              navigate("/user/dashboard-community-section")
                            }
                          >
                            Back
                          </button>
                        </>
                        {/* )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </>
  );
}

export default CommunitySectiionEdit;
